import React, { useEffect, useState, Fragment } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar, MTableAction, MTableActions } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon'
import ImageIcon from '@material-ui/icons/ImageRounded'
import { fade, makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase'
import { Typography } from '@material-ui/core';
import Sellsy from 'node-sellsy'

//var Sellsy = require('node-sellsy');
var sellsy = new Sellsy({
    creds: {
        consumerKey: '4a9663ec9bdc9d51a5ecb27ad301f76a73e76f00',
        consumerSecret: '0986eed5876f3cfd3c7a769be4b27170f8f780f5',
        userToken: 'bf6af015de7da545512b7f3b3a34700cb667d30a',
        userSecret: 'f085896b21aa1229df3040df254756ef84c693aa'
    },

});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    textfield: {
        paddingBottom: 15
    },
    inputLabel: {
        color: "black",
        fontSize: 24,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        "&.focused": {
            color: "red"
        }
    }
}));


const SettingsAbonnements = (props) => {
    const firebase = useFirebase();
    const classes = useStyles();
    const [NewsDatas, setNewsDatas] = React.useState('')
    const [DiffDatas, setDiffDatas] = React.useState(false)
    useEffect(
        () => {
            if (props.abonnements) {
                setNewsDatas(props.abonnements)
            }

        }, [props.abonnements]
    )
    const setNewData = (event) => {
        let newData = NewsDatas;
        const fieldName = event.target.id;
        const newValue = event.target.value;
        const aboIdx = event.target.getAttribute("data-abo");
        console.log(fieldName);
        newData[aboIdx][fieldName] = newValue;
        console.log(newData)
        setNewsDatas(newData);
        if (DiffDatas !== NewsDatas) {
            setDiffDatas(true);
        }
        else {
            setDiffDatas(false);
        }
        console.log(NewsDatas);
    }
    const saveNewData = () => {
        console.log('save')
        firebase.database().ref('constants/').update({
            abonnements: NewsDatas
        });
        setDiffDatas(false);
    }

    if (props.abonnements) {
        console.log(props.abonnements);
        console.log(props.abonnements.length);
        let numOfElement = props.abonnements.length;
        return (
            <Grid container spacing={2} justify={'center'}>
                {props.abonnements.map((abonnement, index) => {

                    return (

                        <Grid key={index} item xs={numOfElement} p={5}>
                            <Box>
                                <TextField fullWidth label={"Titre"} defaultValue={abonnement.title} id="title" onChange={setNewData} inputProps={{ 'data-abo': index }} className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                                <TextField fullWidth label={"Sous-Titre"} defaultValue={abonnement.subheader} id="subheader" onChange={setNewData} inputProps={{ 'data-abo': index }} className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                                {abonnement.description && abonnement.description.map((abonnementDescription, indexDesc) => {
                                    return (
                                        <TextField key={indexDesc} fullWidth label={"Description "} defaultValue={abonnementDescription} id="description" multiline rows={2} inputProps={{ 'data-abo': index }}
                                            className={classes.textfield}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    root: classes.inputLabel,
                                                    focused: "focused"
                                                }
                                            }} />
                                    )
                                })
                                }

                                <TextField fullWidth label={"Texte Prix"} defaultValue={abonnement.priceTxt} id="priceTxt" onChange={setNewData} inputProps={{ 'data-abo': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                                <TextField fullWidth label={"Titre Bouton"} defaultValue={abonnement.buttonText} id="buttonText" onChange={setNewData} inputProps={{ 'data-abo': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                                <TextField fullWidth label={"Devis"} defaultValue={abonnement.devise} id="devise" onChange={setNewData} inputProps={{ 'data-abo': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                                <TextField fullWidth type={'number'} label={"Prix Mois"} defaultValue={abonnement.priceMonth} helperText={'A 2 décimales sans le caractère de séparation E.G: 150,00 => 15000'} id="priceMonth" onChange={setNewData} inputProps={{ 'data-abo': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                                <TextField fullWidth type={'number'} label={"Prix Année"} defaultValue={abonnement.priceYear} helperText={'A 2 décimales sans le caractère de séparation E.G: 150,00 => 15000'} id="priceYear" onChange={setNewData} inputProps={{ 'data-abo': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                            </Box>
                        </Grid>
                    )
                })}
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button variant="outlined" size="medium" color="primary" disabled={!DiffDatas} onClick={saveNewData} >Mettre à jour</Button>
                </Grid>
            </Grid>
        )
    }
    return null;
}

export default SettingsAbonnements