import React, { useRef, useEffect } from 'react';
import { func, shape, string, oneOf } from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import ViewIcon from '@material-ui/icons/RemoveRedEyeOutlined'
//import FineUploaderTraditional from 'fine-uploader-wrappers';

const imageLabelStyle = {
  fontSize: 14,
  lineHeight: '24px',
  width: '100%',
  display: 'inline-block',
  position: 'relative',
  backgroundColor: 'transparent',
  fontFamily: 'Roboto, sans-serif',
  transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  cursor: 'text',
  marginTop: 14,
  color: 'rgba(0, 0, 0, 0.3)',
};

const getName = (name) => {
  return _.replace(name, /\./g, ' ');
};

const FileComponent = ({ field, onChange, srcValue, value, defaultImg, disabled }) => {
  const name = field.name || 'field';
  const key = field.key || field.name || 'key';
  const label = field.label || field.name || 'label';
  const endpoint = field.resource;
  const fileInput = useRef();
  const [userProfileCVValue, setuserProfileCVValue] = React.useState(value);
  //console.log(value)
  //console.log(userProfileCVValue)
//console.log(srcValue)
  const imageStyle = {
    display: 'block',
    marginBottom: 10,
    width: 50,
    height: 50,
  };

  if (_.get(field, 'position') === 'flex') {
    imageStyle.width = '100%';
    imageStyle.height = 150;
    imageStyle.objectFit = 'cover';
  }
  const deleteFile = () => {
    onChange(null)
    //fileInput.current.value = "";
  }

  /*const uploader = new FineUploaderTraditional({
    options: {
      request: {
        multiple: false,
        inputName: 'file',
        endpoint,
      },
      callbacks: {
        onComplete: (id, name, response) => {
          if (response.success) {
            handleChange(key, _.get(field, 'prefix') + name);
            return _.get(field, 'onSuccess', () => {})(name, true);
          }
          return _.get(field, 'onError', () => {})(name, response);
        },
      },
    },
  });*/

  return (
    <FormControl disabled={disabled}>

      <FormLabel>{label}</FormLabel>
      <div style={{ cursor: 'pointer', width: '100%' }}>
       {/* <img
          src={srcValue}
          onError={(e) => {
            e.target.src = defaultImg;
          }}
          style={imageStyle}
        />*/}

        {srcValue && disabled &&
        <IconButton variant="raised" href={srcValue} target="_blank">
          <ViewIcon target="_blank" href={srcValue} />
        </IconButton>
        }
       
        {srcValue && !disabled &&
        <IconButton component="span" disabled={disabled} onClick={() => {
          console.log('delete click');
          deleteFile();
          }}>
          <DeleteIcon disabled={disabled} />
        </IconButton>
        
        }
        <IconButton component="label" disabled={disabled}>
          <input
            type="file"
            style={{ display: 'none' }}
            //value={userProfileCVValue}
            inputref={fileInput}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,application/pdf,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={/*(onChangeEvent) => {
              console.log(onChangeEvent.target);
              console.log(onChangeEvent.target.value);*/
              (onChangeEvent) => {
                //console.log(onChangeEvent.target.files[0].name)
                //let ext = onChangeEvent.target.files[0].type.split("/").pop();
                //console.log(ext)
                //console.log(onChangeEvent.target.files[0])
                onChange(onChangeEvent)
              }
              
              //console.log(onChangeEvent.target.files[0])
              //uploader.methods.addFiles(onChangeEvent.target);
            //}
          }
          />
          <UploadIcon />
        </IconButton>
      </div>
      </FormControl>
  );
};

FileComponent.defaultProps = {
  errors: {},
};

FileComponent.propTypes = {
  onChange: func.isRequired,
  field: shape({
    name: string,
    key: string,
    resource: string,
    position: oneOf(['flex']),
    prefix: string,
  }).isRequired,
  //srcValue: string.isRequired,
  defaultImg: string.isRequired,
};

export default FileComponent;