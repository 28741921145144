import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore' // make sure you add this for firestore
import 'firebase/storage' // make sure you add this for firestore
import 'firebase/analytics'
import { ReactReduxFirebaseProvider, createFirebaseInstance } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
//import Home from './Home'
import configureStore from './store/createStore'
import { firebase as fbConfig, reduxFirebase as rfConfig } from './config/fbConfig'
import { initScripts } from './utils'
import * as serviceWorker from './serviceWorker';
import { version } from '../package.json'
import { env } from './config/fbConfig'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
let store = null;

require('react-web-vector-icons/fonts');
// Window Variables
// ------------------------------------
window.version = version
window.env = env
initScripts()
const initialState = window && window.__INITIAL_STATE__ // set initial state here
store = configureStore(initialState)
// Initialize Firebase instance
firebase.initializeApp(fbConfig)
firebase.analytics()
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  attachAuthIsReady: true
}
/*const middleware = [
  thunk.withExtraArgument({getFirebase, getFirestore})
  // This is where you add other middleware like redux-observable
]*/
const enhancers = []
/*const store = createStore(
  rootReducer,
  //initialState,
  compose(
    applyMiddleware(...middleware),
    reduxFirestore(firebase, rrfConfig),
    ...enhancers
  )
)*/
/*const store = createStore(rootReducer,
    compose(
        applyMiddleware(thunk), //.withExtraArgument({getFirebase, getFirestore})),
       // reactReduxFirebase(fbConfig, { userProfile: 'users', useFirestoreForProfile: true, attachAuthIsReady: true }),
       // reduxFirestore(fbConfig) // redux bindings for firestore
    )
);*/
/*console.log(firebase.analytics().logEvent('app_remove'));
firebase.analytics().logEvent('select_content', {
  content_type: 'image',
  content_id: 'P12453',
  items: [{ name: 'Kittens' }]
});*/
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
  createFirebaseInstance
}
const outerTheme = createMuiTheme({
  appBar: {
    width: "auto",
    marginBottom: 50,
    marginTop: -1,
    marginLeft: -1,
    marginRight: -1,
  },
  palette: {
    primary: {
      //main: 'rgb(152, 0, 16)',
      main: 'rgb(231, 55, 74)',
    },
    secondary: {
      main: 'rgb(14, 111, 182)',
    },
    tertiary: {
      main: 'rgb(14, 111, 182)',
    },
    background: {
      //default: '#d32f2f'
    },
    color: {
      white: '#ffffff'
    }
  },
  overrides: {
    MuiFormControlLabel: {
      root: {
        '&$focused': {
          //color: '#4A90E2'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused': {
          border: '0px solid #4A90E2'
        },
        '&$disabled': {
          borderColor: 'green !important',
          notchedOutline: {
            borderColor: 'rgba(0, 0, 0, 0.1)'
          },
        },
        notchedOutline: {
          border: '1px solid #4A90E2'
        },
      }
    },
    MuiInputBase: {
      root: {
        '&$focused': {
          color: '#4A90E2'
        },
        '&$disabled': {
          color: '#000'
        }
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#4A90E2'
        },
        '&$disabled': {
          color: '#000'
        }
      },
    }
  },
  BgOverlayColor: {
    backgroundColor: 'rgba(255,255,255,0.6)',
  },
  BgOverlayColorBlack: {
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  primaryTxtColor: {
    color: "#000"
  },
  secondaryTxtColor: {
    color: "#fff"
  }
});

//store.firebaseAuthIsReady.then(() => {
ReactDOM.render(<Provider store={store}>
  <ReactReduxFirebaseProvider {...rrfProps}>
    <ThemeProvider theme={outerTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ReactReduxFirebaseProvider>
</Provider>, document.getElementById('root'));
serviceWorker.unregister();
//});

/*import React from 'react'
import ReactDOM from 'react-dom'
import { initScripts } from './utils'
import createStore from './store/createStore'
import { version } from '../package.json'
import { env } from './config/fbConfig'
import App from './containers/App'
import * as serviceWorker from './serviceWorker';
import './index.css'

// import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
window.version = version
window.env = env
initScripts()

// Store Initialization
// ------------------------------------
const initialState = window.___INITIAL_STATE__ || {
  firebase: { authError: null }
}
const store = createStore(initialState)
const routes = require('./constants/routes').default(store)

ReactDOM.render(
  <App store={store} routes={routes} />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
*/
