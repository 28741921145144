import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
  cards: {
    // maxWidth: 345,

  },
  card: {
    // Provide some spacing between cards
    margin: 16,

    // Use flex layout with column direction for components in the card
    // (CardContent and CardActions)
    display: "flex",
    flexDirection: "column",

    // Justify the content so that CardContent will always be at the top of the card,
    // and CardActions will be at the bottom
    justifyContent: "space-between"
  },
  media: {
    height: 140,
  },
});

const AnnonceSummaryList = ({ annonce }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  //console.log(annonce)
  return (
    <div>
      <ListItem alignItems="flex-start" >
        {/*<ListItemAvatar>
          <Avatar alt={annonce.title} src={annonce.localisation.image ? annonce.localisation.image : "/images/quebecHDLowliste.jpg"} />
        </ListItemAvatar>*/}

        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                //component="span"
                /*component={Link} 
                to={{
                  pathname: '/annonce/' + annonce.id,
                  state: {
                    annonce: annonce,
                    disabledProps: true,
                  }
                }}*/
                style={{ textDecoration: 'none' }}
                variant="h6"
                className={classes.inline}
                color="textPrimary"
              >
                {annonce.title}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                component={'span'}
                variant="body2"
                //className={classes.inline}
                style={{ width: '90%', display: 'block' }}
                color="textPrimary"
                gutterBottom={false}
                noWrap={true}
              >

                {annonce.description}
              </Typography>

            </React.Fragment>
          }
        >
        </ListItemText>
       {/*} <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>

        </ListItemSecondaryAction>*/}
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
}
export default AnnonceSummaryList