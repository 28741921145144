import React from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountIcon from '@material-ui/icons/AccountCircle';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deepOrange, deepPurple } from '@material-ui/core/colors';

import { roles } from '../../constants/roles'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: 10,
  },
  menu: {
    marginRight: '50px',
  },
  menuLink: {
    color: theme.primaryTxtColor.color,
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  badge: {
    position: 'absolute',

    /*root: {
      '&.MuiBadge-anchorOriginBottomRightCircle': {
        border: "2px solid white"
      }
    }*/
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: "5px solid lightgrey"//+ theme.palette.primary.main
  },
  AvatarDrawer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .5s ease-in-out',
  },
  drawerMenuHeader: {
    textAlign: 'center',
    textTransform: 'capitalize',
    //height: 0,
    opacity: 0,
    //display: 'none',
    transition: 'all 2s ease-in-out',
  },
  drawerMenuHeaderOpened: {
   // display: 'block',
    opacity: 1
  },
  AvatarBadge: {
    /*position: 'relative',
    height: '70px',
    width: '50px',*/
    display: 'flex',
    alignItems: 'center',
    /* height: 100%; */
    /* width: 100%; */
    justifyContent: 'center',
    marginTop: 8,
    transition: 'all .25s ease-in-out',
  },
  AvatarBadgeOpened: {
    /*position: 'relative',
    height: '70px',
    width: '50px',*/
    // display: 'flex',
    //alignItems: 'center',
    /* height: 100%; */
    /* width: 100%; */
    // justifyContent: 'center',
    marginTop: 20,
  }
}));



const SignedInAvatarDrawer = (props) => {
  //console.log(props)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let RoleName = "";
  let history = useHistory();
  const handleChange = event => {
    //setAuth(event.target.checked);
  };
  if (roles[props.profile.role]) {
    RoleName = roles[props.profile.role].role
    //console.log(RoleName)
  }
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    props.signOut();
    history.push("/");
  };

  return (
    <React.Fragment>
      {props.profile && props.profile.profileImg ?
        <div className={classes.AvatarDrawer}>
          {!props.reduceMenu &&
            <Box component="div" display="block" p={1} m={1} className={clsx(classes.drawerMenuHeader, !props.reduceMenu && classes.drawerMenuHeaderOpened)} >
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1" component="span" className={classes.menuLink} >
                    {props.profile.fullName}
                  </Typography>
                  {RoleName &&
                    <Typography variant="caption" component="p" className={classes.menuLink}>
                      {RoleName}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={4}>
                  <IconButton onClick={handleMenu}>
                    <ExpandMoreIcon className={classes.menuLink} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          }
          <div className={clsx(classes.AvatarBadge, !props.reduceMenu && classes.AvatarBadgeOpened)}>
            <Badge className={classes.badge} badgeContent={0} color="secondary"
              component="div"
              overlap="circle"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Avatar alt="Remy Sharp" src={props.profile.profileImg} className={props.reduceMenu ? classes.small : classes.large} />
            </Badge>
          </div>
        </div>

        :
        <div className={classes.AvatarDrawer}>
          {!props.reduceMenu &&
            <Box component="div" display="block" p={1} m={1} style={{ textAlign: 'center', textTransform: 'capitalize' }} >
              <Grid container spacing={0}>
                <Grid item xs={8}>
                  <Typography variant="body1" component="span" className={classes.menuLink} >
                    {props.profile.fullName}
                  </Typography>
                  {RoleName &&
                    <Typography variant="caption" component="p" className={classes.menuLink}>
                      {RoleName}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={4}>
                  <IconButton onClick={handleMenu}>
                    <ExpandMoreIcon className={classes.menuLink} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          }
          <div className={clsx(classes.AvatarBadge, !props.reduceMenu && classes.AvatarBadgeOpened)}>
            <Badge className={classes.badge} badgeContent={0} color="secondary"
              component="div"
              overlap="circle"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Avatar alt="Remy Sharp" className={props.reduceMenu ? classes.small : classes.large}>{props.profile.initials}</Avatar>
            </Badge>
          </div>
        </div>
      }
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}

        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
      // className={props.classes.menu}
      >
        <MenuItem onClick={handleClose} component={Link} to="/moncompte">
          <ListItemIcon>
            <AccountIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Mon Profil</Typography>
        </MenuItem>
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <LogOutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Se déconnecter</Typography>
        </MenuItem>
      </Menu>

      {/*<ul className="right">
        <li><NavLink to='/create'>New Project</NavLink></li>
        <li><a onClick={props.signOut}>Log Out</a></li>
        <li><NavLink to='/' className="btn btn-floating pink lighten-1">
          {props.profile.initials}
        </NavLink></li>
      </ul>*/}
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInAvatarDrawer)