export const roles = {
    0:{
        label: "Administrateur(s)",
        role: "Admin"
    },
    1:{
        label: "Entreprise(s)",
        role: "Entreprise"
    },
    2:{
        label: "Candidat(s)",
        role: "Candidat"
    }
}
