import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';

import { Accordion, AccordionActions, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import MenuItem from '@material-ui/core/MenuItem';

import { roles } from '../../../constants/roles';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    expansion: {
        //display: 'inherit'
        opacity: 1,
        //height: 200
    },
    notexpansion: {
        /* display: 'none',*/
        opacity: 0,
        height: 0,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        //padding: 12
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function AdvancedSearchExpansionPanel({ expanded, role, AdvancedSearch, CancelSearch, constants }) {
    const classes = useStyles();
    const [advancedSearchvalues, setadvancedSearchvalues] = React.useState({ user: { role: role, fullName: '', firstName: '', name: '', dossiers: false, domaines: [''], profileCV: '' } })
    const [chipData, setChipData] = React.useState(null);
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    const inputRef = React.useRef();

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, [expanded]);
    /* React.useEffect(() => {
         // ComponentDidMount code
         setadvancedSearchvalues({ user: { role: role, fullName: '' } })
       }, []);*/
    React.useEffect(() => {
        /* componentDidUpdate code */
        //setadvancedSearchvalues({ user: { role: role, fullName: '' } });
        setadvancedSearchvalues({ user: { role: role, fullName: '', firstName: '', name: '', dossiers: false, domaines: [], profileCV: '' } })
        //setadvancedSearchvalues({user:{ ...advancedSearchvalues.user, role: role }});
        //console.log(advancedSearchvalues)
    }, [role]);
    React.useEffect(() => {
        /* componentDidUpdate code */
        //setadvancedSearchvalues({ user: { role: role, fullName: '' } });
        if (isLoaded(constants)) {
            // console.log(constants)
            setChipData(constants.Domaines.children)
        }

        //setadvancedSearchvalues({user:{ ...advancedSearchvalues.user, role: role }});
        //console.log(chipData)
    }, [constants]);

    const enterPressed = (event) => {
        if (event.key === 'Enter') {
            //alert('Enter pressed')
            AdvancedSearch(advancedSearchvalues)
        }
    }

    const handleChange = (event) => {
        let name = event.target.name
        /* console.log(advancedSearchvalues)
         console.log(advancedSearchvalues.user)
         console.log(name)*/
        //console.log(event.target.name)
        //console.log(event.target.value)
        if (event.target.name === 'dossiers') {
            setadvancedSearchvalues({ user: { ...advancedSearchvalues.user, [event.target.name]: !advancedSearchvalues.user.dossiers } });
        }
        else {
            setadvancedSearchvalues({ user: { ...advancedSearchvalues.user, [event.target.name]: event.target.value } });
        }
    };
    const Cancel = () => {
        setadvancedSearchvalues({ user: { role: role, fullName: '', firstName: '', name: '', domaines: [], profileCV: '' } })
        CancelSearch();
    }
    /* if (expanded) {*/
    /* console.log(constants)
     console.log(chipData)*/
    //console.log(expanded)
    return (
        <div className={classes.root}>
            <Accordion expanded={expanded} className={expanded ? classes.expansion : classes.notexpansion} >
                <AccordionSummary
                    //expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <Grid container >
                        <Grid item xs={6}>
                            <Typography className={classes.heading}>Recherche avancée  {roles[role].label}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.secondaryHeading}>Choisissez vos filtres</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {/*<div className={classes.column} />*/}
                    <Grid container >
                        <Grid item xs={6}>
                            <Grid container >
                                <Grid item xs={12}>
                                    {/*<Chip label="Barbados" onDelete={() => { }} />*/}
                                    {/*}  <TextField
                            label={"Nom Complet"}
                            type={"text"}
                            fullWidth={true}
                            variant={"outlined"}
                            onChange={handleChange}
                            value={advancedSearchvalues.user.fullName}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'fullName',
                                id: 'fullName',
                                autoCapitalize: "words"
                            }}
                        />*/}
                                    <TextField
                                        inputRef={inputRef}
                                        label={"Prénom"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        onChange={handleChange}
                                        onKeyPress={enterPressed}
                                        //autoFocus={expanded && true}
                                        value={advancedSearchvalues.user.firstName}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ margin: 8 }}
                                        inputProps={{
                                            name: 'firstName',
                                            id: 'firstName',
                                            //autoCapitalize: "words"
                                            style: { height: '36px', padding: '8.5px 14px' }
                                        }}
                                    />
                                    <TextField
                                        label={"Nom"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        onChange={handleChange}
                                        onKeyPress={enterPressed}
                                        value={advancedSearchvalues.user.name}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ margin: 8 }}
                                        inputProps={{
                                            name: 'name',
                                            id: 'name',
                                            //labelWidth,
                                            //autoCapitalize: "words"
                                            style: { height: '36px', padding: '8.5px 14px' }
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={advancedSearchvalues.user.dossiers}
                                                onChange={handleChange}
                                                name="dossiers"
                                                color="primary"
                                                inputProps={{
                                                    name: 'dossiers',
                                                    id: 'dossiers',
                                                    //autoCapitalize: "words"
                                                    style: { height: '36px', padding: '8.5px 14px' }
                                                }}
                                            />
                                        }
                                        label="Dossier ?"
                                    />
                                    {/*<FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel ref={inputLabel} htmlFor="profileCV" shrink>
                                            CV ?
                            </InputLabel>
                                        <Select
                                            native
                                            value={advancedSearchvalues.user.profileCV}
                                            onChange={handleChange}
                                            //labelWidth={labelWidth}
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    labelWidth={labelWidth}
                                                    name={'profileCV'}
                                                    id={'profileCV'}
                                                />
                                            }
                                            inputProps={{
                                                name: 'profileCV',
                                                id: 'profileCV',
                                                style: { height: '36px', padding: '8.5px 14px' }
                                            }}
                                        >
                                            {<option value="" />}
                                            <option value={0}>Tous</option>
                                            <option value={1}>Oui</option>
                                            <option value={2}>Non</option>
                                        </Select>
                                    </FormControl>*/}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel ref={inputLabel} htmlFor="domaines" shrink>
                                            Domaines ?
                            </InputLabel>
                                        <Select
                                            //native
                                            value={advancedSearchvalues.user.domaines}
                                            onChange={handleChange}
                                            //labelWidth={labelWidth}
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    labelWidth={labelWidth}
                                                    name={'domaines'}
                                                    id={'domaines'}
                                                />
                                            }
                                            inputProps={{
                                                name: 'domaines',
                                                id: 'domaines',
                                                style: { height: '36px', padding: '8.5px 14px' }
                                            }}
                                            multiple

                                            input={<Input />}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key={"empty"} value={"aucun"} >
                                                Aucun
                                            </MenuItem>
                                            {constants && constants.Domaines.children.map((domaine, index) => (
                                                <MenuItem key={index} value={domaine.Value} >
                                                    {domaine.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            {/*<Typography variant="caption">
                            Select your destination of choice {role}
                            <br />
                            <a href="#secondary-heading-and-columns" className={classes.link}>
                                Learn more
                            </a>
                        </Typography>*/}

                        </Grid>
                    </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Button size="small" onClick={() => Cancel()}>Annuler</Button>
                    <Button size="small" color="primary" onClick={() => AdvancedSearch(advancedSearchvalues)}>Rechercher</Button>
                </AccordionActions>
            </Accordion>
        </div>
    );
    /*}
    return null*/

}

const mapStateToProps = (state, store) => {
    /*console.log(state);
    console.log(store);*/
    return {
        auth: state.firebase.auth,
        constants: state.firebase.data.constants,
    }
}

const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    /* return {
         loadInitUsers: (role, limit) => dispatch(loadInitUsers(role, limit)),
         loadMoreUsers: (role, limit, startAfter) => dispatch(loadMoreUsers(role, limit, startAfter))
     }*/
}

export default compose(

    firebaseConnect((props, store) => {
        /* console.log(props)
         console.log(store)*/
        return [
            { path: 'constants' }, // make sure to pass "populates"
        ]
    }),
    firestoreConnect(),
    connect(mapStateToProps),
)(AdvancedSearchExpansionPanel)