import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

import Grid from "@material-ui/core/Grid"
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import StarIcon from '@material-ui/icons/StarBorder';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckoutForm from './CheckoutForm';
//import { Elements, StripeProvider } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { AppBar, Toolbar } from '@material-ui/core';
//import CheckoutForm from '../../users/components/stripe/CheckoutForm';
//import {abonnements} from '../../../constants/abonnement'

/*const SubscribeAbonnement = (props) => {
  return (
    <Dialog onClose={handleClickDialog} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">React Stripe Elements Example</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pour souscrire à l'offre sélectionnée veuillez compléter les informations ci-dessous!
        {}
        </DialogContentText>
        <StripeProvider apiKey="pk_test_tFy3guUXhLN7A1If5TJG8xSK00jnLJoOkC">
          <Elements>
            <CheckoutForm subscribe={subscribe} handleSuccessPayment={handleSuccessPayment} />
          </Elements>
        </StripeProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickDialog} color="primary">
          Annuler
      </Button>
        <Button onClick={handleClickSubScribe} color="primary">
          Souscrire
      </Button>
      </DialogActions>
    </Dialog>
  )
}*/

const useStyles = makeStyles(theme => ({
  '@global': {
    /*ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },*/
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  media: {
    height: 140,
  },
  title: {
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'uppercase'
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    backgroundColor:
      theme.palette.primary.main,
    color: theme.secondaryTxtColor.color
    //theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  whiteColor: {
    color: theme.secondaryTxtColor.color
  },
  cardContent: {
    flex: 1,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  priceTable: {
    padding: '50px'
  },
  HeaderDialog: {
    backgroundColor: theme.palette.primary.main,
    color: theme.secondaryTxtColor.color,
    textAlign: "center",
    fontWeight: 600
  }
}));

const stripePromise = loadStripe('pk_test_tFy3guUXhLN7A1If5TJG8xSK00jnLJoOkC');

const OffresAbonnements = ({ userActivity, abonnements }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [subscribe, setSubscribe] = React.useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = React.useState('');
  const [subscribeEnable, setSubscribeEnable] = React.useState(false);
  //console.log(userActivity)
  const handleClickCloseDialog = () => {
    setOpen(false);
    setSubscriptionPlan('')
  };
  const handleClickOpenDialog = (abonnement, formule, choice) => {
    setSubscriptionPlan({ abonnement: abonnement, formule: formule, choice: choice })
    setOpen(true);
  };

  const handleClickSubScribe = () => {
    setSubscribe(true);
  };
  const handleSuccessPayment = () => {
    setOpen(!open);
    setSubscribe(false);
    setSubscribeEnable(false);
  }

  const subscribeFormEnable = (test) => {
    setSubscribeEnable(test);
  }
  /*const InjectedCheckoutForm = ({ subscribe, handleSuccessPayment }) => (
    <ElementsConsumer>
      {({ stripe, elements, subscribe, handleSuccessPayment }) => (
        <CheckoutForm stripe={stripe} elements={elements} subscribe={subscribe} handleSuccessPayment={handleSuccessPayment} />
      )}
    </ElementsConsumer>
  );*/

  return (
    <div>
      <Paper>
        <Typography variant="h6" component="h2" className={classes.title}>
          Souscrire à une offre
      </Typography>
        <Divider />

        <Grid container spacing={0} /*alignItems="flex-end"*/ justify="center" className={classes.priceTables}>
          {abonnements && abonnements.map((abonnement, index) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={index} xs={12} sm={abonnement.title === 'Enterprise' ? 12 : 6} md={3} component={Card} className={classes.card}>

              <CardHeader
                title={abonnement.title}
                subheader={abonnement.subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                //action={abonnement.title === 'Pro' ? <StarIcon /> : null}
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardContent}>
                <div className={classes.cardPricing}>
                  {/*<Typography component="h2" variant="h3" color="textPrimary">
                    {abonnement.price}€
                    </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /mois
                    </Typography>*/}
                  <Typography variant="h6" color="textPrimary">
                    {abonnement.priceTxt}
                  </Typography>
                </div>
                <ul>
                  {abonnement.description.map(line => (
                    <Typography component="li" variant="subtitle1" /*align="center"*/ key={line}>
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                {abonnement.priceMonth &&
                  <Button fullWidth variant={abonnement.buttonVariant} color="primary" onClick={() => handleClickOpenDialog(abonnement, index, 'month')} abo={abonnement} formule={index} choice={'month'}>
                    {abonnement.buttonText} 1 Mois
              </Button>
                }
                {abonnement.priceYear &&
                  <Button fullWidth variant={abonnement.buttonVariant} color="primary" onClick={() => handleClickOpenDialog(abonnement, index, 'year')} abo={abonnement} formule={index} choice={'year'}>
                    {abonnement.buttonText} 1 An
                </Button>
                }
              </CardActions>

            </Grid>
          ))}
        </Grid>

      </Paper>
      <Dialog onClose={handleClickCloseDialog} aria-labelledby="simple-dialog-title" open={open} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle id="simple-dialog-title" className={classes.HeaderDialog}>
          <Typography variant="h5" component="h5">RECAPITULATIF</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Offre choisie : {subscriptionPlan.abonnement && subscriptionPlan.abonnement.title}
          </DialogContentText>
          <DialogContentText>
            Durée choisie : {subscriptionPlan.choice === 'year' ? "1 an" : "1 Mois"}
          </DialogContentText>
          <DialogContentText>
            Montant de l'offre : {subscriptionPlan.abonnement && (subscriptionPlan.choice === 'year' ? (subscriptionPlan.abonnement.priceYear / 100).toFixed(2) : (subscriptionPlan.abonnement.priceMonth / 100).toFixed(2)) + subscriptionPlan.abonnement.devise}
          </DialogContentText>
          <Elements stripe={stripePromise}>
            {/*<InjectedCheckoutForm />*/}
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} subscribe={subscribe} handleSuccessPayment={handleSuccessPayment} subscribeFormEnable={subscribeFormEnable} subscriptionPlan={subscriptionPlan} abonnement={subscriptionPlan.abonnement} formaule={subscriptionPlan.formule} choice={subscriptionPlan.choice} />
              )}
            </ElementsConsumer>
            {/*<CheckoutForm handleClickCardDialog={handleClickCardDialog} />*/}
          </Elements>
          {/*<StripeProvider apiKey="pk_test_tFy3guUXhLN7A1If5TJG8xSK00jnLJoOkC">
            <Elements>
              <CheckoutForm subscribe={subscribe} handleSuccessPayment={handleSuccessPayment} />
            </Elements>
          </StripeProvider>*/}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleClickSubScribe} disabled={!subscribeEnable} color="primary">
            Souscrire
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OffresAbonnements