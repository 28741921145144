export const createUser = (annonce) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    // console.log(annonce)
    //console.log(profile)
    //console.log(authorId)

    firebase.firestore().collection('projects').add({
      ...annonce,
      authorFirstName: profile.firstName,
      authorLastName: profile.name,
      authorId: authorId,
      createdAt: new Date()
    }).then(() => {
      dispatch({ type: 'CREATE_ANNONCE_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'CREATE_ANNONCE_ERROR' }, err);
    });
  }
};

export const loadInitUsers = (role, limit) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let newArr = []
    firebase.firestore().collection('users')
      .where('role', '==', role)
      .orderBy("dateInscription", "desc")
      .limit(limit).get().then((querySnapshot) => {
        //console.log(querySnapshot)
        //if (querySnapshot.exists) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          var userData = doc.data()
          var id = doc.id
          let newDoc = { id, ...userData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        dispatch({
          type: 'LOAD_INIT_USERS_SUCCESS',
          role: role,
          payload: newArr
        });
      }).catch(err => {
        console.log(err)
        dispatch({ type: 'LOAD_INIT_USERS_ERROR' }, err);
      })
  }
};


export const loadMoreUsers = (role, limit, startAfter) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //const profile = getState().firebase.profile;
    //const authorId = getState().firebase.auth.uid;
    //console.log('test')
    //console.log(limit)
    //console.log(startAfter)
    //console.log(role)
    let newArr = []
    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)
    firebase.firestore().collection('users')
      .where('role', '==', role)
      .orderBy("dateInscription", "desc")
      .startAfter(startAfter)
      .limit(limit).get().then((querySnapshot) => {
        //console.log(querySnapshot)
        //if (querySnapshot.exists) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          var userData = doc.data()
          var id = doc.id
          let newDoc = { id, ...userData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        dispatch({
          type: 'LOAD_MORE_USERS_SUCCESS',
          role: role,
          payload: newArr
        });
      }).catch(err => {
        console.log(err)
        dispatch({ type: 'LOAD_MORE_USERS_ERROR' }, err);
      })
  }
};

export const loadAdvancedSearchUsers = (advSearchFields) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let newArr = []
    let role = advSearchFields.user.role;
    console.log(advSearchFields)

    let Query = firebase.firestore().collection('users').where('role', '==', role);//.where('firstName', '==', "arnaud").where('name', '==', '');

    if (advSearchFields.user.firstName && advSearchFields.user.name) {
      Query = Query.where('fullName', "==", advSearchFields.user.firstName + ' ' + advSearchFields.user.name)

    }
    else {
      if (advSearchFields.user.fullName) {
        console.log("name")
        Query = Query.where('fullName', "==", [advSearchFields.user.fullName])
      }
      if (advSearchFields.user.name) {
        console.log("name")
        // Query =  Query.where('name', "==", advSearchFields.user.name )
        //Query =  Query.where('name', 'array-contains-any', [advSearchFields.user.name])
        Query = Query.where('name', 'in', [advSearchFields.user.name])
      }
      if (advSearchFields.user.firstName) {
        console.log("firstName")
        //Query =  Query.where('firstName', "==", advSearchFields.user.firstName)
        //Query =  Query.where('firstName', 'array-contains-any', [advSearchFields.user.firstName])
        Query = Query.where('firstName', 'in', [advSearchFields.user.firstName])
      }
    }

    //console.log(advSearchFields.user.domaines.length)
    if (advSearchFields.user.domaines.length > 0) {
      console.log("domaines")
      //if(advSearchFields.user.domaines != [])
      //Query =  Query.where('firstName', "==", advSearchFields.user.firstName)
      Query = Query.where('domaines', 'array-contains-any', advSearchFields.user.domaines)
    }
    if (advSearchFields.user.profileCV) {
      if (advSearchFields.user.profileCV === "1") {
        console.log("profileCV OUI")
        //Query =  Query.where("profileCV", "==", "''")
        Query = Query.where("hasprofileCV", "==", true)//.orderBy("profileCV", "desc")
      }
      if (advSearchFields.user.profileCV === "2") {
        console.log("profileCV NON")
        Query = Query.where('hasprofileCV', '==', false)
      }

    }
    if (advSearchFields.user.dossiers) {
      //Query =  Query.where("profileCV", "==", "''")
      Query = Query.where("dossiers", "!=", null)//.orderBy("profileCV", "desc")
      Query = Query.orderBy("dossiers")

    }
    console.log(Query)
    Query = Query
      .orderBy("dateInscription", "desc")
      .get()
      .then((querySnapshot) => {
        //console.log(querySnapshot)
        //if (querySnapshot.exists) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.id, " => ", doc.data());
          var userData = doc.data()
          var id = doc.id
          let newDoc = { id, ...userData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        dispatch({
          type: 'LOAD_ADVANCED_SEARCH_USERS_SUCCESS',
          role: role,
          payload: newArr
        });
      }).catch(err => {
        console.log(err)
        dispatch({ type: 'LOAD_ADVANCED_SEARCH_USERS_ERROR' }, err);
      })
  }
};

export const loadAnnoncesUsers = (userDomaines, idEntreprise) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let newArr = []
    let Query = firebase.firestore().collection('annonces')//.where('role', '==', role);//.where('firstName', '==', "arnaud").where('name', '==', '');
    if (idEntreprise) {
      Query = Query.where('idEntreprise', '==', idEntreprise)
    }
    if (userDomaines) {
      Query = Query.where('secteurActivite', 'in', userDomaines)
    }

    Query = Query
      //.orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        //console.log(querySnapshot)
        //if (querySnapshot.exists) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          var userData = doc.data()
          var id = doc.id
          let newDoc = { id, ...userData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        dispatch({
          type: 'LOAD_ANNONCES_USERS_SUCCESS',
          userDomaines: userDomaines,
          idEntreprise: idEntreprise,
          payload: newArr
        });
      }).catch(err => {
        console.log(err)
        dispatch({ type: 'LOAD_ANNONCES_USERS_ERROR' }, err);
      })
  }
};