import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import {
  PaymentRequestButtonElement,
  //CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  IbanElement,
  injectStripe,
  address_zip,
  address
} from 'react-stripe-elements';
import { useStripe, useElements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import Box from "@material-ui/core/Box"
import queryString from 'query-string';
import moment from 'moment';


class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      alertMessage: '',
      alertStyle: '',
      abonnement: this.props.abonnement,
      subscriptionPlan: this.props.subscriptionPlan
    };
    //this.submit = this.submit.bind(this);
    this.submitNewCreditCard = this.submitNewCreditCard.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    console.log('props updt')
    if (prevProps.subscribe !== this.props.subscribe && this.props.subscribe === true) {
      //this.submit()
      console.log('submit card')
      this.submitNewCreditCard();
      //this.handleSubmit()
    }
  }



  getPaymentIntents = async (paymentMethod) => {
    var myHeaders = new Headers({
      'Content-Type': 'application/json',
    });
    /*JSON.stringify({
      amount: 2000,//this.props.route.params.choice === 'year' ? this.props.route.params.abo.priceYear : this.props.route.params.abo.priceMonth,
      currency: "cad",
      //token: this.state.token.tokenId
    });*/
    var Body = JSON.stringify({
      amount: this.state.subscriptionPlan.choice === 'year' ? this.state.subscriptionPlan.abonnement.priceYear : this.state.subscriptionPlan.abonnement.priceMonth,
      currency: "cad",
      payment_method: paymentMethod.id,
      description: this.state.subscriptionPlan.choice === 'year' ? this.state.subscriptionPlan.abonnement.title + ' (1 an)' : this.state.subscriptionPlan.abonnement.title + ' (1 mois)',
      // customer: null,
      //save_payment_method: true,
      receipt_email: this.props.monProfil.mail,
      //setup_future_usage: 'off_session',
    });
    console.log(Body)
    // Use firebase serve for local testing if you don't have a paid firebase account
    fetch(
      //'http://localhost:5001/oq70-objectifquebec/us-central1/getPaymentIntentsStripe',
      'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getPaymentIntentsStripe',
      {
        method: 'POST',
        headers: myHeaders,
        body: Body,
        // mode: 'no-cors',
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        return this.handleServerResponse(responseJson)

      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleServerResponse = (responseJson) => {
    const { stripe, elements } = this.props;
    if (responseJson.error) {
      console.log("error")
      // Show error from server on payment form
    } else if (responseJson.requiresAction) {
      console.log("requiere actions")
      // Use Stripe.js to handle required card action
      stripe.handleCardAction(
        responseJson.clientSecret
      ).then(function (result) {
        if (result.error) {
          // Show `result.error.message` in payment form
        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          fetch('/pay', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ payment_intent_id: result.paymentIntent.id })
          }).then(function (confirmResult) {
            return confirmResult.json();
          }).then(this.handleServerResponse);
        }
      });
    } else {
      console.log("succes")
      return this.confirmPayment(responseJson)
      // Show success message
    }
  }

  confirmPayment = (responseJson) => {
    const { stripe, elements, firebase, handleSuccessPayment } = this.props;
    const { subscriptionPlan } = this.state;
    const userId = this.props.auth.uid;
    console.log(userId)
    const cardElement = elements.getElement(CardElement)//this.props.elements.getElement('card');
    stripe.confirmCardPayment(
      responseJson.client_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: this.props.monProfil.companyName ? this.props.monProfil.companyName : null,
            email: this.props.monProfil.mail ? this.props.monProfil.mail : null,
            phone: this.props.monProfil.phoneNumber ? this.props.monProfil.phoneNumber : null,
            address: {
              city: this.props.monProfil.ville ? this.props.monProfil.ville : null,
              //country: this.props.monProfil.pays ? this.props.monProfil.pays : null,
              line1: this.props.monProfil.rue ? this.props.monProfil.rue : null,
              line2: this.props.monProfil.complementAdresse ? this.props.monProfil.complementAdresse : null,
              postal_code: this.props.monProfil.codePostal ? this.props.monProfil.codePostal : null,
              state: this.props.monProfil.province ? this.props.monProfil.province : null,
            },
          },
        }
      }
    ).then(function (result) {
      if (result.error) {
        // Display error.message in your UI.
        console.log('paiement refusé')
      } else {
        // The payment has succeeded
        console.log('paiement OK')
        console.log(result)
        console.log(result.id)
        console.log(result.paymentIntent)
        /*this.props.firebase.firestore().collection('stripe_customers').doc(userId).set( result ).then(() => {
          //this.props.handleSuccessPayment();
          // Display a success message
        });*/
        firebase.firestore().collection('stripe_customers').doc(userId).set({ lastPayment: firebase.firestore.FieldValue.serverTimestamp() })
          .then(() => {
            console.log('ok stripe customer')
            firebase.firestore().collection('stripe_customers').doc(userId).collection('subscriptions').doc(result.paymentIntent.id).set( result.paymentIntent )
              .then(() => {
                console.log('ok subscription')
                var currentDate = moment(new Date());
                var futureMonth = moment(currentDate).add(1, 'M');
                var futureMonthEnd = moment(futureMonth).endOf('month');
            
                if (currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
                  futureMonth = futureMonth.add(1, 'd');
                }
                firebase.firestore().collection('users').doc(userId).update({ 
                  aboFormule: subscriptionPlan.formule,
                  activeAbonnement: true,
                  aboExpireDate: firebase.firestore.Timestamp.fromDate(futureMonth.toDate())
                })
                  .then(() => {
                    handleSuccessPayment();
                  })

                // firebase.firestore().collection('stripe_customers').doc(userId).collection('subscriptions').doc(result.paymentIntent.id).set({ result })
                //this.props.handleSuccessPayment();
                // Display a success message
              })
            //this.props.handleSuccessPayment();
            // Display a success message
          })
          .catch((err) => {
            console.log('err ', err)
          })
          ;
      }
    })
  }
  submitNewCreditCard = async (ev) => {
    //ev.preventDefault();
    console.log('submit new card')
    //const userId = this.props.auth.uid;
    const { stripe, elements } = this.props;

    const cardElement = elements.getElement(CardElement)//this.props.elements.getElement('card');

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    console.log(error)
    console.log(paymentMethod)
    if (paymentMethod) {
      this.getPaymentIntents(paymentMethod);
    }

    return false;
  }


  /* handleSubmits(ev) {
     //ev.preventDefault();
 
     this.props.stripe.createToken({name: "test"})
       .then(result => {
         if(typeof(result.error) !== 'undefined') {
           this.setState({ error: result.error.message, success: ''});
         } else {
           this.stripeCreateCharge(result.token, 100);
         }
       });
   }
 
   stripeCreateCharges(token, amount) {
     const params = { token: token.id, amount: amount };
     const qParams = queryString.stringify(params);
     const url = ['/api', qParams].join('?');
 console.log(params)
 console.log(qParams)
 console.log(url)
     fetch(url)
       .then(response => response.json())
       .then(val => {
         console.log(val)
         if (val.ok) {
           return val.message;
         } else {
           throw val.message;
         }
       })
       .then(success => {
         console.log(success)
         this.setState({alertMessage: success, alertStyle: 'success'})
       })
       .catch(error => {
         console.log(error)
         this.setState({alertMessage: error, alertStyle: 'danger'})
     });
   }
 
   async submits(ev) {
     let { token } = await this.props.stripe.createToken({ name: "Name" });
     console.log(token)
     let response = await fetch("/charge", {
       method: "POST",
       headers: { "Content-Type": "text/plain" },
       body: token.id
     });
     console.log(response)
     if (response.ok) console.log("Purchase Complete!")
     if (response.ok) this.setState({ complete: true });
     this.props.handleSuccessPayment();
   }
 
   handleSubmits = (ev) => {
     // We don't want to let default form submission happen here, which would refresh the page.
     //ev.preventDefault();
 
     // Use Elements to get a reference to the Card Element mounted somewhere
     // in your <Elements> tree. Elements will know how to find your Card Element
     // becase only one is allowed.
     // See our getElement documentation for more:
     // https://stripe.com/docs/stripe-js/reference#elements-get-element
     const cardElement = this.props.elements.getElement('card');
     console.log(cardElement)
     // From here we cal call createPaymentMethod to create a PaymentMethod
     // See our createPaymentMethod documentation for more:
     // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
     this.props.stripe
       .createPaymentMethod({
         type: 'card',
         card: cardElement,
         billing_details: { name: 'Jenny Rosen' },
       })
       .then(({ paymentMethod }) => {
         console.log('Received Stripe PaymentMethod:', paymentMethod);
       });
 
     // You can also use confirmCardPayment with the PaymentIntents API automatic confirmation flow.
     // See our confirmCardPayment documentation for more:
     // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
     this.props.stripe.confirmCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', {
       payment_method: {
         card: cardElement,
       },
     });
 
     // You can also use confirmCardSetup with the SetupIntents API.
     // See our confirmCardSetup documentation for more:
     // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-setup
     this.props.stripe.confirmCardSetup('{PAYMENT_INTENT_CLIENT_SECRET}', {
       payment_method: {
         card: cardElement,
       },
     });
 
     // You can also use createToken to create tokens.
     // See our tokens documentation for more:
     // https://stripe.com/docs/stripe-js/reference#stripe-create-token
     // With createToken, you will not need to pass in the reference to
     // the Element. It will be inferred automatically.
     this.props.stripe.createToken({ type: 'card', name: 'Jenny Rosen' });
     // token type can optionally be inferred if there is only one Element
     // with which to create tokens
     // this.props.stripe.createToken({name: 'Jenny Rosen'});
 
     // You can also use createSource to create Sources.
     // See our Sources documentation for more:
     // https://stripe.com/docs/stripe-js/reference#stripe-create-source
     // With createSource, you will not need to pass in the reference to
     // the Element. It will be inferred automatically.
     this.props.stripe.createSource({
       type: 'card',
       owner: {
         name: 'Jenny Rosen',
       },
     });
   };*/
   onChangeCardElement = (event) => {
     console.log(event)
     this.props.subscribeFormEnable(event.complete);
     this.setState({
       cardformComplete: event.complete
     })

   }

  render() {
    console.log(this.props.subscribe)
    console.log(this.props)
    if (this.state.complete) return <h1>Purchase Complete</h1>;
    return (
      <Box>
        <p>Voulez vous souscrire ?</p>
        <CardElement onChange={this.onChangeCardElement} />
      </Box>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    monProfil: state.firestore.data.monProfil,
    //stripe_customers: state.firestore.ordered.stripe_customers
  }
}

export default compose(
  connect(mapStateToProps),
  firebaseConnect(),
  firestoreConnect(),
)(CheckoutForm)


//export default CheckoutForm;