import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { map } from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EyeIcon from '@material-ui/icons/RemoveRedEye'
import Videocam from '@material-ui/icons/Videocam';
import GoodQuality from '@material-ui/icons/Done';
import NoQuality from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress'
//import { generateTestMarkerHttp } from '../../../utils/functions/firebaseUtils'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment'
// Path within Database for metadata (also used for file Storage path)


const useStyles = makeStyles(theme => ({
    title: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.color.white
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    input: {
        display: 'none'
    },
    blockContainer: {
        textAlign: 'center',
        margin: 10
    },
    container: {
        position: 'relative',
        //width: '50%',
        maxWidth: '500px',
        margin: '5px auto',
    },
    image: {
        display: 'block',
        width: '100%',
        height: 'auto',
        borderWidth: 1,
        borderStyle: 'solid'
    },
    overlay: {
        position: 'absolute',
        bottom: 0,
        background: 'rgb(0, 0, 0)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: '#f1f1f1',
        width: '100%',
        height: '100%',
        transition: '.5s ease',
        color: 'white',
        fontSize: '40px',
        padding: '20px',
        textAlign: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    inputDrop: {
        textAlign: 'center',
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        borderRadius: '10px',
        outline: 'none',
        padding: 3,
        margin: 2
    },
    percentQuality: {

    },
    rootList: {
        maxHeight: '215px',
        overflow: 'auto'
    },
    iconQuality: {
        fontSize: 45
    },
    iconQualityList: {
        fontSize: 25
    },
    iconQualityListz: {
        position: 'absolute',
        top: 0
    },
    LowQualityColor: {
        color: 'red'
    },
    GoodQualityColor: {
        color: 'green'
    }
}));
const ImagePickerUpload = ({ monProfil, uploadFile, handleImageState, image }) => {
    const classes = useStyles();
    //const [testMarker, setTestMarker] = React.useState('');
    const [markerQuality, setMarkerQuality] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState(image);
    const [progress, setProgress] = React.useState(0);
    const [deletion, setDeletion] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const firebase = useFirebase()
    //const uploadedFiles = useSelector(({ firebase: { data } }) => data[filesPath])
    useEffect(() => {
        //console.log(image);
        if (image) {
            setImageUrl(image);
        }
        else {
            setImageUrl(null)
        }

    }, [image]);
    //console.log(image)
    const onFilesDrop = async (files) => {
        setProgress(true);

        let file = files[0];
        if (imageUrl) {
            //setImageUrl(null)
            //setMarkerQuality('')
           // setSelected(null)
            //onFileDelete(files);
            await handleImageState(files);
        } else {
            await handleImageState(files);
        }

        //setProgress(false)
        //else {

        //}

    }
    /* const onFileDelete = async (files) => {
         console.log(imageUrl)
         var previousImageRef = firebase.storage().refFromURL(imageUrl)
         console.log(previousImageRef)
         return previousImageRef.delete()
             //firebase.deleteFile(previousImageRef)
             .then(async () => {
                 setImageUrl('')
                 setMarkerQuality('')
                 firebase.firestore().collection('TestsMarkers').add({
                     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                     creator: monProfil,
                 }).then(async (doc) => {
                     let docId = doc.id;
                     const filesPath = 'testMarker/' + docId;
                     return await firebase.uploadFiles(filesPath, files, filesPath, { progress: true })
                         .then((snap) => {
                             console.log('upload successful', snap)
                             console.log("test ", snap[0].downloadURL)
                             console.log('downloadUrl', snap[0].downloadURL)
 
                             setImageUrl(snap[0].downloadURL);
                             generateTestMarkerHttp({ url: snap[0].downloadURL, bucket: snap[0].File.bucket, object: snap[0].File })
                                 .then((result) => {
                                     if (result) {
                                         console.log("result ", result)
                                         setMarkerQuality(result.data.markerQuality)
                                         firebase.firestore().collection('TestsMarkers').doc(docId).update({
                                             file: snap[0].File,
                                             downloadUrl: snap[0].downloadURL,
                                             markerQuality: result.data.markerQuality
                                         })
                                     }
                                 })
                                 .catch((error) => {
                                     var code = error.code;
                                     var message = error.message;
                                     var details = error.details;
                                     console.log('error = ', error);
                                     setMarkerQuality("Erreur veuillez réessayer !")
                                 });
                         })
                         .catch(err => console.error('error uploading file', err));
                 })
             });
     }*/
    const deleteFromFirestore = (url, docId) => {
        setDeletion(true)
        setImageUrl('')
        setMarkerQuality('')
        setSelected(null)
        var previousImageRef = firebase.storage().refFromURL(url)
        console.log(previousImageRef)
        return previousImageRef.delete()
            //firebase.deleteFile(previousImageRef)
            .then(() => {
                firebase.firestore().collection('TestsMarkers').doc(docId).delete()
                    .then(() => {
                        setDeletion(false)
                    })
            })
    }
    return (
        <Box>
            <Backdrop className={classes.backdrop} open={deletion} onClick={() => setDeletion(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container >
                {/*<div className="row">
                <progress value={progress} max="100" className="progress" />
            </div>*/}
                <Grid item xs={12}>
                    <div className={classes.blockContainer} style={{ textAlign: 'center', padding: 10 }}>
                        <Dropzone
                            onDrop={onFilesDrop}
                            maxFiles={1}
                            multiple={false}
                            accept={"image/*"}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className={classes.inputDrop}>
                                    <input {...getInputProps()} />
                                    {progress && !imageUrl ?
                                        <CircularProgress color="secondary" />
                                        :
                                        <>
                                            <IconButton color="primary" component="span">
                                                <PhotoCamera style={{ fontSize: 40 }} />
                                            </IconButton>
                                            <Typography variant="body2" component="h6" className={clsx(classes.percentQuality)}>
                                                Cliquez ici ou glissez une image ici la mettre en fond.
                                        </Typography>
                                        </>
                                    }
                                </div>
                            )}
                        </Dropzone>
                        Prévisualisation
                        {imageUrl &&
                            <div className={classes.container}>
                                <img
                                    src={imageUrl}
                                    alt="Uploaded Images"
                                    /* height="300"
                                     width="400"*/
                                    className={classes.image}
                                />
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

//export default ArTestImage
const mapStateToProps = (state) => {
    // console.log(state);
    return {
        TestsMarkers: state.firestore.ordered.TestsMarkers,
        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect(),
    firestoreConnect(props => [
        {
            collection: 'TestsMarkers',
            orderBy: ['createdAt', 'desc'],
            // limit: 10
        }
    ]),

    /*firestoreConnect([
        { collection: 'annonces' },
        limit: 5
    ])*/
)(ImagePickerUpload)