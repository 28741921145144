import React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { compose } from 'redux'
import { Redirect, Link } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import RemoveRedEyeRoundedIcon from '@material-ui/icons/RemoveRedEyeRounded';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'


import { AdminDetails, EntrepriseDetails, CandidatDetails } from '../users/components/RoleScreen/'

/* ----------------- */

const chatTextStyles = (theme) => ({
    root: {
        margin: '8px',
        height: 'calc(100% - 80px)',
    },
    chatInputPaper: {
        height: 'calc(100% - 64px)'
    },
    chatInput: {
        overflowY: 'scroll',
        height: '100%',
        padding: '16px',
        // '&::-webkit-scrollbar': {
        //   width: '8px',
        //   height: '12px',
        //   backgroundColor: '#FFF'
        // },
        // '&::-webkit-scrollbar-thumb': {
        //   backgroundColor: '#025279' //'#23232F' //'#0595DD'
        // },
    },
    composeInputPaper: {
        marginTop: '16px'
    },
    composeInput: {
        padding: '8px'
    },
    sendIcon: {
        color: '#2196f3'
    },
    chatRoomMessage: {
        display: 'flex',
        marginBottom: '8px'
    },
    messageText: {
        flex: 1,
        padding: '8px 0px 0px 16px',
        whiteSpace: 'pre-line'
    },
    messageDate: {
        // textAlign: 'right',
        alignSelf: 'flex-end'
    },
    divider: {
        marginBottom: '8px'
    }
});

class ChatText extends React.Component {
    constructor(props) {
        super(props)
        this.messagesEndRef = React.createRef();
        this.state = {
            //activeLike:'',
            //nblikes: ''
            convToShow: null, 
            sendMessage: ''
        };
    }
    componentDidMount = () => {
        this.setState({
            convToShow: this.props.convToShow
        })
        this.scrollToBottom()

    }
    componentDidUpdate(prevProps) {

        if (this.props.convToShow !== prevProps.convToShow) {
            console.log(this.props.convToShow)
            this.setState({
                convToShow: this.props.convToShow
            })

            //console.log(Object.values(this.state.convToShow))
            //console.log(Object.keys(this.state.convToShow))
        }
        this.scrollToBottom()
    }
    openProfil = (id) => {
        console.log("open Profil ", id)
    }
    scrollToBottom = () => {
        /*console.log(this.messagesEndRef)
        console.log(this.messagesEndRef.current)
        console.log(this.messagesEndRef.current.scrollTop)
        console.log(this.messagesEndRef.current.scrollHeight)
        console.log(this.messagesEndRef.current.clientHeight)*/
        this.messagesEndRef.current.scrollTop = this.messagesEndRef.current.scrollHeight;
        /* console.log(this.messagesEndRef.current.scrollTop)
         console.log(this.messagesEndRef.current.scrollToBottom)*/
        this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })

        //ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };
    /*scrollToBottom = () => {
       const { messageList } = this.refs;
       const scrollHeight = messageList.scrollHeight;
       const height = messageList.clientHeight;
       const maxScrollTop = scrollHeight - height;
       console.log(maxScrollTop)
       ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
       }*/
    /*scrollToBottom = () => {
        console.log(this.refs)
        // const { messageList } = this.messagesEndRef.current;
        const scrollHeight = this.messagesEndRef.current.scrollHeight;
        const height = this.messagesEndRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(this.messagesEndRef.current).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }*/
    /*scrollToBottom = () => {
        console.log(this.messagesEndRef)
        this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }*/
    handleEvent = (event) => {
        console.log("send Message ", event)
        console.log("send Message ", event.target.value)
        this.setState({
            sendMessage: event.target.value
        })

    }

    sendMessage = () => {
        this.props.sendMessage(this.state.sendMessage)
        this.setState({
            sendMessage: ""
        })
    }

    deleteMessage = (index) => {
        console.log(this.props)
        console.log(this.state)
        console.log(index)
        this.props.deleteMessage(index)
        //this.props.sendMessage(this.state.sendMessage)
        /*this.setState({
            sendMessage: ""
        })*/
        this.scrollToBottom()
    }

    UsersDetailsFromRole = (userProfile) => {
        if (userProfile.role === 0) {
            return (
                <AdminDetails profile={userProfile} />
            )
        }
        if (userProfile.role === 1) {
            return (
                <EntrepriseDetails profile={userProfile} />
            )
        }
        if (userProfile.role === 2) {
            return (
                <CandidatDetails profile={userProfile} />
            )
        }
    }
    render() {
        const { classes } = this.props;
        //console.log(this.state.convToShow)
        /*if (!this.state.convToShow) {
            //setcandidatsUserssss(candidatsUsers)
      
            return (
              <Grid container spacing={6}>
                <CircularProgress className={classes.progress} />
              </Grid>
            );
          }*/
        return (
            <section className={classes.root}>
                <Paper className={classes.chatInputPaper} elevation={4}>
                    <div className={classes.chatInput} ref={this.messagesEndRef}>

                        {this.state.convToShow ? Object.values(this.state.convToShow).map((item, index) => {
                            //console.log(item)
                            //console.log(index)
                            return (
                                <React.Fragment key={'messages-' + index} >
                                    <div className={classes.chatRoomMessage} key={'message-' + index}>
                                        {item.users && item.users[item.lastMessageSenderName] && item.users[item.lastMessageSenderName].profileImg != null ?
                                            <Avatar alt='Remy Sharp' src={item.users[item.lastMessageSenderName].profileImg} />
                                            :
                                            <Avatar alt='Remy Sharp' onClick={() => this.props.openProfile(item.sender)}>{item.sendername}</Avatar>
                                        }
                                        <Grid container direction="row" alignItems="center" className={classes.messageText}>
                                            <Grid item >
                                                <Typography variant="body2" component="p">
                                                    {item.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item style={{ paddingLeft: 5 }}>
                                                {item.navAnnonce && <RemoveRedEyeRoundedIcon onClick={() => this.props.openAnnonceDetails(item.navAnnonce)} />}
                                            </Grid>
                                        </Grid>
                                        <div className={classes.messageDate}>
                                            {item.created_at && item.created_at.seconds && moment.unix(item.created_at.seconds).format("DD/MM/YYYY à HH:mm:ss")}
                                        </div>
                                        <div className={classes.messageDate}>
                                            <IconButton onClick={() => this.deleteMessage(index)}>
                                                <DeleteIcon />
                                            </IconButton>

                                        </div>
                                    </div>
                                    <Divider className={classes.divider} key={index} />
                                </React.Fragment>
                            );
                        })
                            :
                            <Typography variant="h6" component="h2">
                                Veuillez sélectionner une conversation à afficher
                    </Typography>
                        }
                    </div>
                </Paper>
                <Paper className={classes.composeInputPaper} elevation={4}>
                    {this.state.convToShow &&
                        <Input classes={{ root: classes.composeInput }} multiline={true} fullWidth={true} disableUnderline={true} value={this.state.sendMessage} placeholder={"Envoyer un message!"}
                            endAdornment={<IconButton onClick={this.sendMessage} disabled={this.state.sendMessage.length >0?false:true}><SendIcon className={classes.searchIcon} /></IconButton>}
                            onChange={(event) => { this.handleEvent(event) }}
                        />
                    }
                </Paper>
            </section>
        );
    }

}

ChatText = withStyles(chatTextStyles)(ChatText);
export default ChatText