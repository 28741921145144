import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { annoncesValues, annoncesFields } from '../../constants/formField'
import Form from '../../utils/formBuilder';
import { Grid } from '@material-ui/core'

import TypeEmploi from './detailAnnonce/typeEmploi'
import TypeFormation from './detailAnnonce/typeFormation'

class AnnonceDetailsTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      annonce: this.props.annonce
    };
  }
  componentDidUpdate(prevProps) {
    console.log(this.props.annonce)
    if (prevProps.annonce !== this.props.annonce) {
      //console.log(this.props.annonce)
      this.setState({
        annonce: this.props.annonce,
      })
    }
    if (prevProps.Undo !== this.props.Undo && this.props.Undo) {
      console.log('Undo')
      this.setState({
        annonce: this.props.annonce,
      })
    }
  }

  render() {
    const { annonce } = this.state;
    if (annonce) {
      return (
        <Grid container justify={"center"} >
          <Grid item xs={8}>
            {annonce.type === "emploi" &&
              <TypeEmploi annonce={annonce} disabled={this.props.disabled} Save={this.props.Save} saveForm={this.props.saveForm} Undo={this.props.Undo} validate={this.props.validate} errors={this.props.errors} onChangeEdit={this.props.onChangeEdit} />
            }
            {annonce.type === "formation" &&
              <TypeFormation annonce={annonce} disabled={this.props.disabled} Save={this.props.Save} saveForm={this.props.saveForm} Undo={this.props.Undo} validate={this.props.validate} errors={this.props.errors} onChangeEdit={this.props.onChangeEdit} />
            }
            {annonce.type === "stage" &&
              <TypeEmploi annonce={annonce} disabled={this.props.disabled} Save={this.props.Save} saveForm={this.props.saveForm} Undo={this.props.Undo} validate={this.props.validate} errors={this.props.errors} onChangeEdit={this.props.onChangeEdit} />
            }
            {/*<form noValidate>
              <Form
                fieldContainerStyle={{ backgroundColor: '#fff', padding: 10 }}
                //inputContainerStyle={{ margin: '30px 0' }}
                onChange={(values) => console.log(values)}
                orientation={'horizontal'}
                onDelayedChange={(values) => console.log('delayed values: ', values)}
                delayTriggers={['simpleTextField']}
                delayTime={300}
                fields={annoncesFields}
                disabled={true}
                values={this.props.annonce}
                //actionContainerStyle={{ backgroundColor: '#000', padding: 10 }}
                //cancelForm={cancelForm}
                //cancelFormButtonName={"Annuler"}
                //saveForm={handleSubmit}
                //saveFormButtonName={"Créer"}
                errors={{ simpleTextFieldWithError: 'This is an error message.' }}
              />
            </form>*/}
          </Grid>
        </Grid>
      )
    } else {
      return (
        <div className="container center">
          <p>Loading project...</p>
        </div>
      )
    }
  }
}

export default AnnonceDetailsTemplate