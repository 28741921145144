// CardSection.js
import React from 'react';
import {CardElement} from 'react-stripe-elements';
import { withStyles } from "@material-ui/core/styles";
const createOptions = () => {
    return {
      style: {
        root: {
          padding: '11px 15px 11px 0',
        },
        base: {
          fontSize: '16px',
          padding: '11px 15px 11px 0',
          color: '#424770',
          fontFamily: 'Open Sans, sans-serif',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#c23d4b',
        },
      }
    }
  };

  const styles = theme => ({
    cardElement: {
      borderColor: 'rgb(207, 215, 223)',
      borderStyle: 'solid',
      borderWidth: '0.05em',
      // backgroundColor: 'green',
      marginTop: '1em',
      padding: '11px 15px 11px 10px',
    }
  });
class CardSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <CardElement
                className={classes.cardElement}
                onReady={(el) => el.focus()}
                //{...createOptions()}
            />
    );
  }
}

export default withStyles(styles)(CardSection);