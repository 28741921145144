import React, { useEffect, useState, Fragment } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar, MTableAction, MTableActions } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon'
import ImageIcon from '@material-ui/icons/ImageRounded'
import { fade, makeStyles } from '@material-ui/core/styles';
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector } from 'react-redux';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import _ from 'lodash'
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    textfield: {
        paddingBottom: 15
    },
    inputLabel: {
        color: "black",
        fontSize: 24,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        "&.focused": {
            color: "red"
        }
    },
    Table: {
        padding: theme.spacing(2),
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        height: '100px',       /* Just for the demo          */
        overflowY: 'auto',    /* Trigger vertical scroll    */
        overflowX: 'hidden'
    },
}));

const columns = () => [
    { title: 'Compteur', field: 'Compteur', editable: 'never' },
    { title: 'Ville', field: 'Ville' },
    { title: 'Date', field: 'Date', /*type: 'date',*/ },
    { title: 'Adresse', field: 'Adresse' },
    { title: 'Note', field: 'Note' },
    { title: 'Activer', field: 'Enabled', type: 'boolean' },
];
const SettingsMoisQuebec = (props) => {
    const firebase = useFirebase();
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    const [NewsDatas, setNewsDatas] = React.useState('')
    const [DiffDatas, setDiffDatas] = React.useState(false);
    const [moisQuebecData, setMoisQuebecData] = useState('');
    const [allInscriptionsData, setAllInscriptionsData] = useState('');
    const [diffMoisQuebecData, setDiffMoisQuebecData] = useState(false);
    const [exportData, setExportData] = useState(null);
    //console.log(props);
    useEffect(
        () => {
            if (props.MoisQuebec) {
                //setTimeout(() => {
                setMoisQuebecData(props.MoisQuebec)

                //}, 4500)
            }

        }, [props.MoisQuebec]
    )

    useEffect(
        () => {
            if (props.allInscriptions) {
                //setTimeout(() => {
                var arr = _.values(props.allInscriptions);
                setAllInscriptionsData(arr)
                if (arr.length === Object.keys(props.allInscriptions).length) {
                    let resultsExport = []
                    _.each(props.allInscriptions, (item) => {
                        _.each(item.inscriptions, (itemInscription) => {
                            resultsExport.push({ 'name': item.name, 'firstname': item.firstName, 'email': item.mail || item.email, 'Ville': itemInscription.Ville })
                        })
                    })
                    setIsLoading(false);
                    setExportData(resultsExport)
                }
                //  console.log(arr.length)
                //}, 1500)
            }

        }, [props.allInscriptions]
    )
    console.log(allInscriptionsData);
    useEffect(
        () => {
            if (!isLoading) {
                //setTimeout(() => {
                /* var arr = _.values(props.allInscriptions);
                 setAllInscriptionsData(arr)
                 if (arr.length === Object.keys(props.allInscriptions).length) {
                     setIsLoading(false);
                 }*/
                let newEvenementsData = moisQuebecData;
                allInscriptionsData.map((item, index) => {

                    //console.log(item.inscriptions)
                    item.inscriptions.map((inscription, index) => {
                        //console.log(inscription.docId)
                        //const count = moisQuebecData.evenements.filter(elem => elem.docId === inscription.docId).length;
                        moisQuebecData.evenements.map((even, index) => {
                            if (even.docId === inscription.docId) {
                                //console.log(moisQuebecData.evenements[index])
                                moisQuebecData.evenements[index].Compteur = even.Compteur ? moisQuebecData.evenements[index].Compteur + 1 : 1;
                            }
                        })
                        // console.log(moisQuebecData.evenements)
                        //if(inscription.docId === )
                    })
                })
                //  console.log(arr.length)
                //}, 1500)
            }

        }, [isLoading]
    )

    const [columnss, setColumnss] = React.useState([
        { title: 'Ville', field: 'Ville' },
        { title: 'Date', field: 'Date', /*type: 'date',*/ },
        { title: 'Adresse', field: 'Adresse' },
        { title: 'Note', field: 'Note' },
        { title: 'Activer', field: 'Enabled', type: 'boolean' },
        /* {
             title: "Image",
             //lookup: StaticLookup.baseAccountTypes,
             field: 'image',
             editComponent: tableData => (
                 <Fragment>
                     <input
                         color="primary"
                         accept="image/*"
                         type="file"
                         onChange={onChange}
                         id="icon-button-file"
                         value={file}
                     //style={{ display: 'none', }}
                     />
                     <Avatar alt="Cindy Baker" src={file} />
 
                     <label htmlFor="icon-button-file">
                         <Button
                             variant="contained"
                             component="span"
                             className={classes.button}
                             size="large"
                             color="primary"
                         >
                             <ImageIcon className={classes.extendedIcon} />
                         </Button>
                     </label>
                 </Fragment>
             ),
         },*/

    ]);
    const handleChangeMoisQuebec = (event) => {
        let newData = moisQuebecData;
        const fieldName = event.target.name;
        const newValue = event.target.value;
        console.log(fieldName);
        if (fieldName === 'Enabled') {
            newData[fieldName] = !newData.Enabled;
        }
        else {
            newData[fieldName] = newValue;
        }

        console.log(newData)
        setMoisQuebecData(newData);
        if (DiffDatas !== NewsDatas) {
            setDiffMoisQuebecData(true);
        }
        else {
            setDiffMoisQuebecData(false);
        }
        console.log(NewsDatas);
    }
    const saveNewData = async (toSaveDatas) => {
        console.log('save')
        //console.log(moisQuebecData)
        console.log(toSaveDatas)
        firebase.database().ref('constants/').update({
            MoisQuebec: toSaveDatas
        }).then(() => {
            console.log("Data saved successfully.");
            setDiffMoisQuebecData(false);
        }).catch((error) => {
            console.log("Data could not be saved." + error);
        });

    }
    const handleChangeMoisQuebecs = (event) => {
        //console.log(event.target.value);
        //console.log(event.target.name);
        const fieldName = event.target.name;
        const newValue = event.target.value;
        if (fieldName === 'Enabled') {
            setTimeout(() => {
                setMoisQuebecData({ ...moisQuebecData, Enabled: !moisQuebecData.Enabled });
            }, 50);
        }
        else {
            setTimeout(() => {
                setMoisQuebecData({ ...moisQuebecData, [fieldName]: newValue });
            }, 50);
        }
        setDiffMoisQuebecData(true)
    }
    /* const createTodo = (dataTemp) => {
         let children = props.listToShow.value.children ? '/children' : '/';
         return firebase.database().ref('constants/' + props.listToShow.key + children).set({
             ...dataTemp
         }, function (error) {
             if (error) {
                 // The write failed...
             } else {
                 // Data saved successfully!
             }
         });
     }*/

    //console.log(moisQuebecData);
    const onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                const moisQuebecDataTemp = moisQuebecData.evenements ? [...moisQuebecData.evenements] : [];
                newData.docId = uuidv4();

                moisQuebecDataTemp.push(newData);
                //console.log(moisQuebecDataTemp)
                setMoisQuebecData({ ...moisQuebecData, evenements: [...moisQuebecDataTemp] });

                //saveNewData({ ...moisQuebecData, evenements: [...moisQuebecDataTemp] });
                setDiffMoisQuebecData(true)
                resolve('Success!');
            }, 1000);
        });
    }



    const onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {

                let moisQuebecDataTemp = [...moisQuebecData.evenements];
                const index = moisQuebecDataTemp.indexOf(oldData);
                moisQuebecDataTemp.splice(index, 1);
                setMoisQuebecData({ ...moisQuebecData, evenements: [...moisQuebecDataTemp] });

                //saveNewData({ ...moisQuebecData, evenements: [...moisQuebecDataTemp] })
                setDiffMoisQuebecData(true)
                resolve();
            }, 1000);
        });

    const onRowUpdate = (newData, oldData) =>
        new Promise((resolve, reject) => {
            setTimeout(() => {

                let moisQuebecDataTemp = [...moisQuebecData.evenements];
                /* if (!newData.Value) {
                     newData.Value = newData.Name
                 }
                 if (!newData.Name) {
                     newData.Name = newData.Value
                 }*/
                const index = moisQuebecDataTemp.indexOf(oldData);
                //console.log("Temporal data", index, moisQuebecDataTemp);
                moisQuebecDataTemp[index] = newData;
                //console.log("New data", moisQuebecDataTemp);
                setMoisQuebecData({ ...moisQuebecData, evenements: [...moisQuebecDataTemp] });
                //console.log("SET STATE");
                setDiffMoisQuebecData(true)
                //saveNewData({ ...moisQuebecData, evenements: [...moisQuebecDataTemp] })

                resolve();
            }, 2500);
        });
    if (moisQuebecData /*&& moisQuebecData.evenements*/) {
        //console.log(moisQuebecData);
        //console.log(moisQuebecData.evenements.length);
        //let numOfElement = moisQuebecData.evenements && moisQuebecData.evenements.length ? moisQuebecData.evenements.length : 0;
        /*const columns = columnss.map((column) => {
            return { ...column };
          });*/
        return (
            <Box my={2}>
                <Paper variant="outlined" style={{ margin: 5, padding: 5 }}>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <Typography variant="caption" display={'block'} align={'left'}>
                                Activer le mois du Québec
                            </Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch checked={moisQuebecData.Enabled || false} onChange={handleChangeMoisQuebec} name="Enabled" />}
                                    label="Activer "
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label={'Titre'}
                                name={'Title'}
                                onChange={handleChangeMoisQuebec}
                                //onBlur={handleChangeMoisQuebec}
                                defaultValue={moisQuebecData.Title || ''}
                                fullWidth

                            //inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label={'Note'}
                                name={'Note'}
                                onChange={handleChangeMoisQuebec}
                                //onBlur={handleChangeMoisQuebec}
                                defaultValue={moisQuebecData.Note || ''}
                                fullWidth
                            //inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button variant="outlined" size="medium" color="primary" disabled={!diffMoisQuebecData} onClick={() => { saveNewData(moisQuebecData) }} >Mettre à jour</Button>
                </Grid>
                {moisQuebecData.evenements &&


                    <Paper variant="outlined" style={{ margin: 5, padding: 5 }}>
                        <h2>{allInscriptionsData.length} Inscriptions Gobales</h2>
                        <CSVLink data={exportData} filename={`Export LMDQ ${moment().format('DD/MM/YYYY HH:mm:ss')}`}>Exporter les inscrits</CSVLink>
                        <MaterialTable
                            title={"Evenements"}
                            columns={columns()}
                            data={moisQuebecData.evenements}
                            /* style={{height: '100%', 
                             overflowY: 'auto',    
                             overflowX: 'hidden'}}*/
                            isLoading={isLoading}
                            className={classes.Table}
                            localization={{
                                header: {
                                    actions: ''
                                }
                            }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsSelect: 'ligne(s)'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} ligne(s) selectionnez',
                                    searchPlaceholder: "Recherche"
                                },
                                header: {
                                    actions: 'Actions'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Aucuns enregistrement à afficher',
                                    filterRow: {
                                        filterTooltip: 'Filtre'
                                    },
                                    editRow: {
                                        deleteText: 'Etes vous sûre de vouloir supprimer la ligne ?'
                                    },
                                    editTooltip: "Modifier",
                                    deleteTooltip: "Supprimer"
                                }
                            }}
                            editable={{
                                isEditable: rowData => true,
                                isDeletable: rowData => true,
                                onRowAdd: onRowAdd,
                                onRowUpdate: onRowUpdate,
                                onRowDelete: onRowDelete
                            }}

                            options={{
                                rowStyle: rowData => ({
                                    backgroundColor: '#EEE'
                                }),
                                pageSize: 20,
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportCsv: (columns, data) => {
                                    alert('You should develop a code to export ' + allInscriptionsData.length + ' rows');
                                }
                                //toolbar: false
                                //actionsColumnIndex: onEdit ? 5 : undefined
                            }}
                        />

                    </Paper>
                }
            </Box>
        )
    }
    return null;
}

const mapStateToProps = (state, props) => ({

    allInscriptions: state.firestore.data.allInscriptions,
    MoisQuebec: state.firebase.data.constants.MoisQuebec

    //monProfil: state.firestore.data.monProfil
})
/*const mapStateToPropsss = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
  mesConversations: ordered.mesConversations,
});*/

const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(/*props => [
        {
            collectionGroup: 'users',
            where: [['inscriptions', '!=', null]],
            storeAs: 'allInscriptions'
        }
    ]*/),

    //withStyles(useStyles),
)(SettingsMoisQuebec)

//export default SettingsMoisQuebec