import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect, NavLink } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockCircle from '@material-ui/icons/LockRounded';
import * as yup from 'yup';
const useStyles = makeStyles(theme => ({
  root: {
    //flexGrow: 1,
    padding: theme.spacing(4),
    backgroundImage: `url('/images/quebecHDLow.jpg')`,
    backgroundSize: "cover",
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    padding: 8,
    textAlign: 'center',
    color: "white"
  },
  paper: {
    background: 'rgba(0, 0, 0, 0.8)',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(3),

    padding: '3em',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      width: '65vh',
    },
    [theme.breakpoints.up('lg')]: {
      width: '65vh',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40vh',
    },
    //background: 'rgba(0, 0, 0, 0.8)',
    //borderRadius: '10px',
    /* boxShadow:
     "0 0.4px 0.6px rgba(0, 0, 0, 0.141)",
     "0 1px 1.3px rgba(0, 0, 0, 0.202)",
     "0 1.9px 2.5px rgba(0, 0, 0, 0.25)",
     "0 3.4px 4.5px rgba(0, 0, 0, 0.298)",
     "0 6.3px 8.4px rgba(0, 0, 0, 0.359)",
     "0 15px 20px rgba(0, 0, 0, 0.5)",*/
  },
  label: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
    color: "white",
    paddingLeft: '10px',
    //color: 'white',
    background: 'transparent',
    border: 'none',
    outline: 'none',

  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
    color: "white",
    paddingLeft: '10px',
    //color: 'white',
    backgroundColor: 'rgba(255,255,255,0.10)',
    border: 'none',
    outline: 'none',
    "&&:-internal-autofill-selected": {
      backgroundColor: 'rgba(255,255,255,0.10) !important',
      backgroundImage: 'none !important',
      color: 'none !important',
    }
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: "white",
    "&&:disabled": {
      //backgroundColor: 'transparent !important',
      //backgroundImage: 'none !important',
      color: 'rgba(255, 255, 255, 0.5)',
    }
  },
  newAccount: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  Focused: {
    color: "white",
    // width: '40%',
    // borderColor: '#80bdff',
    //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    //backgroundColor: "#00FF00",
  },
  errorsRoot: {
    color: 'red',
    paddingLeft: '60px'
  }
}));

const checkoutAddressSchema = yup.object().shape({
  email: yup
    .string()
    .email("Veuillez vérifier votre email")
    .required("email est obligatoire"),
  password: yup
    .string()
    .required('Le mot de passe est requis'),
});

const SignIn = ({ auth, annonces, monProfil, authError, signIn }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = React.useState({});
  const [isValid, setIsValid] = React.useState(false)
  React.useEffect(
    () => {
      if (errors) {
        checkErrors(values)
      }

    },
    [values],
  );
  const handleChange = name => event => {
    console.log("change")
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const checkErrors = async () => {
    console.log("check errors")
    let errorsArray = [];
    let nameError = '';
    const validate = await checkoutAddressSchema.isValid(values);
    setIsValid(validate);
    checkoutAddressSchema.validate(values, { abortEarly: false }).catch(function (err) {
      console.log(err)
      if (err.inner.lenght > 0) {
        err.inner.map((item, index) => {
          //console.log(item)
          nameError = { ...nameError, [item.path]: item.message }
        })
      }
      else {
        //console.log(err.path)
        nameError = { ...nameError, [err.path]: err.message }
      }

      setErrors({ ...nameError });
    });
  }
  //class SignIn extends Component {

  const handleSubmit = (e) => {
    console.log('submit')
    console.log(values)
    e.preventDefault();
    signIn(values)
  }
  //const { authError, auth } = this.props;
  console.log(values)
  console.log(errors)

  console.log(isValid)
  if (auth.uid) return <Redirect to='/' />
  return (
    <div className={classes.root}>
      <Grid container
        alignItems={"center"}
        justify={"center"}
        direction={"column"}
        spacing={6}
      >

        <Grid item xs={10} lg={10}>
          {/*<Paper>
            <Typography variant="h6" component="h2" className={classes.title}>
              Connexion
            </Typography>

          </Paper>
          <Divider />*/}
          <Paper className={classes.paper} elevation={3}>

            <form className={classes.formContainer} noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Typography variant="h4" component="h2" className={classes.title}>
                Connexion
            </Typography>
              <TextField
                fullWidth
                autoComplete={"none"}
                type="email" id='email'
                //onChange={handleChange}
                label="Email"
                //className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                helperText={errors.email}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                //value={values.email}
                onChange={handleChange('email')}
                margin="normal"
              />
              <TextField
                type="password" id='password' //onChange={handleChange}
                label="Mot de passe"
                fullWidth
                autoComplete={"none"}
                //value={values.password}
                onChange={handleChange('password')}
                //className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: 'new-password',
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <LockCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                helperText={errors.password}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                margin="normal"
              />
              <Button variant="contained" color="primary" className={classes.button} disabled={!isValid} type={'submit'}>
                Se connecter
              </Button>
              <Typography variant="h6" component="h2" className={classes.title}>
                {authError ? <p>{authError}</p> : null}
              </Typography>
            </form>
          </Paper>
          {/*<div className={classes.newAccount}>
            <Button variant="contained" color="secondary" className={classes.button}
              component={NavLink} exact to="/signup"
            >Je n'ai pas de compte !</Button>
          </div>*/}

        </Grid>

      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)