import React, { Component } from 'react'
import axios from 'axios'
import WPAPI from 'wpapi'
//var WPAPI = require('wpapi');
//var wp = new WPAPI({ endpoint: 'https://www.shop.pierregagliardi.com/wp-json' });
// You must authenticate to be able to POST (create) a post
/*var wp = new WPAPI({
    endpoint: 'http://www.shop.pierregagliardi.com/wp-json',
    // This assumes you are using basic auth, as described further below
    username: 'firebase',
    password: 'bgavJgqdqw#x)nZt$8XOSm8t'
});*/
var wp = new WPAPI({
    /*endpoint: 'https://shop.pierregagliardi.com/wp-json',
    // This assumes you are using basic auth, as described further below
    username: 'firebase',
    password: 'bgavJgqdqw#x)nZt$8XOSm8t'*/
    endpoint: 'https://objectifquebec.ca/wp-json',
    // This assumes you are using basic auth, as described further below
    username: 'editeurMobile',
    password: 'Oc2Hyp!v%jM5oGGq)qNqmvfu'
});

export const deleteWPMedia = (Values) => {

    wp.posts().create({
        // "title" and "content" are the only required properties
        title: Values.title,
        content: Values.description,
        fields: {
            docID: Values.docId,
        },
        //categories: [wpCategory],
        // Post will be created as a draft by default if a specific "status"
        // is not specified
        status: Values.statut === true ? 'publish' : 'draft'
    }).then(function (response) {
        // "response" will hold all properties of your newly-created post,
        // including the unique `id` the post was assigned on creation
        console.log(response.id);
        //setValues({ ...Values, wpPostId: response.id })
    })
}

export const createWPMedia = (Values) => {

    wp.posts().create({
        // "title" and "content" are the only required properties
        title: Values.title,
        content: Values.description,
        fields: {
            docID: Values.docId,
        },
        //categories: [wpCategory],
        // Post will be created as a draft by default if a specific "status"
        // is not specified
        status: Values.statut === true ? 'publish' : 'draft'
    }).then(function (response) {
        // "response" will hold all properties of your newly-created post,
        // including the unique `id` the post was assigned on creation
        console.log(response.id);
        //setValues({ ...Values, wpPostId: response.id })
    })
}
export const getWPPosts = () => {
    return wp.posts().get()
        .then(async response => {
            return { action: 'getWPPosts', success: true, response: response };
            // do something with the returned posts
        })
        .catch(err => {
            //console.log('error', err);
            return { action: 'getWPPosts', success: false, response: err };
            // handle error
        });
}

export const createWPPost = (Values) => {

    wp.posts().create({
        // "title" and "content" are the only required properties
        title: Values.title,
        content: Values.description,
        excerpt: Values.description.substring(0, 150),
        fields: {
            docID: Values.docId,
        },
        //categories: [wpCategory],
        // Post will be created as a draft by default if a specific "status"
        // is not specified
        status: Values.statut === true ? 'publish' : 'draft'
    }).then(function (response) {
        // "response" will hold all properties of your newly-created post,
        // including the unique `id` the post was assigned on creation
        console.log(response.id);
        //setValues({ ...Values, wpPostId: response.id })
    })
}

export const updateWPPost = (Values) => {
    let myImageLink = "https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0";

    axios({
        url: myImageLink, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then(async (response) => {
        console.log(response.data);
        console.log(new Blob([response.data]));
        const file = new File([response.data], 'test.png');
        console.log(file)
        wp.media()
            // Specify a path to the file you want to upload, or a Buffer
            .file(file)
            .create({
                title: 'My awesome image',
                alt_text: 'an image of something awesome',
                caption: 'This is the caption text',
                description: 'More explanatory information'
            })
            .then(function (response) {
                // Your media is now uploaded: let's associate it with a post
                console.log(response)
                var newImageId = response.id;
                console.log(newImageId)
                wp.posts().id(33).update({
                    featured_media: newImageId
                }).then(function (response) {
                    console.log(response);
                })
                return wp.media().id(newImageId).update({
                    post: 33//associatedPostId
                });
            })
            .then(function (response) {
                console.log('Media ID #' + response.id);
                console.log('is now associated with Post ID #' + response.post);
            });
        /*let responseMediaCreation = await wp.media().file(file).create({
            title: 'My image'
        });
        await wp.posts().create({
            title: 'glagla',
            featured_media: responseMediaCreation.id,
            content: `<h2>glagla</h2><img src="${responseMediaCreation.source_url}" /><div>text</div>`,
            status: 'publish'
        });*/
    });
}

export const deleteWPPost = id => {
    return wp.posts().id(id).delete()
        .then(async response => {
            return { action: 'deleteWPPost', success: true, response: response };
        })
        .catch(err => {
            return { action: 'deleteWPPost', success: false, response: err };
        });
};

export const getWPCategories = (slug) => {
    if (slug) {
        return wp.categories().search(slug).get()
            .then(async response => {
                return { action: 'getWPCategories', success: true, response: response };
                // do something with the returned posts
            })
            .catch(err => {
                return { action: 'getWPCategories', success: false, response: err };
                // handle error
            });
    }
    else {
        return wp.categories().order( 'asc' ).orderby( 'slug' ).perPage( 100 ).get()   //perPage( -1 )
            .then(async response => {
                return { action: 'getWPCategories', success: true, response: response };
                // do something with the returned posts
            })
            .catch(err => {
                return { action: 'getWPCategories', success: false, response: err };
                // handle error
            });
    }

}

export default { getWPPosts, createWPPost, updateWPPost, deleteWPPost, createWPMedia, deleteWPMedia, getWPCategories }