import React from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import { withRouter } from "react-router";
import { compose } from 'redux'
import { loadMoreConversations, loadInitConversations, createMessage } from '../../store/actions/chatActions'
import { Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import ChatText from './ChatMessageList'
import ConversationsList from './ChatConvList'

import { AdminDetails, EntrepriseDetails, CandidatDetails } from '../users/components/RoleScreen/'

import AnnonceDetailsTemplate from '../annonces/AnnonceDetailsTemplate'

/* -------------------------- */
const chatDialogStyles = theme => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})


class ProfileDialog extends React.Component {

  UsersDetailsFromRole = (userProfile) => {
    if (userProfile.role === 0) {
      return (
        <AdminDetails profile={userProfile} />
      )
    }
    if (userProfile.role === 1) {
      return (
        <EntrepriseDetails profile={userProfile} />
      )
    }
    if (userProfile.role === 2) {
      return (
        <CandidatDetails profile={userProfile} />
      )
    }
  }

  render() {
    const { classes } = this.props;
    //console.log(this.props.profileToShow)
    return (
      <Dialog onClose={this.props.closeDialog} aria-labelledby="simple-dialog-title" open={this.props.open}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <h2>Profil Utilisateur</h2>
          <IconButton onClick={this.props.closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {this.props.profileToShow && this.UsersDetailsFromRole(this.props.profileToShow)}
      </Dialog>
    )
  }
}
ProfileDialog = withStyles(chatDialogStyles)(ProfileDialog);

class AnnonceDialog extends React.Component {


  render() {
    const { classes } = this.props;
    // console.log(this.props.annonceToShow)
    return (
      <Dialog onClose={this.props.closeDialog} aria-labelledby="simple-dialog-title" open={this.props.open}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <h2>Détails de l'annonce</h2>
          <IconButton onClick={this.props.closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {this.props.annonceToShow && <AnnonceDetailsTemplate annonce={this.props.annonceToShow} editable={false} />}
      </Dialog>
    )
  }
}
AnnonceDialog = withStyles(chatDialogStyles)(AnnonceDialog);

const chatToolbarStyles = theme => ({
  chatToolbarRoot: {
    color: '#FFF',
    margin: '0 8px',
    minHeight: '32px',
    backgroundColor: '#0377ad', //'#0595DD', //'#23232F',
    borderRadius: '2px'
  },
  innerContent: {
    width: '100%',
  },
  chatTitle: {
    margin: '0 auto'
  },
  searchWrapper: {
    backgroundColor: '#025279',
    borderRadius: '3px',
    border: 'solid 1px #025279',
    position: 'absolute',
    right: 0,
    color: '#FFF',
    margin: '0 8px'
  },
  searchInput: {
    width: '180px',
    paddingLeft: '8px',
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&::placeholder': {
      color: '#FFF',
      opacity: 1
    },
    '&:focus': {
      width: '300px'
    }
  },
  searchIcon: {
    color: '#FFF',
    marginTop: '3px'
  }
});


class ChatToolbar extends React.Component {

  render() {

    const { classes, convName } = this.props;
    //console.log(convName)
    //console.log(this.state.selectedConv)
    return (
      <Toolbar disableGutters={true} className={classes.chatToolbarRoot}>
        {this.props.showDeleteButton && <IconButton onClick={this.props.deleteConversation} >
          <DeleteIcon />
        </IconButton>}



        <Typography variant="body2" className={classes.chatTitle} color="inherit">
          En conversation avec {convName}
        </Typography>
       {/*} <Input classes={{ root: classes.searchWrapper, input: classes.searchInput }} disableUnderline={true} placeholder={"Recherche"}
          endAdornment={<SearchIcon className={classes.searchIcon} />}
        />*/}
      </Toolbar>
    );
  }

}

ChatToolbar = withStyles(chatToolbarStyles)(ChatToolbar);


/* -------------------------- */


const styles = theme => ({
  root: {
    margin: '16px',
    height: 'calc(90vh - 32px)'
  },
  paper: {
    height: '100%'
  },
  gridContainer: {
    height: 'inherit'
  },
  gridItem: {
    height: '100%'
  },
  '@global': {
    'body': {
      fontFamily: '"Roboto"'
    }
  }
});

class ChatRoom extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      //activeLike:'',
      //nblikes: ''
      convToShow: null,
      showProfile: false,
      selected: null,
      showAnnonce: false,
      isFetching: false,
      settings: this.props.settings,
      conversations: null,
      candidat: null
      //conversations: null
    };

  }

  componentDidMount = () => {
    this.props.loadInitConversations(100)
  }
  /*componentWillReceiveProps(nextProps) {
    //console.log(nextProps)
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.chat.conversations !== this.state.conversations) {
      this.setState({ conversations: nextProps.chat.conversations });
    }
    if (nextProps.chat.conversations !== this.state.conversations) {
      this.setState({ settings: nextProps.settings });
    }
  }*/
  componentDidUpdate(prevProps, prevState) {
    if (this.props.chat.conversations !== prevProps.chat.conversations) {
      console.log(this.props.chat.conversations)
      /* this.setState({
           convToShow: this.props.convToShow
       })*/
      this.setState({ conversations: this.props.chat.conversations, isFetching: false });
      //console.log(Object.values(this.state.convToShow))
      //console.log(Object.keys(this.state.convToShow))
    }
    if (this.props.settings !== prevProps.settings && this.props.settings !== null) {
      console.log(this.props.settings)
      this.setState({
        settings: this.props.settings
      })

      //console.log(Object.values(this.state.convToShow))
      //console.log(Object.keys(this.state.convToShow))
    }
    //this.scrollToBottom()
  }

  handleCloseProfile = () => {
    this.setState({
      showProfile: !this.state.showProfile
    })
  };

  handleCloseAnnonceDetails = () => {
    this.setState({
      showAnnonce: !this.state.showAnnonce
    })
  };

  convClick = (convId) => {
    console.log('conversation click', convId)
   // this.state.conversations && this.state.settings && this.state.conversations.map((item, index) => {

      //console.log(settings.groupes.administrateurs)
      let candidats = _.difference(convId.members, this.state.settings.groupes.administrateurs);
      console.log(candidats)
      this.setState({candidat: convId.users[candidats]})
   // })
    //_.difference(item.members, settings.groupes.administrateurs);
    //console.log(convId.id)
    /* this.props.firebase.firestore().collection('messages').doc(convId.id).onSnapshot(function (snapshot) {
       snapshot.docChanges().forEach(function (change) {
         if (change.type === "added") {
           console.log("New city: ", change.doc.data());
           console.log(change.doc)
           const docData = change.doc.data();
           console.log(docData)
           const id = change.doc.id
           this.setState({
             convToShow: docData,
             convToShowId: id,
             selected: convId.id,
             selectedConv: convId
           })
         }
       });
     });*/
    //if (convId.id != this.state.selected) {
    this.props.firebase.firestore().collection('messages').doc(convId.id)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          //console.log(snapshot.data())
          //console.log(doc)
          const docData = snapshot.data();
          //console.log(docData)
          const id = snapshot.id
          this.setState({
            convToShow: docData,
            convToShowId: id,
            selected: convId.id,
            selectedConv: convId
          });
          //console.log(this.state.convToShow.length)
          /* if (this.state.annonceFirstContact) {
               this._sendFirstMessage(this.state.annonceFirstContact)
               this.setState({
                   annonceFirstContact: null
               })
           }*/
        } else {
          console.log('PAS DE MESSAGES ON CREE LE PREMIER')
          this.props.firebase.firestore().collection('messages').doc(convId.id).set({
            0: [],
          });
          //console.log(snapshot.data())
          /*if (this.state.annonceFirstContact) {
              this._sendFirstMessage(this.state.annonceFirstContact)
              this.setState({
                  annonceFirstContact: null
              })
          }*/
          //this.messageRef.doc(this.props.navigation.state.params.itemDetail.id).set()
        }
      })
    //}
  }

  openProfile = (profileID) => {
    console.log('PROFILE OPEN click', profileID)
    //console.log(profileID)
    this.props.firebase.firestore().collection('users')
      .doc(profileID).get().then((doc) => {
        console.log(doc.data())
        const docData = doc.data();
        const id = doc.id
        this.setState({
          profileToShow: { id, ...docData }
        })
      })
    this.handleCloseProfile()
  }

  deleteConversation = () => {
    console.log('delete conversation')
    console.log(this.state.convToShowId)
    /*  this.props.firebase.firestore().collection("conversations").doc(this.state.convToShowId).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });*/
  }

  openAnnonceDetails = (annonceToShow) => {
    //console.log('Open Annonce Details')
    this.setState({
      annonceToShow: annonceToShow,
      disabled: true
    })
    this.handleCloseAnnonceDetails()
    /*  this.props.firebase.firestore().collection("conversations").doc(this.state.convToShowId).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });*/
  }

  loadMoreItems = (event) => {
    //console.log('loadMore ', document.documentElement.scrollTop)
    //console.log('loadMore ', event.target)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log('loadMore end')
      this.setState({
        isFetching: true
      });
      console.log(this.props.chat.conversations.length - 1)
      console.log(this.state.conversations.length - 1)
      //console.log(this.props.chat.conversations[this.props.chat.conversations.length - 1].lastMessageCreatedAt)
      this.props.loadMoreConversations(50, this.state.conversations[this.state.conversations.length - 1].lastMessageCreatedAt);
      //this.props.chat.conversations[this.props.chat.conversations.length - 1].lastMessageCreatedAt

      //setTimeout(() => {
      //adminsUsersSection = fromArrayToSectionData(adminsUsers)

      /*}, 2000);*/

    }
  }
  sendMessage = (message) => {
    let messageIndex = Object.keys(this.state.convToShow).length
    //this.props.sendMessage()
    this.props.firebase.firestore().collection('messages').doc(this.state.convToShowId).update({
      [messageIndex]: {
        message: message,
        sender: this.props.firebase.auth().currentUser.uid,
        sendername: this.props.profile ? this.props.profile.initials : this.props.navigation.state.params.profile.initials,
        senderfullName: this.props.profile ? this.props.profile.fullName : this.props.navigation.state.params.profile.fullName,
        created_at: this.props.firebase.firestore.Timestamp.now(),
      }
    })
    this.convClick(this.state.selectedConv)
  }

  deleteMessage = (index) => {
    let newMessagesConv = Object.values(this.state.convToShow)
    let arr = newMessagesConv.filter((item, idx) => idx !== index)
    //console.log(newMessagesConv)
    //console.log(arr)
    //console.log(this.state.convToShow)
    //console.log(this.state.convToShowId)
    //this.props.sendMessage()
    this.props.firebase.firestore().collection('messages').doc(this.state.convToShowId).set({
      ...arr
      /*[messageIndex]: {
          message: message,
          sender: this.props.firebase.auth().currentUser.uid,
          sendername: this.props.profile ? this.props.profile.initials : this.props.navigation.state.params.profile.initials,
          senderfullName: this.props.profile ? this.props.profile.fullName : this.props.navigation.state.params.profile.fullName,
          created_at: this.props.firebase.firestore.Timestamp.now(),
      }*/
    })
    //this.convClick(this.state.selectedConv)
  }

  setCandidat = (candidat) => {
    this.setState({candidat: candidat})
  }

  render() {

    const { classes, chat, mesConversations, auth, profile, monProfile, settings, compteurs } = this.props;
    //console.log(this.props);
    console.log(settings)
    console.log(this.state.isFetching)
    //console.log(auth)
    if (!auth.uid) return <Redirect to='/signin' />

    if (isEmpty(chat) && !isLoaded(chat) && isEmpty(settings) && !isLoaded(settings)) {
      //setcandidatsUserssss(candidatsUsers)
      console.log("empty")
      return (
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={2}>
            <Grid container spacing={0} justify={"center"} className={classes.gridContainer}>
              <Grid item md={2} className={classes.gridItem}>
                <CircularProgress className={classes.progress} />
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
    }
    else {
      let candidats = "";
      let candidatInitials = "";
      this.state.conversations && settings && this.state.conversations.map((item, index) => {

        //console.log(settings.groupes.administrateurs)
        candidats = _.difference(item.members, settings.groupes.administrateurs);
        //console.log(candidats)
        //let answered = false;
        candidatInitials = item.users[candidats] && item.users[candidats].initials ? item.users[candidats].initials : ' ';
       /* if (item.lastMessageSenderId != candidats) {
          answered = true
        }*/
      })
      console.log(this.state.candidat)
      //let conversationsOrdered = _.orderBy(mesConversations, ['lastMessageCreatedAt'], ['desc']);//_.sortBy(this.props.data, o => o.lastMessageCreatedAt)
      //console.log(chat.conversations)
      return (
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={0}>
            <Grid container spacing={0} justify={"center"} className={classes.gridContainer}>
              <Hidden mdDown>
                <Grid item xs={12} md={4} className={classes.gridItem}>
                  <ConversationsList mesConversations={this.state.conversations} settings={settings} compteurs={compteurs} convClick={this.convClick.bind(this)} auth={auth} selected={this.state.selected} onScroll={this.loadMoreItems} monProfile={profile} isFetching={this.state.isFetching} setCandidat={this.setCandidat} />
                  {this.state.isFetching &&
                    <Grid container spacing={0} justify={"center"} className={classes.gridContainer}>
                      <Grid item md={2} className={classes.gridItem}>
                        <CircularProgress className={classes.progress} />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Hidden>
              <Grid item xs={12} md={8} className={classes.gridItem}>
                {this.state.convToShow &&
                  <ChatToolbar showDeleteButton={this.state.convToShowId ? true : false} deleteConversation={this.deleteConversation} convName={this.state.candidat.initials || ' '/*this.state.selectedConv.lastMessageSenderName*/} />

                }
                <ChatText convToShow={this.state.convToShow} openProfile={this.openProfile} openAnnonceDetails={this.openAnnonceDetails} sendMessage={this.sendMessage} deleteMessage={this.deleteMessage} />
              </Grid>
            </Grid>
          </Paper>
          <ProfileDialog closeDialog={this.handleCloseProfile} open={this.state.showProfile} profileToShow={this.state.profileToShow} />
          <AnnonceDialog closeDialog={this.handleCloseAnnonceDetails} open={this.state.showAnnonce} annonceToShow={this.state.annonceToShow} disabled={true} />
        </div>
      );
    }
  }

}

const mapStateToProps = (state, props) => ({

  profile: state.firebase.profile,
  auth: state.firebase.auth,
  chat: state.chat,
  settings: state.firestore.data.settings,
  mesConversations: state.firestore.ordered.mesConversations,
  compteurs: state.firebase.data.compteurs,

  //monProfil: state.firestore.data.monProfil
})
/*const mapStateToPropsss = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
  mesConversations: ordered.mesConversations,
});*/

const mapDispatchToProps = dispatch => {
  //console.log(dispatch)
  return {
    loadInitConversations: (limit) => dispatch(loadInitConversations(limit)),
    loadMoreConversations: (limit, startAfter) => dispatch(loadMoreConversations(limit, startAfter))
  }
}

//export default withStyles(styles)(ChatRoom);
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect((props, store) => {
    //console.log(props)
    //console.log(store)
    return [
      { path: 'compteurs' }, // make sure to pass "populates"
    ]
  }),
  firestoreConnect(() => ['settings']),

  withStyles(styles),
)(ChatRoom)