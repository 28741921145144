import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect, NavLink } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockCircle from '@material-ui/icons/LockRounded';
import ForwardCircle from '@material-ui/icons/ForwardRounded';
import { signUp } from '../../store/actions/authActions'
import * as yup from 'yup';
import Regex from '../../constants/Regex'

import { getInitial, getNomComplet, upperCaseName } from '../../utils/functions'

const useStyles = makeStyles(theme => ({
  root: {
    //flexGrow: 1,
    padding: theme.spacing(4),
    backgroundImage: `url('/images/quebecHDLow.jpg')`,
    backgroundSize: "cover",
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    padding: 8,
    textAlign: 'center',
    color: "white"
  },
  paper: {
    background: 'rgba(0, 0, 0, 0.8)',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    //width: '20vw',
    padding: '3em',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      width: '65vh',
    },
    [theme.breakpoints.up('lg')]: {
      width: '65vh',
    },
    [theme.breakpoints.up('xl')]: {
      width: '80vh',
    },
    //background: 'rgba(0, 0, 0, 0.8)',
    //borderRadius: '10px',
    /* boxShadow:
     "0 0.4px 0.6px rgba(0, 0, 0, 0.141)",
     "0 1px 1.3px rgba(0, 0, 0, 0.202)",
     "0 1.9px 2.5px rgba(0, 0, 0, 0.25)",
     "0 3.4px 4.5px rgba(0, 0, 0, 0.298)",
     "0 6.3px 8.4px rgba(0, 0, 0, 0.359)",
     "0 15px 20px rgba(0, 0, 0, 0.5)",*/
  },
  label: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white!important"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
    color: "white",
    paddingLeft: '10px',
    //color: 'white',
    background: 'transparent',
    border: 'none',
    outline: 'none',

  },
  textFieldInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
    color: "white",
    paddingLeft: '10px',
    //color: 'white',
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    outline: 'none',
    "&&:-internal-autofill-selected": {
      backgroundColor: 'transparent !important',
      backgroundImage: 'none !important',
      color: 'none !important',
    }
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: "white",
    "&&:disabled": {
      //backgroundColor: 'transparent !important',
      //backgroundImage: 'none !important',
      color: 'rgba(255, 255, 255, 0.5)',
    }
  },
  newAccount: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  Focused: {
    color: "white",
    // width: '40%',
    // borderColor: '#80bdff',
    //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    //backgroundColor: "#00FF00",
  },
  errorsRoot: {
    color: 'red',
    paddingLeft: '60px'
  }
}));

const checkoutAddressSchema = yup.object().shape({
  email: yup
    .string()
    .email("Veuillez vérifier votre email")
    .required("email est obligatoire"),
  firstName: yup
    .string()
    .required("Le prénom est requis"),
  name: yup
    .string()
    .required("Le nom est requis"),
  companyName: yup
    .string()
    .required("Le nom de l'entreprise est requis"),
  recruteur: yup
    .boolean(),
  password: yup
    .string()
    .required('Le mot de passe est requis'),
  confirmPassword: yup
    .string()
    .required('Confirmation du mot de passe requise')
    .oneOf([yup.ref('password'), null], 'Les mots de passes doivent correspondrent')
});

const SignUp = ({ auth, annonces, monProfil, authError, signUp }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    firstName: '',
    name: '',
    companyName: '',
    recruteur: false
  });
  const [errors, setErrors] = React.useState({});
  const [isValid, setIsValid] = React.useState(false)
 
  React.useEffect(
    () => {
      checkErrors(values)
    },
    [values],
  );
  const handleChange = name => event => {
    console.log("change")
    console.log(name)
    console.log(event.target.checked)
    //setState({ ...state, [name]: event.target.checked });
    if (name === 'recruteur') {
      setValues({ ...values, [name]: event.target.checked });
    }
    else {
      setValues({ ...values, [name]: event.target.value });
    }

  };
  const checkErrors = async () => {
    console.log("check errors")
    let errorsArray = [];
    let nameError = '';
    const validate = await checkoutAddressSchema.isValid(values);
    setIsValid(validate);
    checkoutAddressSchema.validate(values, { abortEarly: false }).catch(function (err) {
      err.inner.map((item, index) => {
        nameError = { ...nameError, [item.path]: item.message }
      })
      setErrors({ ...nameError });
    });
  }
  //class SignIn extends Component {

  const handleSubmit = (e) => {
    console.log('submit')
    console.log(values)
    e.preventDefault();
    signUp(values)
  }
  //const { authError, auth } = this.props;

  if (auth.uid) return <Redirect to='/' />
  console.log(errors)
  console.log(errors.email)
  console.log(isValid)
  return (
    <div className={classes.root}>
      <Grid container
        alignItems={"center"}
        justify={"center"}
        direction={"column"}
        spacing={6}
      >

        <Grid item xs={10} lg={10}>
          {/*<Paper>
            <Typography variant="h6" component="h2" className={classes.title}>
              Connexion
            </Typography>

          </Paper>
          <Divider />*/}
          <Paper className={classes.paper} elevation={3}>

            <form className={classes.formContainer} noValidate autoComplete="off" >
              <Typography variant="h4" component="h2" className={classes.title}>
                Création de compte
            </Typography>
              <TextField
                fullWidth
                autoComplete={"none"}
                type="email" id='email'
                autoFocus={true}
                //onChange={handleChange}
                label="Email"
                //className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                    focused: classes.Focused
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.label,
                    //input: classes.textFieldInput,
                    //focused: classes.Focused
                  },
                  //className: classes.label,
                }}
                helperText={errors.email}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                //value={values.email}
                onChange={handleChange('email')}
                margin="normal"
              />
              <TextField
                type="password" id='password' //onChange={handleChange}
                label="Mot de passe"
                fullWidth
                autoComplete={"none"}
                //value={values.password}
                onChange={handleChange('password')}
                //className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: 'new-password',
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <LockCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                helperText={errors.password}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                margin="normal"
              />
              <TextField
                type="confirmPassword" id='confirmPassword' //onChange={handleChange}
                label="Confirmez le mot de passe"
                fullWidth
                autoComplete={"none"}
                //value={values.password}
                onChange={handleChange('confirmPassword')}
                //className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: 'new-password',
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <LockCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                helperText={errors.confirmPassword}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                margin="normal"
              />
              <FormControl /*component="fieldset"*/ fullWidth margin="normal" >
                <InputLabel component="label"
                  className={classes.label}
                  shrink
                >Recruteur ?</InputLabel>

                <FormGroup style={{ marginTop: '16px' }} row>

                  <FormControlLabel
                    value="end"
                    labelPlacement="end"
                    label={values.recruteur ? "Oui" : "Non"}
                    className={classes.textField}
                    control={
                      <React.Fragment>
                        <InputAdornment position="start" className={classes.Focused}>
                          <ForwardCircle />
                        </InputAdornment>
                        <Switch
                          checked={values.recruteur}
                          onChange={handleChange('recruteur')}

                        /*inputProps={{
                          //disableUnderline: true,
                          classes: {
                            root: classes.textField,
                            input: classes.textFieldInput,
                          },
                        }}*/

                        />


                      </React.Fragment>
                    }

                  />
                </FormGroup>
                {/*<FormHelperText>Be careful</FormHelperText>*/}
              </FormControl>

              <TextField
                id='companyName' //onChange={handleChange}
                label="Nom de l'entreprise"
                fullWidth
                autoComplete={"none"}
                //value={values.password}
                onChange={handleChange('companyName')}
                //className={classes.textField}

                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <ForwardCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                helperText={errors.companyName}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                margin="normal"
              />
              <TextField
                id='firstName' //onChange={handleChange}
                label="Prénom du gérant"
                fullWidth
                autoComplete={"none"}
                //value={values.password}
                onChange={handleChange('firstName')}
                //className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <ForwardCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                helperText={errors.firstName}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                margin="normal"
              />
              <TextField
                id='name' //onChange={handleChange}
                label="Nom du gérant"
                fullWidth
                autoComplete={"none"}
                //value={values.password}
                onChange={handleChange('name')}
                //className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.textField,
                    input: classes.textFieldInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start" className={classes.Focused}>
                      <ForwardCircle />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                helperText={errors.name}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorsRoot,
                    error: classes.errors
                  }
                }}
                margin="normal"
              />
              <Button variant="contained" color="primary" className={classes.button} disabled={!isValid} onClick={handleSubmit}>
                Créer le compte
              </Button>
              <Typography variant="h6" component="h2" className={classes.title}>
                {authError ? <p>{authError}</p> : null}
              </Typography>
            </form>
          </Paper>
          <div className={classes.newAccount}>
             <Button variant="contained" color="secondary" className={classes.button}
             component={NavLink} exact to="/signin"
             >Me connecter !</Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds) => dispatch(signUp(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)