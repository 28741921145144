import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        //marginTop: theme.spacing(2),
    },
    divRoot: {
        height: '36px'
    },
    input: {
        padding: "8.5px 14px"
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultiSelect({ field, selectedValue, errors, disabled, onChange, multiline, constants, ...others }) {
    const classes = useStyles();
    const theme = useTheme();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
console.log(constants)
    const key = field.key || field.name || 'key';
    const inputKey = `form-${key}`;
    const name = field.name || 'field';
    const label = field.label || 'label';
    let items =  '';
    if(constants) {
       items = constants && constants[field.items] && constants[field.items].children ? constants[field.items].children : constants[field.items] ? constants[field.items] : '';

    }
    const selectFieldMap = field.selectFieldMap || '';
    const fullWidth = field.fullWidth || false;
    const helpertext = field.helpertext || '';
    const type = field.type || 'text';
    const [personName, setPersonName] = React.useState([]);
    console.log(items)
    /*const handleChange = event => {
       setPersonName(event.target.value);
     };
   
     const handleChangeMultiple = event => {
       const { options } = event.target;
       const value = [];
       for (let i = 0, l = options.length; i < l; i += 1) {
         if (options[i].selected) {
           value.push(options[i].value);
         }
       }
       setPersonName(value);
     };*/
console.log(selectedValue)
    return (
        <div>
            <FormControl className={classes.formControl} fullWidth={fullWidth} variant="outlined" >
                <InputLabel ref={inputLabel} htmlFor="age-native-simple" shrink={true}  >{label}</InputLabel>
                <Select
                    disabled={disabled}
                    value={selectedValue === null ? ["Aucun"] : selectedValue}
                    multiple
                    onChange={onChange}
                    className={classes.root}
                    input={<OutlinedInput labelWidth={labelWidth} notched={true} classes={{ input: classes.input }} />}
                    //labelWidth={63.5}
                    inputProps={{
                        name: key,
                        id: key,
                    }}
                    MenuProps={MenuProps}
                >
                    <MenuItem key={"empty"} value={""} >
                        Aucun
                    </MenuItem>
                    {items && items.map((item, index) => (
                        <MenuItem key={index} value={item.Value} >
                            {item.Name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}