import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import {abonnements} from '../../../constants/abonnement'
const useStyles = makeStyles(theme => ({
    title: {
        padding: 8
    },
    NoAboActive: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        padding: 8,
        textTransform: 'uppercase'
        // marginBottom: '10px'
    },
    AboActive: {
        backgroundColor: 'green', //theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        padding: 8,
        // marginBottom: '10px'
    }
}));

const InfosAbonnement = ({ userActivity, monProfil }) => {
    const classes = useStyles();
    console.log(userActivity)
    return (
        <Paper>
            {!monProfil.activeAbonnement ?

                <Typography variant="h6" component="h6" gutterBottom className={classes.NoAboActive} >ABONNEMENT EXPIRÉ {monProfil.aboExpireDate ? '(Depuis le ' +  moment.unix(monProfil.aboExpireDate.seconds).format("DD/MM/YYYY") : null} ! (Consultation uniquement)</Typography>
                :
                <Typography variant="h6" component="h6" gutterBottom className={classes.AboActive} >ABONNEMENT {abonnements[monProfil.aboFormule] && abonnements[monProfil.aboFormule].title} ACTIF ! (Jusqu'au {monProfil.aboExpireDate && moment.unix(monProfil.aboExpireDate.seconds).format("DD/MM/YYYY")})</Typography>
            } 
        </Paper>
    )
}

export default InfosAbonnement