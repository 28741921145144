import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useFirestore, useFirebase } from "react-redux-firebase";
import { compose } from 'redux'
//import AnnonceSummaryCard from './AnnonceSummaryCard'
//import CreateAnnonce from './CreateAnnonce'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'


// import style manually

import { fade, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image'
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ImageIcon from '@material-ui/icons/Image';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import 'moment/locale/fr'
import _ from 'lodash'
import ImagePickerUpload from '../../utils/imagePickerUpload'
import CKEditorField from '../../utils/CKEditorField'
moment.locale('fr');
const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
}))

// Finish!
function OptionSecteurs(items) {
    console.log(items)

    return (
        items.map((item, i) => (
            <option key={i} value={item.docId}>{item.Value}</option>
        ))
    )
}
// Finish!
function OptionSecteursMultiple(items) {
    console.log(items)

    return (
        items.map((item, i) => (
            <MenuItem key={i} value={item.docId} name={item.Value} >
                {item.Value}
            </MenuItem>
        ))
    )
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FormPost = (props) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [docId, setDocId] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [expanded, setExpanded] = React.useState(false);
    const [Values, setValues] = React.useState();
    const [prevValues, setPrevValues] = React.useState();
    const [checked, setChecked] = React.useState(false);
    const [image, setImage] = React.useState(null);
    //const [selectedIndex, setSelectedIndex] = React.useState(1);
    useEffect(() => {
        // console.log('mounted or updated')
        if (props.edit) {
            setValues(props.postValues);
            //setValues({ ...props.postValues, modified: firestore.FieldValue.serverTimestamp() });
        } else {
            const ref = firestore.collection('emplois').doc();
            setDocId(ref.id);
            setValues({ ...Values, docId: ref.id, modified: firestore.Timestamp.fromDate(new Date()), statut: true })
            //updateParentValues();
        }


    }, []);
    useEffect(() => {
        setTimeout(() => {
            props.updateValues(Values);
        }, 100);
    }, [Values])

    const onChangeInput = (event) => {
        if (event.target.name === 'created') {
            setValues({ ...Values, [event.target.name]: firestore.Timestamp.fromDate(new Date(event.target.value)) })

        }
        else if (event.target.name === 'modified') {
            setValues({ ...Values, [event.target.name]: firestore.Timestamp.fromDate(moment(event.target.value).toDate()) })
        }
        else if (event.target.name === 'secteur') {
            const secteurName = props.secteurs.reduce((a, o) => (

                event.target.value.indexOf(o.docId) !== -1 && a.push(o.Value), a
            ), [])
            setValues({ ...Values, 'secteur': secteurName, 'secteur_id': event.target.value })
        }
        else {
            setValues({ ...Values, [event.target.name]: event.target.value })
        }
    }

    const toggleChecked = (event) => {
        setChecked((prev) => !prev);
        setValues({ ...Values, [event.target.name]: !Values[event.target.name] })
        //updateParentValues();
        //setValues({ ...Values, statut: !Values.statut })
    };

    const handleEditorChange = ((event, editor) => {
        const data = editor.getData();
        console.log({ event, editor, data });
        //console.log('handleEditorChange', html, text)
        setPrevValues({ ...prevValues, description: data })
        setValues({ ...Values, description: data })
        //updateParentValues();
        /*if (prevValues.description !== data) {
            console.log('difference markdown');
            //console.log(text)
            //console.log(html)
        }*/
    })

    const handleImageState = (files) => {
        console.log(files)
        var file = files[0];
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = event => {
            const dataURL = event.target.result
            console.log(dataURL)
            setImage(dataURL)
            //document.getElementById('image').src = dataURL
        }

        console.log(reader.readAsDataURL(file))
        setValues({ ...Values, backgroundImage: files })
        //updateParentValues();
        //console.log(url); // Would see a path?
        //setValues({ ...Values, selectedFile: event.target.files[0] })

    }


    const saveForm = () => {
        props.saveForm(Values)
    }
    console.log(Values)

    if (!Values) {
        console.log('loading')
        return (
            <Box>
                LOADING
            </Box>
        )
    }
    return (
        <Box>
            <Grid
                container
                justify={"center"}
                spacing={5}
                style={{
                    backgroundColor: '#fff',
                    padding: 10,
                    overflowY: 'scroll',
                    // ...this.props.fieldContainerStyle,
                }}
                flex={1}
            //wrap={this.props.wrap}
            >
                <Grid item xs={10}>
                    <Grid container
                        justify={"center"}
                        spacing={5}
                        style={{
                            backgroundColor: '#fff',
                            padding: 10,
                            overflowY: 'scroll',
                            // ...this.props.fieldContainerStyle,
                        }}
                        flex={1}
                    //wrap={this.props.wrap}
                    >
                        <Grid item xs={5}>
                            <TextField
                                label={"Titre"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={onChangeInput}
                                value={Values.title || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    name: 'title',
                                    id: 'title',
                                }}
                            //required
                            //error={errors.title ? true : false}
                            //helperText={errors.title}
                            />

                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                id="created"
                                name={"created"}
                                label="Date création"
                                type="datetime-local"
                                variant={"outlined"}
                                onChange={onChangeInput}
                                value={Values.created ? moment(Values.created.seconds, "X").format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) || '' : moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)}

                                //value={!props.edit ? moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)/*.slice(0, -8)*/ : Values.created && moment(Values.created.seconds, "X").format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)/*.toISOString().slice(0, -8)*/ || ''} //format("DD/MM/YYYY HH:mm")
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={props.edit && true}
                            />
                            <TextField
                                id="modified"
                                name={"modified"}
                                label="Date modification"
                                type="datetime-local"
                                variant={"outlined"}
                                onChange={onChangeInput}
                                value={Values.modified ? moment(Values.modified.seconds, "X").format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) || '' : moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)}

                                //value={!props.edit ? moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)/*.slice(0, -8)*/ : Values.modified && moment(Values.modified.seconds, "X").format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)/*.toISOString().slice(0, -8)*/ || ''} //format("DD/MM/YYYY HH:mm")
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            //disabled={!props.edit && true}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label={"Site Internet"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={onChangeInput}
                                value={Values.webSite || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    name: 'webSite',
                                    id: 'webSite',
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label={"E-mail"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={onChangeInput}
                                value={Values.email || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    name: 'email',
                                    id: 'email',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid container >
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={Values.statut || false} onChange={toggleChecked} />}
                                    label="Publié ?"
                                    id="statut"
                                    name={"statut"}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={Values.hideTitle || false} onChange={toggleChecked} />}
                                    label="Cacher le titre (tuile) ?"
                                    id="hideTitle"
                                    name={"hideTitle"}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={Values.hideTitleInPost || false} onChange={toggleChecked} />}
                                    label="Cacher le titre (article) ?"
                                    id="hideTitleInPost"
                                    name={"hideTitleInPost"}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={10}>
                    <CKEditorField data={Values.description || ''} config={null} onChange={handleEditorChange} />

                </Grid>
                <Grid item xs={2}>
                    <ImagePickerUpload handleImageState={handleImageState} image={/*props.postValues && props.postValues.image ? Values.image :*/ image && image !== Values.image ? image : Values.image} />
                </Grid>
            </Grid>
            {/*<Box style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex', ...props.actionContainerStyle }}>
                {props.cancelForm && (
                    <Button
                        variant="contained"
                        onClick={() => props.cancelForm()}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}
                    >
                        {props.cancelFormButtonName}
                    </Button>
                )}
                {props.saveForm && (
                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={() => saveForm()}
                        //disabled={formNotValid.length > 0 ? true : false}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}
                    >
                        {props.saveFormButtonName}
                    </Button>
                )}
            </Box>*/}
        </Box>
    )
}

export default FormPost
