import React, { Component } from 'react'

import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import moment from 'moment'
import { AdminDetails, EntrepriseDetails, CandidatDetails } from '../users/components/RoleScreen/'


const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8,
        textAlign: 'center'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    form: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //width: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: '90%'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    rootGrid: {
        flexGrow: 1,
      },
}));


const UsersDetailsFromRole = (userProfile) => {
    //console.log(userProfile)
    if (userProfile.role === 0) {
        return (
            <AdminDetails profile={userProfile} />
        )
    }
    if (userProfile.role === 1) {
        return (
            <EntrepriseDetails profile={userProfile} />
        )
    }
    if (userProfile.role === 2) {
        return (
            <CandidatDetails profile={userProfile} />
        )
    }
}
//class Dashboard extends Component {
const MonCompte = ({ auth, profile }) => {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        profile: {...profile, id: auth.uid}
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    //render() {
    //const { annonces, auth } = props;
    console.log(values)
    if (!auth.uid) return <Redirect to='/signin' />
    if (!isLoaded(profile)) {
        //setcandidatsUserssss(candidatsUsers)

        return (
            <Grid container spacing={6}>
                <CircularProgress className={classes.progress} />
            </Grid>
        );
    }
    return (
        <div className={classes.root}>
            <Grid container justify="center" className={classes.rootGrid} spacing={4}>

                <Grid item xs={12}>
                    <Paper component="div" m={1}>
                        <Box component="div" m={1} style={{textAlign: 'center'}}>
                            <Typography variant="h6" component="h2" className={classes.title}>
                                Mon Compte
                        </Typography>
                            <Typography variant="body2" component="p" >
                                {profile.dateInscription && "Inscrit le : " + moment.unix(profile.dateInscription.seconds).format('DD/MM/YYYY à HH:MM')}
                            </Typography>
                            <Typography variant="body2" component="p" >
                                {profile.dateModification && "Modification le : " + moment.unix(profile.dateModification.seconds).format('DD/MM/YYYY à HH:MM')}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper component="div" m={1}>
                        {UsersDetailsFromRole({...profile, id: auth.uid})}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
    //}
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        //annonces: state.firestore.ordered.annonces,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
//export default MonCompte
export default compose(
    connect(mapStateToProps),
    firebaseConnect(),
    firestoreConnect(),
)(MonCompte)