export const abonnements = [
    {
      title: 'DECOUVERTE',
      subheader: '',
      price: '25',
      priceTxt: "150 $ / mois ou 700 $ / an",
      description: [
        "L’entreprise gagne 2 $ par clic « postuler »",
        "L’entreprise contacte un candidat par un clic OQ gagne 20 $",
        "Le candidat répond à la demande de l’entreprise par un clic OQ gagne 200 $"
      ],
      buttonText: 'Souscrire',
      buttonVariant: 'contained',
    },
    {
      title: 'ENGAGEMENT',
      subheader: '',
      price: '18',
      priceTxt: "250 $ / mois ou 1 300 $/ an ",
      description: [
        "Offert frais avocat pour un dossier d’immigration",
        "L’entreprise gagne 2 $ par clic « postuler »",
        "L’entreprise contacte un candidat par un clic OQ gagne 20 $",
        "Le candidat répond à la demande de l’entreprise par un clic OQ gagne 200 $",
        "Au delà d’un dossier : tarif préférentiel 600 $ / candidat (pour un permis jeune pro )",
      ],
      buttonText: 'Souscrire',
      buttonVariant: 'contained',
    },
    {
      title: 'TOUT COMPRIS PERSONNALISEE',
      subheader: '',
      price: '30',
      priceTxt: "400 $ / mois ou 2 200 $ / an",
      description: [
        "Recherche de candidat",
        "Analyse des profils",
        "Par candidat retenu 5000 $",
        "Avec:",
        "Frais d’avocat",
        "Préparation au départ",
        "Recherche de logement",
        "Un interlocuteur attitré"
      ],
      buttonText: 'Souscrire',
      buttonVariant: 'contained',
    },
  ];