import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import Box from '@material-ui/core/Box';
//import Box, { VBox } from 'react-layout-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
//import Select from '../../../utils/components/Select'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import 'moment-timezone';

//import DateFnsUtils from '@date-io/date-fns';
/*import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';*/


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const useStyles = makeStyles(theme => ({
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        //marginTop: theme.spacing(2),
    },
    divRoot: {
        height: '36px'
    },
    input: {
        padding: "14.5px 14px"
    }
}));
const styles = {
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        //marginTop: theme.spacing(2),
    },
    divRoot: {
        height: '36px'
    },
    input: {
        padding: "18.5px 14px"
    },
    notchedOutline: { borderColor: 'green' },
};
function OptionEntreprises(items) {
    //console.log(items)

    return (
        items.map((item, i) => (
            <option key={i} value={item.id}>{item.companyName}</option>
        ))
    )
}
function OptionSecteursActivites(items) {
    //console.log(items)

    return (
        items.map((item, i) => (
            <option key={i} value={item.Value}>{item.Name}</option>
        ))
    )
}

function OptionRegions(items) {
    //console.log(items)

    return (
        items.map((item, i) => (
            <option key={i} value={item.Value}>{item.Name}</option>
        ))
    )
}


function Option(items, selectFieldMap) {
    //console.log(items)

    return (
        items.map((item, i) => (
            <option key={i} value={item.value}>{item.title}</option>
        ))
    )
}
class TypeEmploi extends React.Component {
    style = {
        width: '100%',
    };

    constructor(props) {
        super(props);
        this.state = {
            //values: this.props.values,
            timeout: null,
            disabled: this.props.disabled,
            index: 0,
            selectedDate: "",
            emploiUndo: this.props.annonces,
            errors: null,
            value: 0,
            emploi: {
                id: this.props.annonce.id,
                title: this.props.annonce.title ? this.props.annonce.title : "",
                description: this.props.annonce.description ? this.props.annonce.description : "",
                description_formatted: this.props.annonce.description_formatted ? this.props.annonce.description_formatted : "",
                competences: this.props.annonce.competences ? this.props.annonce.competences : "",
                competences_formatted: this.props.annonce.competences_formatted ? this.props.annonce.competences_formatted : "",
                conditions: this.props.annonce.conditions ? this.props.annonce.conditions : "",
                conditions_formatted: this.props.annonce.conditions_formatted ? this.props.annonce.conditions_formatted : "",
                description_qc: this.props.annonce.description_qc ? this.props.annonce.description_qc : "",
                description_formatted_qc: this.props.annonce.description_formatted_qc ? this.props.annonce.description_formatted_qc : "",
                competences_qc: this.props.annonce.competences_qc ? this.props.annonce.competences_qc : "",
                competences_formatted_qc: this.props.annonce.competences_formatted_qc ? this.props.annonce.competences_formatted_qc : "",
                conditions_qc: this.props.annonce.conditions_qc ? this.props.annonce.conditions_qc : "",
                conditions_formatted_qc: this.props.annonce.conditions_formatted_qc ? this.props.annonce.conditions_formatted_qc : "",
                idEntreprise: this.props.annonce.idEntreprise ? this.props.annonce.idEntreprise : "",
                nomEntreprise: this.props.annonce.nomEntreprise ? this.props.annonce.nomEntreprise : "",
                entree: this.props.annonce.entree ? this.props.annonce.entree : "",
                localisation: this.props.annonce.localisation ? this.props.annonce.localisation : "",
                reference: this.props.annonce.reference ? this.props.annonce.reference : "",
                salaire: this.props.annonce.salaire ? this.props.annonce.salaire : "",
                region: this.props.annonce.region ? this.props.annonce.region : "",
                statut: this.props.annonce.statut ? this.props.annonce.statut : 0,
                secteurActivite: this.props.annonce.secteurActivite ? this.props.annonce.secteurActivite : "",
                createdAt: this.props.annonce.createdAt ? this.props.annonce.createdAt : new Date(),
                type: 'emploi',
            }
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.disabled !== this.props.disabled) {
            this.setState({
                disabled: this.props.disabled,
            })
        }
        //console.log(this.props.annonce)
        if (prevProps.annonce !== this.props.annonce) {
            //console.log(this.props.annonce)
            this.setState({
                emploi: {
                    id: this.props.annonce.id,
                    title: this.props.annonce.title ? this.props.annonce.title : "",
                    description: this.props.annonce.description ? this.props.annonce.description : "",
                    description_formatted: this.props.annonce.description_formatted ? this.props.annonce.description_formatted : "",
                    competences: this.props.annonce.competences ? this.props.annonce.competences : "",
                    competences_formatted: this.props.annonce.competences_formatted ? this.props.annonce.competences_formatted : "",
                    conditions: this.props.annonce.conditions ? this.props.annonce.conditions : "",
                    conditions_formatted: this.props.annonce.conditions_formatted ? this.props.annonce.conditions_formatted : "",
                    description_qc: this.props.annonce.description_qc ? this.props.annonce.description_qc : "",
                    description_formatted_qc: this.props.annonce.description_formatted_qc ? this.props.annonce.description_formatted_qc : "",
                    competences_qc: this.props.annonce.competences_qc ? this.props.annonce.competences_qc : "",
                    competences_formatted_qc: this.props.annonce.competences_formatted_qc ? this.props.annonce.competences_formatted_qc : "",
                    conditions_qc: this.props.annonce.conditions_qc ? this.props.annonce.conditions_qc : "",
                    conditions_formatted_qc: this.props.annonce.conditions_formatted_qc ? this.props.annonce.conditions_formatted_qc : "",
                    idEntreprise: this.props.annonce.idEntreprise ? this.props.annonce.idEntreprise : "",
                    nomEntreprise: this.props.annonce.nomEntreprise ? this.props.annonce.nomEntreprise : "",
                    entree: this.props.annonce.entree ? this.props.annonce.entree : "",
                    localisation: this.props.annonce.localisation ? this.props.annonce.localisation : "",
                    reference: this.props.annonce.reference ? this.props.annonce.reference : "",
                    salaire: this.props.annonce.salaire ? this.props.annonce.salaire : "",
                    region: this.props.annonce.region ? this.props.annonce.region : "",
                    statut: this.props.annonce.statut ? this.props.annonce.statut : 0,
                    secteurActivite: this.props.annonce.secteurActivite ? this.props.annonce.secteurActivite : "",
                    createdAt: this.props.annonce.createdAt ? this.props.annonce.createdAt : new Date(),
                    type: 'emploi',
                }
            })
        }
        if (prevProps.Save !== this.props.Save) {

            this.saveForm()
        }
        if (prevProps.Undo !== this.props.Undo && this.props.Undo) {

            //console.log('undo')
            this.setState({
                emploi: {
                    id: this.props.annonce.id,
                    title: this.props.annonce.title ? this.props.annonce.title : "",
                    description: this.props.annonce.description ? this.props.annonce.description : "",
                    description_formatted: this.props.annonce.description_formatted ? this.props.annonce.description_formatted : "",
                    competences: this.props.annonce.competences ? this.props.annonce.competences : "",
                    competences_formatted: this.props.annonce.competences_formatted ? this.props.annonce.competences_formatted : "",
                    conditions: this.props.annonce.conditions ? this.props.annonce.conditions : "",
                    conditions_formatted: this.props.annonce.conditions_formatted ? this.props.annonce.conditions_formatted : "",
                    description_qc: this.props.annonce.description_qc ? this.props.annonce.description_qc : "",
                    description_formatted_qc: this.props.annonce.description_formatted_qc ? this.props.annonce.description_formatted_qc : "",
                    competences_qc: this.props.annonce.competences_qc ? this.props.annonce.competences_qc : "",
                    competences_formatted_qc: this.props.annonce.competences_formatted_qc ? this.props.annonce.competences_formatted_qc : "",
                    conditions_qc: this.props.annonce.conditions_qc ? this.props.annonce.conditions_qc : "",
                    conditions_formatted_qc: this.props.annonce.conditions_formatted_qc ? this.props.annonce.conditions_formatted_qc : "",
                    idEntreprise: this.props.annonce.idEntreprise ? this.props.annonce.idEntreprise : "",
                    nomEntreprise: this.props.annonce.nomEntreprise ? this.props.annonce.nomEntreprise : "",
                    entree: this.props.annonce.entree ? this.props.annonce.entree : "",
                    localisation: this.props.annonce.localisation ? this.props.annonce.localisation : "",
                    reference: this.props.annonce.reference ? this.props.annonce.reference : "",
                    salaire: this.props.annonce.salaire ? this.props.annonce.salaire : "",
                    region: this.props.annonce.region ? this.props.annonce.region : "",
                    statut: this.props.annonce.statut ? this.props.annonce.statut : 0,
                    secteurActivite: this.props.annonce.secteurActivite ? this.props.annonce.secteurActivite : "",
                    createdAt: this.props.annonce.createdAt ? this.props.annonce.createdAt : new Date(),
                    type: 'emploi',
                }
            })
        }
    }
    onChangeEntreprise = (event) => {
        //console.log(event)
        const idEntreprise = event.target.value
        const nomEntreprise = event.target.options[event.target.selectedIndex].text;
        //console.log(idEntreprise)
        //console.log(nomEntreprise)

        this.setState(prevState => ({
            ...prevState,
            emploi: { ...prevState.emploi, nomEntreprise: nomEntreprise, idEntreprise: idEntreprise },
            //pendingGuest: ""
        }));
    }

    validate = ({ title, secteurActivite, salaire, idEntreprise, entree, description }) => {
        return {
            title: !title || title.trim().length === 0
                ? "Le titre est obligatoire!"
                : false,
            salaire:
                !salaire || salaire.trim().length === 0
                    ? "Le Salaire est obligatoire!"
                    : false,
            secteurActivite:
                !secteurActivite || secteurActivite.trim().length === 0
                    ? "Le secteur est obligatoire!"
                    : false,
            idEntreprise: !idEntreprise || idEntreprise.trim().length === 0
                ? "L'entreprise' est obligatoire!"
                : false,
            entree:
                !entree || entree.trim().length === 0
                    ? "L'entrée est obligatoire!"
                    : false,
            description:
                !description || description.trim().length === 0
                    ? "La description est obligatoire!"
                    : false,
        };
    };
    handleTabsChange = (event, newValue) => {
        this.setState({ value: newValue });
    };
    onChangeEmploi = (event) => {
        //= (event) => {
        //console.log(event)
        //console.log("name", event.target.name);
        let name = event.target.name;
        if (event.target.name === 'entreprise') {
            const idEntreprise = event.target.value
            const nomEntreprise = event.target.options[event.target.selectedIndex].text;
            //console.log(idEntreprise)
            //console.log(nomEntreprise)
            this.setState(prevState => ({
                ...prevState,
                emploi: { ...prevState.emploi, nomEntreprise: nomEntreprise, idEntreprise: idEntreprise },
            }));
        }
        else if (event.target.name === 'secteur') {
            const idSecteur = event.target.value
            const secteurActivite = event.target.options[event.target.selectedIndex].text;
            //console.log(idSecteur)
            //console.log(secteurActivite)
            this.setState(prevState => ({
                ...prevState,
                emploi: { ...prevState.emploi, secteurActivite: secteurActivite },
            }));
        }
        else if (event.target.name === 'statut') {
            const statut = !this.state.emploi.statut
            //console.log(statut)
            this.setState(prevState => ({
                ...prevState,
                emploi: { ...prevState.emploi, statut: statut ? 1 : 0 },
            }));
        }
        else if (event.target.name === 'ville') {
            const Value = event.target.value;
            // const secteurActivite = event.target.options[event.target.selectedIndex].text;
            this.setState(prevState => ({
                ...prevState,
                emploi: { ...prevState.emploi, localisation: { ville: Value } },
            }));
        }
        else if (event.target.name === 'createdAt') {
            //console.log(this.state.emploi.createdAt)
            let newDate = event.target.value
            //console.log(newDate)
            //console.log(new Date(newDate).valueOf())
            let newUnixTimeStamp = moment(newDate, 'YYYY-MM-DDTHH:mm').unix();
            var date = this.props.firebase.firestore.Timestamp.fromDate(new Date(newDate));
            //console.log(date)
            //var date = moment(this.state.emploi.createdAt).unix();
            //console.log(newUnixTimeStamp)
            //console.log(Date(newUnixTimeStamp))
            //  var  timestamp = moment(date).format("X");
            //console.log(timestamp)
            this.setState(prevState => ({
                ...prevState,
                emploi: { ...prevState.emploi, createdAt: date },
            }));
        }
        else {
            let valueField = event.target.value
            this.setState(prevState => ({
                ...prevState,
                emploi: { ...prevState.emploi, [name]: valueField },
            }));
        }
        //this.props.onChangeEdit(this.state.emploi)

    }
    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        })
    };

    saveForm = () => {
        this.setState(prevState => ({
            ...prevState,
            emploi: {
                ...prevState.emploi,
                description_formatted: this.state.emploi.description ? this.state.emploi.description.replace(/\n/g, "__cr__").replace(/\r/g, "") : "",
                competences_formatted: this.state.emploi.competences ? this.state.emploi.competences.replace(/\n/g, "__cr__").replace(/\r/g, "") : "",
                conditions_formatted: this.state.emploi.conditions ? this.state.emploi.conditions.replace(/\n/g, "__cr__").replace(/\r/g, "") : "",

                description_formatted_qc: this.state.emploi.description_qc ? this.state.emploi.description_qc.replace(/\n/g, "__cr__").replace(/\r/g, "") : "",
                competences_formatted_qc: this.state.emploi.competences_qc ? this.state.emploi.competences_qc.replace(/\n/g, "__cr__").replace(/\r/g, "") : "",
                conditions_formatted_qc: this.state.emploi.conditions_qc ? this.state.emploi.conditions_qc.replace(/\n/g, "__cr__").replace(/\r/g, "") : "",
                //reference: "OQ-E-" + moment().format("YYYY") + "-" + moment().format("MM") + "-" + moment().format("HHmmssSSS"),
            },
        }),
            () => {
                this.props.saveForm(this.state.emploi)
            }
        );
    }


    render() {
        const { classes, lesEntreprises, monProfil, constants } = this.props
        //console.log(this.state.emploi.createdAt)
        const errors = this.props.validate(this.state.emploi);
        //let formNotValid = Object.keys(errors).filter(k => errors[k])
        return (
            <Box component="span" m={1} >
                <Grid
                    container
                    justify={"center"}
                    spacing={2}
                    style={{
                        backgroundColor: '#fff',
                        padding: 10,
                        overflowY: 'scroll',
                        // ...this.props.fieldContainerStyle,
                    }}
                    flex={1}
                //wrap={this.props.wrap}
                >

                    <Grid item xs={10}>
                        <TextField
                            label={"Titre"}
                            type={"text"}
                            disabled={this.state.disabled}
                            fullWidth={true}
                            variant={"outlined"}
                            value={this.state.emploi.title}
                            onChange={this.onChangeEmploi}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'title',
                                id: 'title',
                            }}
                            required
                            error={errors.title ? true : false}
                            helperText={errors.title}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.emploi.statut === 1 ? true : false}
                                    onChange={this.onChangeEmploi}
                                    disabled={this.state.disabled}
                                    //value={this.state.emploi.statut}
                                    inputProps={{
                                        name: 'statut',
                                        id: 'statut',
                                    }}
                                />
                            }
                            labelPlacement="top"
                            label="Publiée ?"
                        />
                    </Grid>
                    {monProfil.role === 0 &&
                        <Grid item xs={6} >
                            {isLoaded(lesEntreprises) && !isEmpty(lesEntreprises) &&
                                <FormControl className={classes.formControl} fullWidth={true} variant="outlined" disabled={this.state.disabled} error={errors.secteurActivite ? true : false} >
                                    <InputLabel /*ref={inputLabel}*/ htmlFor="entreprise" shrink={true}  >Entreprise</InputLabel>
                                    <Select
                                        native
                                        disabled={this.state.disabled}
                                        value={this.state.emploi.idEntreprise}
                                        onChange={this.onChangeEmploi}
                                        //labelWidth={labelWidth}
                                        className={classes.root}
                                        input={<OutlinedInput labelWidth={80} notched={true} classes={{ input: classes.input }} />}
                                        //labelWidth={63.5}
                                        inputProps={{
                                            name: 'entreprise',
                                            id: 'entreprise',
                                        }}
                                    >
                                        <option value="" />
                                        {OptionEntreprises(lesEntreprises ? lesEntreprises : null)}
                                    </Select>
                                    <FormHelperText>{errors.idEntreprise}</FormHelperText>
                                </FormControl>
                            }
                        </Grid>

                    }
                    <Grid item xs={6} >
                        {isLoaded(constants) &&
                            <FormControl className={classes.formControl} fullWidth={true} variant="outlined" error={errors.secteurActivite ? true : false} >
                                <InputLabel /*ref={inputLabel}*/ htmlFor="secteurActivite" shrink={true}  >Secteur d'activité</InputLabel>
                                <Select
                                    native
                                    disabled={this.props.disabled}
                                    //value={selectedValue}
                                    value={this.state.emploi.secteurActivite}
                                    onChange={this.onChangeEmploi}
                                    //labelWidth={labelWidth}
                                    className={classes.root}
                                    input={<OutlinedInput labelWidth={130} notched={true} classes={{ input: classes.input }} />}
                                    //labelWidth={63.5}
                                    inputProps={{
                                        name: 'secteurActivite',
                                        id: 'secteurActivite',
                                    }}
                                >
                                    <option value="" />
                                    {OptionSecteursActivites(constants ? constants.Domaines.children : null)}
                                </Select>
                                <FormHelperText>{errors.secteurActivite}</FormHelperText>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={4} >
                        {isLoaded(constants) &&
                            <FormControl className={classes.formControl} fullWidth={true} variant="outlined" error={errors.region ? true : false} >
                                <InputLabel /*ref={inputLabel}*/ htmlFor="region" shrink={true}  >Région</InputLabel>
                                <Select
                                    native
                                    disabled={this.props.disabled}
                                    //value={selectedValue}
                                    value={this.state.emploi.region}
                                    onChange={this.onChangeEmploi}
                                    //labelWidth={labelWidth}
                                    className={classes.root}
                                    input={<OutlinedInput labelWidth={130} notched={true} classes={{ input: classes.input }} />}
                                    //labelWidth={63.5}
                                    inputProps={{
                                        name: 'region',
                                        id: 'region',
                                    }}
                                >
                                    <option value="" />
                                    {OptionRegions(constants ? constants.Regions : null)}
                                </Select>
                                <FormHelperText>{errors.region}</FormHelperText>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={"Entrée"}
                            type={"text"}
                            fullWidth={true}
                            variant={"outlined"}
                            onChange={this.onChangeEmploi}
                            value={this.state.emploi.entree}
                            disabled={this.state.disabled}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'entree',
                                id: 'entree',
                            }}
                            required
                            error={errors.entree ? true : false}
                            helperText={errors.entree}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={"Salaire"}
                            type={"text"}
                            fullWidth={true}
                            variant={"outlined"}
                            onChange={this.onChangeEmploi}
                            value={this.state.emploi.salaire}
                            disabled={this.state.disabled}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'salaire',
                                id: 'salaire',
                            }}
                            required
                            error={errors.salaire ? true : false}
                            helperText={errors.salaire}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            //id="datetime-local"
                            label="Crée le"
                            type="datetime-local"
                            disabled={this.state.disabled}
                            onChange={this.onChangeEmploi}
                            //value={moment.unix(this.state.emploi.createdAt.seconds).format("YYYY-MM-DDTHH:mm")}
                            defaultValue={moment.unix(this.state.emploi.createdAt.seconds).format("YYYY-MM-DDTHH:mm")}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'createdAt',
                                id: 'createdAt',
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={"Ville"}
                            type={"text"}
                            fullWidth={true}
                            variant={"outlined"}
                            onChange={this.onChangeEmploi}
                            value={this.state.emploi.localisation.ville}
                            disabled={this.state.disabled}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'ville',
                                id: 'ville',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs
                            value={this.state.value}
                            indicatorColor="primary"
                            variant="fullWidth"
                            textColor="primary"
                            onChange={this.handleTabsChange}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="Français" />
                            <Tab label="Québécois" />
                        </Tabs>
                        <TabPanel value={this.state.value} index={0}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Description"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        multiline
                                        rows="10"
                                        onChange={this.onChangeEmploi}
                                        value={this.state.emploi.description}
                                        disabled={this.state.disabled}
                                        //defaultValue="Default Value"
                                        //className={classes.textField}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'description',
                                            id: 'description',
                                        }}
                                        required
                                        error={errors.description ? true : false}
                                        helperText={errors.description}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Compétences"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        multiline
                                        rows="10"
                                        onChange={this.onChangeEmploi}
                                        value={this.state.emploi.competences}
                                        disabled={this.state.disabled}
                                        //defaultValue="Default Value"
                                        //className={classes.textField}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'competences',
                                            id: 'competences',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Conditions"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        multiline
                                        rows="10"
                                        onChange={this.onChangeEmploi}
                                        value={this.state.emploi.conditions}
                                        disabled={this.state.disabled}
                                        //defaultValue="Default Value"
                                        //className={classes.textField}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'conditions',
                                            id: 'conditions',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Description QC"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        multiline
                                        rows="10"
                                        onChange={this.onChangeEmploi}
                                        value={this.state.emploi.description_qc}
                                        disabled={this.state.disabled}
                                        //defaultValue="Default Value"
                                        //className={classes.textField}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'description_qc',
                                            id: 'description_qc',
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={"Compétences QC"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        multiline
                                        rows="10"
                                        onChange={this.onChangeEmploi}
                                        value={this.state.emploi.competences_qc}
                                        disabled={this.state.disabled}
                                        //defaultValue="Default Value"
                                        //className={classes.textField}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'competences_qc',
                                            id: 'competences_qc',
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={"Conditions QC"}
                                        type={"text"}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        multiline
                                        rows="10"
                                        onChange={this.onChangeEmploi}
                                        value={this.state.emploi.conditions_qc}
                                        disabled={this.state.disabled}
                                        //defaultValue="Default Value"
                                        //className={classes.textField}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'conditions_qc',
                                            id: 'conditions_qc',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Grid>


                </Grid>
            </Box>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        //auth: state.firebase.auth,
        lesEntreprises: state.firestore.ordered.lesEntreprises,
        monProfil: state.firestore.data.monProfil,
        constants: state.firebase.data.constants
    }
}

/*const mapDispatchToProps = dispatch => {
  return {
    createAnnonce: (annonce) => dispatch(createAnnonce(annonce))
  }
}*/
export default compose(
    withStyles(styles),
    connect(mapStateToProps),
    firebaseConnect(),
    firestoreConnect(props => [
        /*{
            collection: 'users',
            where: [['role', '==', 1]],
            orderBy: ['companyName', 'asc'],
            //limit: 10,
            storeAs: 'lesEntreprises'
        },*/
        /*{
          collection: 'annonces',
          where: [['statut', '==', 1]],
          orderBy: ['createdAt', 'desc'],
          limit: 10,
          storeAs: 'annoncesActives'
        },
        {
          collection: 'annonces',
          where: [['statut', '==', 0]],
          orderBy: ['createdAt', 'desc'],
          limit: 10,
          storeAs: 'annoncesInactive'
        },*/
    ]),
)(TypeEmploi)