import React from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountIcon from '@material-ui/icons/AccountCircle';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deepOrange, deepPurple } from '@material-ui/core/colors';

import { roles } from '../../constants/roles'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: 10,
  },
  menu: {
    marginRight: '50px',
  },
  menuLink: {
    color: theme.primaryTxtColor.color,
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  badge: {
    root: {
      '&.MuiBadge-anchorOriginBottomRightCircle': {
        border: "2px solid white"
      }
    }
  }
}));



const SignedInAvatar = (props) => {
  //console.log(props)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let RoleName = "";
  let history = useHistory();
  const handleChange = event => {
    //setAuth(event.target.checked);
  };
  if (roles[props.profile.role]) {
    RoleName = roles[props.profile.role].role
    //console.log(RoleName)
  }
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    props.signOut();
    history.push("/");
  };

  return (
    <React.Fragment>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {props.profile && props.profile.profileImg ?
          <React.Fragment>
            <Badge className={classes.badge} badgeContent={0} color="secondary"
              component="div"
              overlap="circle"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Avatar alt="Remy Sharp" src={props.profile.profileImg} className={classes.bigAvatar} />
            </Badge>
            {!props.reduceMenu &&
              <Box component="div" display="block" p={1} m={1} style={{ textAlign: 'left', textTransform: 'capitalize' }} >
                <Typography variant="body1" component="span" className={classes.menuLink} >
                  {props.profile.fullName}
                </Typography>
                {RoleName &&
                  <Typography variant="caption" component="p" className={classes.menuLink}>
                    {RoleName}
                  </Typography>
                }
              </Box>
            }
            <ExpandMoreIcon className={classes.menuLink} />
          </React.Fragment>

          :
          <React.Fragment>
            <Badge className={classes.badge} badgeContent={0} color="secondary"
              component="div"
              overlap="circle"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Avatar alt="Remy Sharp" src={props.profile.profileImg} className={classes.bigAvatar}>{props.profile.initials}</Avatar>
            </Badge>
            {!props.reduceMenu &&
              <Box component="div" display="block" p={1} m={1} style={{ textAlign: 'left' }} >
                <Typography variant="body1" component="span" className={classes.menuLink}>
                  {props.profile.fullName}
                </Typography>
                {RoleName &&
                  <Typography variant="caption" component="p" className={classes.menuLink}>
                    {RoleName}
                  </Typography>
                }
              </Box>
            }
            <ExpandMoreIcon className={classes.menuLink} />
          </React.Fragment>
        }
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
      // className={props.classes.menu}
      >
        <MenuItem onClick={handleClose} component={Link} to="/moncompte">
          <ListItemIcon>
            <AccountIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Mon Profil</Typography>
        </MenuItem>
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <LogOutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Se déconnecter</Typography>
        </MenuItem>
      </Menu>

      {/*<ul className="right">
        <li><NavLink to='/create'>New Project</NavLink></li>
        <li><a onClick={props.signOut}>Log Out</a></li>
        <li><NavLink to='/' className="btn btn-floating pink lighten-1">
          {props.profile.initials}
        </NavLink></li>
      </ul>*/}
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInAvatar)