import React from 'react';
import _ from 'lodash';
import { number, func, shape, string, array, bool, oneOfType, oneOf } from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function CustomizedRadios({ field, value, errors, onChange, multiline, ...others }) {
  //const { field } = this.props;
  //console.log(field)
  //console.log(value)
  const key = field.key || field.name || 'key';
  const name = field.name || 'field';
  //const label = field.label || '';
  const disabled = field.disabled || false;
  const fullWidth = field.fullWidth || false;
  const helpertext = field.helpertext || '';
  const items = field.items || '';
  const type = field.type || 'radiogroup';
  //console.log(field.label)
  //console.log(items)
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.label}</FormLabel>
      <RadioGroup value={value}  /*defaultValue={field.defaultValue}*/ aria-label="gender" name="customized-radios"  row>
        {field.items.map((item, index) => {
          //console.log(item)
          return (
             <FormControlLabel key={index} value={item.value} control={<StyledRadio />} label={item.title} />
          )
        })}
      </RadioGroup>
    </FormControl>
  );
}