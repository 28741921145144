import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
const useStyles = makeStyles(theme => ({
    title: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textTransform: 'uppercase'
    },


    content: {
        margin: '10px'
    }
}));
const Actualites = ({ monProfil, annonces }) => {
    const classes = useStyles();
    console.log(monProfil.userActivity)
    console.log(annonces)
    let nbLikes = 0;
    let nbPostulations = 0;
    let nbRendezVous = 0;
    if (annonces) {
        let itemnbUsersLikes = [];
        let itemnbUsersPostulations = [];
        let itemnbUsersRendezVous = [];
        Object.values(annonces).map((annonce, index) => {
            _.filter(annonce.usersactions, function (item) {
                if (item.like) {
                    itemnbUsersLikes.push(item.like)
                }

                //return item.like;
            })
            _.filter(annonce.usersactions, function (item) {
                if (item.postulation) {
                    itemnbUsersPostulations.push(item.postulation)
                }

                //return item.postulation;
            })
            _.filter(annonce.usersactions, function (item) {
                if (item.rendezVous) {
                    itemnbUsersRendezVous.push(item.rendezVous)
                }

                //return item.rendezVous;
            })
            //console.log(itemnbUsersLikes)
        })
        //console.log(itemnbUsersLikes)
        nbLikes = _.size(itemnbUsersLikes)
        nbPostulations = _.size(itemnbUsersPostulations)
        nbRendezVous = _.size(itemnbUsersRendezVous)
    }
    return (
        <div className={classes.root}>
            <Paper elevation={3} >
                <Typography variant="h6" component="h2" className={classes.title}>Actualitées</Typography>
                <Divider />
                <ul>
                    <Typography component="li" variant="subtitle1" >Votre Portefeuille actuel : {nbPostulations * 2} $</Typography>
                    <Typography component="li" variant="subtitle1" >{nbLikes} like(s)</Typography>
                    <Typography component="li" variant="subtitle1" >{nbPostulations} Postulation(s)</Typography>
                    <Typography component="li" variant="subtitle1" >{nbRendezVous} Demande(s) de rendez vous</Typography>
                </ul>
            </Paper>


        </div>
    )
}

export default Actualites