import React, { useEffect, useState, Fragment } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar, MTableAction, MTableActions } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon'
import ImageIcon from '@material-ui/icons/ImageRounded'
import { fade, makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase'
import { Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
    Table: {
        padding: theme.spacing(2),
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        height: '100px',       /* Just for the demo          */
        overflowY: 'auto',    /* Trigger vertical scroll    */
        overflowX: 'hidden'
    },

}));

//export default function 
const MaterialTableSettings = (props) => {
    //const AnnoncesGrid = MaterialTableDemo(props) {
    console.log(props);
    const classes = useStyles();
    const firebase = useFirebase();
    //console.log(props.listToShow && props.listToShow.children ? props.listToShow.children : props.listToShow)
    let Values = [];
    const [onEdit, setonEdit] = React.useState(false)
    const [file, setFile] = React.useState(null)
    const [columns, setColumns] = React.useState([
        //{ title: 'id', field: 'id', type: 'numeric' },
        { title: 'Nom', field: 'Name' },
        { title: 'Valeur', field: 'Value' },
        { title: 'Image', field: 'Image' },
        /* {
             title: "Image",
             //lookup: StaticLookup.baseAccountTypes,
             field: 'image',
             editComponent: tableData => (
                 <Fragment>
                     <input
                         color="primary"
                         accept="image/*"
                         type="file"
                         onChange={onChange}
                         id="icon-button-file"
                         value={file}
                     //style={{ display: 'none', }}
                     />
                     <Avatar alt="Cindy Baker" src={file} />
 
                     <label htmlFor="icon-button-file">
                         <Button
                             variant="contained"
                             component="span"
                             className={classes.button}
                             size="large"
                             color="primary"
                         >
                             <ImageIcon className={classes.extendedIcon} />
                         </Button>
                     </label>
                 </Fragment>
             ),
         },*/

    ]);
    const onChange = (event) => {
        console.log(URL.createObjectURL(event.target.files[0]))
        setFile(URL.createObjectURL(event.target.files[0]))
    }
    const [data, setData] = useState(Values);
    const [typeData, setTypeData] = useState(null);
    
    useEffect(() => {
        if (props.listToShow) {
            console.log(props.listToShow)
            if (props.listToShow.value.type) {
                console.log(props.listToShow.value.type)
                setTypeData(props.listToShow.value.type)
            }
            //console.log(props.listToShow.value.splice(constants[field.items].findIndex(item => item === "type"), 1))
            /*var typeFind = props.listToShow.value.find(item => item.id === 2);
            console.log(typeFind)*/
            if (props.listToShow.value.hasOwnProperty('Type')) {
                // do something
                console.log('Type Existant')
            }
            Object.values(props.listToShow.value.children ? props.listToShow.value.children : props.listToShow.value).map((item, index) => {
                console.log(item)
                console.log(typeof item);
                if (item.hasOwnProperty('Type')) {
                    // do something
                    console.log('Type Existant', item.Type)
                    setTypeData(item.Type)
                }
                if (typeof item !== 'string') {

                    //let itemValue = { 'Name': item.Name, 'Value': item.Value, /*'id': index*/ };
                    //console.log(itemValue)
                    Values.push(item)
                }
            });
        }
        console.log(Values)
        //setState({ columns: [...state.columns], data: [...Values] })
        setData(Values)
    }, [props.listToShow]); // Ne se ré-abonne que si props.friend.id change

    /*useEffect(() => {

    }, [props.listToShow]);*/
    const handleEdit = () => {
        setonEdit(!onEdit);
        console.log(onEdit)
    }
    const createTodo = (dataTemp) => {
        let children = props.listToShow.value.children ? '/children' : '/';
        return firebase.database().ref('constants/' + props.listToShow.key + children).set({
            ...dataTemp
        }, function (error) {
            if (error) {
                // The write failed...
            } else {
                // Data saved successfully!
            }
        });
    }

    const onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    const dataTemp = [...data];
                    newData.docId = uuidv4();
                    if (!newData.Value) {
                        newData.Value = newData.Name
                    }
                    if (!newData.Name) {
                        newData.Name = newData.Value
                    }
                    dataTemp.push(newData);
                    console.log(dataTemp)
                    setData(dataTemp);
                    console.log(data)
                    /*let dataTrim = dataTemp.map((element) => {
                        console.log(element)
                        delete element['tableData']
                        //if
                        return { ...element }
                    })*/
                    //console.log(dataTrim)
                    createTodo(dataTemp);
                    //createTodo(dataTemp);
                }
                resolve('Success!');
            }, 1000);
        });
    }



    const onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    let dataTemp = [...data];
                    const index = dataTemp.indexOf(oldData);
                    dataTemp.splice(index, 1);
                    setData(dataTemp);
                    /*let dataTrim = dataTemp.map((element) => {
                        console.log(element)
                        delete element['tableData']
                        //if
                        return { ...element }
                    })
                    console.log(dataTrim)*/
                    createTodo(dataTemp);
                    //createTodo(dataTemp);
                }
                resolve();
            }, 1000);
        });

    const onRowUpdate = (newData, oldData) =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    const dataTemp = [...data];
                    if (!newData.Value) {
                        newData.Value = newData.Name
                    }
                    if (!newData.Name) {
                        newData.Name = newData.Value
                    }
                    const index = dataTemp.indexOf(oldData);
                    console.log("Temporal data", index, dataTemp);
                    dataTemp[index] = newData;
                    console.log("New data", dataTemp);
                    setData(dataTemp);
                    console.log("SET STATE");
                    /*let dataTrim = dataTemp.map((element) => {
                        console.log(element)
                        delete element['tableData']
                        //if
                        return { ...element }
                    })
                    console.log(dataTrim)*/
                    createTodo(dataTemp);
                    //createTodo(dataTemp);
                }
                resolve();
            }, 1000);
        });

    if (!props.listToShow || !data) {
        return <Typography>Selectionnez une liste</Typography>;
    }   
     console.log(data)
     console.log(typeData)
    if (typeData === 'table' || typeData === null) {
        return (

            <MaterialTable
                title={props.listToShow.key}
                columns={columns}
                data={data}
                /* style={{height: '100%', 
                 overflowY: 'auto',    
                 overflowX: 'hidden'}}*/
                className={classes.Table}
                localization={{
                    header: {
                        actions: ''
                    }
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'ligne(s)'
                    },
                    toolbar: {
                        nRowsSelected: '{0} ligne(s) selectionnez',
                        searchPlaceholder: "Recherche"
                    },
                    header: {
                        actions: 'Actions'
                    },
                    body: {
                        emptyDataSourceMessage: 'Aucuns enregistrement à afficher',
                        filterRow: {
                            filterTooltip: 'Filtre'
                        },
                        editRow: {
                            deleteText: 'Etes vous sûre de vouloir supprimer la ligne ?'
                        },
                        editTooltip: "Modifier",
                        deleteTooltip: "Supprimer"
                    }
                }}
                editable={{
                    isEditable: rowData => true,
                    isDeletable: rowData => true,
                    onRowAdd: onRowAdd,
                    onRowUpdate: onRowUpdate,
                    onRowDelete: onRowDelete
                }}
    
                options={{
                    rowStyle: rowData => ({
                        backgroundColor: '#EEE'
                    }),
                    pageSize: 5,
                    actionsColumnIndex: -1,
                    //toolbar: false
                    //actionsColumnIndex: onEdit ? 5 : undefined
                }}
            />
        );
    }
    else  if (typeData === 'string') {
        return <Typography>{data}</Typography>;
    }
    else {
        return <Typography>Selectionnez une liste</Typography>;
    }

    
}
export default MaterialTableSettings;