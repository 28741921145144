import React from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import { withRouter } from "react-router";
import { compose } from 'redux'
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';

import SettingsGenerals from './SettingsGenerals';
import SettingsTabList from './SettingsTabLists';
import SettingsMoisQuebec from './SettingsMoisQuebec';
import SettingsFaq from './SettingsFaq';
import SettingsAbonnements from './SettingsAbonnements';
import SettingsSellsy from './SettingsSellsy';
import SettingsDevs from './SettingsDevs';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    TabTitle: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        padding: '10px',
        color: 'white',
        textTransform: 'uppercase'
    }
}));
const Settings = (props) => {
    //export default function Settings() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.log(props.constants)
    console.log(props.settings)
    let ListsTabs = _.omit(props.constants, ['Avertissement', 'abonnements', 'faq', 'prixPostulation', 'tarifs']);
    let ListesArr = _.map(ListsTabs, (value, key) => ({ key, value }));
    console.log(ListsTabs);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Générales" {...a11yProps(0)} />
                    <Tab label="F.A.Q" {...a11yProps(1)} />
                    <Tab label="Listes" {...a11yProps(2)} />
                    <Tab label="Mois du Québec" {...a11yProps(3)} />
                    <Tab label="Abonnements" {...a11yProps(4)} />
                    <Tab label="Sellsy" {...a11yProps(5)} />
                    <Tab label="Developpeur" {...a11yProps(6)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Box my={0}>
                    <Toolbar variant={'dense'} disableGutters={true}>
                        <Typography className={classes.TabTitle} variant={"h5"} align={"center"}>Options Generales</Typography>
                    </Toolbar>
                    <SettingsGenerals generales={props.constants && props.constants} />
                </Box>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <Toolbar variant={'dense'} disableGutters={true}>
                    <Typography className={classes.TabTitle} variant={"h5"} align={"center"}>Options des F.A.Q</Typography>
                </Toolbar>
                <SettingsFaq faq={props.constants && props.constants['faq']} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Toolbar variant={'dense'} disableGutters={true}>
                    <Typography className={classes.TabTitle} variant={"h5"} align={"center"}>Options des Listes</Typography>
                </Toolbar>
                <SettingsTabList Listes={ListesArr} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Toolbar variant={'dense'} disableGutters={true}>
                    <Typography className={classes.TabTitle} variant={"h5"} align={"center"}>Options Mois du Québec</Typography>
                </Toolbar>
                <SettingsMoisQuebec /*moisQuebec={props.constants && props.constants.MoisQuebec}*/ />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Toolbar variant={'dense'} disableGutters={true}>
                    <Typography className={classes.TabTitle} variant={"h5"} align={"center"}>Options des Abonnements</Typography>
                </Toolbar>
                <SettingsAbonnements abonnements={props.constants && props.constants.abonnements} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <Toolbar variant={'dense'} disableGutters={true}>
                    <Typography className={classes.TabTitle} variant={"h5"} align={"center"}>Options Sellsy</Typography>
                </Toolbar>
                <SettingsSellsy />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <Toolbar variant={'dense'} disableGutters={true}>
                    <Typography className={classes.TabTitle} variant={"h5"} align={"center"}>Options DEVELOPPEUR</Typography>
                </Toolbar>
                <SettingsDevs {...props}/>
            </TabPanel>
        </div>
    );
}

const mapStateToProps = (state, props) => ({

    profile: state.firebase.profile,
    auth: state.firebase.auth,
    //chat: state.chat,
    constants: state.firebase.data.constants,
    settings: state.firestore.data.settings,
    //mesConversations: state.firestore.ordered.mesConversations,
    compteurs: state.firebase.data.compteurs,

    //monProfil: state.firestore.data.monProfil
})
/*const mapStateToPropsss = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
  mesConversations: ordered.mesConversations,
});*/

const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect((props, store) => {
        //console.log(props)
        //console.log(store)
        return [
            { path: 'compteurs' }, // make sure to pass "populates"
            { path: 'constants' }, // make sure to pass "populates"
        ]
    }),
    firestoreConnect(() => ['settings']),

    //withStyles(useStyles),
)(Settings)