const initState = {
}

const navReducer = (state = initState, action) => {
    switch (action.type) {
        case "DRAWER_OPEN":
            console.log(action.payload)
            return { ...state, drawerOpen: action.payload };
        
        default:
            return state;
    }
};

export default navReducer;