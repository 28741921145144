/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local'

// Replace this with your own config details


export const firebase = {
  apiKey: "AIzaSyBt_ylzbDQM1-cvujdgR2wpnY7pVvbV_Sg",
  authDomain: "oq70-objectifquebec.firebaseapp.com",
  databaseURL: "https://oq70-objectifquebec.firebaseio.com",
  projectId: "oq70-objectifquebec",
  storageBucket: "oq70-objectifquebec.appspot.com",
  messagingSenderId: "217682914943",
  appId: "1:217682914943:web:4508ec40e2f2349a41994a",
  measurementId: "G-R4C17ZKK4D"
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  enableLogging: false
}

//export default { firebase, rrfConfig }
export default { firebase, rrfConfig, env }