const initState = {
  conversations: []
}

const chatReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_MESSAGE_SUCCESS':
      //console.log('create annonce success');
      return state;
    case 'CREATE_MESSAGE_ERROR':
      console.log('create annonce error');
      return state;
    case 'LOAD_INIT_CONVERSATIONS_SUCCESS':
      //console.log('load INIT_CONVERSATIONS success ', action);
      //console.log('load INIT_CONVERSATIONS success ', action.payload);
      //console.log('load INIT_CONVERSATIONS success ', action.userId);
      //console.log('load more annonce success ', action.payload.data());
      //return action.payload;
      return {
        //...state,
        conversations: [ ...action.payload]
      };
    case 'LOAD_INIT_CONVERSATIONS_ERROR':
      console.log('load INIT_CONVERSATIONS error', action.err);
      return action.err;
    case 'LOAD_MORE_CONVERSATIONS_SUCCESS':
      //console.log('load more annonce success ', action);
      //console.log('load more annonce success ', action.payload);
      //console.log('load more annonce success ', state.conversations);
      //console.log('load more annonce success ', action.payload.data());
      //return action.payload;
      return {
        ...state,
        conversations: [...state.conversations, ...action.payload]
      };

    case 'LOAD_MORE_CONVERSATIONS_ERROR':
      console.log('load more annonce error', action.err);
      return action.err;
      default:
        return state;
  }
};

export default chatReducer;