import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar, MTableGroupbar, MTableAction, MTableActions } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon'
import ImageIcon from '@material-ui/icons/ImageRounded'
import { fade, makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase'
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CKEditorField from '../utils/CKEditorField'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    textfield: {
        paddingBottom: 15
    },
    inputLabel: {
        color: "black",
        fontSize: 24,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        "&.focused": {
            color: "red"
        }
    }
}));
const SettingsFaq = (props) => {
    const firebase = useFirebase();
    console.log(props)
    //export default function SimpleTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [NewsDatas, setNewsDatas] = React.useState('')
    const [DiffDatas, setDiffDatas] = React.useState(false)
    useEffect(
        () => {
            if (props.faq) {
                setNewsDatas(props.faq)
            }

        }, [props.faq]
    )
    const setNewData = (event) => {
        let newData = NewsDatas;
        const fieldName = event.target.id;
        const newValue = event.target.value;
        const faqIdx = event.target.getAttribute("data-faq");
        const subKey = event.target.getAttribute("data-subkey");
        console.log(fieldName);
        if (subKey) {
            newData[faqIdx][fieldName][subKey].explication = newValue;
        }
        else {
            newData[faqIdx][fieldName] = newValue;
        }

        console.log(newData)
        setNewsDatas(newData);
        if (DiffDatas !== NewsDatas) {
            setDiffDatas(true);
        }
        else {
            setDiffDatas(false);
        }
        console.log(NewsDatas);
    }
    const saveNewData = () => {
        console.log('save')
        console.log(NewsDatas)
        firebase.database().ref('constants/').update({
            faq: NewsDatas
        });
        setDiffDatas(false);
    }
    const handleEditorChange = ((event, editor, faqIdx, field, subField) => {
        let newData = NewsDatas;
        console.log(newData)
        const data = editor.getData();
        console.log(event)
        console.log({ event, editor, data });
        const fieldName = field;
        const newValue = data;
        console.log(fieldName);
        console.log(newValue);
        newData[faqIdx]['editor'] = newValue;
        console.log(newData)
        setNewsDatas(newData);
        if (DiffDatas !== NewsDatas) {
            setDiffDatas(true);
        }
        else {
            setDiffDatas(false);
        }
        console.log(NewsDatas);
        //console.log('handleEditorChange', html, text)
        //setPrevValues({ ...prevValues, description: data })
        //setValues({ ...Values, description: data })
        /*if (prevValues.description !== data) {
            console.log('difference markdown');
            //console.log(text)
            //console.log(html)
        }*/
    })

    if (props.faq) {
        console.log(props.faq)
        return (
            <>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Qui sommes nous ?" {...a11yProps(0)} />
                        <Tab label="Nos Services" {...a11yProps(1)} />
                        <Tab label="Pourquoi cette application ?" {...a11yProps(2)} />
                        <Tab label="Nos Actions" {...a11yProps(3)} />
                        <Tab label="Questions Fréquentes" {...a11yProps(4)} />
                        <Tab label="Conditions Générales (C.G.V)" {...a11yProps(5)} />
                        <Tab label="Comment ?" {...a11yProps(6)} />
                        <Tab label="Pourquoi ?" {...a11yProps(7)} />


                        <Tab label="Formations" {...a11yProps(8)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Typography variant={'h6'}>Qui sommes nous ? :</Typography>
                    <CKEditorField data={props.faq.quisommesnous.editor || ''} config={null} onChange={(event, editor) => handleEditorChange(event, editor, 'quisommesnous', 'editor')} />

                    <TextField label={"Qui sommes nous ?"} defaultValue={props.faq.quisommesnous.main} fullWidth multiline id="main" onChange={setNewData} inputProps={{ 'data-faq': 'quisommesnous' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />
                    <TextField label={"Qui sommes nous ? (candidats)"} defaultValue={props.faq.quisommesnous.candidats} fullWidth multiline id="candidats" onChange={setNewData} inputProps={{ 'data-faq': 'quisommesnous' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />
                    <TextField label={"Qui sommes nous ? (entreprises)"} defaultValue={props.faq.quisommesnous.entreprises} fullWidth multiline id="entreprises" onChange={setNewData} inputProps={{ 'data-faq': 'quisommesnous' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <Typography variant={'h6'}>Nos Services :</Typography>
                    <CKEditorField data={props.faq.nosservices && props.faq.nosservices.editor || ''} config={null} onChange={(event, editor) => handleEditorChange(event, editor, 'nosservices', 'editor')} />

                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Typography variant={'h6'}>Pourquoi cette application ? :</Typography>
                    <CKEditorField data={props.faq.pourquoicetteapplication && props.faq.pourquoicetteapplication.editor || ''} config={null} onChange={(event, editor) => handleEditorChange(event, editor, 'pourquoicetteapplication', 'editor')} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Typography variant={'h6'}>Nos actions :</Typography>
                    <CKEditorField data={props.faq.nosactions && props.faq.nosactions.editor || ''} config={null} onChange={(event, editor) => handleEditorChange(event, editor, 'nosactions', 'editor')} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <CKEditorField data={props.faq.questionsfrequentes.editor || ''} config={null} onChange={(event, editor) => handleEditorChange(event, editor, 'questionsfrequentes', 'main')} />
                    <TextField label={"Questions Fréquentes"} defaultValue={props.faq.questionsfrequentes.main} fullWidth multiline id="main" onChange={setNewData} inputProps={{ 'data-faq': 'questionsfrequentes' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />
                    <Typography>Candidats</Typography>
                    {props.faq.questionsfrequentes.candidats.map((questionfrequente, index) => {
                        return (
                            <Grid key={index} item xs={12}>
                                <TextField label={questionfrequente.titre} defaultValue={questionfrequente.explication} fullWidth multiline id="candidats" onChange={setNewData} inputProps={{ 'data-faq': 'questionsfrequentes', 'data-subkey': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                            </Grid>
                        )
                    })
                    }
                    <Typography>Entreprises</Typography>
                    {props.faq.questionsfrequentes.entreprises.map((questionfrequente, index) => {
                        return (
                            <Grid key={index} item xs={12}>
                                <TextField label={questionfrequente.titre} defaultValue={questionfrequente.explication} fullWidth multiline id="entreprises" onChange={setNewData} inputProps={{ 'data-faq': 'questionsfrequentes', 'data-subkey': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                            </Grid>
                        )
                    })
                    }
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <Typography variant={'h6'}>Conditions Générales :</Typography>
                    <CKEditorField data={props.faq.conditionsgenerales && props.faq.conditionsgenerales.editor || ''} config={null} onChange={(event, editor) => handleEditorChange(event, editor, 'conditionsgenerales', 'editor')} />
                </TabPanel>

                <TabPanel value={value} index={6}>
                    <TextField label={"Pourquoi ?"} defaultValue={props.faq.pourquoi.main} fullWidth multiline id="main" onChange={setNewData} inputProps={{ 'data-faq': 'pourquoi' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <TextField label={"Comment ?"} defaultValue={props.faq.comment.main} fullWidth multiline id="main" onChange={setNewData} inputProps={{ 'data-faq': 'comment' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />

                </TabPanel>
                <TabPanel value={value} index={8}>
                    <TextField label={"Pourquoi ?"} defaultValue={props.faq.pourquoi.main} fullWidth multiline id="main" onChange={setNewData} inputProps={{ 'data-faq': 'pourquoi' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />
                </TabPanel>
                <TabPanel value={value} index={9}>
                    <TextField label={"Formations"} defaultValue={props.faq.formations.main} fullWidth multiline id="main" onChange={setNewData} inputProps={{ 'data-faq': 'formations' }}
                        className={classes.textfield}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.inputLabel,
                                focused: "focused"
                            }
                        }} />
                    {props.faq.formations.questions.map((question, index) => {
                        return (
                            <Grid key={index} item xs={12}>
                                <TextField label={question.titre} defaultValue={question.explication} fullWidth multiline id="questions" onChange={setNewData} inputProps={{ 'data-faq': 'formations', 'data-subkey': index }}
                                    className={classes.textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: "focused"
                                        }
                                    }} />
                            </Grid>
                        )
                    })
                    }
                </TabPanel>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button variant="outlined" size="medium" color="primary" disabled={!DiffDatas} onClick={saveNewData} >Mettre à jour</Button>
                </Grid>
            </>

        )
    }
    return null;

}

export default SettingsFaq