import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import Box from '@material-ui/core/Box';
//import Box, { VBox } from 'react-layout-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import MenuItem from '@material-ui/core/MenuItem';
//import TextField from '@material-ui/core/TextField';


import CustomTextField from './components/TextField';
import CustomizedRadios from './components/RadioButtons';
//import Tests from './components/test';
import { TextField } from '@material-ui/core';
import FileViewerUpload from './components/FileViewerUpload';
//import ImageUpload from './components/ImageUpload';

import Select from './components/Select';
import MultiSelect from './components/MultiSelect'
//import AsyncSelect from './components/AsyncSelect';
//import ChipInput from './components/ChipInput';


//export default 
class Form extends React.Component {
    style = {
        width: '100%',
    };

    constructor(props) {
        super(props);
        this.state = {
            values: this.props.values,
            timeout: null,
            index: 0,
        };
    }
    componentDidUpdate(prevProps) {
        console.log(this.props.constants)
        if (prevProps.values !== this.props.values) {
            this.setState({
                values: this.props.values,
            })
        }
    }

    DEFAULT_IMG = this.props.defaultImage || 'http://via.placeholder.com/50x50';
    handleChange = (field, value) => {
        const { fields } = this.props;
        const wholeField = fields.find(({ name, key }) => name === field || key === field);

        let transformedValue = value;
        if (wholeField && wholeField.emoji === true) {
            if (wholeField.type === 'text' || wholeField.type === 'multiLineText') {
                //transformedValue = this.handleEmojiText(value);
            }
        }

        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null }, () => this.setChanges(field, transformedValue));
        } else {
            this.setChanges(field, transformedValue);
        }
    };
    setChanges = (field, value) => {
        if (this.props.handleChange) {
            this.props.handleChange(field, value);
        }
        const values = { ...this.props.values, ...this.state.values, [field]: value };
        this.setState({ values }, () => {
            if (!this.props.delayTime || !this.props.onDelayedChange) {
                return false;
            }
            let time = 0;
            if (_.includes(this.props.delayTriggers, field)) {
                time = this.props.delayTime;
            }
            const timeout = setTimeout(() => {
                this.props.onDelayedChange(values);
            }, time);
            this.setState({ timeout });
        });
        if (this.props.onChange) {
            this.props.onChange(values);
        }
    };

    getProperty = (key, defaultValue, formDefault) => {
        const componentDefault = _.get(this.props.values, key); //=== null ? '' : _.get(this.props.values, key);
        //console.log(componentDefault)
        return _.get(this.state.values, key, componentDefault || defaultValue || formDefault);
    };

    getName = (name) => {
        return _.replace(name, /\./g, ' ');
    };
    handleFocus = (event) => {
        event.target.select()
    }

    getField = (field) => {
        const errors = this.props.errors;
       // console.log(field.items)
        const key = field.key || field.name || 'key';
        const type = field.type || 'text';
        const defaultValue = field.default || '';
        const name = field.name || 'field';
        const label = field.label || 'no label';
        const disabled = field.disabled || false;
        const items = field.items || null;
        const fullwidth = field.fullwidth || true;
        const emoji = field.emoji || false;
        const dialogActive = field.dialogActive || false;
        const dialogContent = field.dialogContent || null;
        const dialogTitle = field.dialogTitle || null;
        const selectFieldMap = field.selectFieldMap
        //console.log(selectFieldMap)

        //console.log(items)
        switch (type) {

            case 'text':
            case 'password':
            case 'number':
                return (
                    <CustomTextField
                        field={field}
                        errors={this.props.errors}
                        value={this.getProperty(key, defaultValue, '')}
                        onChange={(event) => this.handleChange(key, event.target.value)}
                        disabled={this.props.disabled}
                        onFocus={this.handleFocus}
                    />
                );

            case 'multiLineText': {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        <CustomTextField
                            field={field}
                            errors={this.props.errors}
                            value={this.getProperty(key, defaultValue, '')}
                            onChange={(event) => this.handleChange(key, event.target.value)}
                            multiline
                            rows={field.rows || 1}
                            rowsMax={field.rowsMax || 3}
                            disabled={this.props.disabled}
                        />
                    </div>
                );
            }
            case 'radiogroup':
                return (
                    <CustomizedRadios
                        field={field}
                        items={items}
                        value={this.getProperty(key, defaultValue, '')}
                        onChange={(event) => this.handleChange(key, event.target.value)}
                        disabled={this.props.disabled}
                    />
                );
            case 'file': {
                const defaultImg = defaultValue || this.DEFAULT_IMG;
                return (
                    <FileViewerUpload
                        field={field}
                        srcValue={this.getProperty(key, '', '') /* + '?' + new Date().getTime()*/}
                        //handleChange={this.handleChange}
                        onChange={(event) => this.handleChange(key, event ? event.target.files[0] : null)}
                        value={this.getProperty(key, defaultValue, '')}
                        defaultImg={this.DEFAULT_IMG}
                        disabled={this.props.disabled}
                    />
                )
            }
            case 'select':
                return (
                    <Select
                        field={field}
                        errors={this.props.errors}
                        selectedValue={this.getProperty(key, defaultValue, field.multiple ? [] : '')}
                        onChange={(event) => this.handleChange(key, event.target.value)}
                        disabled={this.props.disabled}
                        dialogActive={dialogActive}
                        dialogContent={dialogContent}
                        dialogTitle={dialogTitle}
                        constants={this.props.constants}
                    />
                );
            case 'multiselect':
                return (
                    <MultiSelect
                        field={field}
                        errors={this.props.errors}
                        selectedValue={this.getProperty(key, defaultValue, [])}
                        onChange={(event) => this.handleChange(key, event.target.value)}
                        disabled={this.props.disabled}
                        dialogActive={dialogActive}
                        dialogContent={dialogContent}
                        dialogTitle={dialogTitle}
                        constants={this.props.constants}
                    />
                );
            /*case 'select':
                return (
                    <Select
                        field={field}
                        errors={this.props.errors}
                        selectedValue={this.getProperty(key, defaultValue, field.multiple ? [] : '')}
                        onChange={(value) => this.handleChange(key, value)}
                        dialogActive={dialogActive}
                        dialogContent={dialogContent}
                        dialogTitle={dialogTitle}
                    />
                );*/
            /*
                        case 'asyncSelect':
                            return (
                                <AsyncSelect
                                    field={field}
                                    errors={this.props.errors}
                                    onChange={(value) => this.handleChange(key, value)}
                                    selectedValue={this.getProperty(key, defaultValue, field.multiple ? [] : '')}
                                />
                            );
            */
            case 'checkbox': {
                return (
                    <FormControl component="fieldset">
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.getProperty(key, defaultValue, false)}
                                        value={`${this.getProperty(key, defaultValue, false)}`}
                                        onClick={(event) => {
                                            this.handleChange(key, event.target.checked);
                                        }}
                                        disabled={disabled}
                                    />
                                }
                                label={this.getName(label)}
                            />
                        </FormGroup>
                    </FormControl>
                );
            }

            /* case 'chip': {
                 const value = this.getProperty(key, defaultValue, []);
                 return (
                     <ChipInput
                         field={field}
                         value={value}
                         onChange={(value) => this.handleChange(key, value)}
                         errors={errors}
                     />
                 );
             }*/

            /*case 'image':
            case 'image2': {
                const defaultImg = defaultValue || this.DEFAULT_IMG;
                return (
                    <ImageUpload
                        field={field}
                        srcValue={this.getProperty(key, defaultImg, '') + '?' + new Date().getTime()}
                        defaultImg={this.DEFAULT_IMG}
                        handleChange={this.handleChange}
                    />
                );
            }*/
            default:
                return <TextField disabled={this.props.disabled} />;
        }
    };

    getFields = () =>
        this.props.fields.map((field, index) => {
            //console.log(field)
            const gridSize = field.gridSize || 6
            //console.log(gridSize)
            return (
                /* <div
                     key={`form-field-container-${index}`}
                     style={{ margin: '10px 0', ...this.props.inputContainerStyle }}
                 >*/
                <Grid item xs={gridSize} key={index} style={{ padding: '0 10px', margin: '10px 0', ...this.props.inputContainerStyle }}>
                    {this.getField(field)}
                </Grid>
                //</div>
            )
        });



    render() {
        let FieldContainer = Grid;
        if (this.props.orientation === 'horizontal') {
            FieldContainer = Grid;
        }
        return (
            <Box>
                <FieldContainer
                    container
                    justify={"center"}
                    style={{
                        backgroundColor: '#fff',
                        padding: 10,
                        overflowY: 'scroll',
                        ...this.props.fieldContainerStyle,
                    }}
                    flex={1}
                    wrap={this.props.wrap}
                >
                    <FieldContainer
                        container
                        justify={"flex-start"}
                    /* style={{
                          backgroundColor: '#fff',
                          padding: 10,
                          overflowY: 'scroll',
                          ...this.props.fieldContainerStyle,
                      }}*/
                    //flex={1}
                    //wrap={this.props.wrap}
                    >
                        {this.getFields()}
                    </FieldContainer>
                </FieldContainer>
                <Box style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex', ...this.props.actionContainerStyle }}>
                    {this.props.cancelForm && (
                        <Button
                            variant="contained"
                            onClick={() => this.props.cancelForm()}
                            style={{ display: 'block', margin: '0 auto', width: '30%' }}
                        >
                            {this.props.cancelFormButtonName}
                        </Button>
                    )}
                    {this.props.saveForm && (
                        <Button
                            variant="contained"
                            onClick={() => this.props.saveForm(this.state.values)}
                            style={{ display: 'block', margin: '0 auto', width: '30%' }}
                        >
                            {this.props.saveFormButtonName}
                        </Button>
                    )}
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil,
        annoncesUser: state.users.annoncesUser,
        constants: state.firebase.data.constants
    }
}
/*const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    return {
        loadAnnoncesUsers: (userDomaines) => dispatch(loadAnnoncesUsers(userDomaines)),
    }
}*/
export default compose(
    connect(mapStateToProps/*, mapDispatchToProps*/),
    firebaseConnect(),
    firestoreConnect(),
)(Form)