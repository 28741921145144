import firebase from 'firebase/app'
import 'firebase/functions' // <- needed if using httpsCallable
//import COLLECTIONS from '../../Constants/Collections'

export const counterFunctions = (Value, nbLikes) => {
    console.log(firebase.auth().currentUser.uid)
    //console.log(this.props.userActivity)
    //if (Value) {}
    /* firebase.firestore().collection(COLLECTIONS.USERS).doc(firebase.auth().currentUser.uid).set({
         userActivity: {
             nbLikes: Value ? nbLikes + 1 : nbLikes - 1
         }
     },
         { merge: true }
     )*/
    // if(this.state.nbLikes<0){this.setState.}


    /*this.props.firestore.set(
        {
            collection: COLLECTIONS.USERS,
            doc: firebase.auth().currentUser.uid
        },
        {
            userActivity: {
                nbLikes: Value ? this.state.nbLikes + 1 : this.state.nbLikes - 1
            }
        }, { merge: true }
    )*/
    //console.log(this.state.nbLikes)
}

export const createUserbyAdmin = async (data) => {
    console.log(data)
    const createUser = firebase.functions().httpsCallable('createUserAdmin');
    console.log(createUser)
    //const uniquePassword = this.afs.createId();

    await createUser({
        email: data.email,
        password: data.password,
        profil: data.profil
    }).then((result) => {
        // Read result of the Cloud Function.
        console.log('result ', result)
        var sanitizedMessage = result.data.text;
        console.log('sanitizedMessage = ', sanitizedMessage);
        return result;
    }).catch((error) => {
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log('error = ', error);
        return error;
    });

}

export const updateUserbyAdmin = (data) => {
    console.log(data)
    const updateUser = firebase.functions().httpsCallable('updateUserAdmin');
    console.log(updateUser)
    //const uniquePassword = this.afs.createId();

    updateUser({
        uid: data.uid,
        email: data.email,
        password: data.password,
        emailVerified: data.emailVerified,
        displayName: data.email,
        disabled: data.disabled,
        profil: data.profil
    }).then((result) => {
        // Read result of the Cloud Function.
        var sanitizedMessage = result.data.text;
        console.log('sanitizedMessage = ', sanitizedMessage);
    }).catch((error) => {
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log('error = ', error);
    });

}


export const deleteUserbyAdmin = (uid) => {
    console.log(uid)
    const deleteUser = firebase.functions().httpsCallable('deleteUserAdmin');
    console.log(deleteUser)
    //const uniquePassword = this.afs.createId();

    deleteUser(uid).then((result) => {
        // Read result of the Cloud Function.
        var sanitizedMessage = result.data.text;
        console.log('sanitizedMessage = ', sanitizedMessage);
    }).catch((error) => {
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log('error = ', error);
    });

}

export const deleteAtPath = (path) => {
    var deleteFn = firebase.functions().httpsCallable('recursiveDelete');
    return deleteFn({ path: path })
        .then(function (result) {
            let ok = true;
            console.log('Delete success: ' + JSON.stringify(result));
            return {...result, ok}
        })
        .catch(function (err) {
            console.log('Delete failed, see console,');
            console.warn(err);
        });
}