import React, { Component, useEffect, useState } from 'react'
import AnnonceList from '../annonces/AnnoncesList'
import Notifications from './components/Notifications'
import Statistiques from './components/Statistiques'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { getWPPosts, createWPPost, updateWPPost, deleteWPPost, getWPCategories } from '../../utils/wordpress/wordpressUtils'
import axios from 'axios';
import WPAPI from 'wpapi';
import _ from 'lodash'
var wp = new WPAPI({
    endpoint: 'http://www.shop.pierregagliardi.com/wp-json',
    // This assumes you are using basic auth, as described further below
    username: 'pierrega',
    password: '9C%xjFY@&7'
});

/*var apiPromise = WPAPI.discover('http://www.shop.pierregagliardi.com').then(function (site) {
    return site.auth({
        username: 'pierrega',
        password: '9C%xjFY@&7'
    });
});*/
//var apiPromise = WPAPI.discover('http://www.shop.pierregagliardi.com');

const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8
    },
}));
// raw xhr, fetch, or your favorite ajax library
// just remember, you want:
// - to download **binary** data (jQuery's $.ajax won't work out of the box for example)
// - to return a promise of the content
function downloadUrlAsPromise(url) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function (evt) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(new Error("Ajax error for " + url + ": " + xhr.status));
                }
            }
        };
        xhr.send();
    });
}

//class Dashboard extends Component {
const Dashboard = ({ auth, annonces, monProfil, allComments, compteurs, allInscriptions }) => {
    const classes = useStyles();
    //render() {
    // console.log(allComments)
    // Request methods return Promises.
    const [allInscriptionsData, setAllInscriptionsData] = useState('');
    useEffect(
        () => {
            if (allInscriptions) {
                //setTimeout(() => {
                var arr = _.values(allInscriptions);
                setAllInscriptionsData(arr)
                if (arr.length === Object.keys(allInscriptions).length) {
                    //setIsLoading(false);
                }
                //  console.log(arr.length)
                //}, 1500)
            }

        }, [allInscriptions]);

    const _createWPPost = () => {

        wp.posts().create({
            // "title" and "content" are the only required properties
            title: 'Try it',
            content: 'Your post content',
            fields: {
                docID: 'xxxxxx',
            },
            categories: [wpCategory],
            // Post will be created as a draft by default if a specific "status"
            // is not specified
            status: 'publish'
        }).then(function (response) {
            // "response" will hold all properties of your newly-created post,
            // including the unique `id` the post was assigned on creation
            console.log(response.id);
        })
    }
    //console.log(wp);
    /*wp.posts().get(function (err, data) {
        if (err) {
            // handle err
            console.log('err', err);
        }
        // do something with the returned posts
        console.log('data');
        console.log(data);
    });*/
    const [wpCategory, setWpCategory] = React.useState(0)
    const _getWPCategories = async () => {
        let categories = await getWPCategories();
        console.log(categories)
    }
    const _getWPPosts = async () => {
        let response = await getWPPosts();
        console.log(response)
    }
    // Preparing to download image from internet
    /* let downloadFile = (async (url, path) => {
         let res = await fetch(url);
         let fileStream = fs.createWriteStream(path);
         await new Promise((resolve, reject) => {
             res.body.pipe(fileStream);
             res.body.on("error", (err) => {
                 reject(err);
             });
             fileStream.on('finish', function () {
                 resolve();
             });
         });
     });*/

    const _wpMediaUpload = async () => {
        let myImageLink = "https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0";
        //await downloadFile(myImageLink, './myImage.jpg')
        // Uploading image and creating the item in the Wordpress media gallery
        axios({
            url: myImageLink, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then(async (response) => {
            console.log(response.data);
            console.log(new Blob([response.data]));
            const file = new File([response.data], 'test.png');
            console.log(file)
            wp.media()
                // Specify a path to the file you want to upload, or a Buffer
                .file(file)
                .create({
                    title: 'My awesome image',
                    alt_text: 'an image of something awesome',
                    caption: 'This is the caption text',
                    description: 'More explanatory information'
                })
                .then(function (response) {
                    // Your media is now uploaded: let's associate it with a post
                    console.log(response)
                    var newImageId = response.id;
                    console.log(newImageId)
                    wp.posts().id(33).update({
                        featured_media: newImageId
                    }).then(function (response) {
                        console.log(response);
                    })
                    return wp.media().id(newImageId).update({
                        post: 33//associatedPostId
                    });
                })
                .then(function (response) {
                    console.log('Media ID #' + response.id);
                    console.log('is now associated with Post ID #' + response.post);
                });
            /*let responseMediaCreation = await wp.media().file(file).create({
                title: 'My image'
            });
            await wp.posts().create({
                title: 'glagla',
                featured_media: responseMediaCreation.id,
                content: `<h2>glagla</h2><img src="${responseMediaCreation.source_url}" /><div>text</div>`,
                status: 'publish'
            });*/
        });
        /*let responseMediaCreation = await wp.media().file('essai', ['/Users/CcHuMi/Desktop/CAPTURE/bb.png']).create({
            title: 'My image'
        });*/

        // Creating post on your Wordpress website
        /*await wp.posts().create({
            title: 'glagla',
            featured_media: responseMediaCreation.id,
            content: `<h2>glagla</h2><img src="${responseMediaCreation.source_url}" /><div>text</div>`,
            status: 'publish'
        });*/

    }
    const _wpDeletePost = async () => {
        let response = await deleteWPPost(75);
        console.log(response)
    }
    const _wpMediaUploads = async () => {

        //let test = downloadUrlAsPromise("https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0")
        wp.media()
            // Specify a path to the file you want to upload, or a Buffer
            .file('/Users/CcHuMi/Desktop/CAPTURE/bb.png', 'test')
            .create({
                title: 'My awesome image',
                alt_text: 'an image of something awesome',
                caption: 'This is the caption text',
                description: 'More explanatory information'
            })
            .then(function (response) {
                // Your media is now uploaded: let's associate it with a post
                var newImageId = response.id;

                return wp.media().id(newImageId).update({
                    post: 33//associatedPostId
                });
            })
            .then(function (response) {
                console.log('Media ID #' + response.id);
                console.log('is now associated with Post ID #' + response.post);
            });

        /*let test = new File(
            ["https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0"], 
        "https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0");
        console.log(test)
        let file = test;
        let ok = null;
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function () {
            console.log(reader.result);
            ok = reader.result;
        };

        reader.onerror = function () {
            console.log(reader.error);
        };*/
        /* var blob = null
         var xhr = new XMLHttpRequest()
         xhr.open("GET", "https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0")
         xhr.responseType = "blob"
         xhr.onload = function () {
             blob = xhr.response
             console.log(blob)
             //LoadAndDisplayFile(blob)
         }
         xhr.send()*/
        /*wp.media()
            // Specify a path to the file you want to upload, or a Buffer
            .file("gs://oq70-objectifquebec.appspot.com/feeds/0uB2ZHGsof8hCwsPnHu4/posts/9AY38fdkSUl6VHT3UexF/header-5.png")
            .create({
                title: 'My awesome image',
                alt_text: 'an image of something awesome',
                caption: 'This is the caption text',
                description: 'More explanatory information'
            })
            .then(function (response) {
                // Your media is now uploaded: let's associate it with a post
                var newImageId = response.id;
                return wp.media().id(newImageId).update({
                    post: 33,//associatedPostId
                });
            })
            .then(function (response) {
                console.log('Media ID #' + response.id);
                console.log('is now associated with Post ID #' + response.post);
            });*/
        /* fetch('http://localhost:5001/oq70-objectifquebec/us-central1/fn')
        .then(data => {
            console.log(data)
        })*/
        /*fetch("https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0",
            {
                //mode: 'no-cors' // 'cors' by default

            }
        )
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(async (blob) => {
                console.log(blob)
                var bufferPromise = blob.arrayBuffer();
                //var buffer = await blob.arrayBuffer();
                // Here's where you get access to the blob
                // And you can use it for whatever you want
                wp.media()
                    // Specify a path to the file you want to upload, or a Buffer
                    .file('/Users/CcHuMi/Desktop/CAPTURE/bb.png', 'test')
                    .create({
                        title: 'My awesome image',
                        alt_text: 'an image of something awesome',
                        caption: 'This is the caption text',
                        description: 'More explanatory information'
                    })
                    .then(function (response) {
                        // Your media is now uploaded: let's associate it with a post
                        var newImageId = response.id;
                        return wp.media().id(newImageId).update({
                            post: 33//associatedPostId
                        });
                    })
                    .then(function (response) {
                        console.log('Media ID #' + response.id);
                        console.log('is now associated with Post ID #' + response.post);
                    });

                // Here, I use it to make an image appear on the page
                //let objectURL = URL.createObjectURL(blob);
                //let myImage = new Image();
                //myImage.src = objectURL;
                //document.getElementById('myImg').appendChild(myImage)
            });*/

    }

    const _getGoogleConvert = () => {
        axios({
            url: 'https://api.exchangeratesapi.io/latest?base=CAD',
            //url: 'https://data.fixer.io/api/latest?access_key=f2a4c19525fd46ccb4101c54a9e2445e&base=USD&symbols=GBP,JPY,EUR', //your url

            //method: 'GET',
            // headers: { "Access-Control-Allow-Origin": "*" }
            //responseType: 'blob', // important
        }//, { crossdomain: true }
        ).then(async (response) => {

            console.log(response)
        })
    }
    //const { annonces, auth } = props;
    if (!auth.uid) return <Redirect to='/signin' />
    //console.log(monProfil)
    return (
        <div className={classes.root}>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Paper>
                        {/* <Button variant="contained" onClick={_createWPPost}>Create Post Wordpress</Button>
                        <Button variant="contained" onClick={_getWPPosts}>Get Posts Wordpress</Button>
                        <Button variant="contained" onClick={_getWPCategories}>Get Categories Wordpress</Button>
                        <Button variant="contained" onClick={_wpMediaUpload}>Upload Wordpress Media</Button>
    <Button variant="contained" onClick={_wpDeletePost}>Delete Wordpress Post 33</Button>
                        <Button variant="contained" onClick={_getGoogleConvert}>fetch currency</Button>*/}

                        <Typography variant="h6" component="h2" className={classes.title}>
                            Commentaires Récents
                        </Typography>
                        <Divider />
                        {/*<AnnonceList auth={auth} annonces={annonces} />*/}
                    </Paper>

                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <Statistiques compteurs={compteurs} allInscriptions={allInscriptionsData} /*userActivity={profile.userActivity}*/ />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
    //}
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        annonces: state.firestore.ordered.annonces,
        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil,
        lesEntreprises: state.firestore.ordered.lesEntreprises,
        allComments: state.firestore.ordered.allComments,
        compteurs: state.firebase.data.compteurs,
        allInscriptions: state.firestore.data.allInscriptions,
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect([
        { path: 'compteurs' },
        // { path: 'compteurs' }
    ]),
    firestoreConnect(props => [
        {
            collection: 'annonces',
            orderBy: ['createdAt', 'desc'],
            limit: 10
        },
        { collection: 'users', doc: props.auth.uid, storeAs: 'monProfil' },
        {
            collection: 'users',
            where: [['role', '==', 1]],
            orderBy: ['companyName', 'asc'],
            //limit: 10,
            storeAs: 'lesEntreprises'
        },
        {
            collectionGroup: 'comments',
            // where: [['authorId', '==', true]],
            storeAs: 'allComments'
        },
        {
            collectionGroup: 'users',
            where: [['inscriptions', '!=', null]],
            storeAs: 'allInscriptions'
        }
    ]),

    /*firestoreConnect([
        { collection: 'annonces' },
        limit: 5
    ])*/
)(Dashboard)