import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import DocumentIcon from '@material-ui/icons/DescriptionOutlined'
import CategoryIcon from '@material-ui/icons/CategoryOutlined'
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment'

import UserDetails from './UserDetails'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const useStylesDialog = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
function SimpleDialog(props) {
    const classes = useStylesDialog();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = value => {
        onClose(value);
    };
    //console.log(props.user)

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullScreen  TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Détail Utilisateur
            </Typography>
            
          </Toolbar>
        </AppBar>
            <UserDetails user={props.user} userid={props.user.id} />
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    //selectedValue: PropTypes.string.isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        color: 'red'
    },
    inline: {
        display: 'inline',
    },
    textAlignRight: {
        textAlign: 'right'
    },
    listItemText: {
        //maxWidth: '15vw'
    },
    linkIcon: {
        height: '0.90rem'
    },
    domaines: {
        width: '50vw',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));


const UsersListItem = ({ user, detailsView }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [prevUserId, setPrevUserId] = React.useState("");

    const handleClickOpen = () => {
        detailsView(true)
        setOpen(true);
    };

    const handleClose = value => {
        setPrevUserId(user.id)
        detailsView(false)
        setOpen(false);

    };
    //console.log(prevUserId)
    //render() {
    //const { annonces, auth } = props;
    //if (!auth.uid) return <Redirect to='/signin' />
    //console.log(user.domaines.join(", "))
    return (
        <div>
            <ListItem alignItems="flex-start" onClick={handleClickOpen} /*component={Link} to={'/user/' + user.id}*/ user={user} style={{ textDecoration: 'none', color: 'inherit' }} >
                <ListItemAvatar>
                    {user.profileImg ?
                        <Avatar alt="Remy Sharp" src={user.profileImg} />
                        :
                        <Avatar alt="Remy Sharp">{user.initials}</Avatar>
                    }
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Grid container direction="row" alignItems="flex-start" component="div">
                            <Grid item component="div" xs={2}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {user.role === 1 && user.companyName + " -"} {user.fullName}
                                </Typography>
                            </Grid>
                            <Grid item component="div" xs={4}>
                                {user.profileCV && <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {/*<DocumentIcon />*/}C.V en ligne
                                </Typography>}
                            </Grid>
                        </Grid>}
                    className={classes.listItemText}
                    secondary={
                        <React.Fragment>
                            <Grid container direction="row" alignItems="flex-start" component="span">
                                <Grid item component="span" xs={2}>
                                    <Grid container direction="row" alignItems="center" component="span">
                                        {user.birthday ?
                                            <Grid item component="span">
                                                <CakeOutlinedIcon className={classes.linkIcon} />
                                            </Grid>
                                            : null}
                                        {user.birthday ?
                                            <Grid item component="span">
                                                {user.birthday}
                                            </Grid>
                                            : null}
                                    </Grid>
                                </Grid>

                                <Grid item component="span" xs={10}>
                                    <Grid container direction="row" alignItems="center" component="span">
                                        {user.domaines && user.domaines !== null && user.domaines.length > 0 ?
                                            <Grid item component="span">
                                                {/*<CategoryIcon  />*/}
                                            </Grid>
                                            : null}
                                        {user.domaines && user.domaines !== null && user.domaines.length > 0 ?
                                            <Grid item component="span" className={classes.domaines}>
                                                Domaines : {user.domaines.join("/ ")}
                                            </Grid>
                                            : null}
                                    </Grid>
                                </Grid>

                            </Grid>
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction>
                    <Grid container direction="column" alignItems="flex-end" justify={"center"} component={"div"}>
                        <Grid item component="span">
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {"Inscrit le : " + moment.unix(user.dateInscription.seconds).format('DD/MM/YYYY à HH:MM')}
                            </Typography>
                        </Grid>

                        <Grid item component="span">
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {user.dateModification ? "Modifié le : " + moment.unix(user.dateModification.seconds).format('DD/MM/YYYY à HH:MM') : null}
                            </Typography>
                        </Grid>

                    </Grid>
                </ListItemSecondaryAction>

                {/*<Grid container direction="column" alignItems="flex-start" component="span">
                    <Grid item component="span" xs={4}>
                        <ListItemText
                            className={classes.textAlignRight}
                            primary={<React.Fragment>
                                {user.profileCV && <DocumentIcon />}
                            </React.Fragment>}
                        />
                    </Grid>
                    <Grid item component="span" xs={4}>
                        <ListItemText
                            className={classes.textAlignRight}
                            primary={<React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {user.domaines && "Domaines :" + user.domaines}
                                </Typography>
                            </React.Fragment>}
                        />
                    </Grid>
                </Grid>*/}

                {/*} <ListItemText
                    className={classes.textAlignRight}
                    primary={<React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            {"Inscrit le : " + moment.unix(user.dateInscription.seconds).format('DD/MM/YYYY à HH:MM')}
                        </Typography>
                    </React.Fragment>}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {user.dateModification ? "Modifié le : " + moment.unix(user.dateModification.seconds).format('DD/MM/YYYY à HH:MM') : null}
                            </Typography>
                        </React.Fragment>
                    }
                />*/}

            </ListItem>
            <Divider variant="inset" component="li" />
            <SimpleDialog user={user} open={open} onClose={handleClose} prevUserId={prevUserId} />
        </div >

    )
}

export default UsersListItem