import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import SignedInAvatar from './SignedInAvatar'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountIcon from '@material-ui/icons/AccountCircle';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deepOrange, deepPurple } from '@material-ui/core/colors';

import { roles } from '../../constants/roles'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: 10,
  },
  menu: {
    marginRight: '50px',
  },
  menuLink: {
    color: theme.primaryTxtColor.color,
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  badge: {
    root: {
      '&.MuiBadge-anchorOriginBottomRightCircle': {
        border: "2px solid white"
      }
    }
  }
}));

const RoleLinks = (role) => {

  const classes = useStyles();
  if (role === 0) {
    return (
      <div style={{ display: 'initial', paddingRight: '50px' }}>
        <Button className={classes.menuLink} component={Link} to="/">
          Dashboard
    </Button>
        <Button className={classes.menuLink} component={Link} to="/annonces">
          Annonces
    </Button>
        <Button className={classes.menuLink} component={Link} to="/users">
          Utilisateurs
    </Button>
        <Button className={classes.menuLink} component={Link} to="/messages">
          Messages
    </Button>
      </div>
    )
  }
  if (role === 1) {
    return (
      <div style={{ display: 'initial', paddingRight: '50px' }}>
        <Button className={classes.menuLink} component={Link} to="/">
          Dashboard
    </Button>
        <Button className={classes.menuLink} component={Link} to="/annonces">
          Mes Annonces
    </Button>
        <Button className={classes.menuLink} component={Link} to="/messages">
          Messages
    </Button>
      </div>
    )
  }
  if (role === 2) {
    return (
      <div style={{ display: 'initial', paddingRight: '50px' }}>
        <Button className={classes.menuLink} component={Link} to="/">
          Dashboard
    </Button>
        <Button className={classes.menuLink} component={Link} to="/annonces">
          Annonces
    </Button>
        <Button className={classes.menuLink} component={Link} to="/messages">
          Messages
    </Button>
      </div>
    )
  }
}

const SignedInLinks = (props) => {
  //console.log(props)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let RoleName = "";
  const handleChange = event => {
    //setAuth(event.target.checked);
  };
  if (roles[props.profile.role]) {
    RoleName = roles[props.profile.role].role
    //console.log(RoleName)
  }
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {/*RoleLinks(props.profile.role)*/}
      <SignedInAvatar profile={props.profile} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)