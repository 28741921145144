import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useFirebase, useFirestore } from "react-redux-firebase";
import { compose } from 'redux'
//import AnnonceSummaryCard from './AnnonceSummaryCard'
//import CreateAnnonce from './CreateAnnonce'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
/*DIALOG*/
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/locale/fr'
import PostsItems from './postsItems';
import FormPost from './forms/formPost';
import { deleteAtPath } from '../../utils/firebase/FirebaseUtils'
const useStyles = makeStyles(theme => ({
    root: {
        //padding: theme.spacing(2),
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        minHeight: '80vh'
    },
    roots: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    card: {
        // margin: 4,
        //minHeight: '300px'
        /* display: "flex",
         flexDirection: "column",
         justifyContent: "space-between"*/
    },
    title: {
        padding: 4,
        textAlign: 'center'
    },
    contentBody: {
        maxWidth: '1800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: '75vh',
        overflowY: 'scroll'
    },
    indicator: {
        backgroundColor: 'white'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    activeInactiveAppBar: {
        backgroundColor: theme.palette.secondary.main,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    link: {
        textDecoration: 'none'
    },
    clearIcon: {
        cursor: 'pointer'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    flexBackdrop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        "& >*": {
            margin: '10px'
        }
    }
}));

const EtudesScreen = ({ auth, etudesActives, etudesInactives, monProfil, compteurs, constants }) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [postsFromTheme, setPostsFromTheme] = React.useState(null);
    const [values, setValues] = useState();
    const [openTheme, setOpenTheme] = React.useState(false);
    const [openPost, setOpenPost] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [deletePath, setDeletePath] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    useEffect(() => {
        console.log('New Feed');
        console.log(etudesActives)
    }, [etudesActives]);
    const handleListItemClick = async (feedId, event, index) => {
        if (feedId) {
            setSelectedIndex(feedId);
            const docs = await firestore
                .collection("etudes")
                .doc(feedId)
                .collection("posts")
                .orderBy('created', 'desc')
                .get();
            let docconcat = []
            docs.forEach(doc => {
                //console.log(doc.id, '=>', doc.data());
                let docId = doc.id;
                let docData = doc.data();
                docconcat.push({ id: docId, ...docData })

            });
            console.log(docconcat)
            setPostsFromTheme({ ...docconcat });
        }


    };

    const handleOpenTheme = () => {
        setOpenTheme(!openTheme);
        console.log('open Theme')
    }

    const handleOpenPost = () => {
        setOpenPost(!openPost);
        console.log('open post')
    }

    const handleOpenDelete = (path) => {
        setOpenDelete(!openDelete);
        setDeletePath(path);
        console.log('open Delete')
    }
    const handleEditModeTheme = (feed) => {
        setEditMode(feed);
        handleOpenTheme();
    }

    const handleEditModePost = (feed) => {
        setEditMode(feed);
        handleOpenPost();

    }


    /*const updateSecteur = () => {
        firebase.database()
    }*/

    const handleSubmitPost = async () => {
        //e.preventDefault();
        console.log(values);
        if (values.backgroundImage) {
            if (values.image) {
                var previousImageRef = firebase.storage().refFromURL(values.image)
                var previousImageThumbnailRef = firebase.storage().refFromURL(values.imageThumbnail)
                //console.log(previousImageRef)
                await previousImageRef.delete()
                    //firebase.deleteFile(previousImageRef)
                    .then(async () => {
                        console.log("image deletion success")
                        await previousImageThumbnailRef.delete()
                            //firebase.deleteFile(previousImageRef)
                            .then(() => {
                                console.log(" all images deletion success")
                            })
                            .catch((err) => {
                                console.log("error ", err)
                            })
                    })
                    .catch((err) => {
                        console.log("error ", err)
                    })
            }
            /*const options = {
                name: (file) => `header-${file.name}`,
                progress: false
            }*/
            const filesPath = 'etudes/' + values.docId;
            //uploadImage()
            const file = values.backgroundImage[0];
            console.log(file)
            console.log(values.backgroundImage)
            const result = await firebase.storage().ref(filesPath).child(`header-${file.name}`)
                .put(file)
                .then((snap) => {
                    snap.ref.getDownloadURL()
                        .then((url) => {
                            console.log("url", url);
                            delete values.backgroundImage;
                            firestore.collection('etudes').doc(values.docId).set({
                                ...values,
                                userID: auth.uid,
                                created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                                modified: firestore.FieldValue.serverTimestamp(),
                                //feedId: selectedIndex,
                                //order: postsFromTheme.length + 1,
                                image: url
                            }, { merge: true }).then((doc) => {
                                console.log('sucess');
                                setOpenPost(false);
                                //handleListItemClick(selectedIndex);
                            });
                        })
                })
                .catch(err => {
                    console.log("err", err)
                });
            /* await firebase.uploadFile(filesPath, values.backgroundImage[0], null,  options)
                 .then((snap) => {
                     console.log(snap)
                     console.log(snap.downloadURL)
                     delete values.backgroundImage;
                     firestore.collection('etudes').doc(selectedIndex).collection('posts').doc(values.docId).set({
                         ...values,
                         userID: auth.uid,
                         created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                         feedId: selectedIndex,
                         order: postsFromTheme.length + 1,
                         image: snap.downloadURL
                     }, { merge: true }).then((doc) => {
                         console.log('sucess');
                         setOpenPost(false);
                         handleListItemClick(selectedIndex);
                     });
                 })
                 .catch((err) => {
                     console.error('error uploading file', err)
                     //setProgress(false);
                 });*/
        }
        else {
            firestore.collection('etudes').doc(values.docId).set({
                ...values,
                userID: auth.uid,
                //feedId: selectedIndex,
                created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                modified: firestore.FieldValue.serverTimestamp(),
                // order: postsFromTheme.length + 1
            }, { merge: true })
                .then((docRef) => {
                    /*docRef.update(
                        {
                            docID: docRef.id
                        }
                    )*/
                    setOpenPost(false);
                    //handleListItemClick(selectedIndex);
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }
    const handleDelete = async () => {
        /*await deleteAtPath(path).then((result) => {
            console.log(selectedIndex)
            handleListItemClick(selectedIndex);
        }).catch((error) => {
            console.error(error);
        });*/
        if (deletePath) {
            try {
                let response = await deleteAtPath(deletePath);
                console.log(response)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    console.log(selectedIndex)
                    handleListItemClick(selectedIndex);
                    setOpenDelete(false)
                    setDeletePath(null)
                    setOpenPost(false)
                }
            } catch (e) {
                console.log(e);
            }
        }

    }

    const cancelForm = () => {
        //alert('cancel')
        // onCancel()
        setOpenTheme(false);
        setOpenPost(false);
        setEditMode(false);
        setOpenDelete(false);
    }
    const updateValues = async (values) => {
        setValues(values)
    }
    console.log(constants)
    return (
        <div className={classes.root}>
            <AppBar position="static" style={{marginBottom: 10}}>
                <Toolbar >
                    <Typography variant="h5" className={classes.title} style={{ padding: 10, flex: 1 }}>
                        Etudes
            </Typography>
                    <Fab size="small" color="secondary" aria-label="add" className={classes.fabButton} onClick={handleOpenPost} >
                        <AddIcon />
                    </Fab>
                    <Divider />
                </Toolbar>
            </AppBar>
            <Grid container spacing={2} justify="center" className={classes.contentBody}>
                <Grid item xs={12} md={12}  >
                    <Grid container spacing={1} justify="flex-start" className={classes.contentBody}>
                        {!isEmpty(etudesActives) ? Object.values(etudesActives).map((post, index) => {
                            console.log(post)
                            return (
                                <Grid item xs={12} md={3} key={index}  >
                                    <PostsItems post={post} index={index} feedId={selectedIndex} handleListItemClick={() => { handleListItemClick(post.docId) }} handleEditModePost={() => { handleEditModePost(post) }} handleOpenDelete={handleOpenDelete} />
                                </Grid>
                            )
                        })
                            :

                            <Typography>Aucun Article dans cette catégorie veuillez en créer un en cliquant sur +</Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={openPost} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'xl'}
                disableEnforceFocus
            //style={{maxWidth: '100%'}}
            //fullWidth={fullWidth}
            //minWidth={"lg"}
            >
                <DialogTitle id="form-dialog-title">Article</DialogTitle>
                <DialogContent>
                    {/*} <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>*/}
                    <FormPost
                        cancelForm={cancelForm}
                        cancelFormButtonName={"Annuler"}
                        saveForm={handleSubmitPost}
                        saveFormButtonName={editMode ? "Mettre à jour" : "Créer l'article"}
                        edit={editMode}
                        postValues={editMode}
                        feedId={selectedIndex}
                        secteurs={constants && constants.SecteursFormations}
                        updateValues={updateValues}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => cancelForm()}
                        //disabled={formNotValid.length > 0 ? true : false}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}>
                        ANNULER
                    </Button>
                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={() => handleSubmitPost()}
                        //disabled={formNotValid.length > 0 ? true : false}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}>
                        {editMode ? "Mettre à jour" : "Créer l'article"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*DIALOG SUPPRESSION */}
            <Dialog
                open={openDelete}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Voulez vous vraiment supprimer cet élement?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Toute suppression est définitive.
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelForm} color="primary">
                        Annuler
          </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Oui Supprimer
          </Button>
                </DialogActions>
            </Dialog>
        </div>


    )
}

const mapStateToProps = (state) => {
    console.log(state);
    return {

        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil,
        etudesActives: state.firestore.ordered.etudesActives,
        constants: state.firebase.data.constants,
        //etudesInactives: state.firestore.ordered.etudesInactives,
        compteurs: state.firebase.data.compteurs,
        /*annoncesActives: state.firestore.ordered.annoncesActives,
        annoncesInactives: state.firestore.ordered.annoncesInactives,*/
    }
}
const mapDispatchToProps = dispatch => {
    return {
        //deleteAnnonce: (annonceId) => dispatch(deleteAnnonce(annonceId)),
        //loadInitAnnonces: (limit, statut, entreprise, type) => dispatch(loadInitAnnonces(limit, statut, entreprise, type)),
        //loadMoreAnnonces: (limit, startAfter, statut, entreprise, type) => dispatch(loadMoreAnnonces(limit, startAfter, statut, entreprise, type))
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect((props, store) => {
        // console.log(props)
        // console.log(store)
        return [
            { path: 'compteurs' }, // make sure to pass "populates"
        ]
    }),
    firestoreConnect(props => [
        {
            collection: 'etudes',
            //where: [['statut', '==', 1]],
            orderBy: ['modified', 'desc'],
            // limit: 25,
            storeAs: 'etudesActives'
        },
        /*{
            collection: 'etudes',
            //where: [['statut', '==', 0]],
            // orderBy: ['created', 'desc'],
            //limit: 25,
            storeAs: 'etudesInactives'
        },*/
    ]),
)(EtudesScreen)