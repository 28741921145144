import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment'

import UserDetails from './UserDetails'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      },
}));


const UsersListItemCache = ({ user, detailsView }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [prevUserId, setPrevUserId] = React.useState("");
    const bull = <span className={classes.bullet}>•</span>;
    const handleClickOpen = () => {
        detailsView(true)
        setOpen(true);
    };

    const handleClose = value => {
        setPrevUserId(user.id)
        detailsView(false)
        setOpen(false);

    };
    const viewCVCachete = cvcachete => {

            //const url = 'somesite.com?data=yourDataToSend';
            window.open(user.profileCVCachete, '_blank');
    }
    //console.log(prevUserId)
    //render() {
    //const { annonces, auth } = props;
    //if (!auth.uid) return <Redirect to='/signin' />
    //console.log(user.domaines.join(", "))
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                   {user.domaines && user.domaines}
          </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={viewCVCachete}>Voir CV</Button>
            </CardActions>
        </Card>

    )
}

export default UsersListItemCache