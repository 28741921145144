
export const annoncesValues = {
  simpleTextField: 'simple text field default value',
  passwordTextField: 'password text field default value',
  numbertextField: 123124,
  multilientextField:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n' +
    'sed do eiusmod tempor incididunt ut labore et dolore magna\n' +
    'aliqua. Ut enim ad minim veniam, quis nostrud exercitation\n' +
    'ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  multilineTextFieldWithEmoji: '😅😌🤠😤😘',
  isActivated: true,
  chipField: ['chip1', 'chip2', 'chip3'],
  singleselect: 1,
  multiselect: [1, 2],
  asyncSelectSingle: { value: 1, label: 'first item' },
  asyncSelectMultiple: [{ value: 1, label: 'first item' }, { value: 2, label: 'second item' }],
  imageUpload: 'https://picsum.photos/1200/150',
};

const selectItems = [
  { value: 'emploi', title: 'Emploi' },
  { value: 'formation', title: 'Formation' },
  { value: 'stage', title: 'Stage' },
];
const selectTypeAnnonce = [
  { value: 'emploi', title: 'Emploi' },
  { value: 'formation', title: 'Formation' },
  { value: 'stage', title: 'Stage' },
];

export const annoncesFields = [

  {

    name: 'title',
    label: 'Titre',
    type: 'text',
    key: 'title',
    fullWidth: true,
    gridSize: 6

  },
  {
    name: 'entreprise',
    label: 'Entreprise',
    key: 'entreprise',
    fullWidth: true,
    type: 'select',
    gridSize: 6,
    //selectFieldMap: ["id", "companyName"]
   /* type: 'select',
    items: [...selectItems],
    multiple: false,
    empty: true,
    dialogActive: true,
    dialogContent: 'You can add alert dialog to the Single select. When the value is changing the modal pops up.',
    dialogTitle: 'Modal Title',*/
  },
  /*{
    name: 'reference',
    label: 'Réference',
    type: 'text',
    key: 'reference',
    fullWidth: true,
    gridSize: 6
  },*/
  {

    name: 'description',
    label: 'Description',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'description',
    fullWidth: true,
    gridSize: 12

  },
  /*{

    name: 'description_formatted',
    label: 'Description',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'description_formatted',
    fullWidth: true

  },*/
  {
    name: 'competences',
    label: 'Compétences',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'competences',
    fullWidth: true,
    gridSize: 12
  },
  {
    name: 'conditions',
    label: 'Conditions',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'conditions',
    fullWidth: true,
    gridSize: 12
  },
  {
    name: 'entree',
    label: 'Entree',
    type: 'text',
    key: 'entree',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'salaire',
    label: 'Salaire',
    type: 'text',
    key: 'salaire',
    fullWidth: true,
    gridSize: 6
  },
];
export const formationsFields = [
  {

    name: 'title',
    label: 'Titre',
    type: 'text',
    key: 'title',
    fullWidth: true,
    gridSize: 6

  },
  /*{
    name: 'reference',
    label: 'Réference',
    type: 'text',
    key: 'reference',
    fullWidth: true,
    gridSize: 6
  },*/
  {

    name: 'description',
    label: 'Description',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'description',
    fullWidth: true,
    gridSize: 12

  },
  /*{

    name: 'description_formatted',
    label: 'Description',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'description_formatted',
    fullWidth: true

  },*/
  {
    name: 'competences',
    label: 'Compétences',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'competences',
    fullWidth: true,
    gridSize: 12
  },
  {
    name: 'conditions',
    label: 'Conditions',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'conditions',
    fullWidth: true,
    gridSize: 12
  },
  {
    name: 'entree',
    label: 'Entree',
    type: 'text',
    key: 'entree',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'salaire',
    label: 'Salaire',
    type: 'text',
    key: 'salaire',
    fullWidth: true,
    gridSize: 6
  },
];
export const stagesFields = [
  {

    name: 'title',
    label: 'Titre',
    type: 'text',
    key: 'title',
    fullWidth: true,
    gridSize: 6

  },
  /*{
    name: 'reference',
    label: 'Réference',
    type: 'text',
    key: 'reference',
    fullWidth: true,
    gridSize: 6
  },*/
  {

    name: 'description',
    label: 'Description',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'description',
    fullWidth: true,
    gridSize: 12

  },
  /*{

    name: 'description_formatted',
    label: 'Description',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'description_formatted',
    fullWidth: true

  },*/
  {
    name: 'competences',
    label: 'Compétences',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'competences',
    fullWidth: true,
    gridSize: 12
  },
  {
    name: 'conditions',
    label: 'Conditions',
    type: 'multiLineText',
    rows: 10,
    rowsMax: 10,
    key: 'conditions',
    fullWidth: true,
    gridSize: 12
  },
  {
    name: 'entree',
    label: 'Entree',
    type: 'text',
    key: 'entree',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'salaire',
    label: 'Salaire',
    type: 'text',
    key: 'salaire',
    fullWidth: true,
    gridSize: 6
  },
];

const Situation = [
	{
		title: "Célibataire",
		value: "Célibataire",
		id: 1
	},
	{
		title: "Concubinage",
		value: "Concubinage",
		id: 2
	},
	{
		title: "Marié",
		value: "Marié",
		id: 3
	}
]
//const Situation =  getStoreDetails();
//console.log(Constants())
const Nationalite = [
	{
		title: "France",
		value: "France",
    id: 1
	},
	{
		title: "Canada",
		value: "Canada",
		id: 2
	},
	{
		title: "Royaume-Unis",
		value: "Royaume-Unis",
		id: 3
	},
	{
		title: "Belgique",
		value: "Belgique",
		id: 4
	},
	{
		title: "Suisse",
		value: "Suisse",
		id: 5
	},
	{
		title: "Luxembourg",
		value: "Luxembourg",
		id: 6
	},
	{
		title: "Autre",
		value: "Autre",
		id: 7
	}
]

export const candidatsFields = [
  {
    name: 'firstName',
    label: 'Prénom',
    type: 'text',
    key: 'firstName',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'name',
    label: 'Nom',
    type: 'text',
    key: 'name',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'nbEnfants',
    label: 'Nombre d\'enfants',
    type: 'number',
    key: 'nbEnfants',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'pvt',
    label: 'PVT',
    type: 'checkbox',
    key: 'pvt',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'role',
    label: 'Role',
    type: 'number',
    key: 'role',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'nationalite',
    label: 'Nationalité',
    type: 'select',
    key: 'nationalite',
    items: "Nationalites",
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'situation',
    label: 'Situation',
    type: 'select',
    key: 'situation',
    items: "Situation",
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'birthday',
    label: 'Anniversaire',
    type: 'text',
    key: 'birthday',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'domaines',
    label: 'Domaines',
    type: 'multiselect',
    key: 'domaines',
    items: "Domaines",
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'profileCV',
    label: 'C.V',
    type: 'file',
    key: 'profileCV',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'profileCVCachete',
    label: 'C.V Cacheté',
    type: 'file',
    key: 'profileCVCachete',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'sellsyId',
    label: 'Id Sellsy',
    type: 'text',
    key: 'sellsyId',
    fullWidth: true,
    gridSize: 6
  }
];

export const adminsFields = [
  {
    name: 'firstName',
    label: 'Prénom',
    type: 'text',
    key: 'firstName',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'name',
    label: 'Nom',
    type: 'text',
    key: 'name',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'nbEnfants',
    label: 'Nombre d\'enfants',
    type: 'number',
    key: 'nbEnfants',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'nationalite',
    label: 'Nationalité',
    type: 'select',
    key: 'nationalite',
    items: "Nationalites",
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'situation',
    label: 'Situation',
    type: 'select',
    key: 'situation',
    items: "Situation",
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'birthday',
    label: 'Anniversaire',
    type: 'text',
    key: 'birthday',
    fullWidth: true,
    gridSize: 6
  }
];


/*id: "PZ6FZWdtMIRZqu9RM59veRyn9ev2"
codePostal: "G0A 4L0"
companyName: "Patates Dolbec"
complementAdresse: ""
completed: 0
dateInscription: Timestamp {seconds: 1569952527, nanoseconds: 145000000}
docKey: "E00000026"
faxNumber: ""
firstName: "Esther"
firstName2: ""
fonction: ""
fullName: "Esther Savard"
initials: "ES"
mail: "esavard@dolbec.ca"
name: "Savard"
name2: ""
numSiret: ""
password: "ObjectifQuebec70"
pays: "Québec"
phoneNumber: "418 277-2442"
profileImg: ""
role: 1
rue: "295, QC-363"
statut: 1
ville: "Saint-Ubalde"
webSite: "patatesdolbec.com/"*/
export const entreprisesFields = [
  {
    name: 'companyName',
    label: "Nom de l'entreprise",
    type: 'text',
    key: 'companyName',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'numSiret',
    label: "Siret",
    type: 'text',
    key: 'numSiret',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'rue',
    label: 'Rue',
    type: 'text',
    key: 'rue',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'complementAdresse',
    label: "Complement d'adresse",
    type: 'text',
    key: 'complementAdresse',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'codePostal',
    label: "Code postal",
    type: 'text',
    key: 'codePostal',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'ville',
    label: "Ville",
    type: 'text',
    key: 'ville',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'pays',
    label: "Pays",
    type: 'text',
    key: 'pays',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'firstName',
    label: 'Prénom Dirigeant',
    type: 'text',
    key: 'firstName',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'name',
    label: 'Nom Dirigeant',
    type: 'text',
    key: 'name',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'firstName2',
    label: 'Prénom Correspondant',
    type: 'text',
    key: 'firstName2',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'name2',
    label: 'Nom Correspondant',
    type: 'text',
    key: 'name2',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'fonction',
    label: 'Fonction Correspondant',
    type: 'text',
    key: 'fonction',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'phoneNumber',
    label: 'Numéro de télephone',
    type: 'number',
    key: 'phoneNumber',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'faxNumber',
    label: 'Numéro de Fax',
    type: 'number',
    key: 'faxNumber',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'webSite',
    label: "WebSite",
    type: 'text',
    key: 'webSite',
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'domaines',
    label: 'Domaines',
    type: 'multiselect',
    key: 'domaines',
    items: "Domaines",
    fullWidth: true,
    gridSize: 6
  },
  {
    name: 'sellsyId',
    label: 'Id Sellsy',
    type: 'text',
    key: 'sellsyId',
    fullWidth: true,
    gridSize: 6
  }
];


export const fieldss = [
  { name: 'simple text field', type: 'text', key: 'simpleTextField' },

  { name: 'simple text field with error', type: 'text', key: 'simpleTextFieldWithError' },

  { name: 'password text field', type: 'password', key: 'passwordTextField' },

  { name: 'number text field', type: 'number', key: 'numbertextField' },

  { name: 'multiline text field', type: 'multiLineText', key: 'multilientextField' },

  {
    name: 'multiline text field with emoji',
    type: 'multiLineText',
    key: 'multilineTextFieldWithEmoji',
    emoji: true,
  },

  { name: 'Activated', type: 'checkbox', key: 'isActivated' },

  {
    name: 'chips',
    type: 'chip',
    key: 'chipField',
  },

  {
    name: 'single select',
    type: 'select',
    items: [...selectItems],
    key: 'singleselect',
    multiple: false,
    empty: true,
    dialogActive: true,
    dialogContent: 'You can add alert dialog to the Single select. When the value is changing the modal pops up.',
    dialogTitle: 'Modal Title',
  },

  {
    name: 'multiselect',
    type: 'select',
    items: [...selectItems],
    key: 'multiselect',
    multiple: true,
  },

  {
    name: 'asyncSelect single',
    type: 'asyncSelect',
    items: [{ value: 1, label: 'first item' }, { value: 2, label: 'second item' }],
    promiseOptions: (inputValue) =>
      new Promise((resolve) => {
        console.log('promiseOptions', inputValue);
        setTimeout(() => {
          resolve(
            [
              { value: 1, label: 'first item' },
              { value: 2, label: 'second item' },
              { value: 3, label: '3rd item' },
              { value: 4, label: '4th item' },
              { value: 5, label: 'item5' },
              { value: 6, label: 'item6' },
            ].filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
          );
        }, 1000);
      }),
    key: 'asyncSelectSingle',
    empty: true,
  },

  {
    name: 'asyncSelect multiple',
    type: 'asyncSelect',
    items: [{ value: 1, label: 'first item' }, { value: 2, label: 'second item' }],
    promiseOptions: (inputValue) =>
      new Promise((resolve) => {
        console.log('promiseOptions', inputValue);
        setTimeout(() => {
          resolve(
            [
              { value: 1, label: 'first item' },
              { value: 2, label: 'second item' },
              { value: 3, label: '3rd item' },
              { value: 4, label: '4th item' },
              { value: 5, label: 'item5' },
              { value: 6, label: 'item6' },
            ].filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
          );
        }, 1000);
      }),
    key: 'asyncSelectMultiple',
    multiple: true,
  },

  {
    name: 'image upload',
    type: 'image',
    key: 'imageUpload',
    position: 'flex',
    resource: '/test',
    uploadSuccess: () => console.log('upload'),
  },
];