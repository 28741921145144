import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';


const useStyles = makeStyles(theme => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    //marginTop: theme.spacing(2),
  },
  divRoot: {
    height: '36px'
  },
  input: {
    padding: "8.5px 14px"
  }
}));

function Option(items, selectFieldMap) {
  //console.log(items)
  //console.log(selectFieldMap[0])
  /*return (
    <option value="xfd" />
  )    */
  if (selectFieldMap) {
    return (

      items && items.map((item, i) => (
        <option key={i} value={item.id}>{item.companyName}</option>
      ))
    )
  }
  else {
    //console.log(items)
    return (
      items && items.map((item, i) => (
        <option key={i} value={item.Value}>{item.Name}</option>
      ))
    )
  }



  /*{items.map((item) => ({ 

    <option value={item.value}>{item.title }</option>

  }))}*/
  // )
}

const NativeSelects = ({ field, selectedValue, errors, disabled, onChange, multiline, constants, ...others }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const key = field.key || field.name || 'key';
  const inputKey = `form-${key}`;
  const name = field.name || 'field';
  const label = field.label || 'label';
  let items = '';
  /*let test = constants[field.items].filter(function (obj) {
    return obj.field !== 'type';
  });
  console.log(test)*/
  let Values = [];
  let test = '';
  /*Object.values(constants && constants[field.items].children ? constants[field.items].children : constants[field.items].value).map((item, index) => {
    console.log(item)
    console.log(typeof item);
    if (typeof item !== 'string') {
      let itemValue = { 'Name': item.Name, 'Value': item.Value, 'id': index };
      console.log(itemValue)
      Values.push(item)
    }
  });*/

  if (constants) {
    //console.log((constants))
    if (constants[field.items].children) {
      //console.log(constants[field.items].children)
      test = constants[field.items].children;
      //console.log(test)
      if (test.type) {
        delete test.type
      }
      /*Object.entries(constants[field.items].children).map(function (item, index) {
        console.log(item)
        if (item !== 'type') {
          Values.push(item);
        }
      });*/
     // let newArray = new Array(test)
      items = test; //test; // Values; // constants[field.items].children

    }
    else if (constants[field.items]) {
      //console.log(constants[field.items])
      test = constants[field.items];
      //console.log(test)
      if (test.type) {
        delete test.type
      }

       test.map(function (item, index) {
         //console.log(item)
         if (item !== 'type') {
           Values.push(item);
         }
       });
      //console.log(test)
      //Values.push(test)
      //let newArray = new Array(test)
      items = test;//Values; //constants[field.items]
    }
    else {
      items = '';
    }
    //console.log(items)
    /*items = constants[field.items].children ?

      constants[field.items].children
      : constants[field.items] ?
        constants[field.items]
        : '';*/
  }
  const selectFieldMap = field.selectFieldMap || '';
  const fullWidth = field.fullWidth || false;
  const helpertext = field.helpertext || '';
  const type = field.type || 'text';
  //console.log(field)
  //console.log(items)
  //console.log(selectedValue)
  const handleChange = name => event => {
    //console.log(name)
    //console.log(event.target.value)
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      <FormControl className={classes.formControl} fullWidth={fullWidth} variant="outlined" >
        <InputLabel ref={inputLabel} htmlFor="simple-select" shrink={true}  >{label}</InputLabel>
        <Select
          native
          disabled={disabled}
          value={selectedValue}
          onChange={onChange}
          //labelWidth={labelWidth}
          className={classes.root}
          input={<OutlinedInput labelWidth={labelWidth} notched={true} classes={{ input: classes.input }} />}
          //labelWidth={63.5}


          inputProps={{
            name: key,
            id: 'simple-select',
          }}
        >
          <option value="" />
          {Option(items)}
        </Select>
      </FormControl>
    </div>
  );
}

export default NativeSelects