//Fichier de fonctions de formattage de Nom (ex : Recup les initials, le noms complet...)
export function validationEmploi(emploi) {
    return {
        title: !emploi.title || emploi.title.trim().length === 0
            ? "Le titre est obligatoire!"
            : false,
        salaire:
            !emploi.salaire || emploi.salaire.trim().length === 0
                ? "Le Salaire est obligatoire!"
                : false,
        secteurActivite:
            !emploi.secteurActivite || emploi.secteurActivite.trim().length === 0
                ? "Le secteur est obligatoire!"
                : false,
        idEntreprise: !emploi.idEntreprise || emploi.idEntreprise.trim().length === 0
            ? "L'entreprise' est obligatoire!"
            : false,
        entree:
            !emploi.entree || emploi.entree.trim().length === 0
                ? "L'entrée est obligatoire!"
                : false,
        description:
            !emploi.description || emploi.description.trim().length === 0
                ? "La description est obligatoire!"
                : false,
    };
}

export function validationFormation(formation) {
    return {
        title: !formation.title || formation.title.trim().length === 0
            ? "Le titre est obligatoire!"
            : false,
        duree:
            !formation.duree || formation.duree.trim().length === 0
                ? "La durée de la formation est obligatoire!"
                : false,
        /*salaire:
          !salaire || salaire.trim().length === 0
            ? "Le Salaire est obligatoire!"
            : false,*/
        secteurActivite:
            !formation.secteurActivite || formation.secteurActivite.trim().length === 0
                ? "Le secteur est obligatoire!"
                : false,
        /*entree:
          !entree || entree.trim().length === 0
            ? "L'entrée est obligatoire!"
            : false,*/
        description:
            !formation.description || formation.description.trim().length === 0
                ? "La description est obligatoire!"
                : false,
    };
}

//fonction pour récupèrer les initials à partir du Nom et du Prénom
export function getInitial(nom, prenom) {
    if (nom && prenom) {
        return prenom.charAt(0) + nom.charAt(0)
    }
}

//Fonction qui Renvoit le Nom complet en concaténant le nom et le prénom
export function getNomComplet(nom, prenom) {
    if (nom && prenom) {
        return prenom + ' ' + nom
    }
}

//Fonction qui met une majuscule sur la première lettre même pour un nom séparé d'un espace ou d'un tiret
export function upperCaseName(name) {
    if (name) {
        if (name.includes(' ')) {
            var words = name.split(' ')
            let mot1 = words[0].charAt(0).toUpperCase() + words[0].slice(1)
            let mot2 = words[1].charAt(0).toUpperCase() + words[1].slice(1)
            return mot1 + ' ' + mot2
        } else if (name.includes('-')) {
            words = name.split('-')
            let mot1 = words[0].charAt(0).toUpperCase() + words[0].slice(1)
            let mot2 = words[1].charAt(0).toUpperCase() + words[1].slice(1)
            return mot1 + '-' + mot2
        } else {
            return name.charAt(0).toUpperCase() + name.slice(1)
        }
    }


}

//Fonction qui enleve les accents dans un string
export function sansAccent(str) {
    if (str) {
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        for (var i = 0; i < accent.length; i++) {
            str = str.replace(accent[i], noaccent[i]);
        }

        return str;
    }

}