import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import BackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import _, { cloneDeep } from 'lodash'
import { firestoreConnect, useFirestore } from 'react-redux-firebase';
const useStyles = makeStyles((theme) => ({
    root: {
        maxHeight: 650,
        maxWidth: '100%'
    },
    container: {
        display: 'flex',
    },
    paper: {
        margin: theme.spacing(1),
    },
    svg: {
        width: 100,
        height: 100,
    },
    title: {
        flexGrow: 1,
    },
    hidden: {
        // visibility: 'hidden'
        display: 'none'
    },
    polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
    },
    list: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'flex-start',
        overflow: 'hidden',
        maxHeight: 100
    },
    etape: {
        fontWeight: '600',
        fontSize: 16
    },
    backdrop: {
        zIndex: 1400,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    flexBackdrop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        "& >*": {
            margin: '10px'
        }
    },
    formControl: {
        //margin: theme.spacing(1),
        //minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

let typesPermis = [
    { value: "PE", nom: "Permis d’étude" },
    { value: "PT", nom: "Permis de travail (PT) avec ou sans EIMT" },
    { value: "RP", nom: "Résidence permanente" },
    { value: "P", nom: "Parrainage" },
    { value: "VT", nom: "Visa temporaire" }
]



const SuiviDossiers = (props) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const [dossierDetail, setDossierDetail] = React.useState(false);
    const [dossiers, setDossiers] = React.useState(null);
    const [dossierDetailEtapes, setDossierDetailEtapes] = React.useState(false);
    const [dossierDetailEtape, setDossierDetailEtape] = React.useState(false);
    const [openDossierForm, setOpenDossierForm] = React.useState(false);
    const [openEtapeForm, setOpenEtapeForm] = React.useState(false);
    const [currentIndexDossier, setCurrentIndexDossier] = React.useState(false);
    const [currentIndexEtape, setCurrentIndexEtape] = React.useState(false);
    const [hideDossiers, setHideDossiers] = React.useState(false);
    const [addDossier, setAddDossier] = React.useState(false);
    const [addEtape, setAddEtape] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    const [canSaveDossier, setCanSaveDossier] = React.useState(false);
    const [canSaveEtape, setCanSaveEtape] = React.useState(false);
    const { userProfile } = useSelector(state => state.firestore.data)

    console.log(userProfile)
    console.log(props.userid)
    console.log(props)
    /*CHECK SAVE DOSSIER FORM*/
    useEffect(() => {
        if (!dossierDetail.nom || !dossierDetail.prenom || !dossierDetail.dateNaissance /*|| !dossierDetail.typePermis*/) {
            setCanSaveDossier(false)
        }
        else {
            setCanSaveDossier(true)
        }
    }, [dossierDetail])

    /*CHECK SAVE ETAPE FORM*/
    useEffect(() => {
        console.log("cansave")
        console.log(dossierDetailEtape)
        if (!dossierDetailEtape.intitule || !dossierDetailEtape.dateRealisation || dossierDetailEtape.intitule === '' || dossierDetailEtape.dateRealisation === '') {
            setCanSaveEtape(false)
        }
        else {
            setCanSaveEtape(true)
        }
    }, [dossierDetailEtape])

    const _saveDossierToFirestore = (dossierToSave) => {
        console.log(userProfile.id)
        console.log(dossierToSave)

        firestore.collection('users').doc(props.userid).update({
            dossiers: dossierToSave
        }).then((result) => {
            console.log("enrengistrement réussi")
            console.log(result)
            setIsSaving(false)
            //setCanSave(true)
        }).catch((error) => {
            //res.send('Transaction failed:' + error);
            console.log('Transaction failed:', error);
            setIsSaving(false)
        });

    }
    const _gotoDetails = (index) => {
        console.log('go to details')
        let dossierDetailsTemp = dossiers[index];
        setHideDossiers(true)
        setCurrentIndexDossier(index)
        //dossierDetailsTemp =
        setDossierDetail(dossierDetailsTemp)
        setDossierDetailEtapes(dossierDetailsTemp.etapes)
    }

    /*DOSSIERS*/

    const _deleteDossier = (index) => {
        console.log("remove element")
        console.log(dossiers)
        //let newDossiersArray = null;
        console.log(dossiers.length)
        let newDossiersArray = cloneDeep(dossiers)
        // if (dossiers.length > 1) {
        // console.log('> 1')
        //newDossiersArray = dossiers.splice(index, 1);
        newDossiersArray.splice(index, 1);
        /*}
         else {
             console.log('= 1')
             newDossiersArray = [];
         }*/
        console.log(newDossiersArray)
        setDossiers(newDossiersArray)
        _saveDossierToFirestore(newDossiersArray)
    }

    const _editDetailsDossier = (index) => {
        console.log('edit detail')
        console.log(index)
        let dossierDetailsTemp = dossiers[index];
        setCurrentIndexDossier(index)
        // setEditDossier(true)
        setDossierDetail(dossierDetailsTemp)
        handleOpenDossierForm();
    }
    const handleOpenDossierForm = () => {
        setOpenDossierForm(true)
    }
    const handleCloseDossierForm = () => {
        setOpenDossierForm(false)
        setAddDossier(false)
        setDossierDetail(false)
        setCurrentIndexDossier(false)
        //setEditDossier(false)
    }
    const handleSubmitDossier = () => {
        console.log("submit")
        _saveDossier();
    }
    const handleAddNewDossier = () => {
        setDossierDetail({ nom: userProfile.name, prenom: userProfile.firstName })
        setAddDossier(true)
        setOpenDossierForm(true)
    }
    const _saveDossier = () => {
        setIsSaving(true)
        console.log('saving dossier')
        if (addDossier) {
            //let profileDossierDetailArray = cloneDeep(dossierDetail)
            setDossiers([...dossiers, { ...dossierDetail, createdAt: firestore.Timestamp.now() }])
            console.log(dossiers)
            _saveDossierToFirestore([...dossiers, { ...dossierDetail, createdAt: firestore.Timestamp.now() }]);
            setAddDossier(false);
            setOpenDossierForm(false);
            setCurrentIndexDossier(false);
        } else {
            let profileDossierArray = cloneDeep(dossiers)
            profileDossierArray[currentIndexDossier] = { ...dossierDetail, createdAt: firestore.Timestamp.now() };
            console.log("Dossier Detail saved")
            console.log(dossierDetail)
            setDossiers(profileDossierArray)
            console.log(profileDossierArray)
            console.log(dossiers)
            _saveDossierToFirestore(profileDossierArray);
            setAddDossier(false);
            setOpenDossierForm(false);
            setCurrentIndexDossier(false);

        }
        /* setTimeout(()=> {
             _saveDossierToFirestore();
             
         }, 1500)*/
    }

    const handleChangeDossier = (event) => {
        console.log("handleChangeDossier")
        console.log(event.target.name);
        console.log(event.target.value)
        console.log(dossierDetail)
        if (event.target.name === 'dateNaissance') {
            setDossierDetail({ ...dossierDetail, dateNaissance: moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY') })

        }
        else if (event.target.name === 'typePermis') {
            let typePermisObj = typesPermis.find(type => type.value === event.target.value);
            setDossierDetail({ ...dossierDetail, typePermis: event.target.value, typePermisLong: typePermisObj.nom })
        }
        else {
            setDossierDetail({ ...dossierDetail, [event.target.name]: event.target.value })
        }

    }

    /*ETAPES*/
    const _deleteEtape = (index) => {
        console.log("remove Etape element")
        let DossiersClone = cloneDeep(dossiers)
        console.log(dossierDetailEtapes)
        let newEtapesArray = null;
        /*if (DossiersClone[currentIndexDossier].etapes.length > 1) {
            newEtapesArray = DossiersClone[currentIndexDossier].etapes.splice(index, 1);
        }
        else {
            newEtapesArray = [];
        }*/
        DossiersClone[currentIndexDossier].etapes.splice(index, 1);

        setDossierDetailEtapes([...DossiersClone[currentIndexDossier].etapes]);
        setDossierDetail({ ...dossierDetail, etapes: [...DossiersClone[currentIndexDossier].etapes] })
        //DossiersClone[currentIndexDossier].etapes = [...newEtapesArray]
        setDossiers(DossiersClone)
        //let NewDossierArray = { ...dossiers[currentIndexDossier] }
        //console.log(newEtapesArray)
        //let newDossiersArray = [...DossiersClone, DossiersClone[currentIndexDossier]: { ...dossiers[currentIndexDossier], etapes: [...newEtapesArray] }];

        //console.log()
        console.log(DossiersClone)
        _saveDossierToFirestore(DossiersClone)
    }

    const _editDetailsEtape = (index) => {
        console.log('edit detail')
        console.log(index)
        setCurrentIndexEtape(index);
        let dossierDetailsEtapeTemp = dossierDetailEtapes[index];
        console.log(dossierDetailsEtapeTemp)
        setDossierDetailEtape(dossierDetailsEtapeTemp)
        handleOpenEtapeForm();
    }
    const handleOpenEtapeForm = () => {
        setOpenEtapeForm(true)
    }
    const handleCloseEtapeForm = () => {
        setDossierDetailEtape(false)
        setOpenEtapeForm(false)
        setAddEtape(false)
    }
    const handleSubmitEtape = () => {
        console.log("submit")
        _saveEtape();
    }
    const _saveEtape = () => {
        console.log('saving etape')
        setIsSaving(true);
        if (addEtape) {
            if (dossierDetailEtapes) {
                let profileDossierEtapesDetailArray = cloneDeep(dossierDetailEtapes)
                profileDossierEtapesDetailArray = [...profileDossierEtapesDetailArray, { ...dossierDetailEtape, createdAt: firestore.Timestamp.now() }];
                setDossierDetailEtapes(profileDossierEtapesDetailArray);

                let updatedDossier = { ...dossierDetail, etapes: profileDossierEtapesDetailArray }
                setDossierDetail(updatedDossier);

                let profileDossierArray = cloneDeep(dossiers)
                profileDossierArray[currentIndexDossier].etapes = [...profileDossierArray[currentIndexDossier].etapes, { ...dossierDetailEtape, createdAt: firestore.Timestamp.now() }];
                console.log('set new dossier')
                console.log(profileDossierArray)
                setDossiers([...profileDossierArray])
                setAddEtape(false);
                setOpenEtapeForm(false);
                setCurrentIndexEtape(false);
                setDossierDetailEtape(false);
                _saveDossierToFirestore([...profileDossierArray]);
            }
            else {
                //let profileDossierEtapesDetailArray = cloneDeep(dossierDetailEtapes)
                let profileDossierEtapesDetailArray = [{ ...dossierDetailEtape, createdAt: firestore.Timestamp.now() }];
                setDossierDetailEtapes(profileDossierEtapesDetailArray);

                let updatedDossier = { ...dossierDetail, etapes: profileDossierEtapesDetailArray }
                setDossierDetail(updatedDossier);

                let profileDossierArray = cloneDeep(dossiers)
                profileDossierArray[currentIndexDossier] = updatedDossier;
                console.log('set new dossier')
                console.log(profileDossierArray)
                setDossiers([...profileDossierArray])
                setAddEtape(false);
                setOpenEtapeForm(false);
                setCurrentIndexEtape(false);
                setDossierDetailEtape(false);
                _saveDossierToFirestore([...profileDossierArray]);
            }
        } else {
            let profileDossierArray = cloneDeep(dossiers)
            profileDossierArray[currentIndexDossier].etapes[currentIndexEtape] = { ...dossierDetailEtape, createdAt: firestore.Timestamp.now() };
            //[...dossierDetailEtapes, dossierDetailEtapes[currentIndexEtape]:{} ];
            console.log("Dossier Detail saved")
            console.log(dossierDetail)
            setDossierDetail(profileDossierArray[currentIndexDossier]);
            setDossierDetailEtapes(profileDossierArray[currentIndexDossier].etapes);
            setDossiers(profileDossierArray)
            console.log(profileDossierArray)
            console.log(dossiers)
            setDossierDetailEtape(false);
            setOpenEtapeForm(false);
            _saveDossierToFirestore([...profileDossierArray]);
        }
        /* setTimeout(()=> {
             _saveDossierToFirestore(profileDossierArray);
             //setIsSaving(false)
         }, 500)*/

    }

    const handleAddNewEtape = () => {
        setAddEtape(true)
        setOpenEtapeForm(true)
    }
    const handleChangeEtape = (event) => {
        console.log("handleChangeEtape")
        // console.log(event.target.name)
        // console.log(event.target.value)
        //console.log(moment(event.target.value))
        //console.log(Date(event.target.value))
        //console.log(firestore.Timestamp.fromDate(Date(event.target.value)))
        if (event.target.name === 'dateRealisation') {
            console.log(moment(event.target.value).valueOf());
            console.log(moment.utc(event.target.value).toDate())
            //let milliSeconds = moment(event.target.value).milliseconds()
            let localeDate = moment.utc(event.target.value).toDate()
            //moment(event.target.value).toDate()
            setDossierDetailEtape({
                ...dossierDetailEtape,
                //dateRealisation: firestore.Timestamp.fromMillis(milliSeconds)
                dateRealisation: firestore.Timestamp.fromDate(localeDate)
                //dateRealisation: moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY') 
            })
        } else {
            setDossierDetailEtape({ ...dossierDetailEtape, [event.target.name]: event.target.value })
        }
    }

    const handleBackToDossiers = () => {
        setCurrentIndexDossier(false)
        setDossierDetail(false)
        setDossierDetailEtapes(false)
        setHideDossiers(false)
    }


    useEffect(() => {
        if (userProfile.dossiers) {
            setDossiers(userProfile.dossiers);
        }
        else {
            setDossiers([]);
        }
    }, [userProfile])

    useEffect(() => {
        // if (userProfile.dossiers) {
        //setDossiers(userProfile.dossiers);
        if (dossierDetailEtapes) {
            setDossierDetail({ ...dossierDetail, etapes: dossierDetailEtapes })
        }

        //}
    }, [dossierDetailEtapes])
    console.log("dossiers")
    console.log(dossiers)
    console.log("dossierDetail")
    console.log(dossierDetail)
    console.log("dossierDetailEtapes")
    console.log(dossierDetailEtapes)
    console.log("dossierDetailEtape")
    console.log(dossierDetailEtape)
    if (dossiers) {

        return (
            <div className={classes.root}>
                <Backdrop
                    className={classes.backdrop}
                    open={isSaving}
                /*onClick={() => {
                    setIsFetching(false);
                }}*/
                >
                    <div className={classes.flexBackdrop}>
                        <CircularProgress color="inherit" />
                        <Typography variant="caption" gutterBottom className={classes.title}>
                            Enregistrement en Cours...
                </Typography>
                    </div>

                </Backdrop>
                <div className={hideDossiers ? classes.hidden : ''}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="h6" className={classes.title}>Suivi des dossiers</Typography>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleAddNewDossier}
                                color="inherit"
                            >
                                <AddIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <List component="nav" aria-label="main mailbox folders" style={{ padding: 5 }}>
                        {dossiers && dossiers.length > 0 ? dossiers.map((dossier, index) => {
                            //console.log(dossier)
                            return (
                                <Fragment key={index} >
                                    <ListItem key={index} className={classes.list} button onClick={() => _gotoDetails(index)}>
                                        <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            Etape
                                        <ListItemAvatar>
                                                <Avatar>{dossier.etapes && dossier.etapes.length}</Avatar>
                                            </ListItemAvatar>
                                        </div>
                                        <ListItemText
                                            //disableTypography
                                            classes={{ primary: classes.etape }}
                                            primary={dossier.nom + ' ' + dossier.prenom}
                                            secondary={dossier.typePermisLong + '(' + dossier.typePermis + ')'}
                                            style={{ flex: 0.5 }}
                                        />
                                        <ListItemText
                                            primaryTypographyProps={{ variant: 'caption' }}
                                            primary={dossier.dateNaissance}
                                            style={{ flex: 0.4, maxHeight: 70, overflow: 'scroll' }}
                                        />
                                        <ListItemSecondaryAction style={{ flex: 0.1 }}>
                                            <IconButton edge="end" aria-label="comments" onClick={() => _editDetailsDossier(index)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="comments" onClick={() => _deleteDossier(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            )
                        })
                            :

                            <ListItem className={classes.list}>
                                <ListItemText
                                    //disableTypography
                                    classes={{ primary: classes.etape }}
                                    primary={'Aucune dossier! Cliquez sur + pour en ajouter un.'}
                                    style={{ textAlign: 'center' }}
                                />
                            </ListItem>
                        }
                    </List>
                    <Divider />
                </div>
                {dossierDetail && hideDossiers &&  //dossierDetailEtapes &&
                    <div>
                        <AppBar position="static">
                            <Toolbar>
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleBackToDossiers}>
                                    <BackIcon />
                                </IconButton>
                                <Typography variant="h6" component="h6" className={classes.title}>{`Details Dossier : ${dossierDetail.nom ? dossierDetail.nom : ''} ${dossierDetail.prenom ? dossierDetail.prenom : ''}`}</Typography>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleAddNewEtape}
                                    color="inherit"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <List component="nav" aria-label="main mailbox folders" style={{ padding: 5 }}>
                            {dossierDetailEtapes && dossierDetailEtapes.length > 0 ? dossierDetailEtapes.map((etape, index) => {
                                //console.log(dossier)
                                return (
                                    <Fragment key={index}>
                                        <ListItem className={classes.list} button onClick={() => _editDetailsEtape(index)}>
                                            <ListItemText
                                                //disableTypography
                                                classes={{ primary: classes.etape }}
                                                primary={index + 1 + '/ ' + etape.intitule}
                                                secondary={etape.dateRealisation && 'Réalisée le : ' + moment.unix(etape.dateRealisation.seconds).format("DD/MM/YYYY")}
                                                style={{ flex: 0.5 }}
                                            />
                                            <ListItemText
                                                primaryTypographyProps={{ variant: 'caption' }}
                                                primary={etape.details}
                                                style={{ flex: 0.4, maxHeight: 70, overflow: 'scroll' }}
                                            />
                                            <ListItemSecondaryAction style={{ flex: 0.1 }}>
                                                <IconButton edge="end" aria-label="comments" onClick={() => _editDetailsEtape(index)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton edge="end" aria-label="comments" onClick={() => _deleteEtape(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                )
                            })
                                :

                                <ListItem className={classes.list}>
                                    <ListItemText
                                        //disableTypography
                                        classes={{ primary: classes.etape }}
                                        primary={'Aucune étape! Cliquez sur + pour en ajouter une.'}
                                        style={{ textAlign: 'center' }}
                                    />
                                </ListItem>
                            }
                        </List>

                    </div>
                }
                <Dialog open={openDossierForm} onClose={handleCloseDossierForm} aria-labelledby="form-dialog-title" maxWidth={'md'} fullWidth disableBackdropClick>
                    <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>{addDossier ? 'Nouveau Dossier' : 'Modification Dossier'}</DialogTitle>
                    <Divider />
                    <form>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} xl={6}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id={"nom"}
                                        name={"nom"}
                                        label="Nom"
                                        type="text"
                                        variant={"outlined"}
                                        fullWidth
                                        //fullWidth
                                        required
                                        value={dossierDetail.nom || ''}
                                        onChange={handleChangeDossier}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                    <TextField
                                        margin="dense"
                                        id={"prenom"}
                                        name={"prenom"}
                                        label="Prenom"
                                        type="text"
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        //fullWidth
                                        value={dossierDetail.prenom || ''}
                                        onChange={handleChangeDossier}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                    <TextField
                                        id="dateNaissance"
                                        name={"dateNaissance"}
                                        label="Date de Naissance"
                                        type="date"
                                        margin="dense"
                                        variant={"outlined"}
                                        fullWidth
                                        onChange={handleChangeDossier}
                                        required
                                        //defaultValue={dossierDetail.dateNaissance || ''}
                                        value={moment(dossierDetail.dateNaissance, 'DD/MM/YYYY').format('YYYY-MM-DD') || ''}
                                        //value={'1983-08-05'}
                                        //pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                        //value={moment(dossierDetail.dateNaissance, "X").toISOString() || ''} //format("DD/MM/YYYY HH:mm")moment(dossierDetail.dateNaissance, "X").toISOString()
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    //disabled={props.edit && true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                    <FormControl className={classes.formControl} margin="dense" variant={"outlined"}
                                        fullWidth >
                                        <InputLabel id="typePermis" htmlFor="typePermis">Type Permis</InputLabel>
                                        <Select
                                            native
                                            labelId="typePermis"
                                            id="typePermis"
                                            name="typePermis"
                                            label="Type Permis"
                                            inputProps={{
                                                name: 'typePermis',
                                                id: 'typePermis',
                                            }}
                                            value={dossierDetail.typePermis || ''}
                                            onChange={handleChangeDossier}
                                            //required
                                        >
                                            <option aria-label="None" value="" />
                                            {typesPermis.map((typePermis, index) => {

                                                return (

                                                    <option key={index} value={typePermis.value}>{typePermis.nom}</option>
                                                    /*<MenuItem key={index} value={typePermis.value}>{typePermis.nom}</MenuItem>*/
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} xl={12}>
                                    <TextField
                                        margin="dense"
                                        id={"note"}
                                        name={"note"}
                                        label="Note"
                                        variant={"outlined"}
                                        multiline
                                        rows={4}
                                        type="text"
                                        fullWidth
                                        value={dossierDetail.note || ''}
                                        onChange={handleChangeDossier}
                                    />
                                </Grid>
                            </Grid>



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDossierForm} color="primary">Annuler</Button>
                            <Button onClick={handleSubmitDossier} color="primary" disabled={canSaveDossier ? false : true}>Enregistrer</Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Dialog open={openEtapeForm} onClose={handleCloseEtapeForm} aria-labelledby="form-dialog-title" maxWidth={'md'} fullWidth disableBackdropClick>
                    <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>{addEtape ? 'Nouvelle Etape' : 'Modification Etape'}</DialogTitle>
                    <Divider />
                    <form>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} xl={6}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="intitule"
                                        name="intitule"
                                        label="Nom étape"
                                        type="text"
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        //multiline
                                        //rows={2}
                                        value={dossierDetailEtape.intitule || ''}
                                        onChange={handleChangeEtape}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                    <TextField
                                        id="dateRealisation"
                                        name={"dateRealisation"}
                                        label="Date réalisation"
                                        type="date"
                                        //type="datetime-local"
                                        variant={"outlined"}
                                        margin="dense"
                                        required
                                        fullWidth
                                        onChange={handleChangeEtape}
                                        value={dossierDetailEtape.dateRealisation && moment(dossierDetailEtape.dateRealisation.seconds, "X").format("YYYY-MM-DD") || ''} //format("DD/MM/YYYY HH:mm")
                                        //value={dossierDetailEtape.dateRealisation && moment(dossierDetailEtape.dateRealisation.seconds, "X").toISOString().slice(0, -8) || ''} //format("DD/MM/YYYY HH:mm")
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    //disabled={props.edit && true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} xl={12}>
                                    <TextField
                                        margin="dense"
                                        id="details"
                                        name="details"
                                        label="Détails"
                                        variant={"outlined"}
                                        multiline
                                        rows={4}
                                        type="text"
                                        fullWidth
                                        value={dossierDetailEtape.details || ''}
                                        onChange={handleChangeEtape}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEtapeForm} color="primary">Annuler</Button>
                            <Button onClick={handleSubmitEtape} color="primary" disabled={canSaveEtape ? false : true}>Enregistrer</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
    return (null)

}
export default SuiviDossiers