import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { map } from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './styles.css'


const editorConfiguration = {

    mediaEmbed: {
        previewsInData: true
    },
    toolbar: {
        viewportTopOffset : 50,
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'imageUpload',
            'blockQuote',
            //'insertTable',
            'mediaEmbed',
            'undo',
            'redo',
            'alignment',
            'exportPdf',
            //'CKFinder',
            'underline',
            'horizontalLine',
            'fontFamily',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'fullScreen'
        ]
    },
    indentBlock: {
        offset: 1,
        unit: 'em'
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ]
    },
    image: {
        // Configure the available styles.
        styles: [
            'alignLeft', 'alignCenter', 'alignRight'
        ],

        // Configure the available image resize options.
        resizeOptions: [
            {
                name: 'imageResize:original',
                label: 'Original',
                value: null
            },
            {
                name: 'imageResize:50',
                label: '50%',
                value: '50'
            },
            {
                name: 'imageResize:75',
                label: '75%',
                value: '75'
            }
        ],

        // You need to configure the image toolbar, too, so it shows the new style
        // buttons as well as the resize buttons.
        toolbar: [
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
            '|',
            'imageResize',
            '|',
            'imageTextAlternative'
        ]
    },
    config: {
        ui: {
            width: '500px',
            height: '300px'
        }
    }
    /// toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'exportpdf' ],
    /*heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
        ]
    },*/
    //plugins: [ Base64UploadAdapter],
};
//MdEditor.use(CustomImagePlugin)
const CKEditorField = ({ data, style, onChange, onImageUpload, config, view }) => {
    //const classes = useStyles();
    //const [testMarker, setTestMarker] = React.useState('');
    const [markerQuality, setMarkerQuality] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const [deletion, setDeletion] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const firebase = useFirebase()
    return (
        <CKEditor
            editor={ClassicEditor}
            data={data || ""}
            onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
                console.log(data)
            }}
            /*                    onChange={ ( event, editor ) => {
                const data = editor.getData();
                console.log( { event, editor, data } );
            } }*/

            onChange={onChange}
            config={{ ...editorConfiguration, ...config }}
            /* onBlur={(event, editor) => {
                 console.log('Blur.', editor);
             }}
             onFocus={(event, editor) => {
                 console.log('Focus.', editor);
             }}*/
            style={{ height: 600 }}
        />
    )

}
export default CKEditorField