import React, {  useEffect } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import {  Redirect, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import Button from '@material-ui/core/Button'
import AvatarUser from '../avatar/avatar'
import EditButton from '@material-ui/icons/CreateOutlined'
import SaveButton from '@material-ui/icons/CheckCircleOutline'
import TrashButton from '@material-ui/icons/DeleteForeverRounded'

import { entreprisesValues, entreprisesFields } from '../../../../constants/formField'
import Form from '../../../../utils/formBuilder';
import IconButton from '@material-ui/core/IconButton';

import SendIcon from '@material-ui/icons/SendOutlined';
import MonetizationIcon from '@material-ui/icons/MonetizationOnRounded';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';


import AnnonceSummaryCard from '../../../annonces/AnnonceSummaryCard'

import { getNomComplet, getInitial, upperCaseName } from '../../../../utils/functions'

import { loadAnnoncesUsers } from '../../../../store/actions/userActions'
//import { Elements, StripeProvider } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutForm from '../stripe/CheckoutForm'

//import test from '../../../../php/mail.php'
// Create the Stripe object yourself...
const stripePromise = loadStripe('pk_test_tFy3guUXhLN7A1If5TJG8xSK00jnLJoOkC');
const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    appbar: {
        alignItems: 'center',
    },
    title: {
        padding: 8,
        textAlign: 'left'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    form: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //width: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: '90%'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    card: {
        // maxHeight: 150
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EntrepriseDetails = (props) => {
    const classes = useStyles();
    //console.log(props.profile)
    //const {history} = props.history
    const [userProfile, setuserProfile] = React.useState(props.profile);
    //console.log(userProfile)
    const [disabled, setDisabled] = React.useState(true)
    const [userDeleted, setuserDeleted] = React.useState(false)

    const [messageTxt, setMessageTxt] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [openCardDialog, setOpenCardDialog] = React.useState(false);
    const [newUserProfile, setNewUserProfile] = React.useState(props.profile);
    const [userAnnonces, setUserAnnonces] = React.useState("");
    const [getUserAnnoncesClicked, setGetUserAnnoncesClicked] = React.useState(false)
    console.log(props.profile)
    /*const [stripeData, setStripeData] = React.useState({
        data: {
            currentUser: null,
            sources: {},
            stripeCustomerInitialized: false,
            newCreditCard: {
                number: '4242424242424242',
                cvc: '111',
                exp_month: 1,
                exp_year: 2020,
                address_zip: '00000'
            },
            charges: {},
            newCharge: {
                source: null,
                amount: 2000
            }
        }
    })*/

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        //setUserAnnonces("");
        setOpen(false);
    };
    const InjectedCheckoutForm = () => (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
    /*const createStripeUser = async () => {
        console.log("create stripe customer")
        const customer = await props.stripe.customers.create({ email: props.monProfil.email });
        console.log(customer)
        //return admin.firestore().collection('stripe_customers').doc(user.uid).set({ customer_id: customer.id });
    }*/
    const handleClickCardDialog = () => {

        const userId = props.auth.uid;
        setOpenCardDialog(!openCardDialog)
        /*props.firebase.firestore().collection('stripe_customers').doc(`${userId}`).onSnapshot(snapshot => {
            console.log(snapshot)
            if(snapshot.exists) {
                stripeData.stripeCustomerInitialized = (snapshot.data() !== null || snapshot.data() !== undefined);
            }
            else {
                createStripeUser();
            }
            
        }, () => {
            
            stripeData.stripeCustomerInitialized = false;
        });
        props.firebase.firestore().collection('stripe_customers').doc(`${userId}`).collection('sources').onSnapshot(snapshot => {
            let newSources = {};
            snapshot.forEach(doc => {
                const id = doc.id;
                newSources[id] = doc.data();
            })
            stripeData.sources = newSources;
        }, () => {
            stripeData.sources = {};
        });
        props.firebase.firestore().collection('stripe_customers').doc(`${userId}`).collection('charges').onSnapshot(snapshot => {
            let newCharges = {};
            snapshot.forEach(doc => {
                const id = doc.id;
                newCharges[id] = doc.data();
            })
            stripeData.charges = newCharges;
        }, () => {
            stripeData.charges = {};
        });*/
    }

    useEffect(() => () => {
        setUserAnnonces("");
        console.log("unmount");
        //console.log(props.profile)
    }, []);

    useEffect(() => {
        //console.log(props.profile)
        if (props.profile) {
            setUserAnnonces("");
        }
        setuserProfile(props.profile);

        // props.loadAnnoncesUsers(props.profile.domaines)
    }, [props.profile]);

    useEffect(() => {
        // console.log(props.annoncesUser)
        setUserAnnonces(props.annoncesUser)
        // props.loadAnnoncesUsers(props.profile.domaines)
    }, [props.annoncesUser]);

    //console.log(myValues)
    /* useEffect(() => {
         setuserProfile(profile)
       }, userProfile)*/
    //const { userid } = useParams()
    //render() {
    // const {userid} = useParams()
    console.log('user details', userProfile)
    const enableEditMode = () => {
        setDisabled(!disabled)
        //console.log(disabled)
    }

    const saveUser = () => {
        if (userProfile !== newUserProfile) {
            console.log("save profile");
            console.log(newUserProfile);
            //setDisabled(!disabled);
            saveUserNewData(newUserProfile);
            setDisabled(!disabled);
            return null;
        }
        setDisabled(!disabled);
        console.log("not save profile")

    }
    console.log(userProfile.domaines)
    // console.log( loadAnnoncesUsers(userProfile.domaines))

    const saveUserNewData = async (newUserProfile) => {
        if (newUserProfile) {
            console.log(userProfile)
            console.log(newUserProfile)
            var newProfileCV = null;
            var Url_File = null;
            const promises = [];
            /* if (userProfile.profileCV !== newUserProfile.profileCV && newUserProfile.profileCV && newUserProfile.profileCV.type) {
                 //console.log(props)
                 const ref = props.firebase.storage().ref();
                 const file = newUserProfile.profileCV
                 const ext = "." + file.type.split("/").pop()
                 //console.log(file.type.split("/").pop())
                 const name = 'cv_file' + ext;
                 const metadata = {
                     contentType: file.type
                 };
                 const task = ref.child('users/' + userProfile.id + '/' + name).put(file, metadata);
                 promises.push(task);
                 task.on('state_changed', snapshot => {
                     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                     console.log(progress);
                 }, error => { console.log(error) }, () => {
                     task.snapshot.ref.getDownloadURL().then(downloadURL => {
                         //console.log(downloadURL);
                         newProfileCV = downloadURL
                     });
                 });
                 // task
                 //     .then(snapshot => snapshot.ref.getDownloadURL())
                 //     .then((url) => {
                 //         console.log(url);
                 //         //document.querySelector('#someImageTagID').src = url;
                 //     })
                 //     .catch(console.error);
             }
             await Promise.all(promises).then(async tasks => {
                 console.log('all uploads complete');
                 //console.log(tasks)
                 if (tasks.length > 0) {
                     Url_File = await tasks[0].ref.getDownloadURL().then(function (URL) {
                         console.log(URL)
                         return URL;
                     });
                 }
                 else {
                     console.log('delete image')
                     var userStorageRef = props.firebase.storage().ref().child('users/' + userProfile.id + '/');
                     // Now we get the references of these images
                     userStorageRef.listAll().then(function (result) {
                         result.items.forEach(function (cvRef) {
                             console.log(cvRef)
                             console.log(cvRef.name)
                             // And finally display them
                             //displayImage(imageRef);
                             if (cvRef.name.startsWith('cv_file')) {
                                 console.log("start deletion")
                                 // Delete the file
                                 userStorageRef.child(cvRef.name).delete().then(function () {
                                     console.log("deleted success")
                                     // File deleted successfully
                                 }).catch(function (error) {
                                     console.log("deleted error")
                                     console.log(error)
                                     // Uh-oh, an error occurred!
                                 });
                             }
 
                         });
                     }).catch(function (error) {
                         // Handle any errors
                     });
 
                 }
             });*/
            //console.log(Url_File)
            //console.log(tasks.ref.getDownloadURL())
            //console.log(newProfileCV)
            const firstName = upperCaseName(newUserProfile.firstName);
            const name = upperCaseName(newUserProfile.name);
            const fullName = getNomComplet(name, firstName);
            const firstName2 = newUserProfile.firstName2 ? upperCaseName(newUserProfile.firstName2) : '';
            const name2 = newUserProfile.name2 ? upperCaseName(newUserProfile.name2) : '';
            const fullName2 = newUserProfile.firstName2 && newUserProfile.name2 ? getNomComplet(name2, firstName2) : '';
            const initials = getInitial(name, firstName);
            props.firebase.firestore().collection("users").doc(userProfile.id).update({
                ...newUserProfile, name: name, firstName: firstName, fullName: fullName, name2: name2, firstName2: firstName2, fullName2: fullName2, initials: initials
            })
        }
    }

    const deleteUser = () => {
        //console.log(userid)
        setuserDeleted(true)

    }
    let history = useHistory()
    const goBack = () => {

        history.goBack();
    }

    const handleChange = name => event => {
        setuserProfile({ ...userProfile, [name]: event.target.value });
    };

    const sendUserMessage = () => {
        handleClickOpen()
    }
    const getUserAnnonces = () => {
        //console.log(props.annoncesUser)
        if (userProfile.id) {
            props.loadAnnoncesUsers(null, userProfile.id)
            setGetUserAnnoncesClicked(true)
            //console.log(userAnnonces)
        }
        else {
            alert("Aucuns domaines renseignés, Recherche Impossible!")
        }

    }

    const onChangeMessage = (e) => {
        //console.log(e.target.value)
        setMessageTxt(e.target.value)
    }
    const handleSubmitMessage = (e) => {

        var db = props.firebase;
        if (userProfile.userActivity
            && userProfile.userActivity.conversations
            && Object.keys(userProfile.userActivity.conversations).length > 0) {
            let convId = Object.keys(userProfile.userActivity.conversations)[0]
            var messagesRef = db.firestore().collection("messages").doc(convId);
            return db.firestore().runTransaction(transaction => {
                return transaction
                    .get(messagesRef)
                    .then((querySnapshot) => {
                        if (querySnapshot.exists) {
                            let messages = querySnapshot.data();
                            let messagesLength = Object.keys(messages).length;
                            console.log(messages);
                            console.log(messagesLength);
                            console.log(props.monProfil);
                            transaction.update(messagesRef, {
                                [messagesLength]: {
                                    message: messageTxt,
                                    sender: props.auth.uid,
                                    sendername: props.monProfil.initials,
                                    senderfullName: props.monProfil.fullName,
                                    created_at: db.firestore.Timestamp.now(),
                                }
                            })
                        }
                    })
            }).then(() => {
                //res.send(gameData);
                console.log('Transaction successfully committed!');
                setMessageTxt('');
                handleClose();
            }).catch((error) => {
                //res.send('Transaction failed:' + error);
                console.log('Transaction failed:', error);
            });
        }
        else {
            console.log(props)
            var conversationsRef = db.firestore().collection("conversations").doc();
            var administrateursRef = db.firestore().collection("users").where("role", "==", 0);
            var userRef = db.firestore().collection("users").doc(userProfile.id);
            const members = [userProfile.id];
            let users = {
                [userProfile.id]: {
                    id: userProfile.id,
                    initials: userProfile.initials,
                    profilImg: userProfile.profilImg ? userProfile.profilImg : null,
                    unread: 0
                }
            }

            administrateursRef.get()
                .then(querySnapshot => {
                    querySnapshot.docs.forEach(doc => {
                        let userAdmin = doc.data()
                        members.push(doc.id);
                        let usersData = {
                            [doc.id]: {
                                id: doc.id,
                                initials: userAdmin.initials,
                                profilImg: userAdmin.profilImg ? userAdmin.profilImg : null,
                                unread: 0
                            }
                        }
                        users = { ...users, ...usersData }
                    });
                    return db.firestore().runTransaction(transaction => {
                        return transaction
                            .get(conversationsRef)
                            .then((querySnapshot) => {
                                if (!querySnapshot.exists) {
                                    console.log(querySnapshot)
                                    console.log(querySnapshot.id)
                                    const docId = querySnapshot.id
                                    transaction.set(conversationsRef, {
                                        lastMessageSenderId: '',
                                        lastMessageContent: '',
                                        lastMessageCreatedAt: '',
                                        lastMessageSenderName: '',
                                        archive: null,
                                        members: members,
                                        users: users
                                    })
                                    var messagesRef = db.firestore().collection("messages").doc(docId);
                                    transaction.set(messagesRef, {
                                        0: {
                                            message: messageTxt,
                                            sender: props.auth.uid,
                                            sendername: props.monProfil.initials,
                                            senderfullName: props.monProfil.fullName,
                                            created_at: db.firestore.Timestamp.now(),
                                        }
                                    })
                                    transaction.update(userRef,
                                        {
                                            userActivity: {
                                                ...userProfile.userActivity,
                                                conversations: {
                                                    [docId]: []
                                                },
                                            }
                                        }
                                    )
                                    console.log("Document does not exist!");
                                }
                                else {
                                    console.log(querySnapshot);
                                    console.log(querySnapshot.data());
                                    console.log("Document  exist!");
                                }
                            })
                    })
                })
                .then(() => {
                    //res.send(gameData);
                    console.log('Transaction successfully committed!');
                    setMessageTxt('');
                    handleClose();
                })
                .catch((error) => {
                    //res.send('Transaction failed:' + error);
                    console.log('Transaction failed:', error);
                });
        }
        /*e.preventDefault();
        const data = JSON.stringify({ name: myValues, phone: myValues });
        fetch('./mail.php', {
            method: 'POST',
            body: data
        }).then((response) => {
            if (response.ok) {
                this.setState({ success: true })
            }
        })*/
    }

    if (userDeleted) {
        return <Redirect to='/users' />
    }
    if (!userProfile) {
        return <div />
    }
    return (
        <div>
            <Grid container spacing={6} justify="center" alignItems="center">
                <Grid item xs={10} md={8} xl={6}>
                    <Paper>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <AvatarUser profileImg={userProfile.profileImg} initials={userProfile.initials} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" component="h2" className={classes.title} >
                                    {userProfile.fullName
                                    }
                                </Typography>
                                <Typography variant="subtitle1" component="h2" className={classes.title} >
                                    {userProfile.mail}
                                </Typography>
                                <Typography variant="subtitle1" component="h2" className={classes.title} >
                                    {userProfile.birthday && Math.abs(moment().diff(moment(userProfile.birthday, "DD/MM/YYYY"), 'years')) + " ans"}
                                </Typography>
                                {userProfile.activeAbonnement ?
                                    <>
                                    <Typography variant="subtitle1" component="h2" className={classes.title} >
                                            {userProfile.aboFormule && ('Abonnement  '+ props.constants.abonnements[userProfile.aboFormule] + moment.unix(userProfile.aboExpireDate.seconds).format('DD/MM/YYYY à HH:mm:ss'))}
                                        </Typography>
                                        <Typography variant="subtitle1" component="h2" className={classes.title} >
                                            {userProfile.aboExpireDate && ('Abonnement actif jusque ' + moment.unix(userProfile.aboExpireDate.seconds).format('DD/MM/YYYY à HH:mm:ss'))}
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <Typography variant="subtitle1" component="h2" className={classes.title} >
                                            {'Aucun Abonnement Actif'/*userProfile.aboExpireDate*/}
                                        </Typography>
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Divider xs={12} />
                        <Toolbar>
                            {userProfile.id != props.monProfil.id && !disabled &&
                                <IconButton aria-label="Delete" onClick={deleteUser}>
                                    <TrashButton />
                                </IconButton>
                            }
                            <div style={{ marginLeft: 'auto' }}>
                                {!disabled &&
                                    <IconButton aria-label="CreditCard" onClick={handleClickCardDialog}>
                                        <MonetizationIcon />
                                    </IconButton>
                                }
                                {
                                    disabled ?
                                        <IconButton aria-label="Edit" onClick={enableEditMode}>
                                            <EditButton />
                                        </IconButton>
                                        :
                                        <IconButton aria-label="Save" onClick={saveUser}>
                                            <SaveButton />
                                        </IconButton>
                                }
                                {userProfile.id != props.monProfil.id &&
                                    <IconButton aria-label="SendMessage" onClick={sendUserMessage}>
                                        <SendIcon />
                                    </IconButton>
                                }
                            </div>
                        </Toolbar>

                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={6} justify="center" alignItems="center">
                <Grid item item xs={10} md={8} xl={6}>

                    <Paper>
                        <form noValidate>
                            <Form
                                fieldContainerStyle={{ backgroundColor: '#fff', padding: 10 }}
                                //inputContainerStyle={{ margin: '30px 0' }}
                                onChange={(profile) => setNewUserProfile(profile)}
                                orientation={'horizontal'}
                                //onDelayedChange={(values) => console.log('delayed values: ', values)}
                                delayTriggers={['simpleTextField']}
                                delayTime={1000}
                                fields={entreprisesFields}
                                disabled={disabled}
                                values={userProfile}
                                //actionContainerStyle={{ backgroundColor: '#000', padding: 10 }}
                                //cancelForm={cancelForm}
                                //cancelFormButtonName={"Annuler"}
                                //saveForm={handleSubmit}
                                //saveFormButtonName={"Créer"}
                                errors={{ simpleTextFieldWithError: 'This is an error message.' }}
                            />
                        </form>
                        <Divider />

                    </Paper>
                    {userAnnonces && userAnnonces.length > 0 ?
                        <Paper>
                            <Grid container spacing={1} justify="center" className={classes.contentBody}>

                                {userAnnonces.map(annonce => {
                                    return (
                                        <Grid item xs={4} md={3} xl={2} key={annonce.id} className={classes.card}>
                                            <AnnonceSummaryCard annonce={annonce} monProfil={props.monProfil} />
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                        </Paper>
                        :
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {getUserAnnoncesClicked ?
                                <Typography variant="h6" component="p" color="textSecondary" gutterBottom>
                                    AUCUNE(S) ANNONCE(S) TROUVEE(S)
                                </Typography>
                                :
                                <Button onClick={getUserAnnonces} variant="contained" color="secondary" >Voir les annonces associées</Button>
                            }

                        </div>
                    }
                </Grid>
            </Grid>

            {/*<Grid container spacing={1} justify="center" alignItems="center">
                                {userAnnonces.map((userAnnonce, index) => {
                                    //console.log(userAnnonce)
                                    return (
                                        <Grid item xs={4} key={"userAnnonce-" + index}>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <Typography variant="h6" component="p" color="textSecondary" gutterBottom>
                                                        {userAnnonce.title}
                                                    </Typography>
                                                    <Typography variant="body2" component="p" color="textSecondary" gutterBottom>
                                                        ( {userAnnonce.reference} )
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )

                                })}
                            </Grid>*/}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nouveau Message</DialogTitle>
                <form>
                    <DialogContent>
                        <DialogContentText>
                            Veuillez rédiger votre nouveau message
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="message"
                            label="Message"
                            multiline
                            rows={4}
                            type="text"
                            fullWidth
                            value={messageTxt}
                            onChange={onChangeMessage}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Annuler</Button>
                        <Button onClick={handleSubmitMessage} color="primary">Envoyer</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog fullScreen open={openCardDialog} onClose={handleClickCardDialog} TransitionComponent={Transition} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClickCardDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Vos Finances
                        </Typography>
                        {/*<Button autoFocus color="inherit" onClick={handleClickCardDialog}>
                            Enregistrer
                        </Button>*/}
                    </Toolbar>
                </AppBar>
                <DialogTitle id="form-dialog-title">Gestion des moyens de paiements</DialogTitle>
                <form>
                    <DialogContent>
                        <DialogContentText>

                        </DialogContentText>
                        {/*<StripeProvider apiKey="pk_test_tFy3guUXhLN7A1If5TJG8xSK00jnLJoOkC">*/}
                        <Elements stripe={stripePromise}>
                            <InjectedCheckoutForm />
                            {/*<CheckoutForm handleClickCardDialog={handleClickCardDialog} />*/}
                        </Elements>
                        {/*</StripeProvider>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCardDialog} color="primary">Fermer</Button>
                        {/* <Button onClick={handleSubmitMessage} color="primary">Envoyer</Button>*/}
                    </DialogActions>
                </form>
            </Dialog>
        </div>

    )

}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil,
        annoncesUser: state.users.annoncesUser,
        constants: state.firebase.data.constants,
        //stripe_customers: state.firestore.ordered.stripe_customers
    }
}
const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    return {
        loadAnnoncesUsers: (userDomaines, idEntreprise) => dispatch(loadAnnoncesUsers(userDomaines, idEntreprise)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(),
)(EntrepriseDetails)
