import React, { useEffect, useState, Fragment } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar, MTableAction, MTableActions } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon'
import ImageIcon from '@material-ui/icons/ImageRounded'
import { fade, makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase'
import { Typography } from '@material-ui/core';
import Sellsy from 'node-sellsy'
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte'
import InvertColorsIcon from '@material-ui/icons/InvertColors'
import { stateToHTML } from "draft-js-export-html";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
//var Sellsy = require('node-sellsy');
/*var sellsy = new Sellsy({
    creds: {
        consumerKey: '4a9663ec9bdc9d51a5ecb27ad301f76a73e76f00',
        consumerSecret: '0986eed5876f3cfd3c7a769be4b27170f8f780f5',
        userToken: 'bf6af015de7da545512b7f3b3a34700cb667d30a',
        userSecret: 'f085896b21aa1229df3040df254756ef84c693aa'
    },

});*/
const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
                marginTop: 20,
                width: "80%",
                height: 200
            },
            editor: {
                borderBottom: "1px solid gray",
                height: 200
            }
        }
    }
})




const SettingsDevs = (props) => {
    console.log(props)
    const firebase = useFirebase();
    const [fromValue, setFromValue] = React.useState(props.auth.email)
    const setFrom = (event) => {
        console.log(event.target.value);
        setFromValue(event.target.value);
    }
    const [destValue, setDestValue] = React.useState(['cchumi.hacks@gmail.com', 'pierregagliardi@pierregagliardi.com'])
    const setDest = (event) => {
        console.log(event.target.value);
        setDestValue(event.target.value);
    }
    const [sujetValue, setSujetValue] = React.useState("Test Mail")
    const setSubject = (event) => {
        console.log(event.target.value);
        setSujetValue(event.target.value);
    }
    const [htmlMail, setHtmlMail] = React.useState('');


    const [responseSellsy, setResponseSellsy] = React.useState('');
    const sendEmail = async () => {
        var from = props.auth.email;//await props.firebase.auth().currentUser.email;
        var dest = destValue;//['cchumi.hacks@gmail.com', 'pierregagliardi@pierregagliardi.com']; //, 'pierregagliardi@pierregagliardi.com'};
        var subject = sujetValue;
        console.log(dest);
        console.log(from);
        //console.log(JSON.parse(paramsValue));
        //var params = JSON.parse(paramsValue[0]);
        var data = {
            from: from.toString(),
            dest: dest.toString(),
            subject: subject,
            html: htmlMail
        }
        console.log(data);
        console.log(JSON.stringify({ data }))
        const token = await firebase.auth().currentUser.getIdToken()
        const response = await fetch(
            'https://us-central1-oq70-objectifquebec.cloudfunctions.net/sendMail',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                method: 'post',
                body: JSON.stringify({ data })
            }
        )
        const json = await response.json();
        /*response.then(response =>
 console.log(response)
             response.json()
         )
             .then(data =>
                 this.setState({ hits: data.hits })
             );*/
        console.log(response);
        console.log(json);
        //setResponseSellsy(json);
    }
    const change = (datas) => {
        console.log(datas);
        let options = {
            entityStyleFn: (entity) => {
                const entityType = entity.get('type').toLowerCase();
                const data = entity.getData();
                console.log(data)
                switch (entityType) {
                    case 'image':
                        return {
                            element: 'img',
                            attributes: { src: data.url },
                            style: { maxWidth: '100%', height: 'auto' }
                        }
                }
            }
        };
        let html = stateToHTML(datas.getCurrentContent(), options);
        setHtmlMail(html);
        console.log(html);
    }
    const save = (datas) => {
        console.log(datas)
    }
    console.log(htmlMail);
    return (
        <>
            <Typography>STRICTEMENT RESERVE AUX DEVELOPPEURS</Typography>
            <TextField label={"Expediteur"} value={fromValue} onChange={setFrom} fullWidth />
            <TextField label={"Destinataires"} value={destValue} onChange={setDest} fullWidth />
            <TextField label={"Sujet"} defaultValue={sujetValue} onChange={setSubject} fullWidth />
            <MuiThemeProvider theme={defaultTheme}>
                <MUIRichTextEditor
                    label="Type something here..."
                    onSave={save}
                    onChange={change}
                    inlineToolbar={true}
                />
            </MuiThemeProvider>
            <Button onClick={sendEmail}>Test Send Email</Button>


        </>
    )
}

export default SettingsDevs