import authReducer from './authReducer'
import annonceReducer from './annonceReducer'
import usersReducer from './userReducer'
import chatReducer from './chatReducer'
import navReducer from './navReducer'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  annonce: annonceReducer,
  users: usersReducer,
  chat: chatReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  navReducer: navReducer
});

export default rootReducer

// the key name will be the data property on the state object