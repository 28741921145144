import moment from 'moment'
export const createAnnonce = (annonce, annonceType) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)

    firebase.firestore().collection('annonces').add({
      ...annonce,
      authorFirstName: profile.firstName,
      authorLastName: profile.name,
      authorId: authorId,
      type: annonceType,
      createdAt: new Date()
    }).then(() => {
      dispatch({ type: 'CREATE_ANNONCE_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'CREATE_ANNONCE_ERROR' }, err);
    });
  }
};

export const udpateAnnonce = (annonce, annonceType) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    console.log(annonce.id)
    console.log(annonce)
    //console.log(profile)
    //console.log(authorId)
    //console.log(annonceType)
    firebase.firestore().collection('annonces').doc(annonce.id).update({
      ...annonce,
      authorFirstName: profile.firstName,
      authorLastName: profile.name,
      authorId: authorId,
      type: annonceType,
      //createdAt: new Date()
      modifiedAt: new Date()
    }).then(() => {
      dispatch({ type: 'UPDATE_ANNONCE_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'UPDATE_ANNONCE_ERROR' }, err);
    });
  }
};

export const deleteAnnonce = (annonceId) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    //console.log(annonceId)
    //console.log(profile)
    //console.log(authorId)
    //console.log(annonceType)
    firebase.firestore().collection('annonces').doc(annonceId).delete().then(() => {
      dispatch({ type: 'DELETE_ANNONCE_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'DELETE_ANNONCE_ERROR' }, err);
    });
  }
};
export const loadInitAnnonces = (limit, statut, entreprise, type) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    //console.log('test')
    //console.log(type)
    //console.log(statut)

    let newArr = []
    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)
    let query = firebase.firestore().collection('annonces')
      .where('statut', '==', statut);
    if (entreprise) {
      query = query.where('idEntreprise', '==', userId);
    }
    if (type) {
      query = query.where('type', '==', type);
    }
    query.orderBy("createdAt", "desc")
      .limit(limit)
      /*.onSnapshot(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          var userData = doc.data()
          var id = doc.id
          let newDoc = { id, ...userData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        dispatch({
          type: 'LOAD_INIT_ANNONCES_SUCCESS',
          statut: statut,
          payload: newArr
        });
      }, function (error) {
        console.log(error)
        dispatch({ type: 'LOAD_INIT_ANNONCES_ERROR' }, error);
      });*/
      .get().then((querySnapshot) => {
        //console.log(querySnapshot)
        //if (querySnapshot.exists) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          var userData = doc.data()
          var id = doc.id
          let newDoc = { id, ...userData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        dispatch({
          type: 'LOAD_INIT_ANNONCES_SUCCESS',
          statut: statut,
          annonceType: type,
          payload: newArr
        });
      }).catch(err => {
        console.log(err)
        dispatch({ type: 'LOAD_INIT_ANNONCES_ERROR' }, err);
      })

  }
};


export const loadMoreAnnonces = (limit, startAfter, statut, entreprise, type) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //const profile = getState().firebase.profile;
    //const authorId = getState().firebase.auth.uid;
    console.log('test')
    const userId = getState().firebase.auth.uid;
    //console.log(limit)
    //console.log(startAfter)
    //console.log(statut)
    let newArr = []
    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)
    let query = firebase.firestore().collection('annonces')
      .where('statut', '==', statut);
    if (entreprise) {
      query = query.where('idEntreprise', '==', userId);
    }
    if (type) {
      query = query.where('type', '==', type);
    }
    query.orderBy("createdAt", "desc")
      .limit(limit)
      .startAfter(startAfter)
      //.limit(limit)
      .get().then((querySnapshot) => {
        //console.log(querySnapshot)
        //if (querySnapshot.exists) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          var annoncesData = doc.data()
          var id = doc.id
          let newDoc = { id, ...annoncesData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        //} else {
        //console.log(mobileToCheck + "Do Not Exist In DB");
        //};
        //console.log(querySnapshot.id, " => ", querySnapshot.data());
        //const dataDocs = querySnapshot.data()
        //console.log(dataDocs)
        dispatch({
          type: 'LOAD_MORE_ANNONCE_SUCCESS',
          statut: statut,
          annonceType: type,
          payload: newArr
        });
      }).catch(err => {
        console.log(err)
        dispatch({ type: 'LOAD_MORE_ANNONCE_ERROR' }, err);
      })
    /*  firebase.firestore().collection('projects').add({
        ...annonce,
        authorFirstName: profile.firstName,
        authorLastName: profile.name,
        authorId: authorId,
        createdAt: new Date()
      }).then(() => {
        dispatch({ type: 'CREATE_ANNONCE_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_ANNONCE_ERROR' }, err);
      });*/
  }
};