import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useFirebase, useFirestore } from "react-redux-firebase";
import { fade, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import moment from 'moment'
const useStyles = makeStyles(theme => ({
    root: {
        width: 500,
        maxWidth: '100vw',
        height: '100%',
        maxHeight: '50vh',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    paper: {
        zIndex: 1,
        position: "relative",
        //margin: theme.spacing.unit
    },
}));

const LikesScreen = ({ likesBasePath }) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [likes, setLikes] = React.useState();
    const [openReplies, setOpenReplies] = React.useState(false);
    React.useEffect(() => {
        console.log(likesBasePath)
        if (likesBasePath) {
            _launchLikes()
        }
    }, [likesBasePath])
    const _launchLikes = () => {

        /* useFirebaseConnect([
             { path: 'todos' }
         ])*/
        //let commentsData = []
        // var myUserId = uid;
        //.doc(fileDir)

        let postLikes = firestore.doc(likesBasePath).collection('likes').orderBy('created', 'desc')

        postLikes.onSnapshot(querySnapshot => {
            const likesData = [];
            //console.log(`Received query snapshot of size ${querySnapshot.size}`);
            querySnapshot.forEach((doc) => {
                //console.log(doc.id, ' => ', doc.data());
                likesData.push({ id: doc.id, ...doc.data() })
            });
            setLikes(likesData)
            //console.log(comments.length)
            //isVisibleEdit.current = true
            //setIsVisibleEdit(true)
            // ...
        }, err => {
            console.log(`Encountered error: ${err}`);
        });



    }

    console.log(likesBasePath)
    console.log(likes)
    if (likes && likes.length > 0) {
        return (
            <List className={classes.root}>
                {likes && likes.map((like, index) => {
                    console.log(like)
                    return (
                        <React.Fragment key={index}>
                            {!openReplies ? (
                                <Fade in={!openReplies}>
                                    <Paper elevation={0} className={classes.paper}>
                                        <ListItem alignItems="center">

                                            <ListItemAvatar>
                                                {like.authorImage ?
                                                    <Avatar alt="Remy Sharp" src={like.authorImage} />
                                                    :
                                                    <Avatar alt="Remy Sharp">{like.authorInitials}</Avatar>
                                                }

                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={like.created && moment.unix(like.created.seconds).format("DD/MM/YYYY - HH:mm:ss")}
                                            /*secondary={
                                                <span style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        className={classes.inline}
                                                        color="textPrimary"
                                                    >
                                                        {like.created && moment.unix(like.created.seconds).format("DD/MM/YYYY - HH:mm:ss")}
                                                    </Typography>
                                                </span>
                                            }*/
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </Paper>
                                </Fade>
                            )
                                : null
                            }
                        </React.Fragment >
                    )
                })}

            </List >
        )
    }
    return (
        <Paper elevation={0} className={classes.paper}>
            <Typography variant='body2'>
                Aucuns likes pour cet article
                </Typography>
        </Paper>
    )
}

export default LikesScreen