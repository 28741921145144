import React, { Component } from 'react'
import AnnonceList from '../annonces/AnnoncesList'
import Notifications from './components/Notifications'
import OffresAbonnements from './components/OffresAbonnements'
import InfosAbonnement from './components/InfosAbonnement'
import DetailsAbonnement from './components/DetailsAbonnement'

import Actualites from './components/Actualites'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
//import MuiAlert from '@material-ui/lab/Alert';
import { isEmpty } from '@firebase/util'
const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8
    },
    NoAboActive: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        padding: '10px'
        // marginBottom: '10px'
    }
}));

/*function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }*/
//class Dashboard extends Component {
const DashboardEntreprise = ({ auth, annonces, monProfil, constants }) => {
    const classes = useStyles();
    //render() {
    //const { annonces, auth } = props;
    if (!auth.uid) return <Redirect to='/signin' />
    console.log(monProfil)
    return (
        <div className={classes.root}>


            <Grid container spacing={1}>
                <Grid item xs={8}>
                    {!monProfil.activeAbonnement ?
                        <OffresAbonnements /*userActivity={profile.userActivity}*/ abonnements={constants && constants.abonnements} />
                        /*<Alert severity="info">This is an information message!</Alert>*/
                        /*<Typography variant="h6" component="h2" className={classes.title}>
                            Vous n'avez aucun abonnement actif. Vous ne pourrez que consulter vos annonces.
                    </Typography>*/
                        :
                        <DetailsAbonnement monProfil={monProfil} />

                    }
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InfosAbonnement monProfil={monProfil} />
                        </Grid>
                        <Grid item xs={12}>
                            <Actualites monProfil={monProfil} annonces={annonces} />
                        </Grid>

                        <Grid item xs={12}>
                            <Paper>
                                <Typography variant="h6" component="h2" className={classes.title}>
                                    Mes dernières annonces
                        </Typography>
                                <Divider />
                                {!isEmpty(annonces) ? <AnnonceList auth={auth} annonces={annonces} />
                                    :
                                    <Typography variant="h6" component="h2" className={classes.title}>
                                        Vous n'avez crée aucunes annonces
                        </Typography>
                                }

                            </Paper>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
    //}
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {

        auth: state.firebase.auth,
        constants: state.firebase.data.constants,
        monProfil: state.firestore.data.monProfil,
        annonces: state.firestore.ordered.annonces,

    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect(),
    firestoreConnect(props => [
        { collection: 'users', doc: props.auth.uid, storeAs: 'monProfil' },
        {
            collection: 'annonces',
            where: [['statut', '==', 1], ['idEntreprise', '==', props.auth.uid]],
            orderBy: ['createdAt', 'desc'],
            limit: 10,
            //storeAs: "annonces"
        },
    ]),
    /*firestoreConnect([
        {collection: 'annonces' },
            limit: 5
        ])*/
)(DashboardEntreprise)