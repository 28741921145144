import React, { Component } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8,
        textAlign: 'center'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    form: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //width: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: '90%'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
}));


const AdminCreate = ({ role, profile, onChangeProfilValues }) => {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        email: '',
        password: 'ObjectifQuebec70',
        profil: {
            mail: '',
            //password: 'ObjectifQuebec70',
            name: '',
            firstName: '',
            role: role,
            completed: 0,
            fullName: "",
            initials: "",
            verified: false,
            profileImg: "",
            userActivity: {}
        }
    });
    React.useEffect(() => {
        onChangeProfilValues(values);
    }, [values]);


    const handleChange = name => event => {
        //console.log(name)
        //console.log(event.target.value)
        if (name === "email") {
            setValues({
                ...values,
                [name]: event.target.value,
                profil: {
                    ...values.profil,
                    mail: event.target.value
                }
            });
        } else {
            setValues({
                ...values,
                profil: {
                    ...values.profil,
                    [name]: event.target.value
                }
            });
        }

    };
    //console.log(profile)
    return (
        <form className={classes.container} noValidate autoComplete="off">
            <Grid container>
                <Grid item xs={6}>
                    <TextField
                        id={"email"}
                        label="Email"
                        type={"email"}
                        inputProps={{
                            type: "text",
                            autoComplete: 'off'
                        }}
                        className={classes.textField}
                        value={profile.email}
                        onChange={handleChange('email')}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id={"name"}
                        label="Mon Nom"
                        autoComplete='off'
                        className={classes.textField}
                        value={profile.name}
                        onChange={handleChange('name')}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id={"firstName"}
                        label="Mon prénom"
                        autoComplete='off'
                        className={classes.textField}
                        value={profile.firstName}
                        onChange={handleChange('firstName')}
                        margin="normal"
                    />
                </Grid>
            </Grid>
        </form>
    )

}

export default AdminCreate