import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import SignedInAvatarDrawer from './SignedInAvatarDrawer'
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/DashboardRounded';
import FeedsIcon from '@material-ui/icons/LibraryBooks';

import SchoolIcon from '@material-ui/icons/School';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AnnoncesIcon from '@material-ui/icons/DescriptionRounded';
import UsersIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import MessagesIcon from '@material-ui/icons/MessageRounded';
import SettingsIcon from '@material-ui/icons/SettingsApplicationsRounded';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as TourismeIcon } from '../../assets/icons/tourisme.svg';
import { ReactComponent as NewsIcon } from '../../assets/icons/newspaper3.svg';
import { ReactComponent as ExpatriationIcon } from '../../assets/icons/expatriation.svg';
import { ReactComponent as OutilsIcon } from '../../assets/icons/outils.svg'
import { ReactComponent as RelationIcon } from '../../assets/icons/business-relationship.svg'

import { drawerWidthDefault } from '../../constants/default'
const drawerWidth = drawerWidthDefault;

const useStyles = makeStyles(theme => ({
  //offset: theme.mixins.toolbar,

  root: {
    display: "flex",
    flexGrow: 1,
    height: '70px'
  },
  appBar: {
    backgroundImage: `url('/images/quebecHDLow.jpg')`,
    backgroundSize: "cover",
    backgroundPosition: '0 30%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
    //top: 'auto',
    //top: 0,

  },
  bgOverlay: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.BgOverlayColor.backgroundColor,
    position: 'absolute'
  },
  /*menuButton: {
    marginRight: theme.spacing(2),
  },*/
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '75px',
    margin: 'auto',
    textAlign: 'center',
    //maxWidth: '50%',
    //maxHeight: '70%',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    //alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    //justifyContent: 'flex-end',
    backgroundColor: "darkgray",//'rgb(231, 134, 55)',// '#192d3e', //theme.palette.primary.main
    marginBottom: '3.2rem',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    /* backgroundImage: `url('/images/quebecHDLow.jpg')`,
     backgroundSize: "cover",
     backgroundPosition: '0 30%',*/
  },
  drawerMenuIcon: {
    //width: "100%"
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',

    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    //...theme.mixins.toolbar,
    minHeight: '75px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuIcon: {
    padding: 8
  },
  navbar__link: {
    /*margin: '1em',
    fontSize: '2em',
    fontFamily: "'Roboto', sans-serif",
    cursor: 'pointer',
    textDecoration: 'none',
    letterSpacing: '.3em',
    textIndent: '.3em',
    color: '#181818',*/
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderBottom: '3px solid transparent',
    transition: 'all .5s ease-in-out',
    "&&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white !important",
      "& .MuiSvgIcon-root": {
        color: "white !important",
      }
    },
    "&&.active": {
      borderBottom: '3px solid ' + theme.palette.secondary.main,
      transition: 'all .5s ease-in-out',
      color: "white !important",
      backgroundColor: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        color: "white !important",
      }
    },

  },
  tooltip: {

    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },

  /* content: {
     flexGrow: 1,
     padding: theme.spacing(3),
     transition: theme.transitions.create('margin', {
       easing: theme.transitions.easing.sharp,
       duration: theme.transitions.duration.leavingScreen,
     }),
     marginLeft: -drawerWidth,
   },
   contentShift: {
     transition: theme.transitions.create('margin', {
       easing: theme.transitions.easing.easeOut,
       duration: theme.transitions.duration.enteringScreen,
     }),
     marginLeft: 0,
   },*/
}));
const RoleLinks = (role, open) => {
  //console.log(props.location.pathname)
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  if (role === 0) {
    return (
      <List>

        <ListItem button key={"home"} component={NavLink} exact to="/" title={open ? null : "Dashboard"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Dashboard" placement="right" ><DashboardIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem button key={"feeds"} component={NavLink} to="/feeds" title={open ? null : "Les News"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Les News" placement="right">
            <SvgIcon><NewsIcon /></SvgIcon></Tooltip></ListItemIcon>
          <ListItemText primary={"Les News"} />
        </ListItem>
        <ListItem button key={"tourismes"} component={NavLink} to="/tourismes" title={open ? null : "Le Tourisme"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Le Tourisme" placement="right"><SvgIcon>
            <TourismeIcon />
          </SvgIcon></Tooltip></ListItemIcon>
          <ListItemText primary={"Le Tourisme"} />
        </ListItem>
        <ListItem button key={"relations"} component={NavLink} to="/relations" title={open ? null : "Mise en Relation"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Mise en Relation" placement="right"><SvgIcon>
            <RelationIcon />
          </SvgIcon></Tooltip></ListItemIcon>
          <ListItemText primary={"Mise en Relation"} />
        </ListItem>
        <ListItem button key={"etudes"} component={NavLink} to="/etudes" title={open ? null : "Études"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Études" placement="right"><SchoolIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Études"} />
        </ListItem>
        <ListItem button key={"expatriations"} component={NavLink} to="/expatriations" title={open ? null : "L'expatriation"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="L'expatriation" placement="right"><SvgIcon>
            <ExpatriationIcon />
          </SvgIcon></Tooltip></ListItemIcon>
          <ListItemText primary={"L'expatriation"} />
        </ListItem>
        <ListItem button key={"outils"} component={NavLink} to="/outils" title={open ? null : "Les Outils"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Les Outils" placement="right"><SvgIcon>
            <OutilsIcon />
          </SvgIcon></Tooltip></ListItemIcon>
          <ListItemText primary={"Les Outils"} />
        </ListItem>
        <ListItem button key={"users"} component={NavLink} to="/users" title={open ? null : "Utilisateurs"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Utilisateurs" placement="right"><UsersIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Utilisateurs"} />
        </ListItem>
        <ListItem button key={"messages"} component={NavLink} to="/messages" title={open ? null : "Messages"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Messages" placement="right"><MessagesIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Messages"} />
        </ListItem>
        <ListItem button key={"settings"} component={NavLink} to="/settings" title={open ? null : "Préferences"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Préferences" placement="right"><SettingsIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Préférences"} />
        </ListItem>

        <ListItem button key={"annonces"} component={NavLink} to="/annonces" title={open ? null : "Annonces"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Anciennes Annonces" placement="right"><AnnoncesIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Annonces"} />
        </ListItem>
        <ListItem button key={"emplois"} component={NavLink} to="/emplois" title={open ? null : "Emplois"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Emplois" placement="right"><BusinessCenterIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Emplois"} />
        </ListItem>

        <ListItem button key={"boutique"} component={NavLink} to="/boutique" title={open ? null : "Boutique"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><Tooltip title="Boutique" placement="right"><ShoppingBasketIcon /></Tooltip></ListItemIcon>
          <ListItemText primary={"Boutique"} />
        </ListItem>
      </List>
    )
  }
  if (role === 1) {
    return (
      <List>
        <ListItem button key={"home"} component={NavLink} exact to="/" title={open ? null : "Dashboard"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem button key={"annonces"} component={NavLink} to="/annonces" title={open ? null : "Mes Annonces"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><AnnoncesIcon /></ListItemIcon>
          <ListItemText primary={"Mes Annonces"} />
        </ListItem>
        <ListItem button key={"messages"} component={NavLink} to="/messages" title={open ? null : "Messages"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><MessagesIcon /></ListItemIcon>
          <ListItemText primary={"Messages"} />
        </ListItem>
      </List>
    )
  }
  if (role === 2) {
    return (
      <List>
        <ListItem button key={"home"} component={NavLink} exact to="/" title={open ? null : "Dashboard"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem button key={"annonces"} component={NavLink} to="/annonces" title={open ? null : "Annonces"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><AnnoncesIcon /></ListItemIcon>
          <ListItemText primary={"Annonces"} />
        </ListItem>
        <ListItem button key={"messages"} component={NavLink} to="/messages" title={open ? null : "Messages"} className={classes.navbar__link}>
          <ListItemIcon className={classes.menuIcon}><MessagesIcon /></ListItemIcon>
          <ListItemText primary={"Messages"} />
        </ListItem>
      </List>
    )
  }
}


//export default function MenuAppBar() {
const DrawerMenu = (props) => {
  const { auth, profile } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(props.open);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]); // N’exécute l’effet que si count a changé
  const handleDrawerOpen = () => {
    console.log("open drawer")
    props.handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    console.log("close drawer")
    props.handleDrawerClose();
  };


  return (
    <Drawer
      variant="permanent"
      //onMouseEnter={handleDrawerOpen}
      //onMouseLeave={handleDrawerClose}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

      <div className={classes.drawerHeader} elevation={3} >
        {/* <div className={classes.bgOverlay} />*/}
        <div className={classes.toolbar}>
          <Link to={'/'}>
            <img src={'/images/objectif-quebec-logo.png'} className={classes.logo} alt="logo" />
          </Link>
          <IconButton className={classes.drawerMenuIcon} onClick={!open ? handleDrawerOpen : handleDrawerClose}>
            {!open ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <SignedInAvatarDrawer profile={props.profile} reduceMenu={!open} />
      </div>
      <Divider />
      <div className={classes.menu} >
        {RoleLinks(props.profile.role, open)}
      </div>

    </Drawer>

  );
}



export default DrawerMenu