import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import { SvgIcon } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import AvatarUser from '../avatar/avatar'
import EditButton from '@material-ui/icons/CreateOutlined'
import SaveButton from '@material-ui/icons/CheckCircleOutline'
import TrashButton from '@material-ui/icons/DeleteForeverRounded'
import Toolbar from '@material-ui/core/Toolbar';
import { candidatsValues, candidatsFields } from '../../../../constants/formField'
import Form from '../../../../utils/formBuilder';
import IconButton from '@material-ui/core/IconButton';

import SendIcon from '@material-ui/icons/SendOutlined';

import AnnonceSummaryCard from '../../../annonces/AnnonceSummaryCard'

import { getNomComplet, getInitial, upperCaseName } from '../../../../utils/functions'

import Icon from 'react-web-vector-icons';

import { loadAnnoncesUsers } from '../../../../store/actions/userActions'


import SuiviDossiers from './suiviDossiers'
//import test from '../../../../php/mail.php'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8,
        textAlign: 'left'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    form: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //width: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: '90%'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    card: {
        // maxHeight: 150
    },
    InfosColumn: {
        borderLeft: '1px solid ' + theme.palette.secondary.main,
    }
}));


const CandidatDetails = (props) => {
    const classes = useStyles();
    console.log(props)
    //const {history} = props.history
    const [userProfile, setuserProfile] = React.useState(props.profile);
    const [userActivity, setuserActivity] = React.useState(props.UserActivity);
    //console.log(userProfile)
    const [disabled, setDisabled] = React.useState(true)
    const [userDeleted, setuserDeleted] = React.useState(false)

    const [messageTxt, setMessageTxt] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [openSellsyDialog, setOpenSellsyDialog] = React.useState(false);
    const [SellsyDialog, setSellsyDialog] = React.useState({ SellsyDialogValidateButton: 'Créer', SellsyDialogTitle: "Creation d'un nouvel utilisateur Sellsy", SellsyDialogContent: 'Vous êtes sur le point de créer un nouvel utilisateur dans le CRM sellsy. Etes vous sûre ?', loadingSellsy: false, loadingSellsyContent: 'Création en cours' })
    const [newUserProfile, setNewUserProfile] = React.useState(props.profile);
    const [userAnnonces, setUserAnnonces] = React.useState("");
    const [getUserAnnoncesClicked, setGetUserAnnoncesClicked] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        //setUserAnnonces("");
        setOpen(false);
    };
    const handleCloseSellsyDialog = () => {
        //setUserAnnonces("");
        setOpenSellsyDialog(false);
    };

    useEffect(() => () => {
        setUserAnnonces("");
        console.log("unmount");
        //console.log(props.profile)
    }
        , []);

    useEffect(() => {
        //console.log(props.profile)
        if (props.profile) {
            setUserAnnonces("");
        }
        setuserProfile(props.profile);

        // props.loadAnnoncesUsers(props.profile.domaines)
    }, [props.profile]);
    useEffect(() => {
        console.log("change user activity")
        if (props.UserActivity) {
            setuserActivity(props.UserActivity);
        }
        else {
            setuserActivity(null);
        }


        // props.loadAnnoncesUsers(props.profile.domaines)
    }, [props.UserActivity]);


    useEffect(() => {
        // console.log(props.annoncesUser)
        setUserAnnonces(props.annoncesUser)
        // props.loadAnnoncesUsers(props.profile.domaines)
    }, [props.annoncesUser]);

    //console.log(myValues)
    /* useEffect(() => {
         setuserProfile(profile)
       }, userProfile)*/
    const { userid } = useParams()
    //render() {
    // const {userid} = useParams()
    console.log('user details', userProfile)
    console.log('user activity', userActivity)
    const enableEditMode = () => {
        setDisabled(!disabled)
        //console.log(disabled)
    }

    const saveUser = () => {
        if (userProfile !== newUserProfile) {
            console.log("save profile");
            console.log(newUserProfile);
            setDisabled(!disabled);
            saveUserNewData(newUserProfile);
            return null;
        }
        setDisabled(!disabled);
        console.log("not save profile")

    }
    console.log(userProfile.domaines)
    // console.log( loadAnnoncesUsers(userProfile.domaines))
    const uploadImage = async (file, name) => {
        try {
            const ext = "." + file.type.split("/").pop()
            //console.log(file.type.split("/").pop())
            const fileName = name + ext;
            const metadata = {
                contentType: file.type
            };
            //const response = await fetch(uri);
            // const blob = await response.blob();

            const snap = await props.firebase.storage().ref().child('users/' + userProfile.id + '/' + fileName).put(file, metadata);

            const downloadURL = await snap.ref.getDownloadURL();

            return downloadURL;

        } catch (e) {
            console.error(e);
            throw e;
        }

    }
    const saveUserNewData = async (newUserProfile) => {
        try {
            if (newUserProfile) {
                console.log(userProfile)
                console.log(newUserProfile)
                var newProfileCV = null;
                var newProfileCVCachete = null;
                var Url_File = null;
                const promises = [];
                let filesPromise = Promise.resolve([]);
                if (userProfile.profileCV !== newUserProfile.profileCV && newUserProfile.profileCV /*&& newUserProfile.profileCV.type*/) {
                    //console.log(props)
                    const ref = props.firebase.storage().ref();
                    const file = newUserProfile.profileCV
                    //const ext = "." + file.type.split("/").pop()
                    //console.log(file.type.split("/").pop())
                    const name = 'cv_file';// + ext;
                    /*const metadata = {
                        contentType: file.type
                    };*/
                    newProfileCV = await uploadImage(file, name)
                    console.log(newProfileCV)
                    setNewUserProfile({ ...newUserProfile, profileCV: newProfileCV })
                }
                else {
                    if (!newUserProfile.profileCV && userProfile.profileCV) {
                        console.log('delete profile cv ')
                        //var userStorageRef = props.firebase.storage().ref().child('users/' + userProfile.id + '/');
                        var profileCVRef = props.firebase.storage().refFromURL(userProfile.profileCV);
                        // Delete the file
                        profileCVRef.delete().then(function () {
                            console.log('successfully deleted profile cv ')
                            // File deleted successfully
                        }).catch(function (error) {
                            console.log(error)
                            // Uh-oh, an error occurred!
                        });
                    }
                }
                if (userProfile.profileCVCachete !== newUserProfile.profileCVCachete && newUserProfile.profileCVCachete /*&& newUserProfile.profileCVCachete.type*/) {
                    //console.log(props)
                    const ref = props.firebase.storage().ref();
                    const file = newUserProfile.profileCVCachete
                    const name = 'cv_file_cachete';
                    newProfileCVCachete = await uploadImage(file, name)
                    console.log(newProfileCVCachete)
                    setNewUserProfile({ ...newUserProfile, profileCVCachete: newProfileCVCachete, hasprofileCVCachete: true })
                }
                else {
                    if (!newUserProfile.profileCVCachete && userProfile.profileCVCachete) {
                        console.log('delete profile cv cachete')
                        //var userStorageRef = props.firebase.storage().ref().child('users/' + userProfile.id + '/');
                        var profileCVCacheteRef = props.firebase.storage().refFromURL(userProfile.profileCVCachete);
                        // Delete the file
                        profileCVCacheteRef.delete().then(function () {
                            console.log('successfully deleted profile CV CACHETE')
                            setNewUserProfile({ ...newUserProfile, profileCVCachete: null, hasprofileCVCachete: false })
                            // File deleted successfully
                        }).catch(function (error) {
                            console.log(error)
                            // Uh-oh, an error occurred!
                        });
                        // Now we get the references of these images
                    }


                }
                console.log("before promise")
                console.log(newUserProfile)
                console.log(newProfileCVCachete)
                console.log(newUserProfile.profileCVCachete)
                console.log(userProfile.profileCVCachete)
                console.log(newProfileCVCachete ? newProfileCVCachete : newUserProfile.profileCVCachete === null ? null : userProfile.profileCVCachete);
                const firstName = upperCaseName(newUserProfile.firstName);
                const name = upperCaseName(newUserProfile.name);
                const fullName = getNomComplet(name, firstName);
                const initials = getInitial(name, firstName);
                props.firebase.firestore().collection("users").doc(userProfile.id).update({
                    ...newUserProfile,
                    name: name,
                    firstName: firstName,
                    fullName: fullName,
                    initials: initials,
                    profileCV: newProfileCV ? newProfileCV : newUserProfile.profileCV === null ? null : userProfile.profileCV,
                    profileCVCachete: newProfileCVCachete ? newProfileCVCachete : newProfileCVCachete === null ? null : userProfile.profileCVCachete
                })
                //console.log(newUserProfile)
            }
        }
        catch (e) {
            console.log('error ', e)
        }
    }

    const deleteUser = () => {
        console.log(userid)
        setuserDeleted(true)

    }
    let history = useHistory()
    const goBack = () => {

        history.goBack();
    }

    const handleChange = name => event => {
        setuserProfile({ ...userProfile, [name]: event.target.value });
    };

    const sendUserMessage = () => {
        handleClickOpen()
    }
    const getUserAnnonces = () => {
        //console.log(props.annoncesUser)
        if (userProfile.domaines.length > 0) {
            props.loadAnnoncesUsers(userProfile.domaines, null)
            setGetUserAnnoncesClicked(true)
            //console.log(userAnnonces)
        }
        else {
            alert("Aucuns domaines renseignés, Recherche Impossible!")
        }

    }

    const onChangeMessage = (e) => {
        //console.log(e.target.value)
        setMessageTxt(e.target.value)
    }
    /*const getSellsy = async () => {
        var paramsJson = paramsValueJson;
        console.log(paramsJson);
        //console.log(JSON.parse(paramsValue));
        //var params = JSON.parse(paramsValue[0]);
        var data = {
            params: paramsJson,
            method: methodeValue
        }
        const token = await firebase.auth().currentUser.getIdToken()
        const response = await fetch(
            'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getSellsyData',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                method: 'post',
                body: JSON.stringify({ data })
            }
        )
        const json = await response.json();
        response.then(response =>
 console.log(response)
             response.json()
         )
             .then(data =>
                 this.setState({ hits: data.hits })
             );
        console.log(response);
        console.log(json);
        setResponseSellsy(json);
    }*/
    const handleOpenSellsyDialog = async (e) => {
        if (userProfile.sellsyId) {
            setSellsyDialog({ SellsyDialogValidateButton: 'Mettre à jour', SellsyDialogTitle: "Mise à jour de l'utilisateur Sellsy", SellsyDialogContent: "Vous êtes sur le point de mettre à jour l' utilisateur dans le CRM sellsy. Etes vous sûre ?", loadingSellsy: false, loadingSellsyContent: 'Mise à jour en cours' });


        } else {
            //setSellsyDialog({ SellsyDialogValidateButton: 'Créer', SellsyDialogTitle: "Creation d'un nouvel utilisateur Sellsy", SellsyDialogContent: 'Vous êtes sur le point de créer un nouvel utilisateur dans le CRM sellsy. Etes vous sûre ?' })

        }
        setOpenSellsyDialog(true);
    }
    const handleSellsyFunctionss = async (e) => {
        console.log('SELLSY FUNCTION');
        const token = await props.firebase.auth().currentUser.getIdToken();
        console.log(token);
        /*var paramsJsonCustomFields = {
            "params": { "linkedtype": "people", "linkedid": "24015547", "values": [{ "cfid": "82189", "value": ["Villes"] }] }
        };
        var dataCustomFields = {
            method: 'CustomFields.recordValues',
            params: paramsJsonCustomFields,
        };
        console.log(dataCustomFields);
        console.log(JSON.stringify({ dataCustomFields }))
        try {
            const responseCustomFields = await fetch(
                'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getSellsyData',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    method: 'post',
                    body: JSON.stringify({ dataCustomFields })
                }
            );
            const sellsyresponseCustomFields = await responseCustomFields.json();
            console.log("sellsy Custom fields success", sellsyresponseCustomFields);
        } catch (error) {
            console.log("throwing Error", error);
            setOpenSellsyDialog(false);
            setSellsyDialog({ ...SellsyDialog, loadingSellsy: false });

            throw error;

        }*/

        var paramsJsonCustomFields = {
            'linkedtype': 'people', 'linkedid': "24037555", 'values': [{ 'cfid': "82189", 'value': ["Villes"] }]
        };

        var paramsJson = {
            id: 82189
            /* 'pagination': {
                 'nbperpage': 200,
             }*/
        }
        var data = {
            params: paramsJsonCustomFields,
            method: 'CustomFields.recordValues' //'CustomFields.getOne'
            // method: 'CustomFields.getList'
        }
        console.log(JSON.stringify({ data }));
        try {
            const response = await fetch(
                'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getSellsyData',
                {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        Authorization: 'Bearer ' + token
                    },
                    method: 'post',
                    body: JSON.stringify({ data })
                }
            );
            const sellsyresponse = await response.json();
            console.log("sellsy Creation success", sellsyresponse);
            /*if (sellsyresponse.result.response.id) {
                console.log("sellsy Id", sellsyresponse.result.response.id);
                //setNewUserProfile({ ...newUserProfile, sellsyId: sellsyresponse.result.response.id })
            }*/
            if (sellsyresponse.result.status === "success") {
                // setOpenSellsyDialog(false);
                //setSellsyDialog({ ...SellsyDialog, loadingSellsy: false });
            }
            else if (sellsyresponse.result.status === "error") {
                //setSellsyDialog({ ...SellsyDialog, loadingSellsyContent: sellsyresponse.result.error });
            }


        } catch (error) {
            console.log("throwing Error", error);

            throw error;

        }


    }






    const handleSellsyFunction = async (e) => {
        console.log('SELLSY FUNCTION');
        console.log("Sellsy ", props.constants.Sellsy.CustomFields);
        const token = await props.firebase.auth().currentUser.getIdToken();
        console.log("Sellsy ", token);
        const email = await props.firebase.auth().currentUser.email;
        //console.log(email);
        //man,woman,lady
        let CfMapsData = props.constants.Sellsy.CustomFields;
        let sexe = ""
        switch (userProfile.sexe) {
            case 'homme':
                sexe = 'man';
                break;
            case 'femme':
                sexe = 'woman';
                break;
            default:
                sexe = '';
        }
        // var birthdateiso = moment(userProfile.birthday).format("DD/MM/YYYY");
        var birthdate = moment.tz(userProfile.birthday, "DD/MM/YYYY", 'Europe/Paris').format();
        //moment().tz(birthdateiso'Europe/Paris').format();


        /*console.log(birthdate);
        console.log(moment(birthdate).format("X"));
        console.log(moment(birthdate).format("x"));
        console.log(moment(birthdate).unix());
        console.log(moment(birthdate).valueOf());*/

        var paramsJson = {
            'people': {
                'name': userProfile.name,
                'forename': userProfile.firstName,
                'email': userProfile.mail,//email,
                //'tel'           => {{tel}},
                //'fax'           => {{fax}},
                //'mobile'        => {{mobile}},
                //'web'           => {{web}},
                //'position'      => {{position}},
                'civil': sexe,
                'birthdate': moment(birthdate).unix(),
                /* 'stickyNote'	=> {{stickyNote}},
                 'tags'          => {{tags}},
                 'thirdids'      => {{thirdids}},
                 'mailchimp'		=> {{mailchimp}},
                 'mailjet'		=> {{mailjet}},
                 'simplemail'	=> {{simplemail}},
                 'massmailingUnsubscribed'		=> {{massmailingUnsubscribed}},
                 'massmailingUnsubscribedSMS'	=> {{massmailingUnsubscribedSMS}},
                 'phoningUnsubscribed'			=> {{phoningUnsubscribed}},
                 'massmailingUnsubscribedMail'	=> {{massmailingUnsubscribedMail}},
                 'massmailingUnsubscribedCustom'	=> {{massmailingUnsubscribedCustom}}*/
            }
        }
        if (userProfile.sellsyId) {
            var data = {
                params: { ...paramsJson, 'id': userProfile.sellsyId },
                method: 'Peoples.update'
            }
        }
        else {
            var data = {
                params: paramsJson,
                method: 'Peoples.create'
            }
        }

        //console.log(data);
        setSellsyDialog({ ...SellsyDialog, loadingSellsy: true });
        //{ SellsyDialogValidateButton: 'Mettre à jour', SellsyDialogTitle: "Mise à jour de l'utilisateur Sellsy", SellsyDialogContent: "Vous êtes sur le point de mettre à jour l' utilisateur dans le CRM sellsy. Etes vous sûre ?" });
        try {
            const response = await fetch(
                'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getSellsyData',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    method: 'post',
                    body: JSON.stringify({ data })
                }
            );
            const sellsyresponse = await response.json();
            console.log("sellsy Creation success", sellsyresponse);
            if (sellsyresponse.result.response.id) {
                console.log("sellsy Id", sellsyresponse.result.response.id);
                setNewUserProfile({ ...newUserProfile, sellsyId: sellsyresponse.result.response.id })
            }
            if (sellsyresponse.result.status === "success") {
                console.log("Sellsy ", userProfile.domaines);
                /*console.log(sellsyresponse.result.response.id);
                console.log("Sellsy", userProfile)
                console.log("Sellsy", userProfile.situation)*/
                let situation = userProfile.situation ? userProfile.situation : '';
                let nbenfants = userProfile.nbEnfants;//> 0 ? userProfile.nbEnfants : "0";

                console.log("Sellsy ", situation);
                console.log("Sellsy ", nbenfants);
                let pvt = userProfile.pvt ? "Y" : "N";
                console.log("Sellsy ", pvt);
                console.log("Sellsy ", userProfile.pvt);
                console.log("Sellsy ", userProfile.nationalite);
                var paramsJsonCustomFields = {
                    'linkedtype': 'people',
                    'linkedid': sellsyresponse.result.response.id,
                    'values': [{
                        'cfid': CfMapsData[0].Value,
                        'value': ["Candidats"]
                    },
                    /*  {
                          'cfid': "82661",
                          'value': userProfile.domaines
                      },*/
                    {
                        'cfid': "96628",
                        'value': pvt //userProfile.pvt
                    },
                    {
                        'cfid': "66151",
                        'value': situation
                    },
                    {
                        'cfid': "96627",
                        'value': nbenfants
                    },
                    {
                        'cfid': "96630",
                        'value': ["Français"] //[userProfile.nationalite]
                    }]
                };
                var data = {
                    params: paramsJsonCustomFields,
                    method: 'CustomFields.recordValues'
                };
                console.log("Sellsy string data ", JSON.stringify({ data }));
                try {
                    const responseCustomFields = await fetch(
                        'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getSellsyData',
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token
                            },
                            method: 'post',
                            body: JSON.stringify({ data })
                        }
                    );
                    const sellsyresponseCustomFields = await responseCustomFields.json();
                    console.log("sellsy Custom fields success", sellsyresponseCustomFields);
                } catch (error) {
                    console.log("throwing Error", error);
                    setOpenSellsyDialog(false);
                    setSellsyDialog({ ...SellsyDialog, loadingSellsy: false });

                    throw error;

                }

                setOpenSellsyDialog(false);
                setSellsyDialog({ ...SellsyDialog, loadingSellsy: false });
            }
            else if (sellsyresponse.result.status === "error") {
                setSellsyDialog({ ...SellsyDialog, loadingSellsyContent: sellsyresponse.result.error });
            }


        } catch (error) {
            console.log("throwing Error", error);
            setOpenSellsyDialog(false);
            setSellsyDialog({ ...SellsyDialog, loadingSellsy: false });

            throw error;

        }

    }

    const handleSubmitMessage = (e) => {

        var db = props.firebase;
        if (userProfile.userActivity
            && userProfile.userActivity.conversations
            && Object.keys(userProfile.userActivity.conversations).length > 0) {
            let convId = Object.keys(userProfile.userActivity.conversations)[0]
            var messagesRef = db.firestore().collection("messages").doc(convId);
            return db.firestore().runTransaction(transaction => {
                return transaction
                    .get(messagesRef)
                    .then((querySnapshot) => {
                        if (querySnapshot.exists) {
                            let messages = querySnapshot.data();
                            let messagesLength = Object.keys(messages).length;
                            console.log(messages);
                            console.log(messagesLength);
                            console.log(props.monProfil);
                            transaction.update(messagesRef, {
                                [messagesLength]: {
                                    message: messageTxt,
                                    sender: props.auth.uid,
                                    sendername: props.monProfil.initials,
                                    senderfullName: props.monProfil.fullName,
                                    created_at: db.firestore.Timestamp.now(),
                                }
                            })
                        }
                    })
            }).then(() => {
                //res.send(gameData);
                console.log('Transaction successfully committed!');
                setMessageTxt('');
                handleClose();
            }).catch((error) => {
                //res.send('Transaction failed:' + error);
                console.log('Transaction failed:', error);
            });
        }
        else {
            console.log(props)
            var conversationsRef = db.firestore().collection("conversations").doc();
            var administrateursRef = db.firestore().collection("users").where("role", "==", 0);
            var userRef = db.firestore().collection("users").doc(userProfile.id);
            const members = [userProfile.id];
            let users = {
                [userProfile.id]: {
                    id: userProfile.id,
                    initials: userProfile.initials,
                    profilImg: userProfile.profilImg ? userProfile.profilImg : null,
                    unread: 0
                }
            }

            administrateursRef.get()
                .then(querySnapshot => {
                    querySnapshot.docs.forEach(doc => {
                        let userAdmin = doc.data()
                        members.push(doc.id);
                        let usersData = {
                            [doc.id]: {
                                id: doc.id,
                                initials: userAdmin.initials,
                                profilImg: userAdmin.profilImg ? userAdmin.profilImg : null,
                                unread: 0
                            }
                        }
                        users = { ...users, ...usersData }
                    });
                    return db.firestore().runTransaction(transaction => {
                        return transaction
                            .get(conversationsRef)
                            .then((querySnapshot) => {
                                if (!querySnapshot.exists) {
                                    console.log(querySnapshot)
                                    console.log(querySnapshot.id)
                                    const docId = querySnapshot.id
                                    transaction.set(conversationsRef, {
                                        lastMessageSenderId: '',
                                        lastMessageContent: '',
                                        lastMessageCreatedAt: '',
                                        lastMessageSenderName: '',
                                        archive: null,
                                        members: members,
                                        users: users
                                    })
                                    var messagesRef = db.firestore().collection("messages").doc(docId);
                                    transaction.set(messagesRef, {
                                        0: {
                                            message: messageTxt,
                                            sender: props.auth.uid,
                                            sendername: props.monProfil.initials,
                                            senderfullName: props.monProfil.fullName,
                                            created_at: db.firestore.Timestamp.now(),
                                        }
                                    })
                                    transaction.update(userRef,
                                        {
                                            userActivity: {
                                                ...userProfile.userActivity,
                                                conversations: {
                                                    [docId]: []
                                                },
                                            }
                                        }
                                    )
                                    console.log("Document does not exist!");
                                }
                                else {
                                    console.log(querySnapshot);
                                    console.log(querySnapshot.data());
                                    console.log("Document  exist!");
                                }
                            })
                    })
                })
                .then(() => {
                    //res.send(gameData);
                    console.log('Transaction successfully committed!');
                    setMessageTxt('');
                    handleClose();
                })
                .catch((error) => {
                    //res.send('Transaction failed:' + error);
                    console.log('Transaction failed:', error);
                });
        }
        /*e.preventDefault();
        const data = JSON.stringify({ name: myValues, phone: myValues });
        fetch('./mail.php', {
            method: 'POST',
            body: data
        }).then((response) => {
            if (response.ok) {
                this.setState({ success: true })
            }
        })*/
    }

    if (userDeleted) {
        return <Redirect to='/users' />
    }
    if (!userProfile) {
        return <div />
    }
    return (
        <div>
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={10} md={8} xl={12}>
                    <Paper>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={6}>
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid item>
                                        <AvatarUser profileImg={userProfile.profileImg} initials={userProfile.initials} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" component="h2" className={classes.title} >
                                            {userProfile.fullName
                                            }
                                        </Typography>
                                        <Typography variant="subtitle1" component="h2" className={classes.title} >
                                            {userProfile.mail}
                                        </Typography>
                                        <Typography variant="subtitle1" component="h2" className={classes.title} >
                                            {userProfile.birthday && Math.abs(moment().diff(moment(userProfile.birthday, "DD/MM/YYYY"), 'years')) + " ans"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider orientation={'horizontal'} />
                            </Grid>

                            <Grid item xs={6} className={classes.InfosColumn}>
                                <>

                                    <ul>
                                        <li>Version app : {userActivity && userActivity.appVersion && userActivity.appVersion}</li>
                                        <li>Version système : {userActivity && userActivity.systemOs && userActivity.systemOs} - {userActivity && userActivity.systemVersion && userActivity.systemVersion}</li>
                                        <li>Dernière connexion : {userActivity && userActivity.connections && userActivity.connections.last_changed && moment.unix(userActivity.connections.last_changed / 1000).format("DD/MM/YYYY à HH:mm:ss")}</li>
                                        <li>Existe dans Sellsy : {userProfile && userProfile.sellsyId ? 'Oui' : 'Non'}</li>
                                    </ul>
                                </>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid item xs={10} md={8} xl={12}>
                        <Divider xs={12} />
                        <AppBar position="static">
                            <Toolbar>
                                {userProfile.id != props.monProfil.id && !disabled &&
                                    <IconButton aria-label="Delete" onClick={deleteUser}>
                                        <TrashButton />
                                    </IconButton>
                                }
                                <div style={{ marginLeft: 'auto' }}>
                                    {
                                        disabled ?
                                            <IconButton aria-label="Edit" onClick={enableEditMode} style={{ color: 'white' }}>
                                                <EditButton />
                                            </IconButton>
                                            :
                                            <IconButton aria-label="Save" onClick={saveUser} style={{ color: 'white' }}>
                                                <SaveButton />
                                            </IconButton>
                                    }
                                    {userProfile.id != props.monProfil.id &&
                                        <IconButton aria-label="SendMessage" onClick={sendUserMessage} style={{ color: 'white' }}>
                                            <SendIcon />
                                        </IconButton>
                                    }
                                    {
                                        <IconButton aria-label="SellsyFn" onClick={handleOpenSellsyDialog} style={{ color: disabled ? 'white' : 'lightcoral' }} disabled={!disabled}>
                                            <SvgIcon viewBox={"0 0 2048 1462"}>
                                                <path d="M1372,1371V638a49.1,49.1,0,0,0-15-36q-15-15-35-15h-93q-20,0-35,15a49.1,49.1,0,0,0-15,36v733q0,20,15,35t35,15h93q20,0,35-15T1372,1371Zm-284,0V840q0-20-15-35t-35-15H937q-20,0-35,15t-15,35v531q0,20,15,35t35,15h101q20,0,35-15T1088,1371Zm-292,0V942q0-20-15-35t-35-15H645q-20,0-35,15t-15,35v429q0,20,15,35t35,15H746q20,0,35-15T796,1371Zm-292,0V1009q0-20-15-35t-35-15H353q-20,0-35,15t-15,35v362q0,20,15,35t35,15H454q20,0,35-15T504,1371Zm1416-146q0,166-118,284t-284,118H274q-166,0-284-118t-118-284q0-116,63-214.5T103,862q-10-34-10-73,0-113,80.5-193.5T367,515q102,0,180,67,45-183,194-300t338-117q149,0,275,73.5T1553.5,438Q1627,564,1627,713q0,66-14,122,135,33,221,142.5T1920,1225Z" transform="translate(128 -165)" />
                                            </SvgIcon>
                                        </IconButton>
                                    }
                                </div>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} justify="center" alignItems="flex-start">
                <Grid item item xs={10} md={8} xl={4}>

                    <Paper>
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" component="h6">Informations Générales </Typography>
                            </Toolbar>
                        </AppBar>


                        <form noValidate>
                            <Form
                                fieldContainerStyle={{ backgroundColor: '#fff', padding: 10 }}
                                //inputContainerStyle={{ margin: '30px 0' }}
                                onChange={(profile) => setNewUserProfile(profile)}
                                orientation={'horizontal'}
                                onDelayedChange={(values) => console.log('delayed values: ', values)}
                                delayTriggers={['simpleTextField']}
                                delayTime={1000}
                                fields={candidatsFields}
                                disabled={disabled}
                                values={userProfile}
                                //actionContainerStyle={{ backgroundColor: '#000', padding: 10 }}
                                //cancelForm={cancelForm}
                                //cancelFormButtonName={"Annuler"}
                                //saveForm={handleSubmit}
                                //saveFormButtonName={"Créer"}
                                errors={{ simpleTextFieldWithError: 'This is an error message.' }}
                            />
                        </form>
                        <Divider />
                    </Paper>
                </Grid>
                <Grid item item xs={10} md={8} xl={4}>
                    <Paper>
                        <SuiviDossiers userid={userProfile.id} userProfile={userProfile} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={8}>
                    <Paper>
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" component="h6">Annonces Suggérées</Typography>
                            </Toolbar>
                        </AppBar>

                        {userAnnonces && userAnnonces.length > 0 ?
                            <Grid container spacing={2} justify="center" alignItems="center" className={classes.contentBody} style={{ marginTop: 10, padding: 5 }}>

                                {userAnnonces.map(annonce => {
                                    return (
                                        <Grid item xs={4} md={3} xl={2} key={annonce.id} className={classes.card}>
                                            <AnnonceSummaryCard annonce={annonce} monProfil={props.monProfil} />
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                                {getUserAnnoncesClicked ?
                                    <Typography variant="h6" component="p" color="textSecondary" gutterBottom>
                                        AUCUNE(S) ANNONCE(S) TROUVEE(S) POUR CE(S) DOMAINE(S)
                                </Typography>
                                    :
                                    <Button onClick={getUserAnnonces} variant="contained" color="secondary" >Voir les annonces suggérées</Button>
                                }

                            </div>
                        }
                    </Paper>
                </Grid>
            </Grid>

            {/*<Grid container spacing={1} justify="center" alignItems="center">
                                {userAnnonces.map((userAnnonce, index) => {
                                    //console.log(userAnnonce)
                                    return (
                                        <Grid item xs={4} key={"userAnnonce-" + index}>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <Typography variant="h6" component="p" color="textSecondary" gutterBottom>
                                                        {userAnnonce.title}
                                                    </Typography>
                                                    <Typography variant="body2" component="p" color="textSecondary" gutterBottom>
                                                        ( {userAnnonce.reference} )
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )

                                })}
                            </Grid>*/}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nouveau Message</DialogTitle>
                <form>
                    <DialogContent>
                        <DialogContentText>
                            Veuillez rédiger votre nouveau message
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="message"
                            label="Message"
                            multiline
                            rows={4}
                            type="text"
                            fullWidth
                            value={messageTxt}
                            onChange={onChangeMessage}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Annuler</Button>
                        <Button onClick={handleSubmitMessage} color="primary">Envoyer</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openSellsyDialog} /*onClose={handleCloseSellsyDialog}*/ aria-labelledby="sellsy-dialog-title">
                <DialogTitle id="form-dialog-title">{SellsyDialog.SellsyDialogTitle}</DialogTitle>
                <form>
                    {SellsyDialog.loadingSellsy ?
                        <DialogContent style={{ textAlign: 'center' }}>
                            <CircularProgress color="secondary" />
                            <DialogContentText>
                                {SellsyDialog.loadingSellsyContent}...
                            </DialogContentText>
                        </DialogContent>
                        :
                        <DialogContent>
                            <DialogContentText>
                                {SellsyDialog.SellsyDialogContent}
                            </DialogContentText>
                        </DialogContent>
                    }
                    {!SellsyDialog.loadingSellsy &&
                        <DialogActions>
                            <Button onClick={handleCloseSellsyDialog} color="primary">Annuler</Button>
                            <Button onClick={handleSellsyFunction} color="primary">{SellsyDialog.SellsyDialogValidateButton}</Button>
                        </DialogActions>
                    }
                </form>
            </Dialog>
        </div >

    )

}

const mapStateToPropss = (state, props) => {
    return {
        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil,
        annoncesUser: state.users.annoncesUser,
        UserActivity: state.firebase.data.UserActivity,
        constants: state.firebase.data.constants,
        userProfile: state.firestore.data.userProfile,

    }
}
const mapStateToProps = ({ users, firebase, firestore }) => ({
    auth: firebase.auth,
    monProfil: firestore.data.monProfil,
    annoncesUser: users.annoncesUser,
    //userProfile: firestore.data.userProfile,
    UserActivity: firebase.data.UserActivity,
    constants: firebase.data.constants,

});

const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    return {
        loadAnnoncesUsers: (userDomaines, idEntreprise) => dispatch(loadAnnoncesUsers(userDomaines, idEntreprise)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(),
    firebaseConnect((props, store) => {
        // const state = store.getState().userProfile;
        // console.log(state)
        //const page = store.getState().userProfile
        console.log(props)
        console.log('User Profile ID ', props.profile)
        if (props.profile && props.profile.id) {
            // console.log(props.userProfile)
            return [
                {
                    path: 'usersActivity/' + props.profile.id + '/',
                    storeAs: 'UserActivity'
                    //queryParams: [`limitToFirst=${pageNumber}`]
                }]
        }
        else {
            return []
        }
        //const { pageNumber } = state.localUser;

    }),
    /*firebaseConnect((props) => [
        { path: 'usersActivity/' + props.firestore.data.userProfile.id}
      ]),*/
    //firebaseConnect(),

)(CandidatDetails)
