import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
});

export default function ImageAvatars(props) {
    const classes = useStyles();
    console.log(props.sizeAvatar)

    return (
        <div>
            {Boolean(props.profileImg) ?
                <Avatar alt="Remy Sharp" src={props.profileImg} className={classes.bigAvatar} />
                :
                <Avatar alt="Remy Sharp" className={classes.bigAvatar}>{props.initials}</Avatar>
            }
        </div>
    );
}