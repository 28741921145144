import React, { useEffect, useState, Fragment } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar, MTableAction, MTableActions } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon'
import ImageIcon from '@material-ui/icons/ImageRounded'
import { fade, makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase'
import { Typography } from '@material-ui/core';
import ColorPicker from 'material-ui-color-picker'
import Sellsy from 'node-sellsy'
import CKEditorField from '../utils/CKEditorField'

//var Sellsy = require('node-sellsy');
var sellsy = new Sellsy({
    creds: {
        consumerKey: '4a9663ec9bdc9d51a5ecb27ad301f76a73e76f00',
        consumerSecret: '0986eed5876f3cfd3c7a769be4b27170f8f780f5',
        userToken: 'bf6af015de7da545512b7f3b3a34700cb667d30a',
        userSecret: 'f085896b21aa1229df3040df254756ef84c693aa'
    },

});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    inputLabel: {
        color: "black",
        fontSize: 24,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        "&.focused": {
            color: "red"
        }
    }
}));


const SettingsGenerals = (props) => {
    const firebase = useFirebase();
    const classes = useStyles();
    const [taxesDatas, setTaxesDatas] = React.useState('')
    const [emploisDatas, setEmploisDatas] = React.useState('')
    const [etudesDatas, setEtudesDatas] = React.useState('')
    const [GeneralsDatas, setGeneralsDatas] = React.useState('');
    const [DiffTaxesDatas, setDiffTaxesDatas] = React.useState(false)
    const [DiffDatas, setDiffDatas] = React.useState(false)
    const [DiffEmploisDatas, setDiffEmploisDatas] = React.useState(false)
    const [DiffEtudesDatas, setDiffEtudesDatas] = React.useState(false)
    console.log(props)

    useEffect(
        () => {
            console.log(props.generales)
            /*if (props.generales && props.generales.Avertissement) {
                setGeneralsDatas({...GeneralsDatas, ...props.generales.Avertissement})
            }*/
            if (props.generales && props.generales.Avertissements) {
                setGeneralsDatas(props.generales.Avertissements)
            }
            if (props.generales && props.generales.taxes) {
                setTaxesDatas(props.generales.taxes)
            }
            if (props.generales && props.generales.emplois) {
                setEmploisDatas(props.generales.emplois)
            }
            if (props.generales && props.generales.etudes) {
                setEtudesDatas(props.generales.etudes)
            }

        }, [props.generales]
    )
    if (props.generales && emploisDatas && props.generales && etudesDatas && props.generales && taxesDatas) {
        const handleEditorChangeEmplois = ((event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
            //console.log('handleEditorChange', html, text)
            console.log(event.target)
            console.log(emploisDatas);
            setEmploisDatas({ ...emploisDatas, editor: data });
            if (DiffEmploisDatas !== emploisDatas) {
                setDiffEmploisDatas(true);
            }
            else {
                setDiffEmploisDatas(false);
            }
            console.log(emploisDatas);

        })
        const handleEditorChangeEtudes = ((event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
            //console.log('handleEditorChange', html, text)
            console.log(event.target)
            console.log(etudesDatas);
            setEtudesDatas({ ...etudesDatas, editor: data });
            if (DiffEtudesDatas !== etudesDatas) {
                setDiffEtudesDatas(true);
            }
            else {
                setDiffEtudesDatas(false);
            }
            console.log(etudesDatas);

        })
        function isNumeric(value) {
            return /^-?\d+$/.test(value);
        }
        const handleTaxesChange = ((event) => {

            console.log({ event });
            //console.log('handleEditorChange', html, text)
            console.log(event.target)
            console.log(setDiffTaxesDatas);
            //console.log(typeof event.target.value)
            let fieldValue = parseFloat(event.target.value.replace(',', '.'));

            /*if (isNumeric(fieldValue) || fieldValue === 'NaN') {*/
                setTaxesDatas({ ...taxesDatas, [event.target.name]: parseFloat(event.target.value.replace(',', '.')) });
                if (DiffTaxesDatas !== taxesDatas) {
                    setDiffTaxesDatas(true);
                }
                else {
                    setDiffTaxesDatas(false);
                }
           /* }
            else {
                alert('erreur, ce champ doit être remplit en nombre !')
            }*/
            console.log(etudesDatas);

        })
        //console.log(props)
        /*const setNewData = (event) => {
            console.log(event.target)
            const currId = event.target.id;
            const currName = event.target.name;
            let newValue = null;
            if (currName === 'enable') {
                newValue = event.target.checked; //event.target.color;
            }
            else {
                newValue = event.target.value;
            }
            console.log(newValue);

            console.log(GeneralsDatas);
            setGeneralsDatas({ ...GeneralsDatas, [currId]: { ...GeneralsDatas[currId], [currName]: newValue } });
            if (DiffDatas !== GeneralsDatas) {
                setDiffDatas(true);
            }
            else {
                setDiffDatas(false);
            }
            console.log(GeneralsDatas);
            // console.log(currId);
            //console.log(currName);
        }*/
        const saveNewTaxesDatas = () => {
            console.log('save')
            console.log(taxesDatas)
            firebase.database().ref('constants/').update({
                taxes: taxesDatas
            });
            setDiffTaxesDatas(false);
        }

        const saveNewDataEmplois = () => {
            console.log('save')
            firebase.database().ref('constants/').update({
                emplois: emploisDatas
            });
            setDiffEmploisDatas(false);
        }
        const saveNewDataEtudes = () => {
            console.log('save')
            firebase.database().ref('constants/').update({
                etudes: etudesDatas
            });
            setDiffEtudesDatas(false);
        }
        console.log(emploisDatas);
        //console.log(props.generales.Avertissement)
        return (
            <Box my={2}>
                <Typography variant={'h6'} my={2}>Taxes Pourboire</Typography>
                <Paper variant="outlined" style={{ margin: 5, padding: 5 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <TextField
                                label={"TPS"}
                                name={'tps'}
                                onChange={handleTaxesChange}
                                defaultValue={taxesDatas.tps || ''}
                            //inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={'TVQ'}
                                name={'tvq'}
                                onChange={handleTaxesChange}
                                defaultValue={taxesDatas.tvq || ''}
                            //inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button variant="outlined" size="medium" color="primary" disabled={!DiffTaxesDatas} onClick={saveNewTaxesDatas} >Mettre à jour</Button>
                </Grid>
                <Typography variant={'h6'} my={2}>Textes Entete Emplois</Typography>
                <Paper variant="outlined" style={{ margin: 5, padding: 5 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CKEditorField data={props.generales.emplois.editor || ''} config={null} onChange={handleEditorChangeEmplois} />
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button variant="outlined" size="medium" color="primary" disabled={!DiffEmploisDatas} onClick={saveNewDataEmplois} >Mettre à jour</Button>
                </Grid>
                <Typography variant={'h6'} my={2}>Textes Entete Etudes</Typography>
                <Paper variant="outlined" style={{ margin: 5, padding: 5 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CKEditorField data={props.generales.etudes.editor || ''} config={null} onChange={handleEditorChangeEtudes} />
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button variant="outlined" size="medium" color="primary" disabled={!DiffEtudesDatas} onClick={saveNewDataEtudes} >Mettre à jour</Button>
                </Grid>
            </Box>

        )
    }


    if (props.generales && props.generales.Avertissements && GeneralsDatas) {
        const handleEditorChange = ((event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
            //console.log('handleEditorChange', html, text)
            console.log(event.target)
            const currId = event.target.id;
            const currName = event.target.name;
            let newValue = null;

            newValue = data;

            console.log(newValue);

            console.log(GeneralsDatas);
            setGeneralsDatas({ ...GeneralsDatas, [currId]: { ...GeneralsDatas[currId], [currName]: newValue } });
            if (DiffDatas !== GeneralsDatas) {
                setDiffDatas(true);
            }
            else {
                setDiffDatas(false);
            }
            console.log(GeneralsDatas);
            //updateParentValues();
            /*if (prevValues.description !== data) {
                console.log('difference markdown');
                //console.log(text)
                //console.log(html)
            }*/
        })
        //console.log(props)
        const setNewData = (event) => {
            console.log(event.target)
            const currId = event.target.id;
            const currName = event.target.name;
            let newValue = null;
            if (currName === 'enable') {
                newValue = event.target.checked; //event.target.color;
            }
            else {
                newValue = event.target.value;
            }
            console.log(newValue);

            console.log(GeneralsDatas);
            setGeneralsDatas({ ...GeneralsDatas, [currId]: { ...GeneralsDatas[currId], [currName]: newValue } });
            if (DiffDatas !== GeneralsDatas) {
                setDiffDatas(true);
            }
            else {
                setDiffDatas(false);
            }
            console.log(GeneralsDatas);
            // console.log(currId);
            //console.log(currName);
        }
        const saveNewData = () => {
            console.log('save')
            firebase.database().ref('constants/').update({
                Avertissements: GeneralsDatas
            });
            setDiffDatas(false);
        }
        console.log(GeneralsDatas);
        //console.log(props.generales.Avertissement)
        return (
            <Box my={2}>
                <Typography variant={'h6'} my={2}>Textes</Typography>
                {props.generales.Avertissements && props.generales.Avertissements.length > 0 && props.generales.Avertissements.map((avertissement, index) => {
                    return (
                        <Paper key={index} variant="outlined" style={{ margin: 5, padding: 5 }}>
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <TextField key={index}
                                        name={"Title"}
                                        id={index.toString()}
                                        label={"Titre Avertissement " + parseInt(index + 1)} defaultValue={avertissement.Title} fullWidth helperText="Laissez vide pour désactiver le message dans l'application" onChange={setNewData}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.inputLabel,
                                                focused: "focused"
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={GeneralsDatas[index].enable}
                                                value={avertissement.enable}
                                                onChange={setNewData}
                                                id={index.toString()}
                                                name="enable"

                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        }
                                        label={"Activer Avertissement " + parseInt(index + 1)}
                                    />

                                </Grid>
                                {/*<Grid item xs={4}>
                                    <ColorPicker
                                        label={"Couleur de fond " + parseInt(index + 1)}
                                        key={index}
                                        name={"bgColor"}
                                        id={index.toString()}
                                        defaultValue={avertissement.bgColor}
                                        // value={this.state.color} - for controlled component
                                        //onChange={color => console.log(color)}
                                        onChange={setNewData}

                                    />
                                </Grid>*/}
                                <Grid item xs={6}>
                                    <TextField key={index}
                                        name={"Content"}
                                        id={index.toString()}
                                        label={"Contenu Avertissement " + parseInt(index + 1)} defaultValue={avertissement.Content} fullWidth multiline rows={4} helperText="Laissez vide pour désactiver le message dans l'application" onChange={setNewData}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.inputLabel,
                                                focused: "focused"
                                            }
                                        }} />
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                })}
                {/*<Grid container spacing={0}>
                    <Grid item xs={4}>
                        <TextField label={"Texte Avertissement"} defaultValue={props.generales.Avertissement} fullWidth multiline rows={4} helperText="Laissez vide pour désactiver le message dans l'application" onChange={setNewData}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: "focused"
                                }
                            }} />
                    </Grid>
                    <Grid item xs={4}>
                        <ColorPicker
                            name='color'
                            defaultValue='#000'
                            // value={this.state.color} - for controlled component
                            onChange={color => console.log(color)}

                        />
                    </Grid>
                        </Grid>*/}
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button variant="outlined" size="medium" color="primary" disabled={!DiffDatas} onClick={saveNewData} >Mettre à jour</Button>
                </Grid>
            </Box>

        )
    }
    return null;

}

export default SettingsGenerals