import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useFirebase, useFirestore } from "react-redux-firebase";
import { compose } from 'redux'
//import AnnonceSummaryCard from './AnnonceSummaryCard'
//import CreateAnnonce from './CreateAnnonce'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'


import { fade, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ImageIcon from '@material-ui/icons/Image';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LocationIcon from '@material-ui/icons/LocationOn';
import CommentIcon from '@material-ui/icons/Comment';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as WordpressIcon } from '../../assets/icons/wordpress.svg';
import _ from 'lodash';
import moment from 'moment'


const useStyles = makeStyles(theme => ({
    root: {
        //maxWidth: 345,
        position: 'relative',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    overlays: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
    },
    cardWrapper: {
        //display: 'flex',
        position: 'relative',
        cursor: 'pointer',
    },
    overlay: {
        position: 'absolute',
        backgroundColor: theme.BgOverlayColorBlack.backgroundColor,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlayContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        height: '100%'
    },
    overlayDateContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',

    },
    overlayText: {
        display: 'inline',
        alignSelf: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: theme.secondaryTxtColor.color

    },
    overlayDate: {
        display: 'inline',
        alignSelf: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: theme.secondaryTxtColor.color

    }
}))

const PostsItems = ({ post, index, tourismeId, handleListItemClick, handleEditModePost, handleDeletePost, handleOpenDelete, handleViewPostLikes, handleViewPostComments }) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [expanded, setExpanded] = React.useState(false);
    //console.log(post)
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleEditPost = () => {
        handleEditModePost(post.id)
    }

    const handleDeletePostClick = () => {
        //alert('Etes vous sûre de vouloir supprimer cet article ?')
        let path = `tourismes/${tourismeId}/posts/${post.docId}`;
        console.log(path)
        handleOpenDelete(path);
    }

    const handleDeletePosts = () => {
        firestore.collection('tourismes').doc(tourismeId).collection('posts').doc(post.docId).delete()
            .then((doc) => {
                console.log('sucess');
                handleListItemClick()
            }).catch((err) => {
                console.error('error deleting post', err)
                //setProgress(false);
            });
    }

    return (
        <Card className={classes.root}>

            {/* <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        R
            </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={post.title}
                subheader="September 14, 2016"
            />*/}
            <div className={classes.cardWrapper} /*onClick={this.imageClick}*/ onClick={handleEditPost}>
                <CardMedia
                    className={classes.media}
                    image={post.imageThumbnail ? post.imageThumbnail : post.image ? post.image : require('../../assets/questionMark.png')}
                    title="Paella dish"
                />
                <div className={classes.overlay}>
                    <div className={classes.overlayContent}>
                        <Typography variant={'h6'} className={classes.overlayText}>
                            {post.title}
                        </Typography>
                        <div className={classes.overlayDateContent}>
                            {post.created && post.created.seconds &&
                                <Typography variant={'caption'} className={classes.overlayDate}>
                                    crée le {moment.unix(post.created.seconds).format("DD/MM/YYYY à HH:mm:ss")}
                                </Typography>
                            }
                            {post.modified && post.modified.seconds &&
                                <Typography variant={'caption'} className={classes.overlayDate}>
                                    modifié le {moment.unix(post.modified.seconds).format("DD/MM/YYYY à HH:mm:ss")}
                                </Typography>
                            }
                        </div>
                    </div>
                    {post.featured &&
                        <div style={{ position: "absolute", right: 30, top: 0 }}>
                            <BookmarkIcon color="primary" style={{ color: 'white' }} />
                        </div>
                    }
                    {post.wpDocId && post.wpPostLink &&
                        <div style={{ position: "absolute", right: 0, top: 0 }}>
                            <SvgIcon>
                                <WordpressIcon color="primary" style={{ color: 'white' }} />
                            </SvgIcon>
                        </div>
                    }
                    {post.location &&
                        <div style={{ position: "absolute", left: 0, top: 0 }}>
                            <LocationIcon color="primary" style={{ color: 'white' }} />
                        </div>
                    }
                </div>
            </div>

            <CardActions disableSpacing style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <IconButton aria-label="likes" onClick={handleViewPostLikes}>
                    <FavoriteIcon />
                </IconButton>
                <Typography variant={'body2'}>{post.nbLikes ? post.nbLikes : 0}</Typography>
                <IconButton aria-label="comments" onClick={handleViewPostComments}>
                    <CommentIcon />
                </IconButton>
                <Typography variant={'body2'}>{post.nbComments ? post.nbComments : 0}</Typography>

                <Divider orientation="vertical" flexItem />
                <IconButton
                    //className={clsx(classes.expand)}
                    onClick={handleEditPost}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    //className={clsx(classes.expand)}
                    onClick={handleDeletePostClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <DeleteIcon />
                </IconButton>
            </CardActions>
        </Card>

    )
}

export default PostsItems
