import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid"
import moment from 'moment'
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';

import {abonnements} from '../../../constants/abonnement'

const useStyles = makeStyles(theme => ({
    title: {
        padding: 8
    },
    detailsTitle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        padding: 8,
        textTransform: 'uppercase'
        // marginBottom: '10px'
    },

}));


const DetailsAbonnement = ({ userActivity, monProfil }) => {
    const classes = useStyles();
    //console.log(abonnements[monProfil.aboFormule])

    return (
        <Paper>

            <Typography variant="h6" component="h6" gutterBottom className={classes.detailsTitle} >VOTRE ABONNEMENT  {abonnements[monProfil.aboFormule] && abonnements[monProfil.aboFormule].title}</Typography>

            <Grid container spacing={0} /*alignItems="flex-end"*/ justify="center" className={classes.priceTables}>

                <Grid item xs={12}>
                <ul>
                  {abonnements[monProfil.aboFormule] && abonnements[monProfil.aboFormule].description.map(line => (
                    <Typography component="li" variant="subtitle1" /*align="center"*/ key={line}>
                      {line}
                    </Typography>
                  ))}
                </ul>

                </Grid>
            </Grid>
        </Paper>
    )
}

export default DetailsAbonnement