import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import {
  PaymentRequestButtonElement,
  //CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  IbanElement,
  injectStripe,
  address_zip,
  address
} from 'react-stripe-elements';
import { useStripe, useElements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
//import ToggleButton from '@material-ui/lab/ToggleButton';
//import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import queryString from 'query-string';
import MaterialTable, { MTableToolbar, MTableEditRow } from 'material-table';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import CardIcon from '@material-ui/icons/CreditCardOutlined'
import AccountIcon from '@material-ui/icons/AccountBalanceOutlined'




import str from '@stripe/stripe-js'
import moment from 'moment';
import * as yup from 'yup';
import CardSection from './CardSection';

import PaymentSourceTable from './PaymentSourceTable'
import { withStyles } from "@material-ui/core/styles";


const createOptions = () => {
  return {
    style: {
      root: {
        padding: '11px 15px 11px 0',
      },
      base: {
        fontSize: '16px',
        padding: '11px 15px 11px 0',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appbar: {
    alignItems: 'center',
  },
  title: {
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'uppercase'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1em'
  },
  cardElement: {
    borderColor: 'rgb(207, 215, 223)',
    borderStyle: 'solid',
    borderWidth: '0.05em',
    // backgroundColor: 'green',
    marginTop: '1em',
    padding: '11px 15px 11px 10px',
  },
  button: {
    // height: 95, // setting height/width is optional
    width: '50%'
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: 'column'
  },
  icon: {
    fontSize: '32px !important',
    marginBottom: theme.spacing(1)
  },
  listTransaction: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '30vh',
  },
});
const schema = {
  cardNumber: yup.string()
    .label('Card number')
    .max(16)
    .required(),
  cvc: yup.string()
    .label('CVC')
    .min(3)
    .max(4)
    .required(),
  nameOnCard: yup.string()
    .label('Name on card')
    .required(),
  expiryMonth: yup.string()
    .label('Expiry month')
    .min(2)
    .max(2)
    .required(),
  expiryYear: yup.string()
    .label('Expiry year')
    .min(4)
    .max(4)
    .required(),
};
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.editing = true;
    this.state = {
      complete: false,
      open: false,
      editing: false,
      alertMessage: '',
      alertStyle: '',
      defaultCard: '',
      selectedCard: '',
      customerId: '',
      monProfil: {
        email: this.props.monProfil.mail,
        name: this.props.monProfil.name,
        firstName: this.props.monProfil.firstName,

      },
      paymentButtonSelected: 'card',
      stripeData: {
        currentUser: null,
        sources: {},
        stripeCustomerInitialized: false,
        newCreditCard: {
          number: '4242424242424242',
          cvc: '111',
          exp_month: 1,
          exp_year: 2020,
          address_zip: '00000',
          error: ''
        },
        charges: {},
        newCharge: {
          source: null,
          amount: 2000
        }
      },
      data: []
    };
    // this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    //this.handleCheckStripeCustomer();

  }

  componentDidUpdate(prevProps, prevState) {
    //this.handleCheckStripeCustomer();
    if (prevProps.subscribe !== this.props.subscribe && this.props.subscribe === true) {
      //this.submit()
      this.handleSubmit()
    }
    /*if(this.state.customerId) {
      this.getInvoicesPdf();
    }*/
  }

  handleCheckStripeCustomer = (ev) => {
    //ev.preventDefault();
    console.log(this.props)
    const userId = this.props.auth.uid;
    this.props.firebase.firestore().collection('stripe_customers').doc(`${userId}`).onSnapshot(snapshot => {
      console.log(snapshot)
      if (snapshot.exists) {
        this.setState({ ...this.state, defaultCard: snapshot.data().defaultCard, selectedCard: snapshot.data().defaultCard, customerId: snapshot.data().customer_id, stripeData: { ...this.state.stripeData, stripeCustomerInitialized: snapshot.data() !== null || snapshot.data() !== undefined } })
        //stripeData.stripeCustomerInitialized = (snapshot.data() !== null || snapshot.data() !== undefined);
      }
      else {
        this.props.firebase.firestore().collection('stripe_customers').doc(this.props.auth.uid).set({ uid: this.props.auth.uid, email: this.props.auth.email });
        //this.createStripeUser();
      }

    }, () => {
      this.setState({ ...this.state, stripeData: { ...this.state.stripeData, stripeCustomerInitialized: false } })
      //stripeData.stripeCustomerInitialized = false;
    });
    this.props.firebase.firestore().collection('stripe_customers').doc(`${userId}`).collection('sources').onSnapshot(snapshot => {
      let newSources = {};
      let newSourcesData = [];
      console.log(snapshot)
      snapshot.forEach(doc => {
        const id = doc.id;
        newSources[id] = doc.data();
        let data = doc.data();
        console.log(id)
        console.log(doc.data().id)
        console.log(this.state.defaultCard)
        const yearLength = data.exp_year.length;
        let type = ''
        if (data.object === "card") {
          type = "Card"
        }
        if (doc.data().id === this.state.defaultCard) {
          newSourcesData.push({ ...data, expiration: data.exp_month + '/' + String(data.exp_year).substr(-2), default: true })
        }
        else {
          newSourcesData.push({ ...data, expiration: data.exp_month + '/' + String(data.exp_year).substr(-2), default: false })
        }

      })
      console.log(newSources)
      console.log(newSourcesData)
      console.log(this.state.stripeData)
      this.setState({ ...this.state, stripeData: { ...this.state.stripeData, sources: newSources }, data: [...newSourcesData] })
      //stripeData.sources = newSources;
    }, () => {
      this.setState({ ...this.state, stripeData: { ...this.state.stripeData, sources: {} } })
      //stripeData.sources = {};
    });
    this.props.firebase.firestore().collection('stripe_customers').doc(`${userId}`).collection('charges').orderBy('created', 'desc').onSnapshot(snapshot => {
      let newCharges = {};
      snapshot.forEach(doc => {
        const id = doc.id;
        newCharges[id] = doc.data();
      })
      this.setState({ ...this.state, stripeData: { ...this.state.stripeData, charges: newCharges } })
      //stripeData.charges = newCharges;
    }, () => {
      this.setState({ ...this.state, stripeData: { ...this.state.stripeData, charges: {} } })
      //stripeData.charges = {};
    });
  }
  submitNewCreditCardxx = (ev) => {

    ev.preventDefault();

    // Use Elements to get a reference to the Card Element mounted somewhere
    // in your <Elements> tree. Elements will know how to find your Card Element
    // because only one is allowed.
    // See our getElement documentation for more:
    // https://stripe.com/docs/stripe-js/reference#elements-get-element
    const cardElement = this.props.elements.getElement('card');
    console.log(cardElement)


    /*TEST*/
    this.props.stripe.createSource({
      type: 'card',
      currency: 'usd',
      amount: 100 * 100,
      owner: {
        name: this.props.monProfil.name,
        email: this.props.monProfil.email
      }
    }).then(response => {
      if (response.error) {
        this.setState({ messageError: response.error.message });
      } else {
        let source = response.source;
        if (source.card.three_d_secure === 'not_supported' &&
          source.status === 'chargeable') {
          // SEND TO SERVER -> CHARGE
        } else if (source.card.three_d_secure === 'required' ||
          source.card.three_d_secure === 'recommended' ||
          source.card.three_d_secure === 'optional') {
          this.props.stripe.createSource({
            type: 'three_d_secure',
            currency: 'usd',
            amount: 100 * 100,
            type: 'card',
            three_d_secure: {
              card: source.id
            },
            redirect: {
              return_url: window.location.href
            },
            owner: {
              name: this.props.monProfil.name,
              email: this.props.monProfil.email
            }
          }).then(response => {
            console.log(response)
            if (response.error) {
              this.setState({ messageError: response.error.message });
            } else {
              let redirect = response.source.redirect;
              // REDIRECT USER TO redirect.url
              // CALLBACK ??
              console.log(redirect);
            }
          });

        }
      }
    });

    // From here we can call createPaymentMethod to create a PaymentMethod
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    /*this.props.stripe
       .createPaymentMethod({
         type: 'card',
         card: cardElement,
         billing_details: {name: 'Jenny Rosen'},
       })
       .then(({paymentMethod}) => {
         console.log('Received Stripe PaymentMethod:', paymentMethod);
       });*/

    // You can also use confirmCardPayment with the PaymentIntents API automatic confirmation flow.
    // See our confirmCardPayment documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
    /*this.props.stripe.confirmCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', {
      payment_method: {
        card: cardElement,
      },
    });*/

    // You can also use confirmCardSetup with the SetupIntents API.
    // See our confirmCardSetup documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-setup
    /* this.props.stripe.confirmCardSetup('{PAYMENT_INTENT_CLIENT_SECRET}', {
       payment_method: {
         card: cardElement,
       },
     });*/

    // You can also use createToken to create tokens.
    // See our tokens documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    // With createToken, you will not need to pass in the reference to
    // the Element. It will be inferred automatically.
    //this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
    // token type can optionally be inferred if there is only one Element
    // with which to create tokens
    // this.props.stripe.createToken({name: 'Jenny Rosen'});

    // You can also use createSource to create Sources.
    // See our Sources documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-source
    // With createSource, you will not need to pass in the reference to
    // the Element. It will be inferred automatically.
    /*this.props.stripe.createSource({
      type: 'card',
      owner: {
        name: 'Jenny Rosen',
      },
    });*/
  }

  getPaymentIntents = async (paymentMethod) => {
    var myHeaders = new Headers({
      'Content-Type': 'application/json',
    });
    /*JSON.stringify({
      amount: 2000,//this.props.route.params.choice === 'year' ? this.props.route.params.abo.priceYear : this.props.route.params.abo.priceMonth,
      currency: "cad",
      //token: this.state.token.tokenId
    });*/
    var Body = JSON.stringify({
      amount: 2000,
      currency: "cad",
      payment_method: paymentMethod.id
    });
    console.log(Body)
    // Use firebase serve for local testing if you don't have a paid firebase account
    fetch(
      //'http://localhost:5001/oq70-objectifquebec/us-central1/getPaymentIntentsStripe',
      'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getPaymentIntentsStripe',
      {
        method: 'POST',
        headers: myHeaders,
        body: Body,
        // mode: 'no-cors',
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        return this.handleServerResponse(responseJson)

      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleServerResponse = (responseJson) => {
    const { stripe, elements } = this.props;
    if (responseJson.error) {
      console.log("error")
      // Show error from server on payment form
    } else if (responseJson.requiresAction) {
      console.log("requiere actions")
      // Use Stripe.js to handle required card action
      stripe.handleCardAction(
        responseJson.clientSecret
      ).then(function (result) {
        if (result.error) {
          // Show `result.error.message` in payment form
        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          fetch('/pay', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ payment_intent_id: result.paymentIntent.id })
          }).then(function (confirmResult) {
            return confirmResult.json();
          }).then(this.handleServerResponse);
        }
      });
    } else {
      console.log("succes")
      return this.confirmPayment(responseJson)
      // Show success message
    }
  }

  confirmPayment = (responseJson) => {
    const { stripe, elements } = this.props;

    const cardElement = elements.getElement(CardElement)//this.props.elements.getElement('card');
    stripe.confirmCardPayment(
      responseJson.client_secret,
      {
        payment_method: { card: cardElement }
      }
    ).then(function (result) {
      if (result.error) {
        // Display error.message in your UI.
        console.log('paiement refusé')
      } else {
        // The payment has succeeded
        console.log('paiement OK')
        // Display a success message
      }
    });
  }
  submitNewCreditCard = async (ev) => {
    //ev.preventDefault();
    const userId = this.props.auth.uid;
    const { stripe, elements } = this.props;

    const cardElement = elements.getElement(CardElement)//this.props.elements.getElement('card');

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    console.log(error)
    console.log(paymentMethod)
    if (paymentMethod) {
      this.getPaymentIntents(paymentMethod);
    }

    return false;



    console.log(cardElement)

    // this.handleSubmit();
    this.props.stripe.createToken({ name: this.props.monProfil.companyName, email: this.props.auth.email, /*phone: this.props.monProfil.phoneNumber,*/ })
      .then(result => {
        console.log(result)
        if (typeof (result.error) !== 'undefined') {
          console.log(result)
          //this.setState({ error: result.error.message, success: '' });
          this.setState({ ...this.state, stripeData: { ...this.state.stripeData, newCreditCard: { error: result.error.message } } })
        } else {
          console.log(result)
          //          this.props.firebase.firestore().collection('stripe_customers').doc(userId).collection('tokens').add({ token: result.token  }).then(() => {
          this.props.firebase.firestore().collection('stripe_customers').doc(userId).collection('tokens').doc(result.token.card.id).set({ token: result.token }).then(() => {
            this.setState({
              ...this.state, stripeData: {
                ...this.state.stripeData, newCreditCard: {
                  number: '',
                  cvc: '',
                  exp_month: 1,
                  exp_year: 2017,
                  address_zip: '',
                  error: ''
                }
              }
            })
            this.props.firebase.firestore().collection('stripe_customers').doc(userId).update({ defaultCard: result.token.card.id }).then((result) => {
              this.setState({ open: false })
            })
            // this.stripeCreateCharge(result.token, 100);
          })
        }
      })
      .catch((err) => {
        console.log("erreur ", err)
      })
      ;
  }

  submitNewCharge = (ev) => {
    ev.preventDefault();
    console.log(this.state.stripeData.newCharge.amount)
    console.log(parseInt(this.state.stripeData.newCharge.amount))
    const userId = this.props.auth.uid;
    this.props.firebase.firestore().collection('stripe_customers').doc(userId).collection('charges').add({
      source: this.state.stripeData.newCharge.source,
      description: "test abonnement",//this.props.abonnement.title,
      amount: parseInt(this.state.stripeData.newCharge.amount)
    });
  }

  /*handleSubmit = (ev) => {
    ev.preventDefault();
  
    this.props.stripe.createToken({ name: "test" })
      .then(result => {
        if (typeof (result.error) !== 'undefined') {
          console.log(result)
          //this.setState({ error: result.error.message, success: '' });
        } else {
          console.log(result)
          // this.stripeCreateCharge(result.token, 100);
        }
      });
  }*/

  /*stripeCreateCharge(token, amount) {
    const params = { token: token.id, amount: amount };
    const qParams = queryString.stringify(params);
    const url = ['/api', qParams].join('?');
    console.log(params)
    console.log(qParams)
    console.log(url)
    fetch(url)
      .then(response => response.json())
      .then(val => {
        console.log(val)
        if (val.ok) {
          return val.message;
        } else {
          throw val.message;
        }
      })
      .then(success => {
        console.log(success)
        this.setState({ alertMessage: success, alertStyle: 'success' })
      })
      .catch(error => {
        console.log(error)
        this.setState({ alertMessage: error, alertStyle: 'danger' })
      });
  }
  
  async submit(ev) {
    let { token } = await this.props.stripe.createToken({ name: "Name" });
    console.log(token)
    let response = await fetch("/charge", {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: token.id
    });
    console.log(response)
    if (response.ok) console.log("Purchase Complete!")
    if (response.ok) this.setState({ complete: true });
    this.props.handleSuccessPayment();
  }*/


  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment('{{CLIENT_SECRET}}', {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Jenny Rosen',
        },
      },
      setup_future_usage: 'off_session',
    });

    if (result.error) {
      // Show error to your customer
      console.log(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window
        // before callback execution. Set up a webhook or plugin
        // to listen for the payment_intent.succeeded event
        // to save the card to a Customer

        // The PaymentMethod ID can be found on result.paymentIntent.payment_method
      }
    }
  };



  getInvoicesPdf = async (event) => {
    //event.preventDefault();
    console.log(this.state.customerId)
    const invoicesResponse = await this.props.stripe.invoices.list({ customer: this.state.customerId });
    const invoices = invoicesResponse.map(invoice => ({
      invoiceId: invoice['id'],
      pdf: invoice['invoice_pdf'],
    }));
    console.log(invoices)
    return invoices;
  }
  changeNewCard = (event) => {
    console.log("changed new card")
    console.log(event)
    console.log(event.value)
  }

  handleChangeCard = (event) => {
    console.log(event.target.value)
    /* this.setState({
       selectedCard: event.target.value
     })*/
    this.setState({
      ...this.state,
      selectedCard: event.target.value,
      stripeData: {
        ...this.state.stripeData,
        newCharge:
        {
          ...this.state.stripeData.newCharge,
          source: event.target.value
        }
      }
    })
  };
  changeAmount = (event) => {
    this.setState({
      ...this.state,
      stripeData: {
        ...this.state.stripeData,
        newCharge:
        {
          ...this.state.stripeData.newCharge,
          amount: event.target.value
        }
      }
    })
  }
  handleClickDialog = () => {
    this.setState({ open: !this.state.open })
  }

  setPayementButton = (buttonSelected) => {
    console.log(buttonSelected)
    this.setState({
      paymentButtonSelected: buttonSelected
    })
  }

  paymentStatus = (status) => {
    switch (status) {

      case "succeeded": return "Paiement réussi";
      case "fail": return "Paiement échoué";
      case "pending": return "Paiement en attente";
      default: return "Aucun paiment"
    }
  }

  render() {
    const { classes } = this.props;
    //this.handleCheckStripeCustomer();
    console.log(this.props)
    console.log(this.state)
    if (this.state.complete) return <h1>Purchase Complete</h1>;
    return (
      <Box>
        {/*<CardElement onChange={this.changeNewCard} value={this.state.stripeData.newCreditCard} />*/}
        <Grid container spacing={2}>

          {/*<Grid item xs={4} >
            <Paper>
              <PaymentSourceTable handleClickDialog={this.handleClickDialog} data={this.state.data} />
            </Paper>
          </Grid>*/}
          <Grid item xs={8} >
            <Paper>
              <Typography variant="h6" gutterBottom className={classes.title}>
                Historique de vos Abonnements
              </Typography>
              <List dense={true}
                className={classes.listTransaction}
              >
                {this.props.mySubscriptions && Object.values(this.props.mySubscriptions).map((item, index) => {
                  console.log(item)
                  return (
                    <div key={index}>
                      <ListItem key={index}>
                        <ListItemText
                          primary={
                            <div>
                              <Grid container>
                              <Grid item xs={3}>
                                  <Typography variant="h6" gutterBottom>
                                    {item.description && item.description}
                                    {/*item.outcome && item.outcome.seller_message*/}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant="h6" gutterBottom>
                                    {(item.amount / 100).toFixed(2).replace('.', ',')} {item.currency}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant="h6" gutterBottom>
                                    {item.status && this.paymentStatus(item.status)}
                                    {/*item.outcome && item.outcome.seller_message*/}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant="h6" gutterBottom>
                                    {item.source && this.state.stripeData.sources[item.source.fingerprint] && this.state.stripeData.sources[item.source.fingerprint].last4}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          }
                          secondary={item.created && moment.unix(item.created).format("DD/MM/YYYY à HH:mm:ss")}
                        />

                      </ListItem>
                      <Divider />
                    </div>


                  )
                })
                }
              </List>

            </Paper>
          </Grid>
        </Grid>



        <Dialog onClose={this.handleClickDialog} aria-labelledby="simple-dialog-title" open={this.state.open}>
          <DialogTitle id="simple-dialog-title">Ajout d'un nouveau moyen de paiement</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Pour souscrire à l'offre sélectionnée veuillez compléter les informations ci-dessous!
          </DialogContentText>
            {/*<ToggleButtonGroup size="small" value={this.state.paymentButton} exclusive onChange={this.setPayementButton}>
              <ToggleButton key={1} value={"card"}>
                <CardIcon />
              </ToggleButton>
              <ToggleButton key={2} value={"bank"}>
                <AccountIcon />
              </ToggleButton>
        </ToggleButtonGroup>*/}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nom"
              type="text"
              fullWidth
              value={this.state.monProfil.name}
            />
            <TextField
              autoFocus
              margin="dense"
              id="firstName"
              label="Prénom"
              type="text"
              fullWidth
              value={this.state.monProfil.firstName}
            />
            <TextField
              autoFocus
              margin="dense"
              id="address1"
              label="Adresse ligne 1"
              type="text"
              fullWidth
              value={this.state.monProfil.firstName}
            />
            <TextField
              autoFocus
              margin="dense"
              id="address"
              label="Adresse ligne 2"
              type="text"
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="codePostal"
              label="Code Postal"
              type="text"
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="ville"
              label="Ville"
              type="text"
              fullWidth
            />
            <Divider />
            <ButtonGroup color="secondary" aria-label="secondary button group" size={"large"} className={classes.buttonGroup}>
              <Button
                name={"card"}
                classes={{ root: classes.button, label: classes.label }}
                variant={this.state.paymentButtonSelected === 'card' ? "contained" : "outlined"}
                onClick={() => this.setPayementButton("card")}>
                <CardIcon className={classes.icon} />
                Carte Bancaire
              </Button>
              <Button
                name={"bank"}
                classes={{ root: classes.button, label: classes.label }}
                variant={this.state.paymentButtonSelected === 'bank' ? "contained" : "outlined"}
                onClick={() => this.setPayementButton("bank")}>
                <AccountIcon className={classes.icon} />
                Virement Bancaire
              </Button>
            </ButtonGroup>
            <Divider />
            {
              this.state.paymentButtonSelected === 'card' &&
              <>
                {/*}<CardSection />*/}
                <CardElement
                  className={classes.cardElement}
                  onReady={(el) => el.focus()}
                  {...createOptions()}
                />
              </>
            }

            {/*<PaymentRequestButtonElement
              // paymentRequest={this.state.paymentRequest}
              className="PaymentRequestButton"
              style={{
                // For more details on how to style the Payment Request Button, see:
                // https://stripe.com/docs/elements/payment-request-button#styling-the-element
                paymentRequestButton: {
                  theme: 'light',
                  height: '64px',
                },
              }}
            />*/}
            {
              this.state.paymentButtonSelected === 'bank' &&

              <IbanElement
                supportedCountries={['SEPA']}
                placeholderCountry="DE"
                //onChange={this.handleChange}
                //{...createOptions()}
                className={classes.cardElement}
                onReady={(el) => el.focus()}
                {...createOptions()}
              />
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickDialog} color="primary">
              Annuler
          </Button>
            <Button onClick={this.submitNewCreditCard} color="primary">
              Ajouter
          </Button>
          </DialogActions>
        </Dialog>
      </Box >
    );
  }
}

//export default injectStripe(CheckoutForm);
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    monProfil: state.firestore.data.monProfil,
    mySubscriptions: state.firestore.ordered.mySubscriptions
    //annoncesUser: state.users.annoncesUser,
    //stripe_customers: state.firestore.ordered.stripe_customers
  }
}


export default /*injectStripe(*/compose(
  withStyles(styles),
  connect(mapStateToProps),
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: 'stripe_customers',
      doc: props.auth.uid,
      subcollections: [
        {
          collection: 'subscriptions',
          orderBy: [
            ['created', 'desc']
          ],
        },
      ],
      storeAs: 'mySubscriptions',
    }
  ])
)(CheckoutForm)/*)*/