import React, { useEffect } from "react";
import { fade, makeStyles } from '@material-ui/core/styles';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getDetails,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
    },
    searchList: {
        position: 'fixed', /* instead of : position:absolute;*/
        //top: '7%',         /* instead of : top:100%;*/
        backgroundColor: 'white',
        border: '1px solid black',
        zIndex: 999999,
        width: 600
        //display: none;
    }
}))
const PlacesAutocomplete = ({ address, handleGetLocation }) => {
    const classes = useStyles();
    useEffect(() => {
        setValue(address)
    }, [])
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => {
                return results;
            })
            .then(async (data) => {
                //console.log(data[0])
                //console.log( await getLatLng(data[0]))
                //let {lat, lng} = await getLatLng(data[0]);
                let { lat, lng } = await getLatLng(data[0]);
                console.log("📍 Coordinates: ", { lat, lng });
                let details = null;
                const parameter = {
                    // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
                    placeId: data[0].place_id,
                    // Specify the return data that you want (optional)
                    //fields: ["name", "rating"],
                };
                await getDetails(parameter)
                    .then((details) => {
                        console.log("Details: ", details);
                        details = JSON.stringify(details);
                        let resultLocation = {
                            coordinate: {
                                latitude: lat,
                                longitude: lng
                            },
                            /*lat: lat,
                            lng: lng,*/
                            address_components: data[0].address_components,
                            formatted_address: data[0].formatted_address,
                            place_id: data[0].place_id,
                            types: data[0].types,
                            details: details
                        }
                        console.log("📍 Coordinates: ", { ...resultLocation });
                        handleGetLocation(description, resultLocation)
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });





            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)} style={{ cursor: 'pointer' }}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    return (
        <div ref={ref}>
            <TextField
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Quel est l'adresse ?"
                label={"Saisissez l'adresse du lieu"}
                type={"text"}
                fullWidth={true}
                variant={"outlined"}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    name: 'location',
                    id: 'location',
                }}
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && <div style={{ width: 600, position: 'relative' }}>
                <div className={classes.searchList}><ul style={{ listStyle: 'none' }}>{renderSuggestions()}</ul></div></div>}
        </div>
    );
};

export default PlacesAutocomplete