export const createMessage = (annonce) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)

    firebase.firestore().collection('projects').add({
      ...annonce,
      authorFirstName: profile.firstName,
      authorLastName: profile.name,
      authorId: authorId,
      createdAt: new Date()
    }).then(() => {
      dispatch({ type: 'CREATE_MESSAGES_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'CREATE_MESSAGES_ERROR' }, err);
    });
  }
};

export const loadInitConversationss = (limit) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    //console.log('test')
    //console.log(limit)
    //console.log(userId)
    //console.log(annonce)
    //console.log(role)

    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)
    if (userId) {
      firebase.firestore().collection('conversations')
        .where('members', 'array-contains', userId)
        .orderBy("lastMessageCreatedAt", "desc")
        .limit(limit)
        .get().then((querySnapshot) => {
          //if (snapshot.exists) {
          /* console.log(snapshot.data())
           //console.log(doc)
         const docData = snapshot.data();
         console.log(docData)
         const id = snapshot.id*/
          // .get().then((querySnapshot) => {
          console.log(querySnapshot)
          //if (querySnapshot.exists) {
          let newArr = []
          let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          //console.log(lastVisible)
          querySnapshot.forEach(function (doc) {
            //console.log(doc.id, " => ", doc.data());
            var convData = doc.data()
            var id = doc.id
            let newDoc = { id, ...convData }
            //console.log(mobileToCheck + "Exist In DB");
            newArr.push(newDoc)
          })
          //console.log(newArr)
          dispatch({
            type: 'LOAD_INIT_CONVERSATIONS_SUCCESS',
            userId: userId,
            lastVisible: lastVisible,
            payload: newArr
          })

        }).catch(err => {
          console.log(err)
          dispatch({ type: 'LOAD_INIT_ANNONCES_ERROR' }, err);
        })
    }
  }
};

export const loadInitConversations = (limit) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    //console.log('test')
   // console.log(limit)
    //console.log(userId)
    //console.log(annonce)
    //console.log(role)

    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)
    if (userId) {
      firebase.firestore().collection('conversations')
        .where('members', 'array-contains', userId)
        .orderBy("lastMessageCreatedAt", "desc")
        .limit(limit)
        .onSnapshot((querySnapshot) => {
          //if (snapshot.exists) {
          /* console.log(snapshot.data())
           //console.log(doc)
         const docData = snapshot.data();
         console.log(docData)
         const id = snapshot.id*/
          // .get().then((querySnapshot) => {
          //console.log(querySnapshot)
          //if (querySnapshot.exists) {
          let newArr = []
          let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          //console.log(lastVisible)
          querySnapshot.forEach(function (doc) {
           // console.log(doc.id, " => ", doc.data());
            var convData = doc.data()
            var id = doc.id
            let newDoc = { id, ...convData }
            //console.log(mobileToCheck + "Exist In DB");
            newArr.push(newDoc)
          })
          //console.log(newArr)
          dispatch({
            type: 'LOAD_INIT_CONVERSATIONS_SUCCESS',
            userId: userId,
            lastVisible: lastVisible,
            payload: newArr
          })

        }, err => {
          console.log(err)
          /*const items = firebase.firestore().collection('conversations')
            .where('members', 'array-contains', userId)
            .orderBy("lastMessageCreatedAt", "desc")
            .limit(limit);
          const unsubscribe = () => items.onSnapshot(() => { })

          unsubscribe()*/
          //dispatch({ type: 'LOAD_INIT_ANNONCES_ERROR' }, err);
        })
    }
  }
};


export const loadMoreConversations = (limit, startAfter) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    //console.log('test')
    //console.log(limit)
    //console.log(startAfter)
    //console.log(role)
    let newArr = []
    //console.log(annonce)
    //console.log(profile)
    //console.log(authorId)
    firebase.firestore().collection('conversations')
      .where('members', 'array-contains', userId)
      .orderBy("lastMessageCreatedAt", "desc")
      .startAfter(startAfter)
      .limit(limit)
      .get().then((querySnapshot) => {
        //console.log(querySnapshot)
        //if (querySnapshot.exists) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          var convData = doc.data()
          var id = doc.id
          let newDoc = { id, ...convData }
          //console.log(mobileToCheck + "Exist In DB");
          newArr.push(newDoc)
        });
        dispatch({
          type: 'LOAD_MORE_CONVERSATIONS_SUCCESS',
          userId: userId,
          payload: newArr
        });
      }).catch(err => {
        console.log(err)
        dispatch({ type: 'LOAD_MORE_CONVERSATIONS_ERROR' }, err);
      })
  }
};