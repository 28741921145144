import React from 'react'
import AnnonceSummaryList from './AnnonceSummaryList'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  list: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 800,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
}));


const AnnoncesList = ({ annonces }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List className={classes.list}>

        {annonces && annonces.map(annonce => {
          return (
            <AnnonceSummaryList annonce={annonce} key={annonce.id} />
          )
        })}
      </List>
    </div>
  )
}

export default AnnoncesList