import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'

import MaterialTable, { MTableToolbar, MTableEditRow } from 'material-table';
import {
    PaymentRequestButtonElement,
    //CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    IbanElement,
    injectStripe,
    address_zip,
    address
  } from 'react-stripe-elements';

  import TextField from '@material-ui/core/TextField';
  import FormHelperText from '@material-ui/core/FormHelperText';
  import FormControl from '@material-ui/core/FormControl';
  import Select from '@material-ui/core/Select';
  import Typography from '@material-ui/core/Typography';
import CardIcon from '@material-ui/icons/CreditCardOutlined'
import AccountIcon from '@material-ui/icons/AccountBalanceOutlined'
import str from '@stripe/stripe-js'
import moment from 'moment';
import * as yup from 'yup';
import CardSection from './CardSection';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
      flexGrow: 1
    },
    appbar: {
      alignItems: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1em'
    },
    cardElement: {
      borderColor: 'rgb(207, 215, 223)',
      borderStyle: 'solid',
      borderWidth: '0.05em',
      // backgroundColor: 'green',
      marginTop: '1em',
      padding: '11px 15px 11px 10px',
    },
    button: {
      // height: 95, // setting height/width is optional
      width: '50%'
    },
    label: {
      // Aligns the content of the button vertically.
      flexDirection: 'column'
    },
    icon: {
      fontSize: '32px !important',
      marginBottom: theme.spacing(1)
    },
    listTransaction: {
      width: '100%',
      //maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: '30vh',
    },
  });
  const schema = {
    cardNumber: yup.string()
      .label('Card number')
      .max(16)
      .required(),
    cvc: yup.string()
      .label('CVC')
      .min(3)
      .max(4)
      .required(),
    nameOnCard: yup.string()
      .label('Name on card')
      .required(),
    expiryMonth: yup.string()
      .label('Expiry month')
      .min(2)
      .max(2)
      .required(),
    expiryYear: yup.string()
      .label('Expiry year')
      .min(4)
      .max(4)
      .required(),
  };


  class CheckoutForm extends Component {
    constructor(props) {
      super(props);
      this.editing = true;
      this.state = {
        complete: false,
        open: false,
        editing: false,
        alertMessage: '',
        alertStyle: '',
        defaultCard: '',
        selectedCard: '',
        customerId: '',
        columns: [
          { title: 'Défaut', field: 'default', editable: 'onUpdate', type: 'boolean' },
          {
            title: 'Type', field: 'brand', editable: 'never',
            render: rowData => {
              if (rowData.object === "card") {
                //if (rowData.type !== "B") {
                return (
                  <CardIcon />
                );
                //}
                return <Typography variant="body1">{rowData.expiration}</Typography>;
              }
              return <Typography variant="body1">{rowData.type}</Typography>
              //return <CardCVCElement data={rowData.exp} />;
            },
          },
          {
            title: 'Numéro', field: 'last4', editable: 'onAdd', filterPlaceholder: "grrrrrrr", type: 'numeric',
            render: rowData => {
              if (rowData.config === "A") {
                //if (rowData.type !== "B") {
                return (
                  <>
                    <Typography variant="body1">****-****-****-{rowData.last4}</Typography>
                  </>
                );
                //}
                return <Typography variant="body1">****-****-****-{rowData.last4}</Typography>;
              }
              return <Typography variant="body1">****-****-****-{rowData.last4}</Typography>;
              //return <CardNumberElement data={rowData} />;
            },
            editComponent: props => {
              if (props.rowData.type === "A") {
                if (props.rowData.type !== "B") {
                  return (
                    <CardNumberElement id="exp"
                      onChange={e => {
                        props.onRowDataChange({
                          ...props.rowData,
                          cvc: e.target.value
                        },
                          this.setState({
                            ...this.state, stripeData: {
                              ...this.state.stripeData, newCreditCard: {
                                ...this.state.stripeData.newCreditCard, cvc: e.target.value
                              }
                            }
                          })
                        )
  
                      }
                      }
                      value={props.rowData.cvc} />
                    /*} <>
                       <Grid item xs={12} sm={12} md={12} lg={6}>
                         <TextField
                           id="pips"
                           onChange={e =>
                             props.onRowDataChange({
                               ...props.rowData,
                               pips: e.target.value
                             })
                           }
                           value={props.rowData.pips}
                         />
                       </Grid>
                       <Grid item xs={12} sm={12} md={12} lg={6}>
                         <TextField
                           id="percentage"
                           onChange={e =>
                             props.onRowDataChange({
                               ...props.rowData,
                               percentage: e.target.value
                             })
                           }
                           value={props.rowData && props.rowData.percentage}
                         />
                       </Grid>
                         </>*/
                  );
                }
                return (
                  <TextField
                    id="fixed"
                    onChange={e => props.onChange(e.target.value)}
                    value={props.rowData.fixed}
                  />
                );
              }
              return <CardNumberElement id="exp"
  
              />;
            }
            /*render: rowData => {
              console.log(rowData)
              if (rowData.tableData.editing) {
                return <CardNumberElement type="number" inputProps={{ maxLength: 16 }} placeholder={"****-****-****-" + rowData.last4}  onChange={(e) => this.setState({
                  ...this.state, stripeData: {
                    ...this.state.stripeData, newCreditCard: {
                      ...this.state.stripeData.newCreditCard, number: e.target.value
                    }
                  }
                })} />
              } else {
                return "****-****-****-" + rowData.last4
              }
            }*/
          },
  
          {
            title: 'Expiration', field: 'expiration',
            render: rowData => {
              if (rowData.config === "A") {
                //if (rowData.type !== "B") {
                return (
                  <>
                    <Typography variant="body1">{rowData.expiration}</Typography>
                  </>
                );
                //}
                return <Typography variant="body1">{rowData.expiration}</Typography>;
              }
              return <Typography variant="body1">{rowData.expiration}</Typography>
              //return <CardCVCElement data={rowData.exp} />;
            },
            editComponent: props => {
              if (props.rowData.type === "A") {
                if (props.rowData.type !== "B") {
                  return (
                    <CardExpiryElement id="exp"
                      onChange={e => {
                        props.onRowDataChange({
                          ...props.rowData,
                          cvc: e.target.value
                        },
                          this.setState({
                            ...this.state, stripeData: {
                              ...this.state.stripeData, newCreditCard: {
                                ...this.state.stripeData.newCreditCard, cvc: e.target.value
                              }
                            }
                          })
                        )
  
                      }
                      }
                      value={props.rowData.cvc} />
                    /*} <>
                       <Grid item xs={12} sm={12} md={12} lg={6}>
                         <TextField
                           id="pips"
                           onChange={e =>
                             props.onRowDataChange({
                               ...props.rowData,
                               pips: e.target.value
                             })
                           }
                           value={props.rowData.pips}
                         />
                       </Grid>
                       <Grid item xs={12} sm={12} md={12} lg={6}>
                         <TextField
                           id="percentage"
                           onChange={e =>
                             props.onRowDataChange({
                               ...props.rowData,
                               percentage: e.target.value
                             })
                           }
                           value={props.rowData && props.rowData.percentage}
                         />
                       </Grid>
                         </>*/
                  );
                }
                return (
                  <TextField
                    id="fixed"
                    onChange={e => props.onChange(e.target.value)}
                    value={props.rowData.fixed}
                  />
                );
              }
              return <CardExpiryElement id="exp"
  
              />;
            }
          },
          {
            title: 'CCV', field: 'cvc', //hidden: this.editing,
            render: rowData => {
              if (rowData.config === "A") {
                //if (rowData.type !== "B") {
                return (
                  <>
                    <Typography variant="body1">{rowData.cvc}</Typography>
                  </>
                );
                //}
                return <Typography variant="body1">{rowData.cvc}</Typography>;
              }
              return <Typography variant="body1">***</Typography>;
              //return <CardCVCElement data={rowData.cvc} />;
            },
            editComponent: props => {
              if (props.rowData.type === "A") {
                if (props.rowData.type !== "B") {
                  return (
                    <CardCVCElement id="cvc"
                      onChange={e => {
                        props.onRowDataChange({
                          ...props.rowData,
                          cvc: e.target.value
                        },
                          this.setState({
                            ...this.state, stripeData: {
                              ...this.state.stripeData, newCreditCard: {
                                ...this.state.stripeData.newCreditCard, cvc: e.target.value
                              }
                            }
                          })
                        )
  
                      }
                      }
                      value={props.rowData.cvc} />
                    /*} <>
                       <Grid item xs={12} sm={12} md={12} lg={6}>
                         <TextField
                           id="pips"
                           onChange={e =>
                             props.onRowDataChange({
                               ...props.rowData,
                               pips: e.target.value
                             })
                           }
                           value={props.rowData.pips}
                         />
                       </Grid>
                       <Grid item xs={12} sm={12} md={12} lg={6}>
                         <TextField
                           id="percentage"
                           onChange={e =>
                             props.onRowDataChange({
                               ...props.rowData,
                               percentage: e.target.value
                             })
                           }
                           value={props.rowData && props.rowData.percentage}
                         />
                       </Grid>
                         </>*/
                  );
                }
                return (
                  <TextField
                    id="fixed"
                    onChange={e => props.onChange(e.target.value)}
                    value={props.rowData.fixed}
                  />
                );
              }
              return <CardCVCElement id="cvc"
  
              />;
            }
            /*render: rowData => {
              console.log(rowData)
              if (rowData.tableData.editing) {
                return <CardCVCElement data={rowData} />
              } else {
                return "***"
              }
            }*/
            /* editComponent: props => {
               // this.setState({editing: true})
               this.editing = false;
               console.log(this.editing)
               return (
                 <CardCVCElement value={}
                 />
               )
             },*/
          },
          // { title: 'Mois expiration', field: 'exp_month', initialEditValue: 'initial edit value' },
          //  { title: 'Année expiration', field: 'exp_year', initialEditValue: 'initial edit value' },
          //{ title: 'CCV', field: 'cvc', type: 'numeric' },
        ],
        data: this.props.data
      };
      // this.submit = this.submit.bind(this);
    }
    componentDidUpdate (prevProps, prevState) {
        if(prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data
            })
        }
    }

    render () {
        return (
            <MaterialTable
                title="Vos cartes "
                columns={this.state.columns}
                data={this.state.data}
                editable={{
                  /* onRowAdd: newData =>
                     new Promise((resolve, reject) => {
                       setTimeout(() => {
                         {
   
                           console.log(newData)
                           const data = this.state.data;
                           data.push(newData);
   
                           this.setState({
                             ...this.state, stripeData: {
                               ...this.state.stripeData, newCreditCard: {
                                 number: newData.last4,
                                 cvc: newData.cvc,
                                 exp_month: 9,
                                 exp_year: 2025,
                                 address_zip: '21360',
                                 error: ''
                               }
                             }
                           }, () => {
                             this.submitNewCreditCard();
                           });
                           this.setState({ data }, () => resolve());
                         }
                         resolve()
                       }, 1000)
                     }),*/
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          let newArray = [];
                          const data = this.state.data;
                          const index = data.indexOf(oldData);
                          console.log(index)
                          data[index] = newData;
                          console.log(data)
                          console.log(data)
                          //this.setState({ data }, () => resolve());
                          this.setState({ defaultCard: newData.id })
                          data.map((item, idx) => {
                            console.log(item.id)
                            console.log(this.state.defaultCard)
                            if (item.id === this.state.defaultCard) {
                              newArray.push({ ...item, default: true })
                            }
                            else {
                              newArray.push({ ...item, default: false })
                            }
                          })
                          this.setState({ data: [...newArray] }, () => resolve());
                          this.props.firebase.firestore().collection('stripe_customers').doc(`${this.props.auth.uid}`).update({ defaultCard: newData.id })
                        }
                        resolve()
                      }, 1000)
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {

                          this.props.firebase.firestore().collection('stripe_customers').doc(`${this.props.auth.uid}`).collection('sources').doc(oldData.fingerprint).delete();
                          this.props.firebase.firestore().collection('stripe_customers').doc(`${this.props.auth.uid}`).collection('tokens').doc(oldData.id).delete();
                          let data = this.state.data;
                          const index = data.indexOf(oldData);
                          data.splice(index, 1);


                          this.setState({ data }, () => resolve());
                        }
                        resolve()
                      }, 1000)
                    }),
                }}
                components={{
                  /* Action: props => (
                     <Button
                       onClick={(event) => props.action.onClick(event, props.data)}
                       color="primary"
                       variant="contained"
                       style={{textTransform: 'none'}}
                       size="small"
                     >
                       My Button
                     </Button>
                   ),*/
                  /*EditRow: props => {
                   // this.setState({editing: true})
                    return (
                    <MTableEditRow />
                  )},*/
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}'
                  },
                  toolbar: {
                    nRowsSelected: '{0} ligne(s) selectionnée(s)',
                    searchPlaceholder: 'Recherche',
                    searchTooltip: 'Recherche'
                  },
                  header: {
                    actions: 'Actions'
                  },
                  body: {
                    addTooltip: 'Ajouter',
                    deleteTooltip: 'Supprimer',
                    editTooltip: 'Modifier',
                    emptyDataSourceMessage: 'Aucune(s) carte(s) enregistrée(s)',
                    filterRow: {
                      filterTooltip: 'Filtre'
                    },
                    editRow: {
                      deleteText: 'Etes vous sûre de vouloir supprimer cette carte ?',
                      cancelTooltip: 'Annuler',
                      saveTooltip: 'Enregistrer'
                    }
                  }
                }}
                actions={[
                  {
                    icon: 'add_box',
                    tooltip: 'Add User',
                    isFreeAction: true,
                    onClick: (event) => this.props.handleClickDialog()//alert("You want to add a new row")
                  }
                ]}
                options={{
                  //selection: true
                }}
              />
        )
    }
}

export default compose(
    withStyles(styles),
    //connect(mapStateToProps),
    //firebaseConnect(),
    //firestoreConnect(),
  )(CheckoutForm)