import React, { useEffect, useState, Fragment } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar, MTableAction, MTableActions } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon'
import ImageIcon from '@material-ui/icons/ImageRounded'
import { fade, makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase'
import { Typography } from '@material-ui/core';
import Sellsy from 'node-sellsy'

//var Sellsy = require('node-sellsy');
var sellsy = new Sellsy({
    creds: {
        consumerKey: '4a9663ec9bdc9d51a5ecb27ad301f76a73e76f00',
        consumerSecret: '0986eed5876f3cfd3c7a769be4b27170f8f780f5',
        userToken: 'bf6af015de7da545512b7f3b3a34700cb667d30a',
        userSecret: 'f085896b21aa1229df3040df254756ef84c693aa'
    },

});




const SettingsSellsy = (props) => {
    const firebase = useFirebase();
    const [methodeValue, setMethodeValue] = React.useState('Peoples.create')
    const setMethode = (event) => {
        console.log(event.target.value);
        setMethodeValue(event.target.value);
    }
    const [paramsValue, setParamsValue] = React.useState({
        /* "search": {
             "contains": "avocat"
         },*/
        /*"pagination": {
            "nbperpage": 1,
        }*/
        "people": { "name": "pierre test" }
    })
    const [paramsValueJson, setParamsValueJson] = React.useState({
        /* "search": {
             "contains": "avocat"
         },*/
        people: {
            name: "pierre test",
        }
    })
    const setParams = (event) => {
        console.log(event.target.value);
        let parameters = JSON.parse(event.target.value)
        setParamsValue(JSON.stringify(parameters));
        setParamsValueJson(parameters);
        //setParamsValue(JSON.parse(event.target.value));
    }
    const [responseSellsy, setResponseSellsy] = React.useState('');
    const getSellsy = async () => {
        var paramsJson = paramsValueJson;
        console.log(paramsJson);
        //console.log(JSON.parse(paramsValue));
        //var params = JSON.parse(paramsValue[0]);
        var data = {
            params: paramsJson,
            method: methodeValue
        }
        const token = await firebase.auth().currentUser.getIdToken()
        const response = await fetch(
            'https://us-central1-oq70-objectifquebec.cloudfunctions.net/getSellsyData',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                method: 'post',
                body: JSON.stringify({ data })
            }
        )
        const json = await response.json();
        /*response.then(response =>
 console.log(response)
             response.json()
         )
             .then(data =>
                 this.setState({ hits: data.hits })
             );*/
        console.log(response);
        console.log(json);
        setResponseSellsy(json);
    }

    return (
        <>
            <Typography>NE PAS UTILISER C'EST UN PARAMETRE DEVELOPPEUR !!!</Typography>
            <TextField label={"Méthode"} value={methodeValue} onChange={setMethode} />
            <TextField label={"Parametres"} defaultValue={JSON.stringify(paramsValue)} onChange={setParams} fullWidth multiline rows={10} />
            <Button onClick={getSellsy}>Test Sellsy</Button>
            <Typography>Resultat</Typography>
            {JSON.stringify(responseSellsy)}
        </>
    )
}

export default SettingsSellsy