const initState = {
  adminsUsers: [],
  entreprisesUsers: [],
  candidatsUsers: [],
  annoncesUser: []
}

const usersReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_USERS_SUCCESS':
      console.log('create USERS success');
      return state;
    case 'CREATE_USERS_ERROR':
      console.log('create USERS error');
      return state;
    case 'LOAD_INIT_USERS_SUCCESS':
      if (action.role === 0) {
        return {
          ...state,
          adminsUsers: [...action.payload]
        }
      }
      if (action.role === 1) {
        return {
          ...state,
          entreprisesUsers: [...action.payload]
        }
      }
      if (action.role === 2) {
        return {
          ...state,
          candidatsUsers: [...action.payload]
        }
      }
    case 'LOAD_INIT_USERS_ERROR':
      if (action.err) {
        console.log('load more USERS error', action.err);
        return action.err;
      }
      return state;
    case 'LOAD_MORE_USERS_SUCCESS':
      //console.log('load more USERS success ', action);
      //console.log('load more USERS success ', action.payload);
      //console.log('load more USERS success ', state.candidatsUsers);
      //console.log('load more annonce success ', action.payload.data());
      //return action.payload;
      if (action.role === 0) {
        return {
          ...state,
          adminsUsers: [...state.adminsUsers, ...action.payload]
        }
      }
      if (action.role === 1) {
        return {
          ...state,
          entreprisesUsers: [...state.entreprisesUsers, ...action.payload]
        }
      }
      if (action.role === 2) {
        return {
          ...state,
          candidatsUsers: [...state.candidatsUsers, ...action.payload]
        }
      }
    case 'LOAD_MORE_USERS_ERROR':
      console.log('load more USERS error', action.err);
      return action.err;
    case 'LOAD_ADVANCED_SEARCH_USERS_SUCCESS':
      //console.log('load more USERS success ', action);
      //console.log('load more USERS success ', action.payload);
      //console.log('load more USERS success ', state.candidatsUsers);
      //console.log('load more annonce success ', action.payload.data());
      //return action.payload;
      if (action.role === 0) {
        return {
          ...state,
          adminsUsers: [...action.payload]
        }
      }
      if (action.role === 1) {
        return {
          ...state,
          entreprisesUsers: [...action.payload]
        }
      }
      if (action.role === 2) {
        return {
          ...state,
          candidatsUsers: [...action.payload]
        }
      }
    case 'LOAD_ADVANCED_SEARCH_USERS_ERROR':
      console.log('load more USERS error', action.err);
      return state;
    case 'LOAD_ANNONCES_USERS_SUCCESS':
      //console.log('load more USERS success ', action);
      //console.log('load more USERS success ', action.payload);
      //console.log('load more USERS success ', state.candidatsUsers);
      //console.log('load more annonce success ', action.payload.data());
      //return action.payload;
      return {
        ...state,
        annoncesUser: [...action.payload]
      }
    case 'LOAD_ANNONCES_USERS_ERROR':
      console.log('load ANNONCES USERS error', action.err);
      return state;
    default:
      return state;
  }
};

export default usersReducer;