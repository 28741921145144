
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Navbar from './components/layout/Navbar'
import Dashboard from './components/dashboard/Dashboard'
import DashboardEntreprise from './components/dashboard/DashboardEntreprise'
import DashboardCandidat from './components/dashboard/DashboardCandidat'
import Annonces from './components/annonces/AnnoncesGrid'
import AnnoncesEntreprise from './components/annonces/AnnoncesGridEntreprise'
import AnnoncesCandidat from './components/annonces/AnnoncesGridCandidat'
import AnnonceDetails from './components/annonces/AnnonceDetails'
import Feeds from './components/feeds/FeedsScreen'
import Tourismes from './components/tourismes/TourismesScreen'
import Relations from './components/relations/RelationsScreen'
import Expatriations from './components/expatriations/ExpatriationsScreen'
import Outils from './components/outils/OutilsScreen'
import Emplois from './components/emplois/EmploisScreen'
import Etudes from './components/etudes/EtudesScreen'
import Boutique from './components/boutique/BoutiqueScreen'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import CreateAnnonce from './components/annonces/CreateAnnonce'
import MonCompte from './components/account/MonCompte'
import UsersList from './components/users/UsersList'
import UsersListEnt from './components/users/UsersListEnt'
import Settings from './components/settings/Settings'
import UsersDetails from './components/users/UserDetails'
import ChatList from './components/messages/ChatList'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { drawerWidthDefault } from './constants/default'
import { acDrawerOpen } from './store/actions/navActions'
const drawerWidth = drawerWidthDefault;

const useStyles = theme => ({
  //offset: theme.mixins.toolbar,
  App: {
    //display: 'flex',
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: !this.props.auth.uid ? 0 : 40,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth - 50,
  },
});



class App extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  BackButton = () => {
    let history = useHistory()
    history.goBack()
    /*return (
      <button type="button" id="test" onClick={() => history.goBack()}>
      </button>
    )*/
  }
  handleDrawerOpen = () => {
    //console.log("open drawer")
    acDrawerOpen(!this.props.navReducer.drawerOpen);
    this.setState({
      open: true
    })
    //setOpen(true);
  };

  handleDrawerClose = () => {
    //console.log("close drawer")
    this.setState({
      open: false
    })
    //setOpen(false);
  };
  render() {
    if (this.props.monProfil) {
      //console.log(this.props.monProfil.role)
    }
    if (!this.props.auth.uid) {
      console.log("no uid")
    }


    //console.log(this.props)
    //console.log(this.props.auth);
    //console.log(this.props.monProfil);
    const { classes } = this.props;
    return (

      <BrowserRouter>
        <div className={classes.App}>
          {this.props.auth.uid &&
            <Navbar handleDrawerOpen={this.handleDrawerOpen} handleDrawerClose={this.handleDrawerClose} open={this.state.open} />
          }
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: this.state.open,
            })}
            style={{ marginLeft: !this.props.auth.uid ? 0 : 40, padding: !this.props.auth.uid ? 0 : 30, }}
          >
            <Switch style={{ marginLeft: '80px' }}>
              {isLoaded(this.props.auth) && !isEmpty(this.props.auth) && isLoaded(this.props.monProfil) && !isEmpty(this.props.monProfil) &&
                <React.Fragment>
                  {this.props.monProfil.role === 0 &&
                    <React.Fragment>
                      <Route exact path='/' component={Dashboard} />
                      <Route path='/annonces' component={Annonces} />
                      <Route path='/feeds' component={Feeds} />
                      <Route path='/tourismes' component={Tourismes} />
                      <Route path='/relations' component={Relations} />
                      <Route path='/etudes' component={Etudes} />
                      <Route path='/expatriations' component={Expatriations} />
                      <Route path='/outils' component={Outils} />
                      <Route path='/emplois' component={Emplois} />
                      <Route path='/boutique' component={Boutique} />
                    </React.Fragment>}
                  {this.props.monProfil.role === 1 &&
                    <React.Fragment>
                      <Route exact path='/' component={DashboardEntreprise} />
                      <Route path='/annonces' component={AnnoncesEntreprise} />
                      <Route path='/userscandidats' component={UsersListEnt} />
                      

                    </React.Fragment>}
                  {this.props.monProfil.role === 2 &&
                    <React.Fragment>
                      <Route exact path='/' component={DashboardCandidat} />
                      <Route path='/annonces' component={AnnoncesCandidat} />

                    </React.Fragment>}

                  <Route path='/signin' component={SignIn} />
                  <Route path='/signup' component={SignUp} />
                  {/*<Route path='/annonces' component={Annonces} />*/}
                  {/*<Route path='/annonce/:id' component={AnnonceDetails} />*/}
                  <Route path='/create' component={CreateAnnonce} />
                  <Route path='/users' component={UsersList} />
                  <Route path='/user/:userid' component={UsersDetails} />
                  <Route path='/moncompte' component={MonCompte} />
                  <Route path='/messages' component={ChatList} />
                  <Route path='/settings' component={Settings} />
                </React.Fragment>
              }

              {isLoaded(this.props.auth) && isEmpty(this.props.auth) &&

                <React.Fragment>
                   <Route exact path='/' component={Dashboard} />
                  <Route path='/signin' component={SignIn} />
                  <Route path='/signup' component={SignUp} />


                  {/*<Route path='/annonces' component={Annonces} />
                <Route exact path='/' component={Dashboard} />
              <Route path='/create' component={CreateAnnonce} />
              <Route path='/moncompte' component={MonCompte} />
              <Route path='/create' component={CreateAnnonce} />
              <Route path='/users' component={UsersList} />
              <Route path='/moncompte' component={MonCompte} />
              <Route path='/messages' component={ChatList} />
              <Route path='/settings' component={Settings} />*/}
                </React.Fragment>

              }

            </Switch>
          </main>
        </div>
      </BrowserRouter>

    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    // annonces: state.firestore.ordered.annonces,
    auth: state.firebase.auth,
    constants: state.firebase.data.constants,
    monProfil: state.firestore.data.monProfil,
    settings: state.firestore.ordered.settings,
    lesEntreprises: state.firestore.ordered.lesEntreprises,
    navReducer: state.navReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acDrawerOpen: (term) => dispatch(acDrawerOpen(term))
  }
}
export default compose(

  withStyles(useStyles, {
    name: 'App',
  }),
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect((props, store) => {
    //console.log(props)
    //console.log(store)
    //console.log("ici1")
    if (props.auth.uid) {
      return [
        { path: 'compteurs' }, // make sure to pass "populates"
        { path: 'constants' }, // make sure to pass "populates"
      ]
    }
    else {
      return []
    }
  }),
  firestoreConnect(props => {
    //console.log("ici2")
    if (props.auth.uid) {
      return [
        /* {
           collection: 'annonces',
           orderBy: ['createdAt', 'desc'],
           limit: 3
         },*/
        { collection: 'users', doc: props.auth.uid, storeAs: 'monProfil' },
        { collection: 'settings' },
        {
          collection: 'users',
          where: [['role', '==', 1]],
          orderBy: ['companyName', 'asc'],
          //limit: 10,
          storeAs: 'lesEntreprises'
      },
      ]
    } else {
      return []
    }

  }),

  /*firestoreConnect([
  {collection: 'annonces' },
    limit: 5
])*/
)(App)
//export default App;