import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import AnnonceSummaryCard from './AnnonceSummaryCard'
import CreateAnnonce from './CreateAnnonce'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/ClearOutlined';
/*DIALOG*/
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

/*MENU*/
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { deleteAnnonce, loadInitAnnonces, loadMoreAnnonces } from '../../store/actions/annonceActions'

const ITEM_HEIGHT = 48;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`annonce-tabpanel-${index}`}
      aria-labelledby={`annonce-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `annonce-tab-${index}`,
    'aria-controls': `annonce-tabpanel-${index}`,
  };
}


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
    margin: 4,
    //minHeight: '300px'
    /* display: "flex",
     flexDirection: "column",
     justifyContent: "space-between"*/
  },
  title: {
    padding: 4,
    textAlign: 'center'
  },
  contentBody: {
    maxWidth: '1600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: '80vh',
    overflowY: 'scroll'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  link: {
    textDecoration: 'none'
  },
  clearIcon: {
    cursor: 'pointer'
  }
}));

const AnnoncesGridCandidat = ({ auth, annoncesActives, annoncesInactives, monProfil, deleteAnnonce, loadMoreAnnonces, loadInitAnnonces }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [valueType, setValueType] = React.useState("annonce");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [annoncesActivesResults, setannoncesActivesResults] = React.useState([]);
  const [annoncesInactivesResults, setannoncesInactivesResults] = React.useState([]);

  const [duplicateAnnonce, setDuplicateAnnonce] = useState("");     // second, we create a new useState Hook for our filter
  console.log(annoncesActives)

  /*const { annoncesActivesResults} = useSelector(state => ({
    annoncesActivesResults: state.annoncesActives,
    //user: state.user,
  }));*/
  const [data, setData] = useState([]);
  const [enteredFilter, setEnteredFilter] = useState("");     // second, we create a new useState Hook for our filter
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    console.log(value)
    if (value === 0) {
      let filteredData = annoncesActives && annoncesActives.filter(item => {
        // console.log(item)   // third, we defined our filteredData array
        // console.log(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        return (
          item.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0 ||
          item.reference.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0 ||
          item.localisation.ville.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0
        )
      });
      setannoncesActivesResults(filteredData);                                         // fifth, we called out fetchData function inside useEffect Hook

    }
    if (value === 1) {
      let filteredData = annoncesInactives && annoncesInactives.filter(item => {
        // console.log(item)   // third, we defined our filteredData array
        // console.log(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        return (
          item.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0 ||
          item.reference.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0 ||
          item.localisation.ville.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0
        )
      });
      setannoncesInactivesResults(filteredData);                                         // fifth, we called out fetchData function inside useEffect Hook

    }

  }, [enteredFilter]);                                       // sixth, we set enteredFilter as a dependency


  useEffect(() => {

    loadInitAnnonces(25, 0);
    loadInitAnnonces(25, 1);
  }, []);

  useEffect(() => {
    setannoncesActivesResults(annoncesActives)
  }, [annoncesActives]); // N’exécute l’effet que si annoncesActives a changé

  useEffect(() => {
    setannoncesInactivesResults(annoncesInactives)
  }, [annoncesInactives]); // N’exécute l’effet que si annoncesInactives a changé


  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);

    //clickCreateAnnonce()
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const clickCreateAnnonce = (type) => {
    console.log(type)
    setValueType(type);
    handleCloseMenu()
    handleClickOpen()
  };

  const clickDuplicateAnnonce = (annonce) => {
    console.log(annonce.type)
    setDuplicateAnnonce(annonce)
    setValueType(annonce.type);
    handleCloseMenu()
    handleClickOpen()
  };

  const clickDeleteAnnonce = (annonceId) => {
    console.log(annonceId)
    deleteAnnonce(annonceId)
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = () => {
    setannoncesActivesResults(annoncesActives)
  };
  const clearSearch = () => {
    setEnteredFilter("")
  };
  const loadMoreItems = (event) => {
    console.log(annoncesActivesResults)

    if (event.target.offsetHeight + event.target.scrollTop === (event.target.scrollHeight /*- event.target.scrollTop*/)) {
      console.log('loadMore end')
      console.log(annoncesActives.length)
      console.log(annoncesActives[annoncesActives.length - 1].createdAt)
      //user is at the end of the list so load more items
      // let more = loadMoreAnnonces(25, annoncesActives[annoncesActives.length - 1].createdAt, 1)
      //loadMoreUsers(parseInt(event.currentTarget.dataset.role, 10), 25, adminsUsers[adminsUsers.length - 1].dateInscription);
      //setannoncesActivesResults(more)
      setTimeout(() => {
        //setannoncesActivesResults(loadMoreAnnonces(25, annoncesActives[annoncesActives.length - 1].createdAt, 1))
        //setannoncesActivesResults(more)
        //adminsUsersSection = fromArrayToSectionData(adminsUsers)
        setIsFetching(false);
      }, 2000);
    }
  }

  if (!auth.uid) return <Redirect to='/signin' />
  if (!isLoaded(annoncesActives) || !isLoaded(annoncesInactives)) {
    return (
      <div className={classes.root}>
        <Grid container spacing={6} justify="center" alignItems="center">
          <CircularProgress className={classes.progress} />
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom className={classes.title}>
        Les Annonces
      </Typography>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="annonce Tabs" style={{ alignSelf: 'center' }}>
          <Tab label="Actives" {...a11yProps(0)} />
          <Tab label="Inactives" {...a11yProps(1)} />
        </Tabs>
        <Toolbar style={{ position: 'absolute', right: 0, minHeight: '56px' }}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Recherche…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={enteredFilter}
              onChange={e => setEnteredFilter(e.target.value)}
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                enteredFilter &&
                <InputAdornment position="end" onClick={clearSearch} classes={classes.clearIcon}>
                  <ClearIcon />
                </InputAdornment>
              }
            />
          </div>
          <div className={classes.grow} />
          {monProfil && monProfil.role === 0 &&
            <Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={handleClickMenu} >
              <AddIcon />
            </Fab>
          }
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            <MenuItem key={"annonce"} onClick={() => clickCreateAnnonce("emploi")}>
              {"Emploi"}
            </MenuItem>
            <MenuItem key={"formation"} onClick={() => clickCreateAnnonce("formation")}>
              {"Formation"}
            </MenuItem>
            <MenuItem key={"stage"} onClick={() => clickCreateAnnonce("stage")}>
              {"Stage"}
            </MenuItem>
          </Menu>
          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
      <TabPanel value={value} index={0}
        onChange={handleChange}>
        <Grid container spacing={3} justify="center" className={classes.contentBody} onScroll={loadMoreItems}>

          {
            annoncesActivesResults && annoncesActivesResults.map(annonce => {
              return (
                <Grid item xs={2} key={annonce.id} className={classes.card}>
                  <AnnonceSummaryCard annonce={annonce} monProfil={monProfil} deleteAnnonce={clickDeleteAnnonce} duplicateAnnonce={clickDuplicateAnnonce} />
                </Grid>
              )
            })

          }
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}
        onChange={handleChange}>
        <Grid container spacing={3} justify="center" className={classes.contentBody} onScroll={loadMoreItems}>

          {annoncesInactivesResults ? annoncesInactivesResults.map(annonce => {
            return (
              <Grid item xs={2} key={annonce.id} className={classes.card}>
                <AnnonceSummaryCard annonce={annonce} monProfil={monProfil} deleteAnnonce={clickDeleteAnnonce} />
              </Grid>
            )
          })
            : "Aucune annonce inactive"
          }
        </Grid>
      </TabPanel>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      //style={{maxWidth: '100%'}}
      //fullWidth={fullWidth}
      //minWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">Nouvelle {valueType}</DialogTitle>
        <DialogContent>
          {/*} <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>*/}
          <CreateAnnonce onCancel={handleClose} annonceType={valueType} duplicateAnnonce={duplicateAnnonce} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {

    auth: state.firebase.auth,
    monProfil: state.firestore.data.monProfil,
    annoncesActives: state.annonce.annoncesActives,
    annoncesInactives: state.annonce.annoncesInactives
    /*annoncesActives: state.firestore.ordered.annoncesActives,
    annoncesInactives: state.firestore.ordered.annoncesInactives,*/
  }
}
const mapDispatchToProps = dispatch => {
  return {
    deleteAnnonce: (annonceId) => dispatch(deleteAnnonce(annonceId)),
    loadInitAnnonces: (limit, statut) => dispatch(loadInitAnnonces(limit, statut)),
    loadMoreAnnonces: (limit, startAfter, statut) => dispatch(loadMoreAnnonces(limit, startAfter, statut))
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
  firestoreConnect(/*props => [
    {
      collection: 'annonces',
      where: [['statut', '==', 1]],
      orderBy: ['createdAt', 'desc'],
      limit: 25,
      storeAs: 'annoncesActives'
    },
    {
      collection: 'annonces',
      where: [['statut', '==', 0]],
      orderBy: ['createdAt', 'desc'],
      limit: 25,
      storeAs: 'annoncesInactives'
    },
  ]*/),
)(AnnoncesGridCandidat)