import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { createAnnonce } from '../../store/actions/annonceActions'
import { Redirect } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { annoncesValues, annoncesFields, formationsFields, stagesFields } from '../../constants/formField'
import Form from '../../utils/formBuilder';
import TypeEmploi from './createAnnonce/typeEmploi'
import TypeEmploiEntreprise from './createAnnonce/typeEmploiEntreprise'
import TypeFormation from './createAnnonce/typeFormation'
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));


const CreateAnnonce = ({ auth, /*lesEntreprises,*/ monProfil, onCancel, annonceType, createAnnonce, duplicateAnnonce }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    title: '',
    description: '',
    description_formatted: '',
    competences: '',
    competences_formatted: '',
    conditions: '',
    conditions_formatted: '',
    description_qc: '',
    description_formatted_qc: '',
    competences_qc: '',
    competences_formatted_qc: '',
    conditions_qc: '',
    conditions_formatted_qc: '',
    idEntreprise: '',
    nomEntreprise: '',
    entree: '',
    duree: '',
    region: '',
    localisation: {
      ville: ''
    },
    reference: '',
    salaire: '',
    statut: 0,
    type: '',
  });
  console.log(monProfil)
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    if (name === "entreprise") {
      console.log(event.target)
      setValues({ ...values, 'idEntreprise': event.target.value, 'nomEntreprise': '' });
    }
  };
  const validate = ({ title, secteurActivite, salaire, idEntreprise, entree, duree, description, annonceType }) => {
    if (annonceType === "emploi") {
      return {
        title: !title || title.trim().length === 0
          ? "Le titre est obligatoire!"
          : false,
        salaire:
          !salaire || salaire.trim().length === 0
            ? "Le Salaire est obligatoire!"
            : false,
        secteurActivite:
          !secteurActivite || secteurActivite.trim().length === 0
            ? "Le secteur est obligatoire!"
            : false,
        idEntreprise: !idEntreprise || idEntreprise.trim().length === 0
          ? "L'entreprise' est obligatoire!" 
          : false,
        entree:
          !entree || entree.trim().length === 0
            ? "L'entrée est obligatoire!"
            : false,
        description:
          !description || description.trim().length === 0
            ? "La description est obligatoire!"
            : false,
      };
    }
    if (annonceType === "formation") {
      return {
        title: !title || title.trim().length === 0
          ? "Le titre est obligatoire!"
          : false,
        duree:
          !duree || duree.trim().length === 0
            ? "La durée de la formation est obligatoire!"
            : false,
        /*salaire:
          !salaire || salaire.trim().length === 0
            ? "Le Salaire est obligatoire!"
            : false,*/
        secteurActivite:
          !secteurActivite || secteurActivite.trim().length === 0
            ? "Le secteur est obligatoire!"
            : false,
        /*entree:
          !entree || entree.trim().length === 0
            ? "L'entrée est obligatoire!"
            : false,*/
        description:
          !description || description.trim().length === 0
            ? "La description est obligatoire!"
            : false,
      };
    }

  };
  /* const handleChange = (e) => {
     this.setState({
       [e.target.id]: e.target.value
     })
   }*/
  const cancelForm = () => {
    //alert('cancel')
    onCancel()
  }
  const handleSubmit = (annonceValues) => {
    //e.preventDefault();
    console.log(annonceValues);

    //OQ-2019-09-000046
    createAnnonce(annonceValues, annonceType);
    onCancel()
    //this.props.history.push('/');
  }
  /*console.log(annonceType)
  console.log(annoncesFields)
  console.log(annoncesFields.key)*/

  /*lesEntreprises && annoncesFields && annoncesFields.map((field, index) => {
  if(field.key === "entreprise") {
    
    //const items = lesEntreprises
    console.log(lesEntreprises)
    field["items"] = lesEntreprises
    console.log(field)
  }
})*/
  //render() {
  //const { auth } = this.props;
  if (!auth.uid) return <Redirect to='/signin' />
  return (
    <form noValidate>
      {annonceType && annonceType === "emploi" && monProfil &&
        monProfil.role === 0 &&
        <TypeEmploi cancelForm={cancelForm}
          cancelFormButtonName={"Annuler"}
          saveForm={handleSubmit}
          saveFormButtonName={"Créer"}
          duplicateAnnonce={duplicateAnnonce}
        />
      }
      {annonceType && annonceType === "emploi" && monProfil && monProfil.role === 1 &&
        <TypeEmploiEntreprise cancelForm={cancelForm}
          cancelFormButtonName={"Annuler"}
          saveForm={handleSubmit}
          saveFormButtonName={"Créer"}
          duplicateAnnonce={duplicateAnnonce}
          idEntreprise={auth.uid}
          nomEntreprise={monProfil.fullName}
        />
        /*<Form
          fieldContainerStyle={{ backgroundColor: '#fff', padding: 10 }}
          //inputContainerStyle={{ margin: '30px 0' }}
          onChange={(values) => console.log(values)}
          orientation={'horizontal'}
          onDelayedChange={(values) => console.log('delayed values: ', values)}
          delayTriggers={['simpleTextField']}
          delayTime={300}
          fields={annoncesFields}
          //values={values}
          //lesEntreprises={lesEntreprises}
          actionContainerStyle={{ backgroundColor: '#fff', padding: 10 }}
          cancelForm={cancelForm}
          cancelFormButtonName={"Annuler"}
          saveForm={handleSubmit}
          saveFormButtonName={"Créer"}
          errors={{ simpleTextFieldWithError: 'This is an error message.' }}
  />*/}
      {annonceType && annonceType === "formation" && monProfil &&
        monProfil.role === 0 &&
        <TypeFormation cancelForm={cancelForm}
          cancelFormButtonName={"Annuler"}
          saveForm={handleSubmit}
          saveFormButtonName={"Créer"}
          duplicateAnnonce={duplicateAnnonce}
        />
      }
      {annonceType && annonceType === "stage" &&
        <Form
          fieldContainerStyle={{ backgroundColor: '#fff', padding: 10 }}
          //inputContainerStyle={{ margin: '30px 0' }}
          onChange={(values) => console.log(values)}
          orientation={'horizontal'}
          onDelayedChange={(values) => console.log('delayed values: ', values)}
          delayTriggers={['simpleTextField']}
          delayTime={300}
          fields={stagesFields}
          //values={values}
          actionContainerStyle={{ backgroundColor: '#fff', padding: 10 }}
          cancelForm={cancelForm}
          cancelFormButtonName={"Annuler"}
          saveForm={handleSubmit}
          saveFormButtonName={"Créer"}
          errors={{ simpleTextFieldWithError: 'This is an error message.' }}
        />}

    </form>


  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    monProfil: state.firestore.data.monProfil
    //lesEntreprises: state.firestore.ordered.lesEntreprises
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createAnnonce: (annonce, annonceType) => dispatch(createAnnonce(annonce, annonceType))
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
  firestoreConnect(props => [
    /*{
      collection: 'users',
      where: [['role', '==', 1]],
      //orderBy: ['createdAt', 'desc'],
      //limit: 10,
      storeAs: 'lesEntreprises'
    },*/
    /*{
      collection: 'annonces',
      where: [['statut', '==', 1]],
      orderBy: ['createdAt', 'desc'],
      limit: 10,
      storeAs: 'annoncesActives'
    },
    {
      collection: 'annonces',
      where: [['statut', '==', 0]],
      orderBy: ['createdAt', 'desc'],
      limit: 10,
      storeAs: 'annoncesInactive'
    },*/
  ]),
)(CreateAnnonce)