import React from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


import _ from 'lodash'
import moment from 'moment'

const conversationsStyles = theme => ({
  root: {
    overflowY: 'scroll',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '8px',
      backgroundColor: '#FFF'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#025279' //'#23232F' //'#0595DD'
    },
  },
  subHeader: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    boxShadow: '0px 5px 15px black'
  },
  listitemtext: {
    paddingLeft: '10px',
    color: "white"
  },
  lastMessage: {
    color: "black"
  },
  date: {
    color: "white"
  }
});

class ConversationsList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      //activeLike:'',
      //nblikes: ''
      selected: null,
      loadMore: this.props.loadMore,
      constants: this.props.constants,
      settings: this.props.settings
    };
  }

  componentDidUpdate(prevProps) {

    if (this.props.constants !== prevProps.constants) {
      console.log("new convs")
       this.setState({ constants: this.props.constants });
    }
    if (this.props.settings !== prevProps.settings) {
      console.log("new settings")
       this.setState({ settings: this.props.settings });
    }

  }

  render() {

    const { classes, constants, listClick, settings, compteurs, Listes } = this.props;
    console.log(constants)
    console.log(Listes)
    console.log(settings)
    return (
      <List className={classes.root} //onScroll={this.props.onScroll} 
      subheader={
        <ListSubheader component="div" id="nested-list-subheader"  className={classes.subHeader}>
          Listes de choix
        </ListSubheader>
      }
      >
        {Listes && settings && Listes.map((item, index) => {
          
          //const answered = item.lastMessageSenderId  === candidats //!== _.difference(item.lastMessageSenderId, settings.groupes.administrateurs);//this.props.auth.uid;
          //console.log(answered)
          return (
            <React.Fragment key={'constants-' + index} >
              <ListItem key={index} dense button onClick={() => listClick(index)} key={'constant-' + index}
              selected={this.props.selected === index}
              /*style={{ backgroundColor: answered ? this.props.selected === item.id ? 'rgb(231, 55, 74)' :'rgb(0, 128, 0)' : this.props.selected === item.id ? 'rgb(0, 128, 0)' : 'rgb(231, 55, 74)' }}*/
              >
                <ListItemText disableTypography className={classes.listitemtext} >
                  <Typography variant="body2" className={classes.lastMessage} style={{ body2: classes.lastMessage }} noWrap>
                    {item.key}
                  </Typography>
                </ListItemText>

                {/*<ListItemText primary={item.lastMessageContent} primaryTypographyProps={{noWrap:true, width:'200px'}} />
              <ListItemText secondary={moment.unix(item.lastMessageCreatedAt.seconds).format("DD/MM/YYYY à HH:mm:ss")} />*/}
              </ListItem>
              {index !== constants.length - 1 && <Divider key={index} />}
            </React.Fragment>
          )
        })}
      </List>
    );
  }

}

ConversationsList = withStyles(conversationsStyles)(ConversationsList);

export default ConversationsList