import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import BackIcon from '@material-ui/icons/ArrowBack';
import UndoIcon from '@material-ui/icons/UndoRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { udpateAnnonce, deleteAnnonce } from '../../store/actions/annonceActions'

import AnnonceDetailsTemplate from './AnnonceDetailsTemplate'
import AnnonceDetailsCandidatures from './detailAnnonce/detailsCandidatures'
import { Grid } from '@material-ui/core'

import { validationEmploi, validationFormation } from '../../utils/functions'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    //flexGrow: 1,
    padding: theme.spacing(4),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  title: {
    padding: 8
  },
  progress: {
    margin: theme.spacing(2),
  },
  list: {
    //width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    //position: 'relative',
    overflow: 'auto',
    maxHeight: '80vh',
    //maxHeight: '70%',
    paddingTop: 0,
    //maxHeight:900,
    overflowY: 'scroll'
  },
  listSection: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff'
  },
  // this group of buttons will be aligned to the right side
  toolbarButtons: {
    marginLeft: 'auto',
  },
}));

const AnnonceDetails = ({ auth, location, udpateAnnonce, monProfil, history, deleteAnnonce, annonce, disabledProps }) => {
  //const { auth } = props;
  //const { annonce, disabledProps } = location.state
  //console.log(annonce)
  //console.log(monProfil)
  const classes = useStyles();
  const [disabled, setDisabled] = React.useState(disabledProps);
  const [Save, setSave] = React.useState(false);
  const [Undo, setUndo] = React.useState(false);
  const [annonceState, setannonceState] = React.useState(annonce);
  const [errorsState, setErrorsState] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const onClickDelete = () => {
    //console.log('delete')
    //alert("suppression de l'annonce")
    if (window.confirm("Voulez vous vraiment supprimer cette annonce " + annonce.id + " ?")) {
      deleteAnnonce(annonce.id)
      goBack()
      // Code à éxécuter si le l'utilisateur clique sur "OK"
    } else {
      // Code à éxécuter si l'utilisateur clique sur "Annuler" 
    }

    //handleClose()
  };
  const onChangeEdit = (annonceValues) => {
    //console.log("change")
    //console.log(annonceValues)
    setannonceState(annonceValues)
  };

  const onClickEdit = () => {
    //console.log(disabled)
    setDisabled(!disabled)
    setSave(false)
    //setExpanded(!expanded);
    //console.log(disabled)
  };

  const onClickSave = () => {
    //console.log(Save)
    setSave(true)
    setDisabled(!disabled)
    //setExpanded(!expanded);
    //console.log(Save)
  };

  const onClickUndo = () => {
    setUndo(false)
    //console.log(annonce)
    setannonceState(annonce)
    setUndo(true)
    setDisabled(!disabled)
    //console.log(annonceState)
    //setUndo(false)
  }
  const validates = ({ title, secteurActivite, salaire, idEntreprise, entree, duree, description, type }) => {
    if (type === "emploi") {
      return {
        title: !title || title.trim().length === 0
          ? "Le titre est obligatoire!"
          : false,
        salaire:
          !salaire || salaire.trim().length === 0
            ? "Le Salaire est obligatoire!"
            : false,
        secteurActivite:
          !secteurActivite || secteurActivite.trim().length === 0
            ? "Le secteur est obligatoire!"
            : false,
        idEntreprise: !idEntreprise || idEntreprise.trim().length === 0
          ? type === "emploi" ? "L'entreprise' est obligatoire!" : false
          : false,
        entree:
          !entree || entree.trim().length === 0
            ? "L'entrée est obligatoire!"
            : false,
        description:
          !description || description.trim().length === 0
            ? "La description est obligatoire!"
            : false,
      };
    }
    if (type === "formation") {
      return {
        title: !title || title.trim().length === 0
          ? "Le titre est obligatoire!"
          : false,
        duree:
          !duree || duree.trim().length === 0
            ? "La durée de la formation est obligatoire!"
            : false,
        /*salaire:
          !salaire || salaire.trim().length === 0
            ? "Le Salaire est obligatoire!"
            : false,*/
        secteurActivite:
          !secteurActivite || secteurActivite.trim().length === 0
            ? "Le secteur est obligatoire!"
            : false,
        /*entree:
          !entree || entree.trim().length === 0
            ? "L'entrée est obligatoire!"
            : false,*/
        description:
          !description || description.trim().length === 0
            ? "La description est obligatoire!"
            : false,
      };
    }

  };
  const validate = (annonce) => {
    //console.log(annonce)
    if (annonce.type === 'emploi') {
      // setErrorsState(annonce)
      return validationEmploi(annonce);
    }
    if (annonce.type === 'formation') {
      //console.log(annonce)
      //setErrorsState(annonce)
      return validationFormation(annonce);
    }
    if (annonce.type === 'stage') {
      //setErrorsState(annonce)
      return validationFormation(annonce);
    }
  }

  const saveForm = (annonceValues) => {
    //console.log(annonceValues)
    setSave(false)
    //props.dispatch(udpateAnnonce(annonceValues, annonceValues.type));
    udpateAnnonce(annonceValues, annonceValues.type);
    //setExpanded(!expanded);
    //console.log(annonceValues)
  };
  const goBack = () => {
    history.goBack();
  }

  if (!auth.uid) return <Redirect to='/signin' />
  if (annonce) {
    let errors = validate(annonceState);
    //console.log(annonceState)
    //console.log(validationFormation(annonceState))
    //console.log(errors)
    let formNotValid = errors && Object.keys(errors).filter(k => errors[k])
    //console.log(formNotValid)
    return (
      <div className="root">
        <Grid container justify={"center"} spacing={4} >
          <Grid item xs={8} >
            <Grid container justify={"center"} spacing={4} >
              <Grid item /*style={{margin: '10px 0'}}*/>
                <Typography variant="h2" className={classes.chatTitle} color="inherit">
                  {annonce.title}
                </Typography>
                <Typography variant="body2" className={classes.chatTitle} style={{ textAlign: 'center' }} color="inherit">
                  ({annonce.reference})
                </Typography>
                <Typography variant="body2" className={classes.chatTitle} style={{ textAlign: 'center' }} color="inherit">
                  {annonce.authorFirstName && "crée par " + annonce.authorFirstName + " " + annonce.authorLastName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <AppBar position="static">
              <Tabs value={tabValue} onChange={handleChange} aria-label="annonce details Tabs" style={{ alignSelf: 'center' }}>
                <Tab label="Annonce" {...a11yProps(0)} />
                <Tab label="Candidatures" {...a11yProps(1)} />
              </Tabs>
              {tabValue === 0 &&
                <Toolbar>
                  {/*<IconButton edge="start" color="inherit" aria-label="menu" onClick={goBack}>
                <BackIcon />
              </IconButton>*/}
                  {!disabled &&
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={onClickDelete}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  <div className={classes.toolbarButtons}>
                    {disabled ?
                      <IconButton edge="start" color="inherit" aria-label="menu" onClick={onClickEdit}>
                        <EditIcon />
                      </IconButton>
                      :
                      <React.Fragment>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={onClickUndo}>
                          <UndoIcon />
                        </IconButton>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={onClickSave} disabled={formNotValid.length > 0 ? true : false} >
                          <SaveIcon />
                        </IconButton>
                      </React.Fragment>

                    }
                  </div>
                </Toolbar>
              }
              {tabValue === 1 &&
              <Toolbar>
                <Typography></Typography>
              </Toolbar>
              }

            </AppBar>
          </Grid>
          <Grid item xs={8} >
            <TabPanel value={tabValue} index={0}>
              <AnnonceDetailsTemplate annonce={annonceState} disabled={disabled} saveForm={saveForm} Save={Save} Undo={Undo} validate={validate} errors={errors} onChangeEdit={onChangeEdit} />

            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <AnnonceDetailsCandidatures annonce={annonceState} />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return (
      <div className="container center">
        <p>Chargement ...</p>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  /*const id = ownProps.match.params.id;
  const annonces = state.firestore.data.annonces;
  const annonce = annonces ? annonces[id] : null*/
  return {
    //annonce: annonce,
    auth: state.firebase.auth,
    monProfil: state.firestore.data.monProfil,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    udpateAnnonce: (annonce, annonceType) => dispatch(udpateAnnonce(annonce, annonceType)),
    deleteAnnonce: (annonceId) => dispatch(deleteAnnonce(annonceId))
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(/*[{
    collection: 'annonces'
  }]*/)
)(AnnonceDetails)