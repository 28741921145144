import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useFirestore, useFirebase } from "react-redux-firebase";
import { compose } from 'redux'
//import AnnonceSummaryCard from './AnnonceSummaryCard'
//import CreateAnnonce from './CreateAnnonce'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

// import style manually

import { fade, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image'
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";

import MenuItem from "@material-ui/core/MenuItem";

import ImageIcon from '@material-ui/icons/Image';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';
import 'moment/locale/fr'
import ImagePickerUpload from '../../utils/imagePickerUpload'


import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CKEditorField from '../../utils/CKEditorField'
import { values } from 'lodash';

//import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
//import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

moment.locale('fr');
const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    menuItemParent: {
        paddingLeft: 10
    }
}))
const editorConfiguration = {
    /// toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'exportpdf' ],
    /*heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
        ]
    },*/
    //plugins: [ Base64UploadAdapter],
};

// Finish!

const FormTheme = (props) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [docId, setDocId] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [expanded, setExpanded] = React.useState(false);
    const [Values, setValues] = React.useState();
    const [prevValues, setPrevValues] = React.useState();
    const [checked, setChecked] = React.useState(true);
    const [image, setImage] = React.useState(null);

    //const [selectedIndex, setSelectedIndex] = React.useState(1);
    useEffect(() => {
        console.log('mounted or updated')
        if (props.edit) {
            setValues(props.themeValues);
            //setValues({ ...props.themeValues, modified: firestore.Timestamp.fromDate(moment().toDate()) });
        } else {
            const ref = firestore.collection('tourismes').doc();
            setDocId(ref.id);
            setValues({ ...Values, docId: ref.id, modified: firestore.Timestamp.fromDate(new Date()), statut: true })
        }


    }, []);
    const onChangeInput = (event) => {
        console.log(event.target.name);
        if (event.target.name === 'created') {
            setValues({ ...Values, [event.target.name]: firestore.Timestamp.fromDate(new Date(event.target.value)) })
        }
        else if (event.target.name === 'modified') {
            //console.log(new Date(event.target.value))
            // console.log(firestore.Timestamp.fromDate(new Date(event.target.value)))
            //console.log(moment(event.target.value).format('LLLL'))
            console.log(moment(event.target.value).toDate())
            setValues({ ...Values, [event.target.name]: firestore.Timestamp.fromDate(moment(event.target.value).toDate()) })
        }
        else if (event.target.name === 'wpCatId') {
            setValues({ ...Values, [event.target.name]: event.target.value })
        }
        else {
            setValues({ ...Values, [event.target.name]: event.target.value })
        }

    }
    const handleEditorChange = ((event, editor) => {
        const data = editor.getData();
        console.log({ event, editor, data });
        //console.log('handleEditorChange', html, text)
        setPrevValues({ ...prevValues, description: data })
        setValues({ ...Values, description: data })
        /*if (prevValues.description !== data) {
            console.log('difference markdown');
            //console.log(text)
            //console.log(html)
        }*/
    })
    const handleEditorChangeold = ({ html, text }) => {
        //console.log('handleEditorChange', html, text)
        setPrevValues({ ...prevValues, description: text })
        setValues({ ...Values, description: text })
        if (prevValues.description !== text) {
            console.log('difference markdown');
            //console.log(text)
            //console.log(html)
        }
    }
    const handleImageUpload = async (file) => {
        console.log(props.themeValues.docId)
        const store = firebase.storage();
        /*const doc = await db
            .collection("users")
            .doc(userId)
            .collection("images")
            .add({
                name: file.name,
            });*/

        const uploadTask = await store
            .ref()
            .child(`tourismes/${props.themeValues.docId}/content/${props.themeValues.docId}-${file.name}`)
            .put(file);

        return uploadTask.ref.getDownloadURL();
        /* return new Promise((resolve) => {
             const reader = new FileReader();
             reader.onload = (data) => {
                 resolve(data.target.result);
             };
             //reader.readAsBinaryString(file)
             reader.readAsDataURL(file);
         }).then(console.log("done"));*/
    };

    const onCustomImageUpload = (event) => {
        console.log('onCustomImageUpload', event);
        return new Promise((resolve, reject) => {
            const result = window.prompt('Please enter image url here...');
            resolve({ url: result });
            // custom confirm message pseudo code
            // YourCustomDialog.open(() => {
            //   setTimeout(() => {
            //     // setTimeout 模拟oss异步上传图片
            //     // 当oss异步上传获取图片地址后，执行calback回调（参数为imageUrl字符串），即可将图片地址写入markdown
            //     const url = 'https://avatars0.githubusercontent.com/u/21263805?s=80&v=4'
            //     resolve({url: url, name: 'pic'})
            //   }, 1000)
            // })
        });
    }

    const handleImageState = (files) => {
        console.log(files)
        var file = files[0];
        const reader = new FileReader();


        reader.onloadend = function (e) {
            //setValues({ ...Values, image: [reader.result] })
            setImage([reader.result])
            /*this.setState({
              selectedFile: [reader.result]
            });*/
        }.bind(this);
        var url = reader.readAsDataURL(file);
        setValues({ ...Values, backgroundImage: files })
        console.log(url); // Would see a path?
        //setValues({ ...Values, selectedFile: event.target.files[0] })

    }
    const handleImageUploads = (files) => {
        firestore.collection('TestsMarkers').add({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            //creator: monProfil,
            //file: snap[0].File,
            //downloadUrl: snap[0].downloadURL
        }).then(async (doc) => {
            let docId = doc.id;
            const filesPath = 'testMarker/' + docId;

            return await firebase.uploadFiles(filesPath, image, filesPath, { progress: true })
                .then((snap) => {
                    console.log('upload successful', snap)
                    console.log("test ", snap[0].downloadURL)
                    console.log('downloadUrl', snap[0].downloadURL)

                    setValues({ ...Values, image: snap[0].downloadURL })
                    firestore.collection('TestsMarkers').doc(docId).update({
                        file: snap[0].File,
                        downloadUrl: snap[0].downloadURL,

                    })
                })
                .catch((err) => {
                    console.error('error uploading file', err)
                    //setProgress(false);
                });
        })
    }
    const toggleChecked = () => {
        setChecked((prev) => !prev);
        setValues({ ...Values, statut: !Values.statut })
    };
    const handleUploadClick = (files, event) => {
        console.log();
        var file = files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setValues({ ...Values, image: [reader.result] })
            /*this.setState({
              selectedFile: [reader.result]
            });*/
        }.bind(this);
        console.log(url); // Would see a path?
        setValues({ ...Values, selectedFile: event.target.files[0] })
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const saveForm = () => {
        /*this.setState(prevState => ({
            ...prevState,
            emploi: {
                ...prevState.emploi,
                description_formatted: this.state.emploi.description.replace(/\n/g, "__cr__").replace(/\r/g, ""),
                competences_formatted: this.state.emploi.competences.replace(/\n/g, "__cr__").replace(/\r/g, ""),
                conditions_formatted: this.state.emploi.conditions.replace(/\n/g, "__cr__").replace(/\r/g, ""),

                description_formatted_qc: this.state.emploi.description_qc.replace(/\n/g, "__cr__").replace(/\r/g, ""),
                competences_formatted_qc: this.state.emploi.competences_qc.replace(/\n/g, "__cr__").replace(/\r/g, ""),
                conditions_formatted_qc: this.state.emploi.conditions_qc.replace(/\n/g, "__cr__").replace(/\r/g, ""),
                reference: "OQ-E-" + moment().format("YYYY") + "-" + moment().format("MM") + "-" + moment().format("HHmmssSSS"),
            },
        }),
            () => {
                this.props.saveForm(this.state.emploi)
            }
        );*/
        props.saveForm(Values)
    }
    const [openCatChooser, setOpenCatChooser] = React.useState(false);
    const handleOpenCatChooser = () => {
        setOpenCatChooser(!openCatChooser);
    };
    console.log(Values)
    if (!Values) {
        console.log('loading')
        return (
            <Box>
                LOADING
            </Box>
        )
    }
    return (
        <Box>
            <Grid
                container
                justify={"flex-start"}
                spacing={2}
                style={{
                    backgroundColor: '#fff',
                    padding: 10,
                    overflowY: 'scroll',
                    // ...this.props.fieldContainerStyle,
                }}
                flex={1}
            //wrap={this.props.wrap}
            >

                <Grid item xs={6}>
                    <TextField
                        label={"Titre"}
                        type={"text"}
                        fullWidth={true}
                        variant={"outlined"}
                        onChange={onChangeInput}
                        value={Values.title || ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            name: 'title',
                            id: 'age-native-simple',
                        }}
                        required
                    //error={errors.title ? true : false}
                    //helperText={errors.title}
                    />
                </Grid>
                
                <Grid item xs={2}>
                    <TextField
                        id="wpCatId"
                        name={"wpCatId"}
                        label="Id catégorie Wordpress"
                        type="text"
                        onChange={onChangeInput}
                        variant={"outlined"}
                        value={Values.wpCatId || ''}
                        disabled
                        //value={ Values.modified  ? moment(Values.modified.seconds, "X").toISOString().slice(0, -8) || '': moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            name: 'wpCatId',
                            id: 'wpCatId',
                        }}
                    //disabled={!props.edit && true}

                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl className={classes.textField}>
                        <Button id="openMenu" onClick={handleOpenCatChooser} variant="contained">
                            Choisir
                        </Button>
                        <Select
                            multiple
                            value={Values.wpCatId || []}
                            onChange={onChangeInput}
                            id="wpCatId"
                            name={"wpCatId"}
                            input={<Input id="wpCatId" />}
                            style={{ display: "none" }}
                            open={openCatChooser}
                            onClose={handleOpenCatChooser}
                            MenuProps={{
                                anchorEl: document.getElementById("openMenu"),
                                style: { marginTop: 60 }
                            }}
                        >
                            {props.wpCategory && props.wpCategory.length > 0 && props.wpCategory.map((cat, index) => {
                                //console.log(Values.wpCatId.split(","));
                                //console.log(cat.id);
                                //console.log(Values.wpCatId.split(",").map(Number).indexOf(cat.id));
                                return (
                                    <MenuItem key={cat.name + index} value={cat.id} style={{paddingLeft: cat.parent !=0 ? 10 : 0}}>
                                        <Checkbox checked={Values.wpCatId && Values.wpCatId.map(Number).indexOf(cat.id) > -1} />
                                        <ListItemText primary={cat.name} />
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <TextField
                        id="created"
                        name={"created"}
                        label="Date création"
                        type="datetime-local"
                        variant={"outlined"}
                        onChange={onChangeInput}
                        value={!props.edit ? moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)/*.slice(0, -8)*/ : Values.created && moment(Values.created.seconds, "X").toISOString().slice(0, -8) || ''} //format("DD/MM/YYYY HH:mm")
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={props.edit && true}
                    />
                    <TextField
                        id="modified"
                        name={"modified"}
                        label="Date modification"
                        type="datetime-local"
                        onChange={onChangeInput}
                        variant={"outlined"}
                        value={Values.modified ? moment(Values.modified.seconds, "X").format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) || '' : moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)}
                        //value={ Values.modified  ? moment(Values.modified.seconds, "X").toISOString().slice(0, -8) || '': moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!props.edit && true}

                    />
                </Grid>

                <Grid item xs={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={Values.statut || false} onChange={toggleChecked} />}
                            label="Publié ?"
                        />
                    </FormGroup>
                </Grid>

                {/*<Grid item xs={10}>
                    <CKEditorField data={Values.description || ''} config={editorConfiguration} onChange={handleEditorChange} />*/}
                {/*<Editor
                        //editorState={EditorState}
                        //toolbarClassName="toolbarClassName"
                        //wrapperClassName="wrapperClassName"
                        //editorClassName="editorClassName"
                        //onEditorStateChange={this.onEditorStateChange}
                    />*/}
                {/*<MdEditorField
                        value={Values.description || ''}
                        style={{ height: "500px" }}
                        //renderHTML={(text) => mdParser.render(text)}
                        onChange={handleEditorChange}
                        onImageUpload={handleImageUpload}
                        config={{
                            view: {
                                menu: !props.view && true,
                                md: !props.view && true,
                                html: true,
                            },
                            canView: {
                                menu: true,
                                md: true,
                                html: true,
                                fullScreen: true,
                                hideMenu: true,
                            },
                            syncScrollMode: ['rightFollowLeft', 'leftFollowRight'],
                        }}
                    />*/}

                {/*</Grid>*/}
                {/*} <Grid item xs={2}>
                    <ImagePickerUpload handleImageState={handleImageState} image={Values && Values.backgroundImage ? image : Values.image} />
                </Grid>*/}
            </Grid>
            <Box style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex', ...props.actionContainerStyle }}>
                {props.cancelForm && (
                    <Button
                        variant="contained"
                        onClick={() => props.cancelForm()}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}
                    >
                        {props.cancelFormButtonName}
                    </Button>
                )}
                {props.saveForm && (
                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={() => saveForm()}
                        //disabled={formNotValid.length > 0 ? true : false}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}
                    >
                        {props.saveFormButtonName}
                    </Button>
                )}
            </Box>
        </Box>
    )
}

export default FormTheme
