import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useFirebase, useFirestore } from "react-redux-firebase";
import { fade, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import moment from 'moment'
const useStyles = makeStyles(theme => ({
    root: {
        width: 500,
        maxWidth: '100vw',
        height: '100%',
        maxHeight: '50vh',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    paper: {
        zIndex: 1,
        position: "relative",
        //margin: theme.spacing.unit
    },
}));

const CommentsScreen = ({ commentsBasePath }) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [comments, setComments] = React.useState();
    const [openReplies, setOpenReplies] = React.useState(false);
    React.useEffect(() => {
        console.log(commentsBasePath)
        if (commentsBasePath) {
            _launchComments()
        }
    }, [commentsBasePath])
    const _launchComments = () => {

        /* useFirebaseConnect([
             { path: 'todos' }
         ])*/
        //let commentsData = []
        // var myUserId = uid;
        //.doc(fileDir)

        let postComments = firestore.doc(commentsBasePath).collection('comments').orderBy('created', 'desc')

        postComments.onSnapshot(querySnapshot => {
            const commentsData = [];
            //console.log(`Received query snapshot of size ${querySnapshot.size}`);
            querySnapshot.forEach((doc) => {
                //console.log(doc.id, ' => ', doc.data());
                commentsData.push({ id: doc.id, ...doc.data() })
            });
            setComments(commentsData)
            //console.log(comments.length)
            //isVisibleEdit.current = true
            //setIsVisibleEdit(true)
            // ...
        }, err => {
            console.log(`Encountered error: ${err}`);
        });



    }

    const viewReplies = () => {
        setOpenReplies(true)
    }
    console.log(commentsBasePath)
    console.log(comments)
    if (comments && comments.length > 0) {
        return (
            <List className={classes.root}>
                {comments && comments.map((comment, index) => {
                    console.log(comment)
                    return (
                        <React.Fragment key={index}>
                            {!openReplies ? (
                                <Fade in={!openReplies}>
                                    <Paper elevation={0} className={classes.paper}>
                                        <ListItem alignItems="flex-start">

                                            <ListItemAvatar>
                                                {comment.authorImage ?
                                                    <Avatar alt="Remy Sharp" src={comment.authorImage} />
                                                    :
                                                    <Avatar alt="Remy Sharp">{comment.authorInitials}</Avatar>
                                                }

                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={comment.comment}
                                                secondary={
                                                    <span style={{
                                                        display: 'flex',
                                                        flex: 1,
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={classes.inline}
                                                            color="textPrimary"
                                                        >
                                                            {comment.created && moment.unix(comment.created.seconds).format("DD/MM/YYYY - HH:mm:ss")}
                                                        </Typography>
                                                        {comment.nbReplies &&
                                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    // className={classes.inline}
                                                                    color="textPrimary"
                                                                >
                                                                    {comment.nbReplies > 1 ? comment.nbReplies + ' réponses' : comment.nbReplies + ' réponse'}
                                                                </Typography>
                                                                <IconButton onClick={viewReplies}>
                                                                    <EyeIcon />
                                                                </IconButton>
                                                            </span>
                                                        }
                                                    </span>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </Paper>
                                </Fade>
                            )
                                : null
                            }
                            {openReplies ? (
                                /*<Slide direction="down" in={openReplies} mountOnEnter unmountOnExit>*/
                                <Fade in={openReplies}>
                                    <Paper elevation={0} className={classes.paper}>
                                        <IconButton onClick={() => setOpenReplies(false)} >
                                            <CloseIcon />
                                        </IconButton>
                                        <List className={classes.root}>
                                            {comment && comment.replies && comment.replies.map((reply, index) => {
                                                console.log(reply)
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ListItem alignItems="flex-start" key={index}>

                                                            <ListItemAvatar>
                                                                {reply.authorImage ?
                                                                    <Avatar alt="Remy Sharp" src={reply.authorImage} />
                                                                    :
                                                                    <Avatar alt="Remy Sharp">{reply.authorInitials}</Avatar>
                                                                }

                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={reply.reply}
                                                                secondary={
                                                                    <span style={{
                                                                        display: 'flex',
                                                                        flex: 1,
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <Typography
                                                                            component="span"
                                                                            variant="body2"
                                                                            className={classes.inline}
                                                                            color="textPrimary"
                                                                        >
                                                                            {reply.created && moment.unix(reply.created.seconds).format("DD/MM/YYYY - HH:mm:ss")}
                                                                        </Typography>
                                                                    </span>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider variant="inset" component="li" />
                                                    </React.Fragment>
                                                )
                                            })}
                                        </List>
                                    </Paper>
                                </Fade>
                                /*</Slide>*/
                            )
                                : null}

                        </React.Fragment >
                    )
                })}

            </List >
        )
    }
    return (
        <Paper elevation={0} className={classes.paper}>
            <Typography variant='body2'>
                Aucuns commentaires pour cet article
            </Typography>
        </Paper>
        )
}

export default CommentsScreen