import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
const useStyles = makeStyles(theme => ({
    title: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textTransform: 'uppercase'
    },


    content: {
        margin: '10px'
    }
}));
const Statistiques = ({ compteurs, allInscriptions }) => {
    const classes = useStyles();

    let nbLikes = 0;
    let nbCandidats = 0;
    let nbComments = 0;
    let nbPostulations = 0;
    let nbRendezVous = 0;
    if (compteurs) {
        //console.log(compteurs)
        nbCandidats = compteurs.users[2];
        let emplois = compteurs.emplois;
        let etudes = compteurs.etudes;
        let feeds = compteurs.feeds;
        let boutique = compteurs.boutique;
//console.log(Object.values(emplois))
        const items = Object.keys(emplois).find(item => item.nbLikes > 0);
        //console.log(items)
        /* let itemnbUsersLikes = [];
         let itemnbUsersPostulations = [];
         let itemnbUsersRendezVous = [];
         Object.values(annonces).map((annonce, index) => {
             _.filter(annonce.usersactions, function (item) {
                 if (item.like) {
                     itemnbUsersLikes.push(item.like)
                 }
 
                 //return item.like;
             })
             _.filter(annonce.usersactions, function (item) {
                 if (item.postulation) {
                     itemnbUsersPostulations.push(item.postulation)
                 }
 
                 //return item.postulation;
             })
             _.filter(annonce.usersactions, function (item) {
                 if (item.rendezVous) {
                     itemnbUsersRendezVous.push(item.rendezVous)
                 }
 
                 //return item.rendezVous;
             })
             //console.log(itemnbUsersLikes)
         })
         //console.log(itemnbUsersLikes)
         nbLikes = _.size(itemnbUsersLikes)
         nbPostulations = _.size(itemnbUsersPostulations)
         nbRendezVous = _.size(itemnbUsersRendezVous)*/
    }
    return (
        <div className={classes.root}>
            <Paper elevation={3} >
                <Typography variant="h6" component="h2" className={classes.title}>Statistiques</Typography>
                <Divider />
                <ul>
                    <Typography component="li" variant="subtitle1" >{nbCandidats ? nbCandidats : 0} inscrits dans l'application</Typography>
                    <Typography component="li" variant="subtitle1" >{allInscriptions ? allInscriptions.length : 0} inscriptions au mois du québec</Typography>
                    <Typography component="li" variant="subtitle1" >{nbLikes ? nbLikes : 0} like(s)</Typography>
                    <Typography component="li" variant="subtitle1" >{nbComments ? nbComments : 0} commentaire(s)</Typography>
                </ul>
            </Paper>


        </div>
    )
}

export default Statistiques