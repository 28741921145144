import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import BackIcon from '@material-ui/icons/ArrowBack';
import UndoIcon from '@material-ui/icons/UndoRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { Grid } from '@material-ui/core'
import moment from 'moment'
//import { validationEmploi, validationFormation } from '../../utils/functions'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    //flexGrow: 1,
    padding: theme.spacing(4),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  title: {
    padding: 8
  },
  progress: {
    margin: theme.spacing(2),
  },
  list: {
    //width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    //position: 'relative',
    overflow: 'auto',
    maxHeight: '80vh',
    //maxHeight: '70%',
    paddingTop: 0,
    //maxHeight:900,
    overflowY: 'scroll'
  },
  listSection: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff'
  },
  // this group of buttons will be aligned to the right side
  toolbarButtons: {
    marginLeft: 'auto',
  },
}));

const DetailsCandidatures = ({ auth, annonce, firestore }) => {
  //const { auth } = props;
  //const { annonce, disabledProps } = location.state
  //console.log(annonce)
  const [cvLink, setCvLink] = React.useState('');
  const [othersCandidatCV, setOthersCandidatCV] = React.useState(null);
  //console.log(monProfil)
  const classes = useStyles();
  const getPostulationCandidatCV = (candidat) => {
    console.log(candidat)
    firestore.get('users/' + candidat).then((snapshot) => {
      let data = snapshot.data();
      console.log(data);
      if (data.profileCVCachete) {
        setCvLink(data.profileCVCachete)
        OpenCandidatCV(data.profileCVCachete);
      }
      else {
        setCvLink(data.profileCV)
        OpenCandidatCV(data.profileCV);
      }
    })

  }
  const OpenCandidatCV = (CVLink) => {
    window.open(CVLink, "_blank");
  }

  const getOthersCandidatCV = () => {
    console.log(annonce.secteurActivite)
    firestore.collection('users').where('domaines', 'array-contains-any', [annonce.secteurActivite]).where('hasprofileCVCachete', '==', true).get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        let data = [];

        snapshot.forEach(doc => {
          data.push(doc.data())
          console.log(doc.id, '=>', doc.data());
        });
        setOthersCandidatCV(data)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    /* .then((snapshot) => {
       //if(snapshot.exists())
       let data = snapshot.data();
       console.log(data);
       if (data) {
         setOthersCandidatCV(data.profileCVCachete)
       }
     })*/

  }


  if (!auth.uid) return <Redirect to='/signin' />
  if (annonce) {

    let candidatsPostulation = [];
    let itemUsersPostulations = _.filter(annonce.usersactions, function (item, index) {
      // console.log(index)
      if (item.postulation) {
        candidatsPostulation.push(index)
      }
    });
    let itemnbUsersPostulations = _.filter(annonce.usersactions, function (item) {
      return item.postulation;
    });
    //let nbPostulations = _.size(itemnbUsersPostulations)

    return (
      <div className="root">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AppBar position={"static"}>
              <Toolbar>
                <Typography variant="h5" component="h5">Liste des candidats ayant postulé</Typography>
              </Toolbar>

            </AppBar>
            <Grid container justify={"flex-start"} spacing={1} >
              {candidatsPostulation && candidatsPostulation.length > 0 ?
                candidatsPostulation.map((candidat, index) => {
                  let currentCandidat = annonce.usersactions[candidat]
                  return (
                    <Grid item xs={6} key={index} style={{ padding: 10 }}  >
                      <Card /*className={classes.root}*/>
                        <CardContent>
                          <Typography>Postulant N°{index}</Typography>
                          {currentCandidat.postulation.postulated_at &&
                            <Typography>A postulé le {moment.unix(currentCandidat.postulation.postulatedAt.seconds).format("DD/MM/YYYY à HH:mm:ss")}</Typography>
                          }
                        </CardContent>
                        <CardActions>
                          <Button variant="contained" color="primary" /*href="#contained-buttons"*/ onClick={() => getPostulationCandidatCV(candidat)}>
                            Voir le CV
                        </Button>
                          <Button variant="contained" color="secondary" /*href="#contained-buttons"*/ onClick={() => getPostulationCandidatCV(candidat)}>
                            Demande de rendez-vous
                        </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })
                :
                <Grid item xs={12} style={{ padding: 10 }}  >
                  <Typography align={"center"}>Aucun Candidat n'a postulé pour cette offre!</Typography>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <AppBar position={"static"}>
              <Toolbar>
                <Typography variant="h5" component="h5">Liste des candidats correspondant au secteur d'activités</Typography>
              </Toolbar>
              {!othersCandidatCV ?
                <Button variant="contained" color="secondary" /*href="#contained-buttons"*/ onClick={() => getOthersCandidatCV()}>
                  Rechercher les candidats !
                </Button>
                :
                othersCandidatCV.map((candidat, index) => {
                  return (
                    <Grid item xs={6} key={index} style={{ padding: 10 }}  >
                      <Card /*className={classes.root}*/>
                        <CardContent>
                          <Typography>Postulant N°{index}</Typography>

                        </CardContent>
                        <CardActions>
                          <Button variant="contained" color="primary" /*href="#contained-buttons"*/ onClick={() => OpenCandidatCV(candidat.profileCVCachete)}>
                            Voir le CV
                        </Button>
                          <Button variant="contained" color="secondary" /*href="#contained-buttons"*/ onClick={() => getPostulationCandidatCV(candidat)}>
                            Demande de rendez-vous
                        </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })
              }
            </AppBar>
          </Grid>
        </Grid>


      </div>
    )
  }
  return null

}

const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  /*const id = ownProps.match.params.id;
  const annonces = state.firestore.data.annonces;
  const annonce = annonces ? annonces[id] : null*/
  return {
    //annonce: annonce,
    auth: state.firebase.auth,
    monProfil: state.firestore.data.monProfil,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    // udpateAnnonce: (annonce, annonceType) => dispatch(udpateAnnonce(annonce, annonceType)),
    //deleteAnnonce: (annonceId) => dispatch(deleteAnnonce(annonceId))
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(/*[{
    collection: 'annonces'
  }]*/)
)(DetailsCandidatures)