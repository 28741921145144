import React, { Component } from 'react'
import AnnonceList from '../annonces/AnnoncesList'
import Notifications from './components/Notifications'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8 
    },
}));


//class Dashboard extends Component {
const DashboardEnt = ({ auth, mesAnnonces, monProfil }) => {
    const classes = useStyles();
    //render() {
    //const { annonces, auth } = props;
    if (!auth.uid) return <Redirect to='/signin' />
    console.log(monProfil)
    return (
        <div className={classes.root}>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Paper>
                        <Typography variant="h6" component="h2" className={classes.title}>
                            Mes dernières annonces
                        </Typography>
                        <Divider />
                        <AnnonceList auth={auth} annonces={mesAnnonces} />
                    </Paper>

                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <Notifications /*userActivity={profile.userActivity}*/ />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
    //}
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        
        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil,
        mesAnnonces: state.firestore.ordered.mesAnnonces,
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect(),
    firestoreConnect(props => [
        { collection: 'users', doc: props.auth.uid, storeAs: 'monProfil' },
        {
            collection: 'annonces',
            where:[['statut', '==', 1], ['idEntreprise', '==', props.auth.uid]],
            orderBy: ['createdAt', 'desc'],
            limit: 10,
            storeAs: "mesAnnonces"
        },
        {
            collection: 'users',
            where: [['role', '==', 1]],
            orderBy: ['companyName', 'asc'],
            //limit: 10,
            storeAs: 'lesEntreprises'
        },
      ]),

    /*firestoreConnect([
        { collection: 'annonces' },
        limit: 5
    ])*/
)(DashboardEnt)