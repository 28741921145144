import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';
import Tooltip from "@material-ui/core/Tooltip";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import DocumentIcon from '@material-ui/icons/DescriptionOutlined'
import CategoryIcon from '@material-ui/icons/CategoryOutlined'
import LikeIcon from '@material-ui/icons/FavoriteBorderOutlined'
import PostulationIcon from '@material-ui/icons/BookmarkBorderOutlined'
import RendezVousIcon from '@material-ui/icons/CalendarTodayOutlined'
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import CloseIcon from '@material-ui/icons/Close';


import moment from 'moment';

import TypeEmploi from './detailAnnonce/typeEmploi';
import AnnonceDetail from './AnnonceDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
function SimpleDialog(props) {
  const classes = useStylesDialog();
  const { onClose, selectedValue, open, disabledProps } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullScreen TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Détail Annonce
            </Typography>

        </Toolbar>
      </AppBar>
      <AnnonceDetail annonce={props.annonce} annonceid={props.annonce.id} disabledProps={disabledProps} />
      {/*<TypeEmploi annonce={props.annonce} annonceid={props.annonce.id} disabledProps={disabledProps} />*/}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //selectedValue: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  cards: {
    // maxWidth: 345,

  },
  cards: {
    minHeight: '300px'
    // Provide some spacing between cards
    // margin: 16,

    // Use flex layout with column direction for components in the card
    // (CardContent and CardActions)
    //display: "flex",
    //flexDirection: "column",

    // Justify the content so that CardContent will always be at the top of the card,
    // and CardActions will be at the bottom
    //justifyContent: "space-between"
  },
  media: {
    height: 140,
  },
  date: {
    backgroundColor: 'white',
    color: 'black'
  },
  reference: {
    //height: '2rem',
    //whiteSpace: 'nowrap',
    fontSize: '0.675rem',
    //fontWeight: "bold",
    textAlign: "left"
  },
  description: {
    height: '5rem',
    whiteSpace: 'initial',
    marginBottom: '1em'
  },
  card: {
    /* display: "grid",
     gridTemplateRows: "1fr auto",*/
    gridGap: "8px",
    minHeight: 300,
    position: 'relative',
    /* backgroundImage: `url(https://via.placeholder.com/100x200)`,
     backgroundSize: "cover"*/
  },
  title: {
    minHeight: "5em"
  },
  subheader: {
    //minHeight: "15rem"
  },

  body: {
    alignSelf: "end",
    textAlign: "center"
  },
  badge: {
    //padding: 12,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    //position: 'absolute',
    //right: '0',
    //top: '0',
    //justifyContent: "space-between"
  }
});

const ITEM_HEIGHT = 48;
const ProjectSummary = ({ annonce, monProfil, deleteAnnonce, duplicateAnnonce, setParentOpenDetailsFlag }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [disabledProps, setdisabledProps] = React.useState(true);
  //const [nbLikes, setNbLikes] = React.useState(null);
  const handleClickOpen = () => {
    setOpenDetail(true);
    if(setParentOpenDetailsFlag) {
      setParentOpenDetailsFlag(true)
   }
    handleClose();
  };
  const handleClickOpenEdit = () => {
    setdisabledProps(false);
    setOpenDetail(true);
    if(setParentOpenDetailsFlag) {
       setParentOpenDetailsFlag(true)
    }
    handleClose();
  };

  const handleClickClose = value => {
    setOpenDetail(false);
    if(setParentOpenDetailsFlag) {
      setParentOpenDetailsFlag(false)
   }

  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickDeleteAnnonce = () => {
    console.log('delete')
    //alert("suppression de l'annonce")
    if (window.confirm("Voulez vous vraiment supprimer cette annonce ?")) {
      deleteAnnonce(annonce.id)
      // Code à éxécuter si le l'utilisateur clique sur "OK"
    } else {
      // Code à éxécuter si l'utilisateur clique sur "Annuler" 
    }

    handleClose()
  };

  const clickDuplicateAnnonce = () => {
    console.log('delete')
    //alert("suppression de l'annonce")
    if (window.confirm("Voulez vous vraiment dupliquer cette annonce ?")) {
      duplicateAnnonce(annonce)
      // Code à éxécuter si le l'utilisateur clique sur "OK"
    } else {
      // Code à éxécuter si l'utilisateur clique sur "Annuler" 
    }

    handleClose()
  };
  //console.log(monProfil)
  let itemnbUsersLikes = _.filter(annonce.usersactions, function (item) {
    return item.like;
  });
  let itemnbUsersPostulations = _.filter(annonce.usersactions, function (item) {
    return item.postulation;
  });
  let itemnbUsersRendezVous = _.filter(annonce.usersactions, function (item) {
    return item.rendezVous;
  });
  let nbLikes = _.size(itemnbUsersLikes)
  let nbPostulations = _.size(itemnbUsersPostulations)
  let nbRendezVous = _.size(itemnbUsersRendezVous)
  return (
    <div>
      <Card className={classes.card}>

        <CardActionArea className={classes.body}
          onClick={handleClickOpen}
          /*component={Link}
          to={{
            pathname: '/annonce/' + annonce.id,
            state: {
              annonce: annonce,
              disabledProps: true,
            }
          }}*/
          style={{ textDecoration: 'none' }}
        >
          <CardHeader
            //className={classes.title}
            classes={{
              title: classes.title,
              subheader: classes.subheader,
            }}
            title={annonce.title}
            titleTypographyProps={{ variant: 'body2' }}
            subheader={
              <React.Fragment>
                <Typography variant="body2" color="textSecondary" component="p" noWrap={true} className={classes.reference}>
                  <i>Mise en ligne le</i>: {moment.unix(annonce.createdAt.seconds).format("DD/MM/YYYY")}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" noWrap={true} className={classes.reference}>
                  <i>Référence</i>: {annonce.reference}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" noWrap={true} gutterBottom className={classes.reference} style={{ width: '230px' }}>
                  <i>Secteur</i>: {annonce.secteurActivite}
                </Typography>
              </React.Fragment>
            }
            subheaderTypographyProps={{ variant: 'body2' }}
          />
          <Divider variant={'middle'} />
          {/*<CardMedia
            className={classes.media}
            image={annonce.localisation.image ? annonce.localisation.image : "/images/quebecHDLowliste.jpg"}
            //image="/static/images/cards/contemplative-reptile.jpg"
            title={annonce.title}
          >
          </CardMedia>*/}
          <CardContent>
            {/*<Typography variant="body2" color="textSecondary" component="p" noWrap={true} gutterBottom className={classes.reference}>
              <u>Référence:</u> {annonce.reference}
            </Typography>*/}
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom noWrap={true} gutterBottom className={classes.description}>
              {annonce.description}
            </Typography>
            {/*<Grid container>
              <Grid item xs={3}>
                <Badge badgeContent={nbLikes} color="primary">
                  <LikeIcon />
                </Badge>
              </Grid>
              <Grid item xs={3}>
                <Badge badgeContent={nbPostulations} color="primary">
                  <PostulationIcon />
                </Badge>
              </Grid>
              <Grid item xs={3}>
                <Badge badgeContent={nbRendezVous} color="primary">
                  <RendezVousIcon />
                </Badge>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            </Grid>*/}
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actions}>
          <Grid container alignItems={"center"} alignContent={"center"} justify={"center"}>
            <Grid item xs={3}>
              <Tooltip title={"Candidat(s) ayant aimé(s) cette annonce : " + nbLikes}>
                <Badge badgeContent={nbLikes} color="primary" className={classes.badge}>
                  <LikeIcon />
                </Badge>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={ "Candidat(s) ayant postulé(s) à cette annonce : " + nbPostulations}>
                <Badge badgeContent={nbPostulations} color="primary" className={classes.badge}>
                  <PostulationIcon />
                </Badge>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={ "Rendez-vous en cours pour cette annonce : " + nbRendezVous}>
                <Badge badgeContent={nbRendezVous}
                  color="primary" className={classes.badge}>
                  <RendezVousIcon />
                </Badge>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            <MenuItem
              key={"view"}
              onClick={handleClickOpen}
            >
              {"Voir"}
            </MenuItem>
            {monProfil && monProfil.role === 0 &&
              <MenuItem
                key={"edit"}
                onClick={handleClickOpenEdit}
              >
                {"Modifier"}
              </MenuItem>
            }
            {monProfil && monProfil.role === 0 &&
              <MenuItem key={"delete"} onClick={clickDeleteAnnonce}>
                {"Supprimer"}
              </MenuItem>
            }
            {monProfil && monProfil.role === 0 &&
              <MenuItem key={"duplicate"} onClick={clickDuplicateAnnonce}>
                {"Dupliquer"}
              </MenuItem>
            }
          </Menu>
        </CardActions>
      </Card>
      <SimpleDialog annonce={annonce} open={openDetail} onClose={handleClickClose} disabledProps={disabledProps} />
    </div>
  );
}
export default ProjectSummary