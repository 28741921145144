import React, { Component, useState, useEffect } from 'react'
//import AnnonceList from '../annonces/AnnoncesList'
import PropTypes from 'prop-types';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
//import { loadMoreAnnonce, createAnnonce } from '../../store/actions/annonceActions'
import { loadMoreUsers, loadInitUsers, createUser, loadAdvancedSearchUsers } from '../../store/actions/userActions'

import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import UsersListItemCache from './UsersListItemCache';
import SearchAdvanced from './components/SearchAdvanced'

import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Toolbar from '@material-ui/core/Toolbar';

import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import EntrepriseCreate from './components/CreateUser/Entreprises'
import { createUserbyAdmin } from '../../utils/firebase/FirebaseUtils'
import { getInitial, getNomComplet, upperCaseName } from '../../utils/functions'
import _ from "lodash";
import moment from 'moment';
import { roles } from '../../constants/roles';

/*const roles = {
    0:{
        label: "Administrateurss(s)"
    },
    1:{
        label: "Entreprise(s)"
    },
    2:{
        label: "Candidat(s)"
    }
}*/

const chatToolbarStyles = theme => ({
    chatToolbarRoot: {
        color: '#FFF',
        //margin: '4px',
        position: 'absolute',
        right: 0,
        minHeight: '32px',
        // backgroundColor: '#0377ad', //'#0595DD', //'#23232F',
        borderRadius: '2px'
    },
    innerContent: {
        width: '100%',
    },
    chatTitle: {
        margin: '0 auto'
    },
    searchWrapper: {
        backgroundColor: '#025279',
        borderRadius: '3px',
        border: 'solid 1px #025279',
        color: '#FFF',
        margin: '5px'
    },
    searchInput: {
        width: '180px',
        paddingLeft: '8px',
        transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&::placeholder': {
            color: '#FFF',
            opacity: 1
        },
        '&:focus': {
            width: '300px'
        }
    },
    searchIcon: {
        color: '#FFF',
        marginTop: '3px'
    },
    expandMore: {
        color: '#FFF',
        marginTop: '3px'
    },
    expandMoreRotate: {
        color: '#FFF',
        marginTop: '3px',
        transform: 'rotate(180deg)'
    },
    addUserButton: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },

});


class UsersListEntToolbar extends React.Component {

    render() {

        const { classes, onClickExpand, expand, onChange, value, addClick, monProfil } = this.props;
        //console.log(convName)
        //console.log(this.state.selectedConv)
        return (
            <Toolbar disableGutters={true} className={classes.chatToolbarRoot}>
                <Input classes={{ root: classes.searchWrapper, input: classes.searchInput }} disableUnderline={true} placeholder={"Recherche"}
                    value={value}
                    onChange={onChange}
                    endAdornment={<SearchIcon className={classes.searchIcon} />}
                />
                <IconButton className={expand ? classes.expandMoreRotate : classes.expandMore} onClick={() => onClickExpand()}>
                    <ExpandMoreIcon />
                </IconButton>
                <div className={classes.grow} />
                {monProfil && monProfil.role === 0 &&
                    <IconButton aria-label="add" className={classes.addUserButton} onClick={addClick} >
                        <AddIcon />
                    </IconButton>
                }
                <div className={classes.grow} />
            </Toolbar>
        );
    }

}

UsersListEntToolbar = withStyles(chatToolbarStyles)(UsersListEntToolbar);


class AddUserDialog extends React.Component {

    render() {
        const { classes, onClickExpand, expand, onChange, value, addClick, closeDialog, monProfil, openAddUser, CreateUser, passwordError, onChangeProfilValues } = this.props;

        {
            if (value === 0) {
                return (
                    <Dialog open={openAddUser} onClose={closeDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Nouvel {roles[value].label}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Pour ajouter un nouvel utilisateur veuillez remplir TOUS les champs ci-dessous!
                                Le mot de passe par défaut est "ObjectifQuebec70".
                        </DialogContentText>
                            {/*<EntrepriseCreate role={value} profile={''} onChangeProfilValues={onChangeProfilValues} />*/}
                        </DialogContent>
                        {/*<DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={CreateUser} color="primary" disabled={passwordError ? true : false} >
                                Créer
                            </Button>
                        </DialogActions>*/}
                    </Dialog>
                )
            }
            else if (value === 1) {
                return (
                    <Dialog open={openAddUser} onClose={closeDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Nouvelle {roles[value].label}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Pour ajouter un nouvel utilisateur veuillez remplir TOUS les champs ci-dessous!
                                Le mot de passe par défaut est "ObjectifQuebec70".
                            </DialogContentText>
                            <EntrepriseCreate role={value} profile={''} onChangeProfilValues={onChangeProfilValues} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={CreateUser} color="primary" disabled={passwordError ? true : false} >
                                Créer
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            else if (value === 2) {
                return (
                    <Dialog open={openAddUser} onClose={closeDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Nouveau {roles[value].label}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Pour ajouter un nouvel utilisateur veuillez remplir TOUS les champs ci-dessous!
                                Le mot de passe par défaut est "ObjectifQuebec70".
                            </DialogContentText>
                            {/*<EntrepriseCreate role={value} profile={''} onChangeProfilValues={onChangeProfilValues} />*/}
                        </DialogContent>
                        {/*<DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={CreateUser} color="primary" disabled={passwordError ? true : false} >
                                Créer
                            </Button>
                        </DialogActions>*/}
                    </Dialog>
                )
            }
        }

    }

}

const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        //padding: theme.spacing(2),
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        //padding: 4,
        textAlign: 'center'
    },
    progress: {
        margin: theme.spacing(2),
    },
    list: {
        //width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        //position: 'relative',
        overflow: 'auto',
        maxHeight: '75vh',
        //maxHeight: '70%',
        paddingTop: 0,
        //maxHeight:900,
        overflowY: 'scroll'
    },
    listSection: {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    flexBackdrop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        "& >*": {
            margin: '10px'
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


/*Fonction LODASH de structuration des section depuis la liste FIREBASE
Fonction commentee = ajout index de tableau si besoin
*/
const fromArrayToSectionData = (data) => {
    //console.log("trie")
    //console.log(data)
    //console.log(data)
    //console.log(data.createdAt)
    //console.log(moment.unix(data.createdAt.seconds).format("DD/MM/YYYY"))
    let ds = _.groupBy(data, (d) => moment.unix(d.dateInscription.seconds).format("YYYYMMDD"));//moment.unix(d.dateInscription.seconds).format("DD-MM-YYYY")//moment.unix(d.createdAt.seconds).format("MM/DD/YYYY");d.createdAt.seconds
    //console.log('section avant ', ds)
    ds = _.reduce(
        ds,
        (acc, next, index) => {
            //console.log(index)
            /* let id = ''
             _.map(data, (material, indexs) => {
                 if (material.createdAt.seconds == index) {
                     id = indexs
                 }
             })*/
            acc.push({
                key: index,
                //id: id,
                data: next
            });
            return acc;
        },
        []
    );
    ds = _.orderBy(ds, ["key"], 'desc');
    //console.log(ds)
    return ds;
}


//class UsersList extends Component {
const UsersListEnt = ({ auth, loadMoreUsers, loadInitUsers, loadAdvancedSearchUsers, users, compteurs, monProfil }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [ExpandValue, setExpandValue] = React.useState(false)
    const [isFetching, setIsFetching] = useState(false);
    const [enteredFilter, setEnteredFilter] = useState("");
   // const [adminsUsers, setadminsUsers] = React.useState([]);
    //const [entreprisesUsers, setentreprisesUsers] = React.useState([]);
    const [candidatsUsers, setcandidatsUsers] = React.useState([]);
    const [openAddUser, setopenAddUser] = React.useState(false);
    const [newUser, setnewUser] = React.useState('');
    const [detailsViewOpen, setDetailsViewOpen] = React.useState(false);
    const [advancedSearch, setAdvancedSearch] = React.useState(false);
    /* const [newUser, setnewUser] = React.useState({
         email: '',
         password: '',
         passwordConf: '',
         firstName: '',
         name: '',
         fullName: '',
         role: value
     });*/
    const [showPassword, setshowPassword] = React.useState({
        showPasswordFirst: false,
        showPasswordConfirm: false,
    });
    const [passwordError, setpasswordError] = useState(false);
    const handleClickShowPassword = (value) => {
        //console.log(event.target.id)
        //console.log(event.target.value)
        setshowPassword({ ...showPassword, [value]: !showPassword[value] });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };


    const handleCreateUser = event => {
        let firstName = upperCaseName(newUser.profil.firstName);
        let name = upperCaseName(newUser.profil.name);
        let initials = getInitial(name, firstName);
        let fullName = getNomComplet(name, firstName);
        //console.log(event)
        createUserbyAdmin({
            ...newUser, profil: {
                ...newUser.profil,
                fullName: fullName,
                name: name,
                firstName: firstName,
                initials: initials
            }

        });
        handleCloseCreateUserDialog();
        //setAnchorEl(event.currentTarget);
        //createUser(newUser.email, newUser.password, newUser)
        //clickCreateAnnonce()
    };


    const onChangeProfilValues = (values) => {
        console.log(values)
        setnewUser(values)
        //setopenAddUser(true);
    };
    const handleOpenCreateUserDialog = () => {
        setopenAddUser(true);
    };

    const handleCloseCreateUserDialog = () => {
        setopenAddUser(false);
    };
    //loadInitUsers(2, 25);
    useEffect(() => {

        //loadInitUsers(0, 25);
        //loadInitUsers(1, 25);
        loadInitUsers(2, 850);
    }, []);

    useEffect(() => {
        //setadminsUsers(users.adminsUsers);
        //setentreprisesUsers(users.entreprisesUsers);
        setcandidatsUsers(users.candidatsUsers);

    }, [users]); // N’exécute l’effet que si users a changé

    useEffect(() => {
        if (!isFetching) return;
        //fetchMoreListItems();
    }, [isFetching]);
    useEffect(() => {
       
            let filteredData = users && users.candidatsUsers.filter(item => {
                return (
                    item.firstName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0 ||
                    item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0 ||
                    item.fullName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(enteredFilter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0
                )
            });
            setcandidatsUsers(filteredData)
            // fifth, we called out fetchData function inside useEffect Hook
    

    }, [enteredFilter]);
    //await loadInitUsers(2, 25);

    //useSelector(state => state.firestore.ordered.candidatsUsers)

    //const [name, setName] = useState('John Doe');
    //const [listItems, setListItems] = useState(Array.from(Array(30).keys(), n => n + 1));
    //console.log(name)
    //console.log(candidatsUsers)
    //console.log(candidatsUsers)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setEnteredFilter("");
        setExpandValue(false);
    };

    const onClickExpand = () => {
        setExpandValue(!ExpandValue);
    };

    /*useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
      function handleScroll() {
        console.log('test')
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        console.log('Fetch more list items!');
      }*/
    /*const handleScroll = (event) => {
        console.log('test')
        console.log('loadMore ' , event.target)
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        console.log('Fetch more list items!');
      }*/
    const onAdvancedSearch = (values) => {
        console.log(values)
        loadAdvancedSearchUsers(values)
        setAdvancedSearch(true)
    }
    const CancelSearch = () => {
        setExpandValue(false);
        setAdvancedSearch(false)
        //loadInitUsers(0, 25);
        //loadInitUsers(1, 25);
        loadInitUsers(2, 150);
    }
    const detailsView = () => {
        setDetailsViewOpen(!detailsViewOpen)
    }
    const loadMoreItems = (event) => {

        if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight) && !enteredFilter && !detailsViewOpen && !advancedSearch) {
            console.log('loadMore end')
            setIsFetching(true);

            // if (parseInt(event.currentTarget.dataset.role, 10) === 2) {
            loadMoreUsers(parseInt(event.currentTarget.dataset.role, 10), 25, candidatsUsers[candidatsUsers.length - 1].dateInscription);

            setTimeout(() => {
                candidatsUsersSection = fromArrayToSectionData(candidatsUsers)
                setIsFetching(false);
            }, 2000);
            //}
        }
    }
    //render() {
    //const { annonces, auth } = props;
    if (!auth.uid) return <Redirect to='/signin' />
    if (!isLoaded(candidatsUsers)) {
        //setcandidatsUserssss(candidatsUsers)

        return (
            <Grid container spacing={6}>
                <CircularProgress className={classes.progress} />
            </Grid>
        );
    }
    //loadInitUsers(2, 25);
    //const [candidatsUserssss, setcandidatsUserssss ] = useState(Array.from(candidatsUsers));
    /*let adminsUsersSection = fromArrayToSectionData(adminsUsers)
    let entreprisesUsersSection = fromArrayToSectionData(entreprisesUsers)*/
    let candidatsUsersSection = fromArrayToSectionData(candidatsUsers)
    //console.log(candidatsUsersSection)

    return (
        <div className={classes.root}>
            <Backdrop
                className={classes.backdrop}
                open={isFetching}
            /*onClick={() => {
                setIsFetching(false);
            }}*/
            >
                <div className={classes.flexBackdrop}>
                    <CircularProgress color="inherit" />
                    <Typography variant="caption" gutterBottom className={classes.title}>
                        Chargement des utilisateurs suivants...
                </Typography>
                </div>

            </Backdrop>

            <AppBar position="static">
                <Typography variant="h5" className={classes.title} style={{ padding: 10 }}>
                    LES CANDIDATS
            </Typography>
                <Divider />

                {/*<UsersListEntToolbar onClickExpand={onClickExpand} expand={ExpandValue} value={enteredFilter}
                    onChange={e => setEnteredFilter(e.target.value)} addClick={handleOpenCreateUserDialog} monProfil={monProfil} />*/}

                {/*<SearchAdvanced expanded={ExpandValue} role={value} AdvancedSearch={onAdvancedSearch} CancelSearch={CancelSearch} />*/}
            </AppBar>
            <Grid container spacing={1} justify="center" className={classes.contentBody} onScroll={loadMoreItems} data-role={2}>
                {
                    candidatsUsersSection.map((section, index) => {
                        //console.log(section)
                        return (

                            section.data.map((user, index) => {
                                //console.log(user)
                                console.log(user.profileCVCachete)
                                if (user.profileCVCachete) {
                                    return (
                                        <>
                                            <Grid item xs={4} md={3} xl={2} key={'candidats-' + index} className={classes.card}>

                                                <UsersListItemCache user={user} key={'candidats-' + user.id} detailsView={detailsView} />
                                            </Grid>
                                        </>
                                    )
                                }


                            })

                        )

                    })
                }
            </Grid>

            <Typography> {isFetching && 'Chargement des utilisateurs suivants...'}</Typography>
        </div>
    )


    //}
}

const mapStateToProps = (state, store) => {
    //console.log(state);
    //console.log(store);
    return {
        auth: state.firebase.auth,
        compteurs: state.firebase.data.compteurs,
        monProfil: state.firestore.data.monProfil,
        //adminsUsers: state.firestore.ordered.adminsUsers,
        //entreprisesUsers: state.firestore.ordered.entreprisesUsers,
        //candidatsUsers: state.firestore.ordered.candidatsUsers,
        //candidatsUsers: state.users.candidatsUsers,
        users: state.users
    }
}

const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    return {
        loadInitUsers: (role, limit) => dispatch(loadInitUsers(role, limit)),
        loadMoreUsers: (role, limit, startAfter) => dispatch(loadMoreUsers(role, limit, startAfter)),
        loadAdvancedSearchUsers: (searchFields) => dispatch(loadAdvancedSearchUsers(searchFields)),
        createUserbyAdmin: (data) => dispatch(createUserbyAdmin(data))
    }
}

export default compose(

    firebaseConnect((props, store) => {
        console.log(props)
        console.log(store)
        return [
            { path: 'compteurs' }, // make sure to pass "populates"
        ]
    }),
    firestoreConnect(props => [
        /* {
             collection: 'users',
             where: [['role', '==', 0]],
             orderBy: ['dateInscription', 'desc'],
             limit: 20,
             storeAs: 'adminsUsers'
         },
         {
             collection: 'users',
             where: [['role', '==', 1]],
             orderBy: ['dateInscription', 'desc'],
             limit: 20,
             storeAs: 'entreprisesUsers'
         },*/
        /* {
             collection: 'users',
             where: [['role', '==', 2]],
             orderBy: ['dateInscription', 'desc'],
             limit: 25,
             storeAs: 'candidatsUsers'
         },*/
    ]),
    connect(mapStateToProps, mapDispatchToProps),
)(UsersListEnt)