const initState = {
  annonces: [],
  annoncesActives: [],
  annoncesInactives: [],
}

const annonceReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_ANNONCE_SUCCESS':
      console.log('create annonce success');
      return state;
    case 'CREATE_ANNONCE_ERROR':
      console.log('create annonce error');
      return state;
    case 'UPDATE_ANNONCE_SUCCESS':
      console.log('update annonce success');
      return state;
    case 'UPDATE_ANNONCE_ERROR':
      console.log('update annonce error');
      return state;

    case 'LOAD_INIT_ANNONCES_SUCCESS':
      //console.log('load more ANNONCES success ', action);
      //console.log('load more ANNONCES success ', action.annonceType);
      //console.log('load more ANNONCES success ', action.statut);
      //console.log('load more annonce success ', action.payload.data());
      //return action.payload;
      //console.log('here')
      if (action.statut === 0) {
        //if (action.annonceType === "emploi") {
          return {
            ...state,
            annoncesInactives: [...action.payload]
          }
        //}

      };
      if (action.statut === 1) {
       // if (action.annonceType === "emploi") {
          return {
            ...state,
            annoncesActives: [...action.payload]
          }
        //}
      };

    case 'LOAD_INIT_ANNONCES_ERROR':
      console.log('load more ANNONCES error', action.err);
      return action.err;
    case 'LOAD_MORE_ANNONCE_SUCCESS':
      //console.log('load more annonce success ', action);
      //console.log('load more annonce success ', action.payload);
      //console.log('load more annonce success ', state.annonces);
      //console.log('load more annonce success ', action.payload.data());
      //return action.payload;
      if (action.statut === 0) {
        //if (action.annonceType === "emploi") {
          return {
            ...state,
            annoncesInactives: [...state.annoncesInactives, ...action.payload]
          }
        //}
      };
      if (action.statut === 1) {
        //if (action.annonceType === "emploi") {
          return {
            ...state,
            annoncesActives: [...state.annoncesActives, ...action.payload]
          }
       // }
      };
    case 'LOAD_MORE_ANNONCE_ERROR':
      console.log('load more annonce error', action.err);
      return action.err;
    default:
      return state;
  }
};

export default annonceReducer;