import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useFirebase, useFirestore } from "react-redux-firebase";
import { compose } from 'redux'
//import AnnonceSummaryCard from './AnnonceSummaryCard'
//import CreateAnnonce from './CreateAnnonce'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
/*DIALOG*/
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormTheme from './forms/formTheme';
import Button from '@material-ui/core/Button';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import 'moment/locale/fr'
import PostsItems from './postsItems';
import FormPost from './forms/formPost';
import LikesScreen from '../likes/Likes'
import CommentsScreen from '../comments/Comments'
import { getWPPosts, createWPPost, updateWPPost, deleteWPPost, getWPCategories } from '../../utils/wordpress/wordpressUtils'
import { deleteAtPath } from '../../utils/firebase/FirebaseUtils'
import axios from 'axios';
import WPAPI from 'wpapi';
var wp = new WPAPI({
    /*endpoint: 'https://shop.pierregagliardi.com/wp-json',
    // This assumes you are using basic auth, as described further below
    username: 'firebase',
    password: 'bgavJgqdqw#x)nZt$8XOSm8t'*/
    endpoint: 'https://objectifquebec.ca/wp-json',
    // This assumes you are using basic auth, as described further below
    username: 'editeurMobile',
    password: 'Oc2Hyp!v%jM5oGGq)qNqmvfu'
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
    root: {
        //padding: theme.spacing(2),
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        minHeight: '80vh'
    },
    roots: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    card: {
        // margin: 4,
        //minHeight: '300px'
        /* display: "flex",
         flexDirection: "column",
         justifyContent: "space-between"*/
    },
    title: {
        padding: 4,
        textAlign: 'center'
    },
    contentBody: {
        paddingTop: 8,
        maxWidth: '1800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: '75vh',
        overflowY: 'scroll'
    },
    indicator: {
        backgroundColor: 'white'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    activeInactiveAppBar: {
        backgroundColor: theme.palette.secondary.main,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    link: {
        textDecoration: 'none'
    },
    clearIcon: {
        cursor: 'pointer'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    flexBackdrop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        "& >*": {
            margin: '10px'
        }
    }
}));

const TourismesScreen = ({ auth, tourismesActives, tourismesInactives, monProfil, compteurs }) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [currentFeed, setCurrentFeed] = React.useState(null);
    const [postsFromTheme, setPostsFromTheme] = React.useState(null);
    const [values, setValues] = useState();
    const [openTheme, setOpenTheme] = React.useState(false);
    const [openPost, setOpenPost] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [deletePath, setDeletePath] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [openLikes, setOpenLikes] = React.useState(false);
    const [openComments, setOpenComments] = React.useState(false);
    const [commentsBasePath, setCommentsBasePath] = React.useState(null);
    const [likesBasePath, setLikesBasePath] = React.useState(null);

    const [wpCategory, setWpCategory] = React.useState(null)
    const _getWPCategories = async () => {
        let categories = await getWPCategories();
        //console.log(categories)
        let wpCatParent = categories.response.find(cat => cat.slug === 'tourisme');
        let currWpCatArray = [];
        if (wpCatParent) {
            //currWpCatArray.push()
            currWpCatArray = categories.response.filter(cat => cat.parent === wpCatParent.id)
            //.find(x => x.parent === wpCatParent.id);
            currWpCatArray.unshift(wpCatParent);
            //console.log(currWpCatArray);
            setWpCategory(currWpCatArray);
        }
        else {
            let wpCatUncategorized = categories.response.find(cat => cat.id === 1);
            setWpCategory(wpCatUncategorized);
        }
        //console.log(wpCatParent); 
    }
    useEffect(() => {

        _getWPCategories();
    }, []);
    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };

    useEffect(() => {
        //console.log('New Feed');
        //console.log(tourismesActives)
    }, [tourismesActives]);
    const handleListItemClick = async (tourismeId, event, index) => {
        if (tourismeId) {
            setSelectedIndex(tourismeId);
            let currFeed = tourismesActives.filter((tourisme) => {
                //console.log(tourisme)
                return tourisme.id == tourismeId;
            })
            //console.log(...test)
            setCurrentFeed(...currFeed);
            const docs = await firestore
                .collection("tourismes")
                .doc(tourismeId)
                .collection("posts")
                .orderBy('modified', 'desc')
                .get();
            let docconcat = []
            docs.forEach(doc => {
                //console.log(doc.id, '=>', doc.data());
                let docId = doc.id;
                let docData = doc.data();
                docconcat.push({ id: docId, ...docData })

            });
            //console.log(docconcat)
            setPostsFromTheme({ ...docconcat });
        }


    };

    const handleOpenTheme = () => {
        setOpenTheme(!openTheme);

        console.log('open Theme')
    }

    const handleOpenPost = () => {
        setOpenPost(!openPost);
        console.log('open post')
    }

    const handleOpenDelete = (path) => {
        setOpenDelete(!openDelete);
        setDeletePath(path);
        console.log('open Delete')
    }
    const handleEditModeTheme = (tourisme) => {
        setEditMode(tourisme);
        handleOpenTheme();
    }

    const handleEditModePost = (tourisme) => {
        setEditMode(tourisme);
        handleOpenPost();

    }

    const handleSubmitTheme = async (values) => {
        //e.preventDefault();
        console.log(values);
        if (values.backgroundImage) {
            if (values.image) {
                var previousImageRef = await firebase.storage().refFromURL(values.image)
                console.log(previousImageRef)
                await previousImageRef.delete()
                    //firebase.deleteFile(previousImageRef)
                    .then(() => {
                        console.log("image deletion success")
                    })
                    .catch((err) => {
                        console.log("error ", err)
                    })
            }
            const options = {
                name: (file) => `header-${file.name}`,
                progress: true
            }
            const filesPath = 'tourismes/' + values.docId;
            await firebase.uploadFiles(filesPath, values.backgroundImage, filesPath, options)
                .then((snap) => {
                    console.log('upload successful', snap)
                    console.log("test ", snap[0].downloadURL)
                    console.log('downloadUrl', snap[0].downloadURL)
                    delete values.backgroundImage;
                    firestore.collection('tourismes').doc(values.docId).set({
                        ...values,
                        userID: auth.uid,
                        created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                        //modified: firestore.FieldValue.serverTimestamp(),
                        order: tourismesActives.length + 1,
                        //nbPosts: 0,
                        image: snap[0].downloadURL
                    }, { merge: true }).then((doc) => {
                        console.log('sucess');
                        setOpenTheme(false);

                    });
                })
                .catch((err) => {
                    console.error('error uploading file', err)
                    //setProgress(false);
                });
        }
        else {
            firestore.collection('tourismes').doc(values.docId).set({
                ...values,
                userID: auth.uid,
                created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                //modified: firestore.FieldValue.serverTimestamp(),
                order: tourismesActives.length + 1
            }, { merge: true }).then((docRef) => {
                console.log(docRef)
                /* docRef.update(
                     {
                         docId: docRef.id
                     }
                 );*/
                setOpenTheme(false);
            }).catch((error) => {
                console.log(error)
            });
        }



    }

    /**
 * Upload the image to the specific firebase path
 * @param {String} uri Uri of the image
 * @param {String} name Name of the image
 * @param {String} firebasePath Firebase image path to store
 */
    const uploadImage = async (uri, name, firebasePath) => {
        const imageRef = firebase.storage().ref(`${firebasePath}/${name}`)
        await imageRef.putFile(uri, { contentType: 'image/jpg' }).catch((error) => { throw error })
        const url = await imageRef.getDownloadURL().catch((error) => { throw error });
        return url
    }



    const handleSubmitPost = async () => {
        //e.preventDefault();
        setOpenBackdrop(true);
        console.log(values);
        if (values.backgroundImage) {
            if (values.image) {
                var previousImageRef = await firebase.storage().refFromURL(values.image)
                if (values.imageThumbnail) {
                    var previousImageThumbnailRef = await firebase.storage().refFromURL(values.imageThumbnail)
                }

                //console.log(previousImageRef)
                await previousImageRef.delete()
                    //firebase.deleteFile(previousImageRef)
                    .then(async () => {
                        console.log("image deletion success")
                        await previousImageThumbnailRef.delete()
                            //firebase.deleteFile(previousImageRef)
                            .then(() => {
                                console.log(" all images deletion success")
                            })
                            .catch((err) => {
                                console.log("error ", err)
                            })
                    })
                    .catch((err) => {
                        console.log("error ", err)
                    })
            }
            /*const options = {
                name: (file) => `header-${file.name}`,
                progress: false
            }*/
            const filesPath = 'tourismes/' + selectedIndex + '/posts/' + values.docId;
            //uploadImage()
            const file = values.backgroundImage[0];
            console.log(file)
            console.log(values.backgroundImage)
            await firebase.storage().ref(filesPath).child(`${file.name}`)
                .put(file)
                .then((snap) => {
                    snap.ref.getDownloadURL()
                        .then((url) => {
                            console.log("url", url);
                            delete values.backgroundImage;
                            firestore.collection('tourismes').doc(selectedIndex).collection('posts').doc(values.docId).set({
                                ...values,
                                userID: auth.uid,
                                created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                                // modified: firestore.FieldValue.serverTimestamp(),
                                tourismeId: selectedIndex,
                                order: postsFromTheme.length + 1,
                                image: url
                            }, { merge: true }).then((doc) => {
                                console.log('sucess');
                                setOpenPost(false);
                                handleListItemClick(selectedIndex);
                            }).catch((err) => {
                                console.error('error saving document', err)
                                //setProgress(false);
                            });
                        })
                })
                .catch(err => {
                    console.log("err", err)
                });
            /* await firebase.uploadFile(filesPath, values.backgroundImage[0], null,  options)
                 .then((snap) => {
                     console.log(snap)
                     console.log(snap.downloadURL)
                     delete values.backgroundImage;
                     firestore.collection('tourismes').doc(selectedIndex).collection('posts').doc(values.docId).set({
                         ...values,
                         userID: auth.uid,
                         created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                         tourismeId: selectedIndex,
                         order: postsFromTheme.length + 1,
                         image: snap.downloadURL
                     }, { merge: true }).then((doc) => {
                         console.log('sucess');
                         setOpenPost(false);
                         handleListItemClick(selectedIndex);
                     });
                 })
                 .catch((err) => {
                     console.error('error uploading file', err)
                     //setProgress(false);
                 });*/
        }
        else {
            firestore.collection('tourismes').doc(selectedIndex).collection('posts').doc(values.docId).set({
                ...values,
                userID: auth.uid,
                tourismeId: selectedIndex,
                created: values.created ? values.created : firestore.FieldValue.serverTimestamp(),
                //modified: firestore.FieldValue.serverTimestamp(),
                order: postsFromTheme.length + 1
            }, { merge: true })
                .then((docRef) => {
                    /*docRef.update(
                        {
                            docID: docRef.id
                        }
                    )*/
                    setOpenPost(false);
                    handleListItemClick(selectedIndex);
                })
                .catch((err) => {
                    // Give your error the firebase.FirebaseError type and
                    // you'll have access to all the FirebaseError properties
                    console.log(`code`, err.code);
                    console.log(`message`, err.message);
                    console.log(`name`, err.name);
                    console.log(`stack`, err.stack);
                });
        }
        setOpenBackdrop(false);
    }
    const handleDelete = async () => {
        /*await deleteAtPath(path).then((result) => {
            console.log(selectedIndex)
            handleListItemClick(selectedIndex);
        }).catch((error) => {
            console.error(error);
        });*/
        setOpenBackdrop(true);
        if (deletePath) {
            try {
                let response = await deleteAtPath(deletePath);
                console.log(response)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    console.log(selectedIndex)
                    handleListItemClick(selectedIndex);
                    setOpenDelete(false)
                    setDeletePath(null)
                    setOpenPost(false)
                    setOpenTheme(false)
                }
            } catch (e) {
                console.log(e);
            }
        }
        setOpenBackdrop(false);

    }

    const cancelForm = () => {
        //alert('cancel')
        // onCancel()
        setOpenTheme(false);
        setOpenPost(false);
        setEditMode(false);
        setOpenDelete(false);
    }
    const updateValues = async (values) => {
        setValues(values)
    }

    const handleTogglePostLikes = (path) => {
        setOpenLikes(!openLikes)
        setLikesBasePath(path)
        /* if (path) {
             setLikesBasePath(path)
         }
         else {
             setLikesBasePath(null)
         }*/

    }
    const handleTogglePostComments = (path) => {
        setOpenComments(!openComments)
        setCommentsBasePath(path)
        /* if (path) {
             setCommentsBasePath(path)
         }
         else {
             setCommentsBasePath(null)
         }*/

    }

    function slugify(str) {
        var map = {
            '-': ' ',
            '-': '_',
            'a': 'á|à|ã|â|À|Á|Ã|Â',
            'e': 'é|è|ê|É|È|Ê',
            'i': 'í|ì|î|Í|Ì|Î',
            'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
            'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
            'c': 'ç|Ç',
            'n': 'ñ|Ñ'
        };

        for (var pattern in map) {
            str = str.replace(new RegExp(map[pattern], 'g'), pattern);
        };

        return str;
    };
    const _createWPPost = async (values) => {
        console.log('create WP Post')
        console.log(values)
        let myImageLink = values.image;
        setOpenBackdrop(true);
        //"https://firebasestorage.googleapis.com/v0/b/oq70-objectifquebec.appspot.com/o/feeds%2F0uB2ZHGsof8hCwsPnHu4%2Fposts%2F9AY38fdkSUl6VHT3UexF%2Fheader-5.png?alt=media&token=d8e9ea59-e5e9-465b-8abe-7ba20e4269d0";
        //await downloadFile(myImageLink, './myImage.jpg')
        // Uploading image and creating the item in the Wordpress media gallery
        axios({
            url: myImageLink, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then(async (response) => {
            console.log(response.data);
            console.log(new Blob([response.data]));
            const file = new File([response.data], `${slugify(values.title)}.png`);
            console.log(file)
            await wp.media()
                // Specify a path to the file you want to upload, or a Buffer
                .file(file)
                .create({
                    title: `${slugify(values.title)}`,
                    alt_text: `${slugify(values.title)}`,
                    caption: `${slugify(values.title)}`,
                    description: `${slugify(values.title)}`
                })
                .then(async (response) => {
                    // Your media is now uploaded: let's associate it with a post
                    console.log(response)
                    var newImageId = response.id;
                    var newPostId = null;
                    var newPostLink = null;
                    console.log(newImageId)
                    //let cats = currentFeed.wpCatId.split(',');
                    await wp.posts().create({
                        // "title" and "content" are the only required properties
                        featured_media: newImageId,
                        title: values.title,
                        content: values.description,
                        fields: {
                            docID: values.id,
                        },
                        comment_status: 'closed',
                        categories:  currentFeed.wpCatId,
                        status: 'publish'
                    }).then(async (response) => {
                        console.log('post')
                        console.log(response)
                        // "response" will hold all properties of your newly-created post,
                        // including the unique `id` the post was assigned on creation
                        console.log(response.id);
                        newPostId = response.id;
                        newPostLink = response.link;
                        await firestore.collection('tourismes').doc(selectedIndex).collection('posts').doc(values.docId).update({
                            wpDocId: newPostId,
                            wpPostLink: newPostLink
                        })
                    })
                    // wp.posts().id(33).update({
                    //    featured_media: newImageId
                    //}).then(function (response) {
                    //    console.log(response);
                    //})
                    console.log(newPostId)
                    return wp.media().id(newImageId).update({
                        post: newPostId//associatedPostId
                    });
                })
                .then(function (response) {
                    console.log(response)
                    console.log('Media ID #' + response.id);
                    console.log('is now associated with Post ID #' + response.post);
                });
            setOpenBackdrop(false);
            cancelForm();
        });

    }

    const _updateWPPost = async (values) => {
        console.log('update WP Post')
        setOpenBackdrop(true);
        //let cats = currentFeed.wpCatId.split(',');
        await wp.posts().id(values.wpDocId).update({
            // Update the title
            title: values.title,
            content: values.description,
            categories: currentFeed.wpCatId,
        }).then(function (response) {
            console.log(response);
            console.log('succes update WP POST')
            setSnackBarSeverity("success");
            setSnackbarMessage("Mise à jour réussie");
            setOpenBackdrop(false);
            setOpenSnackbar(true);
            //cancelForm();
        }).catch((error) => {
            console.log(error);
            console.log('error update WP POST')
            setSnackBarSeverity("error");
            setSnackbarMessage("Erreur de mise à jour.");
            setOpenBackdrop(false);
            setOpenSnackbar(true);
            //cancelForm();
        })
    }
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = React.useState(null);
    const [snackbarMessage, setSnackbarMessage] = React.useState(null);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };
    //console.log(tourismesActives)
    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <AppBar position="static">
                <Typography variant="h5" className={classes.title} style={{ padding: 10 }}>
                    Le Tourisme
            </Typography>
                <Divider />
            </AppBar>
            <Grid container spacing={2} justify="center" className={classes.contentBody}>
                <Grid item xs={12} md={4}  >
                    <AppBar position="static">
                        <Toolbar >
                            <Typography variant="h5" className={classes.title} style={{ padding: 10, flex: 1 }}>
                                Thèmes
                        </Typography>
                            {monProfil && monProfil.role === 0 &&
                                <Fab size="small" color="secondary" aria-label="add" className={classes.fabButton} onClick={handleOpenTheme} >
                                    <AddIcon />
                                </Fab>
                            }
                        </Toolbar>
                    </AppBar>
                    <List
                        style={{/*borderWidth: 1, borderColor: 'red',borderStyle: 'solid',*/ height: '60vh', backgroundColor: '#fafafa', overflow: 'auto' }}
                    >
                        {!isEmpty(tourismesActives) ? tourismesActives.map((tourisme, index) => {
                            return (
                                <div key={index}>
                                    <ListItem
                                        key={index}
                                        id={tourisme.id}
                                        button
                                        selected={selectedIndex === tourisme.id}
                                        onClick={(event) => handleListItemClick(tourisme.id, event, index)}

                                    /* style={{  
                                         backgroundImage: "url(" + tourisme.image + ")",
                                         backgroundPosition: 'center',
                                         backgroundSize: 'cover',
                                         backgroundRepeat: 'no-repeat'
                                       }}*/
                                    >
                                        {/*<ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={tourisme.image}  >
                                                <ImageIcon />
                                            </Avatar>
                                        </ListItemAvatar>*/}
                                        <ListItemText

                                            primary={<React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="h6"
                                                    style={{ width: '40%' }}
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {tourisme.title}
                                                </Typography>
                                            </React.Fragment>}
                                            secondary={<React.Fragment>
                                                <Typography
                                                    component="p"
                                                    variant="caption"
                                                    //style={{ lineHeight: 2.3 }}
                                                    //className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {`Crée le : ${tourisme.created && moment(tourisme.created.seconds, 'X').format('DD/MM/YYYY à HH:mm:ss')}`}
                                                </Typography>
                                                <Typography
                                                    component="p"
                                                    variant="caption"
                                                    //style={{ lineHeight: 2.3 }}
                                                    //className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {`Modifié le : ${tourisme.modified && moment(tourisme.modified.seconds, 'X').format('DD/MM/YYYY à HH:mm:ss')}`}
                                                </Typography>
                                            </React.Fragment>}
                                            disableTypography={true}
                                        // secondaryTypographyProps={{ style: {display: 'grid'}}}
                                        //{`Crée le : ${moment(tourisme.created.seconds, 'X').format('DD/MM/YYYY à HH:mm:ss')}`}

                                        />
                                        <ListItemText
                                            primary={<React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    style={{ lineHeight: 2.3 }}
                                                    //className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {`${tourisme.nbPosts ? tourisme.nbPosts : 0} article(s)`}
                                                </Typography>
                                            </React.Fragment>}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete" onClick={() => { handleEditModeTheme(tourisme) }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={() => { handleOpenDelete(`tourismes/${tourisme.id}`) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </div>
                            )
                        })
                            :
                            <Typography variant={'h6'} style={{ textAlign: 'center', width: '100%' }}>Aucun Thème. Veuillez en ajouter.</Typography>
                        }
                    </List>

                </Grid>
                <Grid item xs={12} md={8}  >
                    <AppBar position="static">
                        <Toolbar >

                            <Typography variant="h5" className={classes.title} style={{ padding: 10, flex: 1 }}>
                                Articles</Typography>
                            {monProfil && monProfil.role === 0 && postsFromTheme &&
                                <Fab size="small" color="secondary" aria-label="add" className={classes.fabButton} onClick={handleOpenPost} >
                                    <AddIcon />
                                </Fab>
                            }

                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={1} justify="flex-start" className={classes.contentBody}>
                        {!isEmpty(postsFromTheme) ? Object.values(postsFromTheme).map((post, index) => {
                            //console.log(post)

                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}  >
                                    <PostsItems post={post} index={index} currentFeed={currentFeed} tourismeId={selectedIndex} handleListItemClick={() => { handleListItemClick(selectedIndex) }} handleEditModePost={() => { handleEditModePost(post) }} handleOpenDelete={handleOpenDelete} handleViewPostLikes={() => { handleTogglePostLikes(`tourismes/${post.tourismeId}/posts/${post.docId}/`) }} handleViewPostComments={() => { handleTogglePostComments(`tourismes/${post.tourismeId}/posts/${post.docId}/`) }} />
                                </Grid>
                            )
                        })
                            :
                            !selectedIndex ?
                                <Typography variant={'h6'} style={{ textAlign: 'center', width: '100%' }}>Veuillez sélectionner un thème dans la colonne de gauche</Typography>
                                :
                                <Typography variant={'h6'} style={{ textAlign: 'center', width: '100%' }}>Aucun Article pour ce thème veuillez en créer un en cliquant sur +</Typography>

                        }
                    </Grid>
                </Grid>
            </Grid>

            <Dialog open={openTheme} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            //style={{maxWidth: '100%'}}
            //fullWidth={fullWidth}
            //minWidth={"lg"}
            >
                <DialogTitle id="form-dialog-title">Thème</DialogTitle>
                <DialogContent>
                    {/*} <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>*/}
                    <FormTheme
                        cancelForm={cancelForm}
                        cancelFormButtonName={"Annuler"}
                        saveForm={handleSubmitTheme}
                        saveFormButtonName={editMode ? "Mettre à jour" : "Créer le Thème"}
                        edit={editMode}
                        themeValues={editMode}
                        wpCategory={wpCategory}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={openPost} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'xl'}
                disableEnforceFocus
            //style={{maxWidth: '100%'}}
            //fullWidth={fullWidth}
            //minWidth={"lg"}
            >
                <DialogTitle id="form-dialog-title">Article</DialogTitle>
                <DialogContent>
                    {/*} <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>*/}
                    <FormPost
                        cancelForm={cancelForm}
                        cancelFormButtonName={"Annuler"}
                        saveForm={handleSubmitPost}
                        saveFormButtonName={editMode ? "Mettre à jour" : "Créer l'article"}
                        edit={editMode}
                        postValues={editMode}
                        tourismeId={selectedIndex}
                        updateValues={updateValues}
                        createWPPost={_createWPPost}
                        updateWPPost={_updateWPPost}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => cancelForm()}
                        //disabled={formNotValid.length > 0 ? true : false}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}>
                        ANNULER
                    </Button>
                    <Button
                        variant="contained"
                        color={"primary"}
                        onClick={() => handleSubmitPost()}
                        //disabled={formNotValid.length > 0 ? true : false}
                        style={{ display: 'block', margin: '0 auto', width: '30%' }}>
                        {editMode ? "Mettre à jour" : "Créer l'article"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*DIALOG SUPPRESSION */}
            <Dialog
                open={openDelete}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Voulez vous vraiment supprimer cet élement?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Toute suppression est définitive.
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelForm} color="primary">
                        Annuler
          </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Oui Supprimer
          </Button>
                </DialogActions>
            </Dialog>
            {/*DIALOG LIKES */}
            <Dialog
                open={openLikes}
                onClose={() => handleTogglePostLikes(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Voici tous les likes"}</DialogTitle>
                <DialogContent>
                    <LikesScreen likesBasePath={likesBasePath} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleTogglePostLikes(null)} color="primary">
                        Fermer
          </Button>
                </DialogActions>
            </Dialog>
            {/*DIALOG COMMENTAIRES */}
            <Dialog
                open={openComments ? true : false}
                onClose={() => handleTogglePostComments(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Voici les commentaires"}</DialogTitle>
                <DialogContent>

                    <CommentsScreen commentsBasePath={commentsBasePath} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleTogglePostComments(null)} color="primary">
                        Fermer
          </Button>

                </DialogActions>
            </Dialog>
        </div >


    )
}

const mapStateToProps = (state) => {
    //console.log(state);
    return {

        auth: state.firebase.auth,
        monProfil: state.firestore.data.monProfil,
        tourismesActives: state.firestore.ordered.tourismesActives,
        //tourismesInactives: state.firestore.ordered.tourismesInactives,
        compteurs: state.firebase.data.compteurs,
        /*annoncesActives: state.firestore.ordered.annoncesActives,
        annoncesInactives: state.firestore.ordered.annoncesInactives,*/
    }
}
const mapDispatchToProps = dispatch => {
    return {
        //deleteAnnonce: (annonceId) => dispatch(deleteAnnonce(annonceId)),
        //loadInitAnnonces: (limit, statut, entreprise, type) => dispatch(loadInitAnnonces(limit, statut, entreprise, type)),
        //loadMoreAnnonces: (limit, startAfter, statut, entreprise, type) => dispatch(loadMoreAnnonces(limit, startAfter, statut, entreprise, type))
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect((props, store) => {
        // console.log(props)
        // console.log(store)
        return [
            { path: 'compteurs' }, // make sure to pass "populates"
        ]
    }),
    firestoreConnect(props => [
        {
            collection: 'tourismes',
            //where: [['statut', '==', 1]],
            orderBy: ['modified', 'desc'],
            // limit: 25,
            storeAs: 'tourismesActives'
        },
        /*{
            collection: 'tourismes',
            //where: [['statut', '==', 0]],
            // orderBy: ['created', 'desc'],
            //limit: 25,
            storeAs: 'tourismesInactives'
        },*/
    ]),
)(TourismesScreen)