import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
const useStyles = makeStyles({
  title: {
    padding: 8
  },
});
const Notifications = ({userActivity}) => {
  const classes = useStyles();
  console.log(userActivity)
  return (
    <div>
      <Typography variant="h6" component="h2" className={classes.title}>
        Notifications
      </Typography>
      <Divider />
    </div>
  )
}

export default Notifications