import React, { Component } from 'react'
//import AnnonceList from '../annonces/AnnoncesList'

import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { useParams, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { AdminDetails, EntrepriseDetails, CandidatDetails } from '../users/components/RoleScreen/'


import moment from 'moment'
import { withStyles } from '@material-ui/styles'


/*const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8
    },
    progress: {
        margin: theme.spacing(2),
    },
}));*/

const styles = theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(4)
    },
    title: {
        padding: 8
    },
    progress: {
        margin: theme.spacing(2),
    },
  });



class UsersDetails extends Component {
//const UsersDetails = ({ auth, userProfile }) => {
    //const {classes} = this.props;//useStyles();
    constructor(props) {
        super(props)
    
        this.state = {
          //activeLike:'',
          //nblikes: ''
          selected: null,
          loadMore: this.props.loadMore
        };
      }
    
    //render() {
    // const {userid} = useParams()
    
    UsersDetailsFromRole = (userProfile, id, disabled) => {
        //console.log(userProfile)
        let fulluserProfile = {id, ...userProfile}
        console.log(id)
        console.log(fulluserProfile) 
        if (userProfile.role === 0) {
            return (
                <AdminDetails profile={fulluserProfile} disabled={disabled} />
            )
        }
        if (userProfile.role === 1) {
            return (
                <EntrepriseDetails profile={fulluserProfile} disabled={disabled} />
            )
        }
        if (userProfile.role === 2) {
            return (
                <CandidatDetails profile={fulluserProfile} disabled={disabled} />
            )
        }
    }
render() {
    const {classes, userProfile, disabled, userid} = this.props;
   console.log(userid)
    //const  userid  = this.props.match.params.userid;
    //console.log('user details', userProfile)
    //const { annonces, auth } = props;
    //if (!auth.uid) return <Redirect to='/signin' />
    //console.log(monProfil)

    if (!isLoaded(userProfile)) {
        return (
            <div className={classes.root}>
                <Grid container spacing={6} justify="center" alignItems="center">
                    <CircularProgress className={classes.progress} />
                </Grid>
            </div>
        );
    }
    return (
        <div className={classes.root}>
            {this.UsersDetailsFromRole(userProfile, userid, disabled)}
        </div>
    )
    //}
}
}

const mapStateToProps = (state) => {
    //console.log(state);
    return {
        auth: state.firebase.auth,
        userProfile: state.firestore.data.userProfile
    }
}


export default compose(
    withStyles(styles),
    connect(mapStateToProps),
    firebaseConnect(),
    /* firestoreConnect(props => [
         {
             collection: 'users',
             doc: 'Qrv2hgMvB0Z4oK8snnNrh5uk38V2',  //props.userid,
             //where: [['role', '==', 0]],
             storeAs: 'userProfile'
         }
     ]),*/
    firestoreConnect(props => {
        //console.log(props)
        //const {userid} = useParams()
        //console.log(props.match.params.userid)
        return [
            {
                collection: 'users',
                doc: props.userid,//props.match.params.userid,  props.userid,
                //where: [['role', '==', 0]],
                storeAs: 'userProfile'
            }
        ]
    }),
)(UsersDetails)