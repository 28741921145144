import React from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChatText from './ChatMessageList'

import _ from 'lodash'
import moment from 'moment'





const conversationsStyles = theme => ({
  root: {
    overflowY: 'scroll',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '8px',
      backgroundColor: '#FFF'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#025279' //'#23232F' //'#0595DD'
    },
  },
  subHeader: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    boxShadow: '0px 5px 15px black'
  },
  listitemtext: {
    paddingLeft: '10px',
    color: "white"
  },
  lastMessage: {
    color: "white"
  },
  date: {
    color: "white"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  flexBackdrop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    "& >*": {
      margin: '10px'
    }
  }
});

class ConversationsList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      //activeLike:'',
      //nblikes: ''
      selected: null,
      loadMore: this.props.loadMore,
      mesConversations: this.props.mesConversations,
      settings: this.props.settings,
      isFetching: this.props.isFetching
    };
  }

  componentDidUpdate(prevProps) {

    if (this.props.mesConversations !== prevProps.mesConversations) {
      console.log("new convs")
      this.setState({ mesConversations: this.props.mesConversations });
    }
    if (this.props.settings !== prevProps.settings) {
      console.log("new settings")
      this.setState({ settings: this.props.settings });
    }
    if (this.props.isFetching !== prevProps.isFetching) {
      console.log("new isFetching")
      this.setState({ isFetching: this.props.isFetching });
    }

  }

  render() {

    const { classes, mesConversations, convClick, settings, compteurs, isFetching } = this.props;
    console.log(mesConversations)
    console.log(settings)
    return (
      <List className={classes.root} onScroll={this.props.onScroll}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" className={classes.subHeader}>
            {compteurs && compteurs.chat.conversations} conversations
        </ListSubheader>
        }
      >
        <Backdrop
          className={classes.backdrop}
          open={this.state.isFetching}
        /*onClick={() => {
          setIsFetching(false);
        }}*/
        >
          <div className={classes.flexBackdrop}>
            <CircularProgress color="inherit" />
            <Typography variant="caption" gutterBottom className={classes.title}>
              Chargement des conversations suivantes...
            </Typography>
          </div>

        </Backdrop>
        {mesConversations && settings && mesConversations.map((item, index) => {

          //console.log(settings.groupes.administrateurs)
          let candidats = _.difference(item.members, settings.groupes.administrateurs);
          //this.props.setCandidat(candidats);
          let answered = false
          if (item.lastMessageSenderId != candidats) {
            answered = true
          }
          //const answered = item.lastMessageSenderId  === candidats //!== _.difference(item.lastMessageSenderId, settings.groupes.administrateurs);//this.props.auth.uid;
          //console.log(answered)
          return (
            <React.Fragment key={'conversation-' + item.id} >
              <ListItem key={index} dense button onClick={() => convClick(item)} key={'conversation-' + item.id} selected={this.props.selected === item.id} style={{ backgroundColor: answered ? this.props.selected === item.id ? 'rgb(166, 20, 62)' : 'rgb(0, 128, 0)' : this.props.selected === item.id ? 'rgb(166, 20, 62)' : 'rgb(231, 55, 74)' }}>
                {item.users[candidats] && item.users[candidats].profileImg != null ?
                  <Avatar alt='Remy Sharp' src={item.users[candidats].profileImg} />
                  :
                  <Avatar alt='Remy Sharp'>{item.users[candidats] && item.users[candidats].initials ? item.users[candidats].initials : ' '}</Avatar>
                }
                <ListItemText disableTypography className={classes.listitemtext} >
                  <Typography variant="body2" className={classes.lastMessage} style={{ body2: classes.lastMessage }} noWrap>
                    {item.lastMessageContent}
                  </Typography>
                  <Typography variant="body2" style={{ body2: classes.date }} noWrap>
                    {moment.unix(item.lastMessageCreatedAt.seconds).format("DD/MM/YYYY à HH:mm:ss")}
                  </Typography>
                </ListItemText>

                {/*<ListItemText primary={item.lastMessageContent} primaryTypographyProps={{noWrap:true, width:'200px'}} />
              <ListItemText secondary={moment.unix(item.lastMessageCreatedAt.seconds).format("DD/MM/YYYY à HH:mm:ss")} />*/}
              </ListItem>
              {index !== mesConversations.length - 1 && <Divider key={index} />}
            </React.Fragment>
          )
        })}
      </List>
    );
  }

}

ConversationsList = withStyles(conversationsStyles)(ConversationsList);

export default ConversationsList