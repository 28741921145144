import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import SignedInAvatar from './SignedInAvatar'
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { drawerWidthDefault } from '../../constants/default'

import DrawerMenu from './DrawerMenu'

const drawerWidth = drawerWidthDefault;

const useStyles = makeStyles(theme => ({
  //offset: theme.mixins.toolbar,

  root: {
    display: "flex",
    flexGrow: 1,
    height: '70px'
  },
  appBar: {
    backgroundImage: `url('/images/quebecHDLow.jpg')`,
    backgroundSize: "cover",
    backgroundPosition: '0 30%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer - 1,
    //top: 'auto',
    //top: 0,

  },
  bgOverlay: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.BgOverlayColor.backgroundColor,
    position: 'absolute'
  },
  /*menuButton: {
    marginRight: theme.spacing(2),
  },*/
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '75px',
    margin: 'auto',
    textAlign: 'center',
    //maxWidth: '50%',
    //maxHeight: '70%',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',

    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    //...theme.mixins.toolbar,
    minHeight: '75px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  /* content: {
     flexGrow: 1,
     padding: theme.spacing(3),
     transition: theme.transitions.create('margin', {
       easing: theme.transitions.easing.sharp,
       duration: theme.transitions.duration.leavingScreen,
     }),
     marginLeft: -drawerWidth,
   },
   contentShift: {
     transition: theme.transitions.create('margin', {
       easing: theme.transitions.easing.easeOut,
       duration: theme.transitions.duration.enteringScreen,
     }),
     marginLeft: 0,
   },*/
}));

//export default function MenuAppBar() {
const Navbar = (props) => {
  const { auth, profile } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(props.open);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]); // N’exécute l’effet que si count a changé
  const handleDrawerOpen = () => {
    console.log("open drawer")
    props.handleDrawerOpen();
    //setOpen(true);
  };

  const handleDrawerClose = () => {
    console.log("close drawer")
    props.handleDrawerClose();
    //setOpen(false);
  };
  //const [auth, setAuth] = React.useState(true);

  const links = auth.uid ? <SignedInLinks auth={auth} profile={profile} /> : null;//<SignedOutLinks />;
  if (!auth.uid) {
    handleDrawerClose();
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="sticky"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <div className={classes.bgOverlay} />
        <Toolbar>
          {auth.uid && <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          //onMouseOver={handleDrawerOpen}
          //onMouseEnter={handleDrawerOpen}
          //onMouseLeave={handleDrawerClose}
          >
            <MenuIcon />
          </IconButton>}
          <div className={classes.title}>
            <Link to={'/'}>
              <img src={'/images/objectif-quebec-logo.png'} className={classes.logo} alt="logo" />
            </Link>
          </div>
          {/*<Typography variant="h4" className={classes.title} component={NavLink} to={'/'} style={{ color: "#fff", textDecoration: 'none' }}>
            OQ 7.0
        </Typography>*/}
          {auth.uid && (
            <div>
              {links}
            </div>
          )}
        </Toolbar>
      </AppBar>
      {auth.uid &&
        <DrawerMenu profile={profile} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} />
      }
      {/* <Drawer
        variant="permanent"
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <Link to={'/'}>
            <img src={'/images/objectif-quebec-logo.png'} className={classes.logo} alt="logo" />
          </Link>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <SignedInAvatar profile={props.profile} />
        <Divider />

        <List >
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>*/}
      {/*<div className={classes.offset} />*/}
    </React.Fragment>
  );
}

/*const Navbar = (props) => {
  const { auth, profile } = props;
  // console.log(auth);
  const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;

  return (
    <nav className="nav-wrapper grey darken-3">
      <div className="container">
        <Link to='/' className="brand-logo">MarioPlan</Link>
        {links}
      </div>
    </nav>
  )
}*/

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(Navbar)