import React from 'react';
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import { withRouter } from "react-router";
import { compose } from 'redux'
import { loadMoreConversations, loadInitConversations, createMessage } from '../../store/actions/chatActions'
import { Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import SettingsContentList from './SettingsContentList'
import SettingsList from './SettingsList'

import { AdminDetails, EntrepriseDetails, CandidatDetails } from '../users/components/RoleScreen/'

import AnnonceDetailsTemplate from '../annonces/AnnonceDetailsTemplate'

/* -------------------------- */
const chatDialogStyles = theme => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})


class ProfileDialog extends React.Component {

    UsersDetailsFromRole = (userProfile) => {
        if (userProfile.role === 0) {
            return (
                <AdminDetails profile={userProfile} />
            )
        }
        if (userProfile.role === 1) {
            return (
                <EntrepriseDetails profile={userProfile} />
            )
        }
        if (userProfile.role === 2) {
            return (
                <CandidatDetails profile={userProfile} />
            )
        }
    }

    render() {
        const { classes } = this.props;
        //console.log(this.props.profileToShow)
        return (
            <Dialog onClose={this.props.closeDialog} aria-labelledby="simple-dialog-title" open={this.props.open}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <h2>Profil Utilisateur</h2>
                    <IconButton onClick={this.props.closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {this.props.profileToShow && this.UsersDetailsFromRole(this.props.profileToShow)}
            </Dialog>
        )
    }
}
ProfileDialog = withStyles(chatDialogStyles)(ProfileDialog);

class AnnonceDialog extends React.Component {


    render() {
        const { classes } = this.props;
        // console.log(this.props.annonceToShow)
        return (
            <Dialog onClose={this.props.closeDialog} aria-labelledby="simple-dialog-title" open={this.props.open}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <h2>Détails de l'annonce</h2>
                    <IconButton onClick={this.props.closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {this.props.annonceToShow && <AnnonceDetailsTemplate annonce={this.props.annonceToShow} editable={false} />}
            </Dialog>
        )
    }
}
AnnonceDialog = withStyles(chatDialogStyles)(AnnonceDialog);

const chatToolbarStyles = theme => ({
    chatToolbarRoot: {
        color: '#FFF',
        margin: '0 8px',
        minHeight: '32px',
        backgroundColor: '#0377ad', //'#0595DD', //'#23232F',
        borderRadius: '2px'
    },
    innerContent: {
        width: '100%',
    },
    chatTitle: {
        margin: '0 auto'
    },
    searchWrapper: {
        backgroundColor: '#025279',
        borderRadius: '3px',
        border: 'solid 1px #025279',
        position: 'absolute',
        right: 0,
        color: '#FFF',
        margin: '0 8px'
    },
    searchInput: {
        width: '180px',
        paddingLeft: '8px',
        transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&::placeholder': {
            color: '#FFF',
            opacity: 1
        },
        '&:focus': {
            width: '300px'
        }
    },
    searchIcon: {
        color: '#FFF',
        marginTop: '3px'
    }
});


class ChatToolbar extends React.Component {

    render() {

        const { classes, convName } = this.props;
        //console.log(convName)
        //console.log(this.state.selectedConv)
        return (
            <Toolbar disableGutters={true} className={classes.chatToolbarRoot}>
                {this.props.showDeleteButton && <IconButton onClick={this.props.deleteConversation} >
                    <DeleteIcon />
                </IconButton>}



                <Typography variant="body2" className={classes.chatTitle} color="inherit">
                    En conversation avec {convName}
                </Typography>
                <Input classes={{ root: classes.searchWrapper, input: classes.searchInput }} disableUnderline={true} placeholder={"Recherche"}
                    endAdornment={<SearchIcon className={classes.searchIcon} />}
                />
            </Toolbar>
        );
    }

}

ChatToolbar = withStyles(chatToolbarStyles)(ChatToolbar);


/* -------------------------- */


const styles = theme => ({
    root: {
        margin: '16px',
        height: 'calc(90vh - 32px)'
    },
    paper: {
        //height: '50%'
        //height: '100%'
    },
    gridContainer: {
        height: '100%'
    },
    gridItem: {
        height: '100%'
    },
    '@global': {
        'body': {
            fontFamily: '"Roboto"'
        }
    }
});

class SettingsTabList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            //activeLike:'',
            //nblikes: ''
            convToShow: null,
            showProfile: false,
            selected: null,
            showAnnonce: false,
            isFetching: false,
            settings: null,
            conversations: null
            //conversations: null
        };

    }

    componentDidMount = () => {
    }
    /*componentWillReceiveProps(nextProps) {
      //console.log(nextProps)
      // You don't have to do this check first, but it can help prevent an unneeded render
      if (nextProps.chat.conversations !== this.state.conversations) {
        this.setState({ conversations: nextProps.chat.conversations });
      }
      if (nextProps.chat.conversations !== this.state.conversations) {
        this.setState({ settings: nextProps.settings });
      }
    }*/
    componentDidUpdate(prevProps, prevState) {
        /* if (this.props.chat.conversations !== prevProps.chat.conversations) {
           console.log(this.props.chat.conversations)
           this.setState({ conversations: this.props.chat.conversations });
         }
         if (this.props.settings !== prevProps.settings && this.props.settings !== null) {
           console.log(this.props.settings)
           this.setState({
             settings: this.props.settings
           })
         }*/
    }

    handleCloseProfile = () => {
        this.setState({
            showProfile: !this.state.showProfile
        })
    };

    handleCloseAnnonceDetails = () => {
        this.setState({
            showAnnonce: !this.state.showAnnonce
        })
    };

    listClick = (index) => {
        console.log('conversation click', index)
        this.setState({
            selected: index,
            listToShow: this.props.Listes[index]
        })


    }

    openProfile = (profileID) => {
        console.log('PROFILE OPEN click', profileID)
        //console.log(profileID)
        this.props.firebase.firestore().collection('users')
            .doc(profileID).get().then((doc) => {
                console.log(doc.data())
                const docData = doc.data();
                const id = doc.id
                this.setState({
                    profileToShow: { id, ...docData }
                })
            })
        this.handleCloseProfile()
    }

    deleteConversation = () => {
        console.log('delete conversation')
        console.log(this.state.convToShowId)
        /*  this.props.firebase.firestore().collection("conversations").doc(this.state.convToShowId).delete().then(function() {
            console.log("Document successfully deleted!");
        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });*/
    }

    openAnnonceDetails = (annonceToShow) => {
        //console.log('Open Annonce Details')
        this.setState({
            annonceToShow: annonceToShow,
            disabled: true
        })
        this.handleCloseAnnonceDetails()
        /*  this.props.firebase.firestore().collection("conversations").doc(this.state.convToShowId).delete().then(function() {
            console.log("Document successfully deleted!");
        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });*/
    }

    loadMoreItems = (event) => {
        //console.log('loadMore ', document.documentElement.scrollTop)
        //console.log('loadMore ', event.target)
        if (event.target.offsetHeight + event.target.scrollTop === (event.target.scrollHeight /*- event.target.scrollTop*/)) {
            console.log('loadMore end')
            this.setState({
                isFetching: true
            });
            console.log(this.props.chat.conversations.length - 1)
            console.log(this.state.conversations.length - 1)
            //console.log(this.props.chat.conversations[this.props.chat.conversations.length - 1].lastMessageCreatedAt)
            this.props.loadMoreConversations(50, this.state.conversations[this.state.conversations.length - 1].lastMessageCreatedAt);
            //this.props.chat.conversations[this.props.chat.conversations.length - 1].lastMessageCreatedAt

            //setTimeout(() => {
            //adminsUsersSection = fromArrayToSectionData(adminsUsers)
            this.setState({
                isFetching: false
            });
            /*}, 2000);*/

        }
    }
    sendMessage = (message) => {
        let messageIndex = Object.keys(this.state.convToShow).length
        //this.props.sendMessage()
        this.props.firebase.firestore().collection('messages').doc(this.state.convToShowId).update({
            [messageIndex]: {
                message: message,
                sender: this.props.firebase.auth().currentUser.uid,
                sendername: this.props.profile ? this.props.profile.initials : this.props.navigation.state.params.profile.initials,
                senderfullName: this.props.profile ? this.props.profile.fullName : this.props.navigation.state.params.profile.fullName,
                created_at: this.props.firebase.firestore.Timestamp.now(),
            }
        })
        this.convClick(this.state.selectedConv)
    }

    deleteMessage = (index) => {
        let newMessagesConv = Object.values(this.state.convToShow)
        let arr = newMessagesConv.filter((item, idx) => idx !== index)
        //console.log(newMessagesConv)
        //console.log(arr)
        //console.log(this.state.convToShow)
        //console.log(this.state.convToShowId)
        //this.props.sendMessage()
        this.props.firebase.firestore().collection('messages').doc(this.state.convToShowId).set({
            ...arr
            /*[messageIndex]: {
                message: message,
                sender: this.props.firebase.auth().currentUser.uid,
                sendername: this.props.profile ? this.props.profile.initials : this.props.navigation.state.params.profile.initials,
                senderfullName: this.props.profile ? this.props.profile.fullName : this.props.navigation.state.params.profile.fullName,
                created_at: this.props.firebase.firestore.Timestamp.now(),
            }*/
        })
        //this.convClick(this.state.selectedConv)
    }


    render() {

        const { classes, chat, mesConversations, auth, profile, monProfile, settings, compteurs, constants, Listes } = this.props;
        //console.log(this.props);
        console.log(settings)
        //console.log(this.state.conversations)
        //console.log(auth)
        if (!auth.uid) return <Redirect to='/signin' />

        if (isEmpty(constants) && !isLoaded(constants) && isEmpty(compteurs) && !isLoaded(compteurs)) {
            //setcandidatsUserssss(candidatsUsers)
            console.log("empty")
            return (
                <>
                    <Paper className={classes.paper} elevation={2}>
                        <Grid container spacing={0} justify={"center"} className={classes.gridContainer}>
                            <Grid item md={2} className={classes.gridItem}>
                                <CircularProgress className={classes.progress} />
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            );
        }
        else {


            //let conversationsOrdered = _.orderBy(mesConversations, ['lastMessageCreatedAt'], ['desc']);//_.sortBy(this.props.data, o => o.lastMessageCreatedAt)
            //console.log(chat.conversations)
            return (
                <>
                    <Typography variant="h6" component="h6">
                            Paramètrage des listes d'options
                        </Typography>
                    <Paper className={classes.paper} elevation={3}>
                        
                        <Grid container spacing={0} justify={"center"} className={classes.gridContainer}>
                            <Hidden mdDown>
                                <Grid item xs={12} md={4} className={classes.gridItem}>
                                    <SettingsList constants={constants} Listes={Listes} settings={settings} compteurs={compteurs} listClick={this.listClick.bind(this)} auth={auth} selected={this.state.selected} />
                                    {this.state.isFetching &&
                                        <Grid container spacing={0} justify={"center"} className={classes.gridContainer}>
                                            <Grid item md={2} className={classes.gridItem}>
                                                <CircularProgress className={classes.progress} />
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={8} className={classes.gridItem}>
                                {this.state.convToShow &&
                                    <ChatToolbar showDeleteButton={this.state.convToShowId ? true : false} deleteConversation={this.deleteConversation} convName={this.state.selectedConv.lastMessageSenderName} />

                                }
                                <SettingsContentList listToShow={this.state.listToShow} openProfile={this.openProfile} openAnnonceDetails={this.openAnnonceDetails} sendMessage={this.sendMessage} deleteMessage={this.deleteMessage} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <ProfileDialog closeDialog={this.handleCloseProfile} open={this.state.showProfile} profileToShow={this.state.profileToShow} />
                    <AnnonceDialog closeDialog={this.handleCloseAnnonceDetails} open={this.state.showAnnonce} annonceToShow={this.state.annonceToShow} disabled={true} />
                    
                </>
            );
        }
    }

}

const mapStateToProps = (state, props) => ({

    profile: state.firebase.profile,
    auth: state.firebase.auth,
    //chat: state.chat,
    constants: state.firebase.ordered.constants,
    settings: state.firestore.data.settings,
    //mesConversations: state.firestore.ordered.mesConversations,
    compteurs: state.firebase.data.compteurs,

    //monProfil: state.firestore.data.monProfil
})
/*const mapStateToPropsss = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
  mesConversations: ordered.mesConversations,
});*/

const mapDispatchToProps = dispatch => {
    //console.log(dispatch)
    return {

    }
}

//export default withStyles(styles)(ChatRoom);
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect((props, store) => {
        //console.log(props)
        //console.log(store)
        return [
            { path: 'compteurs' }, // make sure to pass "populates"
            { path: 'constants' }, // make sure to pass "populates"
        ]
    }),
    firestoreConnect(() => ['settings']),

    withStyles(styles),
)(SettingsTabList)